import api from './axios'
import {User} from "../models/User.model"
import {Booking} from "../models/Booking.model"
import HistoryOverview from "../models/HistoryOverview.model"
import {Page} from "../models/Page"
import FileSaver from "file-saver"
import {Subscription} from "../models/Subscription.model";

/**
 * Handles all actions related to users.
 */
export default class AdminUserService {

    static convertToWebFormat(user?: User): User {
        if (!user) return {}

        return {
            ...user,
            createdAt: user.createdAt && new Date(user.createdAt),
            updatedAt: user.updatedAt && new Date(user.updatedAt)
        }
    }

    static getAll = async (page?: number, size?: number, organizationId?: number, search?: string): Promise<Page<User>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            organizationId ? `organizationId=${organizationId}` : '',
            search ? `search=${search}` : '',
        ].filter(x => !!x).join('&')

        const response = await api.get<Page<User>>(`/admin/user?${params}`)

        return {
            ...response.data,
            content: response.data.content?.map(AdminUserService.convertToWebFormat)
        }
    }

    static get = async (id: number) => {
        const response = await api.get(`/admin/user/${id}`)
        return AdminUserService.convertToWebFormat(response.data)
    }

    static update = async (user: User) => {
        const response = await api.put<User>(`/admin/user/${user.id}`, user)
        return AdminUserService.convertToWebFormat(response.data)
    }

    static delete = async (user?: User) => {
        const response = await api.delete<User>(`/admin/user/${user?.id}`)
        return AdminUserService.convertToWebFormat(response.data)
    }

    static getAllInvoicesForUser = async (user?: User): Promise<any[]> => {
        if (!user) return []

        const response = await api.get<Booking[]>(`/admin/user/${user?.id}/invoice`)
        return response.data
    }

    static getOverview = async (id: number) => {
        const response = await api.get<HistoryOverview>(`/admin/user/${id}/overview`)
        return response.data
    }

    static exportDataToXls = async (user?: User) => {
        const response = await api.get(`/admin/user/${user?.id}/exportToXLS`, {
            responseType: 'blob',
            timeout: 30000,
            // headers: {
            //     'Authorization': `Bearer ${token}`
            // }
        })

        await FileSaver.saveAs(response.data, 'export.xlsx')
    }

    static sendMessage = async (user: User, subject: string, message: string, sms: boolean, email: boolean) => {
        const response = await api.post<{ sent: boolean }>(`/admin/user/${user.id}/message`, {
            subject,
            message,
            sms,
            email
        })
        return response.data
    }

    static getSubscription = async (id: number): Promise<Subscription | undefined> => {
        try {
            const response = await api.get<Subscription>(`/admin/user/${id}/subscription`);
            return {
                ...response.data,
                createdAt: response?.data?.createdAt && new Date(response.data.createdAt),
                expiration: response?.data?.expiration && new Date(response.data.expiration),
            };
        } catch (e: any) {
            console.log(e)
        }
    }

}
