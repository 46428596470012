import {Button, Theme, withStyles} from "@material-ui/core";
import {red} from "@material-ui/core/colors";

const DangerButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);

export default DangerButton;