import React, {useEffect} from 'react';
import FlexView from "react-flexview";
import {Route, RouteComponentProps, Switch, withRouter} from "react-router";
import Landing from "./landing/Landing/Landing.component";
import classes from './App.module.scss';
import Header from "./header/Header/Header.component";
import {connect} from "react-redux";
import {initTokenSignin} from "../store/actions/auth.actions";
import Signup from "./auth/Signup/Signup.component";
import Signin from "./auth/Signin/Signin.component";
import LicenceUpload from "./auth/LicenceUpload/LicenceUpload.component";
import SelectSubscription from "./auth/SelectPackage/SelectPackage.component";
import Listing from "./listing/Listing/Listing.component";
import ForgotPassword from "./auth/ForgotPassword/ForgotPassword.component";
import FAQ from "./faq/FAQ/FAQ.component";
import Contact from "./contact/Contact/Contact.component";
import MyBookings from "./history/MyBookings/MyBookings.component";
import Profile from './Profile/Profile.component';
import Admin from "./admin/Admin.component";
import ToS from "./legal/ToS.component";
import Privacy from "./legal/Privacy.component";
import AuthorizePayment from "./auth/AuthorizePayment/AuthorizePayment/AuthorizePayment";
import Booking from '../components/booking/Booking/Booking.component';
import VerifyEmail from "./auth/VerifyEmail/VerifyEmail.component";
import DefaultProps from "../common/defaultProps.interface";
import DynamicScrollToTop from "../common/ui/DynamicScrollToTop";
import {bindActionCreators} from "redux";
import {useTranslation} from "react-i18next";
import CookieConsent, {Cookies, getCookieConsentValue} from "react-cookie-consent";
import {COLORS} from "../styles/theme";
import {initGA, trackCurrentPage} from "../services/googleAnalytics";

interface Props extends RouteComponentProps, DefaultProps {
    initTokenSignin: typeof initTokenSignin;
}

const App = (props: Props) => {

    const t = useTranslation().t

    useEffect(() => {
        props.initTokenSignin();
    }, [false]);

    const handleAcceptCookie = () => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
            trackCurrentPage()
        }
    }

    useEffect(() => {
        const isConsent = getCookieConsentValue('gcpCookiesConsent');
        console.log(isConsent)
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    return (
        <React.Fragment>
            <Header/>
            <DynamicScrollToTop/>
            <FlexView className={classes.mainContainer}>
                <Switch>
                    <Route path="/admin" component={Admin}/>
                    <Route path="/auth/signup" component={Signup}/>
                    <Route path="/auth/signin" component={Signin}/>
                    <Route path="/profile" component={Profile}/>
                    <Route path="/auth/forgot-password" component={ForgotPassword}/>
                    <Route path="/auth/license" component={LicenceUpload}/>
                    <Route path="/auth/verify" component={VerifyEmail}/>
                    <Route path="/auth/select-subscription" component={SelectSubscription}/>
                    <Route path="/find-car" component={Listing}/>
                    <Route path="/faq" component={FAQ}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/car/:modelMake/:carId" component={Booking}/>
                    <Route path="/my-bookings/:modelMake/:carId/:bookingId" component={Booking}/>
                    <Route path="/my-bookings" component={MyBookings}/>
                    <Route path="/terms-of-service" component={ToS}/>
                    <Route path="/authorizePayment/:invoiceId/:clientSecret/:paymentMethodId"
                           component={AuthorizePayment}/>
                    <Route path="/privacy-policy" component={Privacy}/>
                    <Route path="/" component={Landing}/>
                </Switch>
            </FlexView>

            <CookieConsent
                // location="bottom"
                cookieName="gcpCookiesConsent"
                style={{background: "#2B373B"}}
                buttonStyle={{backgroundColor: COLORS.PRIMARY, color: 'white', fontSize: "14px"}}
                declineButtonStyle={{backgroundColor: COLORS.SECONDARY, color: 'white', fontSize: "14px"}}
                expires={150}
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={() => {
                    //remove google analytics cookies
                    Cookies.remove("_ga");
                    Cookies.remove("_gat");
                    Cookies.remove("_gid");
                }}
                declineButtonText={t('I decline')}
                buttonText={t('Ok')}
            >
                {t('This website uses cookies')}
            </CookieConsent>

        </React.Fragment>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        language: state.auth.language,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({initTokenSignin}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
