import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import FlexView from "react-flexview";
import classes from './LicenceUpload.module.scss';
import clsx from "clsx";
import { Button, CircularProgress } from "@material-ui/core";
import Dropzone from "../../../common/ui/Dropzone/Dropzone.component";
import { connect } from "react-redux";
import { initUpdateInfo } from "../../../store/actions/auth.actions";
import GcpInput from "../../../common/ui/GcpInput";
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { isFormValid, requiredValidator, validateForm } from "../../../common/validators.util";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { bindActionCreators } from "redux";
import Footer from "../../../common/ui/Footer/Footer.component";
import { v4 as uuidv4 } from 'uuid';
import VehicleService from "../../../services/vehicle.service";
import { myFirebase } from "../../../services/firebase";
import { AuthClaims } from "../../../models/AuthClaims.model";
import { useTranslation } from "react-i18next";
import { trackCurrentPage } from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps {
    claims?: AuthClaims,

    updateInfoInProgress: boolean;
    initUpdateInfo: typeof initUpdateInfo;
}

type FormFields = 'address' | 'city' | 'country' | 'phone';

const LicenceUpload = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t

    const [form, setForm] = useState({
        address: {
            label: t('Address'),
            placeholder: 'ex. Valley Rd. 123',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        city: {
            label: t('City'),
            placeholder: 'eg. Stockholm',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        country: {
            label: t('Country'),
            placeholder: 'ex. Sweden',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        phone: {
            label: t('Phone'),
            placeholder: '',
            type: 'phone',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                ]
            },
            countryCode: 'se'
        }
    });

    const [licenceImage, setLicenceImage] = useState<File>();
    const [licenceUploadInProgress, setLicenceUploadInProgress] = useState(false);
    const [licenceDropzoneError, setLicenceDropzoneError] = useState(false);

    const uiDisabled = licenceUploadInProgress || props.updateInfoInProgress;

    // ***** Effects ***** //

    useEffect(() => {
        trackCurrentPage()
    }, [])

    const loadIpData = async () => {
        try {
            const result = await VehicleService.getUserLocationByIp();
            setForm({
                ...form,
                city: {
                    ...form.city,
                    value: form.city.value || result.cityName
                },
                country: {
                    ...form.country,
                    value: form.country.value || result.countryName
                },
                phone: {
                    ...form.phone,
                    // value: form.phone.value || result.countryCallingCode,
                    countryCode: result.countryCode && result.countryCode.toLowerCase() || 'se'
                },
            });
        } catch (e: any) {

        }
    };

    useEffect(() => {
        loadIpData();
    }, [loadIpData]);

    // ***** Handlers ***** //

    const pickPhotoHandler = (files: any) => {
        setLicenceImage(files[0]);
        setLicenceDropzoneError(false);
    };

    const remotePhotoHandler = () => setLicenceImage(undefined);

    const inputHandler = (key: FormFields, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
        if (typeof e === 'string') {
            const formField = {
                ...form[key],
                value: e,
                error: ''
            };

            setForm({
                ...form,
                [key]: formField
            });
        }
    };

    const uploadLicence = async () => {
        if (!licenceImage) return;

        const uploadRef = myFirebase.storage().ref(`${uuidv4()}-${licenceImage.name}`);
        await uploadRef.put(licenceImage);
        return await uploadRef.getDownloadURL();
    };

    const submitHandler = async () => {

        // Make sure all the fields are filled in and image is selected
        const validatedForm = validateForm(form);
        setForm(validatedForm);
        setLicenceDropzoneError(!licenceImage);

        const canProceed = isFormValid(validatedForm) && licenceImage;

        if (canProceed) {
            // First upload licence
            setLicenceUploadInProgress(true);
            const licenceUrl = await uploadLicence();
            setLicenceUploadInProgress(false);

            // Update profile
            const phoneNumberParsed = parsePhoneNumberFromString(form.phone?.value || '')?.format('E.164');
            await props.initUpdateInfo({
                id: props.claims?.user?.id,
                address: form.address.value,
                city: form.city.value,
                country: form.country.value,
                phone: phoneNumberParsed,
                licenseUrl: licenceUrl
            });

            // Next step...
            props.history.push('/auth/select-subscription');
        }
    };

    // ***** Render ***** //

    const renderDropzone = () => (
        <Dropzone
            className={clsx(classes.dropzone, licenceDropzoneError && classes.dropzoneError)}
            onFilesAdded={pickPhotoHandler}
        />
    );

    const renderLicence = () => (
        <FlexView column className={classes.licencePreview} vAlignContent='center' hAlignContent='center'>

            <img className={clsx('container', classes.licencePhoto)}
                 src={URL.createObjectURL(licenceImage!)}/>

            <Button
                className={classes.removePhoto}
                color="primary"
                onClick={remotePhotoHandler}
                disabled={uiDisabled}
            >
                {t('Remove')}
            </Button>
        </FlexView>
    );

    const renderForm = () => {
        const keys: FormFields[] = ['address', 'city', 'country'];
        const phoneElement = form['phone'];

        return (
            <FlexView column className={classes.form}>

                {
                    keys.map((key: FormFields) => {
                        const formElement = form[key];
                        return (
                            <GcpInput
                                error={!!formElement.validation.error}
                                key={key}
                                className={classes.licenceNumber}
                                label={formElement.label}
                                placeholder={formElement.placeholder}
                                value={formElement.value}
                                disabled={uiDisabled}
                                helperText={formElement.validation.error}
                                onChange={(event) => inputHandler(key, event.target.value)}
                            />
                        )
                    })
                }

                <FlexView className={classes.phoneNumber}>
                    <MaterialUiPhoneNumber
                        error={!!phoneElement.validation.error}
                        margin="normal"
                        defaultCountry={phoneElement && phoneElement.countryCode || 'se'}
                        value={phoneElement.value}
                        disabled={uiDisabled}
                        fullWidth
                        helperText={phoneElement.validation.error}
                        onChange={(value) => inputHandler('phone', value)}/>
                </FlexView>

            </FlexView>
        )
    };

    return (
        <FlexView column className='component' vAlignContent='top' hAlignContent='center'>

            <div className={classes.title}>{t('Upload photo of your driving licence')}</div>
            <div className={classes.subtitle}>{t('We delete the photo immediately after verification.')}</div>

            <FlexView
                className={classes.licenceImageHolder}
                hAlignContent='center'
                vAlignContent='center'
            >

                {
                    licenceImage ? renderLicence() : renderDropzone()
                }

                {
                    licenceUploadInProgress &&
                    <FlexView
                        className={classes.imageUploadProgressHolder}
                        hAlignContent='center'
                        vAlignContent='center'
                    >
                        <CircularProgress/>
                    </FlexView>
                }

            </FlexView>

            <div className={classes.title}>{t('And tell us something about yourself')}</div>

            {renderForm()}

            <div className={classes.actionButton}>
                {
                    uiDisabled ?
                        <CircularProgress/>
                        :
                        <Button variant="contained" color="primary" onClick={submitHandler}>{t('Submit')}</Button>
                }
            </div>

            <Footer/>

        </FlexView>
    )
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        updateInfoInProgress: state.auth.updateInfoInProgress
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({initUpdateInfo}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenceUpload);
