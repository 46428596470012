import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './Booking.module.scss';
import {Button, CircularProgress} from "@material-ui/core";
import {isAfter, isBefore, subMinutes} from 'date-fns';
import {connect} from "react-redux";
import {setBookingTime, setCarId,} from "../../../store/actions/booking.actions";
import {RouteComponentProps} from "react-router";

import clsx from 'clsx';
import AlertDialog from "../../../common/ui/AlertDialog.component";
import PaymentDialog from "../PaymentDialog/PaymentDialog.component";
import {setSuccessSignRedirect} from "../../../store/actions/auth.actions";
import {bindActionCreators} from "redux";
import CarDetails from "../CarDetails/CarDetails.component";
import {BookingState} from "../../../store/reducers/booking.reducer";
import Footer from "../../../common/ui/Footer/Footer.component";
import OutstandingPaymentsDialog from "../OutstandingPaymentsDialog/OutstandingPaymentsDialog.component";
import VehicleService from "../../../services/vehicle.service";
import BookingService from "../../../services/booking.service";
import {Vehicle} from "../../../models/Vehicle.model";
import {Booking, BookingStatus} from "../../../models/Booking.model";
import {Maintenance} from "../../../models/Maintenance.model";
import Summary from "../Summary/Summary.component";
import SessionActions from "../SessionActions/SessionActions.component";
import {
    multiplePeriodsOverlap,
    calculateBookingPrice,
    calculateBookingPriceFormattedWeb, convertPeriodicAvailabilityAsMaintenance,
    formatDateFull
} from "../../../common/booking.util";
import BookingDoneDialog from "../ConfirmBookingDialog/BookingDoneDialog.component";
import CarHeader from "../CarHeader/CarHeader.component";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {isUserInfoFull} from "../../../common/user.util";
import BillingService from "../../../services/billing.service";
import GcpError from "../../../models/error.enum";
import LoggedInAs from "../../listing/LoggedInAs/LoggedInAs.component";
import AuthServiceNew from "../../../services/auth.service";
import BookingTimepickers from "./BookingTimepickers.component";
import {GroupVisibilityLevel} from "../../../models/Group.model";
import {PeriodicAvailability} from "../../../models/PeriodicAvailability.model";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Params {
    bookingId: string;
    carId: string;
}

interface Props extends RouteComponentProps<Params>, BookingState {
    claims?: AuthClaims;
    setBookingTime: typeof setBookingTime,
    setCarId: typeof setCarId,
    // initUpdatePhoneAndSendConfirmation: typeof initUpdatePhoneAndSendConfirmation,
    setSuccessSignRedirect: typeof setSuccessSignRedirect,
}

const BookingComponent = (props: Props) => {

    const t = useTranslation().t
    const [alertDialogVisible, setAlertDialogVisible] = useState(false);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [checkingPayments, setCheckingPayments] = useState(false);
    const [outstandingPaymentsDialogVisible, setOutstandingPaymentsDialogVisible] = useState(false);
    const [bookingFailedOpen, setBookingFailedOpen] = useState(false)
    // const [overview, setOverview] = useState<HistoryOverview>();

    const [liveBooking, setLiveBooking] = useState<Booking>();
    const [liveBookingLoading, setLiveBookingLoading] = useState(false);
    // @ts-ignore
    const [liveBookingLoadingError, setLiveBookingLoadingError] = useState(GcpError.NONE);

    const [vehicle, setVehicle] = useState<Vehicle>();
    const [carLoading] = useState(false);

    const [existingBookings, setExistingBookings] = useState<Booking[]>([]);

    const [maintenancePeriods, setMaintenancePeriods] = useState<Maintenance[]>([]);
    // @ts-ignore
    const [maintenanceLoading, setMaintenancePeriodsLoading] = useState(false);

    const [cancelInProgress, setCancelInProgress] = useState(false);
    const [bookingInProgress, setBookingInProgress] = useState(false);
    const [bookingDone, setBookingDone] = useState(false);

    const [editing, setEditing] = useState(false);

    const [verificationEmailStatus, setVerificationEmailStatus] = useState('none');

    const isNewBooking = props.match.path.startsWith('/car');
    const carId = +(props.match.params.carId || 0);
    const bookingId = +(props.match.params.bookingId || 0);
    const params = new URLSearchParams(props.location.search);

    const user = props.claims?.user;
    const isSuspended = user?.suspended;
    const isVerified = user?.verified;
    const [tick, setTick] = useState<any>(0);
    const [loadedOnce, setLoadedOnce] = useState(false);

    const isEmailVerified = props.claims?.user?.emailVerified || !user?.createdAt || isBefore(user.createdAt, new Date(2020, 9, 19, 20, 0, 0));

    let periodicAvailability: PeriodicAvailability[] = []
    if (vehicle?.group?.visibilityLevel === GroupVisibilityLevel.PUBLIC) {
        if (!vehicle.group?.organizations?.find(o => o.id === user?.organization?.id)) {
            periodicAvailability = vehicle?.group?.periodicAvailability || []
        }
    }

    // ***** Effects ***** //

    const fetchVehicleInfo = async () => {
        const vehicle = await VehicleService.getVehicle(carId);
        setVehicle(vehicle);

        setMaintenancePeriodsLoading(true);
        const unavailablePeriods = await VehicleService.getUnavailablePeriods(carId, editing ? bookingId : undefined);
        setMaintenancePeriods(unavailablePeriods || []);
        setMaintenancePeriodsLoading(false);
    }

    const fetchExistingBooking = async (forceLoader?: boolean) => {
        forceLoader && setLiveBookingLoading(true);
        setLiveBookingLoadingError(GcpError.NONE);
        const booking = await BookingService.getOne(+bookingId);
        if (booking) {
            setLiveBooking(booking);
            // setExistingBookings([booking]);
        }
        forceLoader && setLiveBookingLoading(false);
        setLiveBookingLoadingError(GcpError.FAIL);
    }

    useEffect(() => {
        fetchVehicleInfo();

        if (isNewBooking) {
            if (!isSuspended && isVerified && !isEmailVerified && params.get('action') === 'completeIfProfileFull') {
                handleOnMakeBooking();
            }
        } else {
            // fetchExistingBooking();
        }

    }, [editing]);

    useEffect(() => {
        if (!isNewBooking) {
            setLiveBookingLoading(true);
        }
    }, [])

    useEffect(() => {
        trackCurrentPage()
    }, [])

    useEffect(() => {
        if (!isNewBooking && props.claims?.user) {
            fetchExistingBooking(!loadedOnce);
            setLoadedOnce(true);
            setTimeout(() => setTick(tick + 1), liveBooking?.status === BookingStatus.ACTIVE ? 2000 : 10000);
        }
    }, [tick, props.claims]);

    const getActiveBooking = (): Booking | undefined => {
        if (isNewBooking && !bookingDone) {
            if (props.start && props.end) {
                return {
                    from: props.start,
                    to: props.end,
                    vehicle: vehicle
                }
            } else {
                return undefined;
            }
        } else {
            return liveBooking;
        }
    };

    const getEditingBooking = (): Booking | undefined => {
        if (props.start && props.end) {
            return {
                from: props.start,
                to: props.end,
                vehicle: vehicle
            }
        } else {
            return undefined;
        }
    };

    const booking = getActiveBooking();

    const checkIfCanCompleteBookingAndFinish = async () => {
        // if (overview && overview.totalUnpaidInvoices && overview.totalUnpaidInvoices >= 2) {
        //     setOutstandingPaymentsDialogVisible(true);
        // } else {
        setBookingInProgress(true);

        const bookingToCreate: Booking = {
            vehicle: vehicle,
            from: props.start,
            to: props.end,
            user: user
        }

        try {
            const booking = await BookingService.create(bookingToCreate);
            setLiveBooking(booking);
            setBookingDone(true);
        } catch (e: any) {
            console.log(e)
            setBookingFailedOpen(true)
        } finally {
            setBookingInProgress(false);
        }
        // }
    };

    const handleOnMakeBooking = async () => {
        if (isUserInfoFull(user)) {
            try {
                setCheckingPayments(true);

                let paymentMethods = await BillingService.loadPaymentMethods();
                if (paymentMethods?.length) {
                    setCheckingPayments(false);
                    checkIfCanCompleteBookingAndFinish();
                } else {
                    setPaymentDialogOpen(true);
                    setCheckingPayments(false);
                }
            } catch (e: any) {
                setPaymentDialogOpen(true);
                setCheckingPayments(false);
            }
        } else {
            props.setSuccessSignRedirect(`${props.match.url}?action=completeIfProfileFull`);
            props.history.push('/auth/signup')
        }
    };

    const handleConfirmEdit = async () => {
        if (!booking) return;

        setBookingInProgress(true);
        const updatedBooking = await BookingService.updateBooking(booking, props.start, props.end);
        setBookingDone(true);
        setEditing(false);
        setLiveBooking(updatedBooking);
        setBookingInProgress(false);
    };

    const handleFinishBooking = () => {
        setPaymentDialogOpen(false);

        if (user) {
            checkIfCanCompleteBookingAndFinish();
        }
    };

    const handleCancelBooking = () => {
        setAlertDialogVisible(true);
    };

    const handleBookingDoneDialogClose = (phone?: string) => {
        props.setBookingTime(undefined, undefined)
        if (phone) {
            // TODO
            // props.initUpdatePhoneAndSendConfirmation(user, phone, booking.id);
        }
        props.history.push('/my-bookings');
    };

    const isNewBookingEnabled = () => {
        const newBooking = editing ? getEditingBooking() : getActiveBooking();

        const periodicAvailabilityToMaintenance = convertPeriodicAvailabilityAsMaintenance(newBooking?.from, newBooking?.to, 21, periodicAvailability)

        return newBooking &&
            props.start && props.end &&
            !multiplePeriodsOverlap(existingBookings, newBooking) &&
            !multiplePeriodsOverlap(maintenancePeriods, newBooking) &&
            !multiplePeriodsOverlap(periodicAvailabilityToMaintenance, newBooking) &&
            isAfter(props.start, new Date());
    };

    // const renderTotalPriceDesktop = () => {
    //     if (!isPhone() && (props.start && props.end || !isNewBooking)) {
    //
    //         return (
    //             <FlexView vAlignContent='center' className={classes.totalCost}>
    //                 <div className={classes.totalCounterLabel}>{t('Total price')}</div>
    //                 <div
    //                     className={classes.totalCostValue}>{getTotalPrice()} kr
    //                 </div>
    //             </FlexView>
    //         )
    //     }
    // };

    const renderTotalPrice = () => {
        if ((props.start && props.end || !isNewBooking)) {
            if (!booking) return;

            let priceContent;
            if (booking.status === 'sessionEnded') {
                if (booking.totalPrice) {
                    priceContent = (
                        <React.Fragment>
                            <span className={classes.totalCounterLabel}>Total</span>
                            {booking.totalPrice.toFixed(2)}
                        </React.Fragment>
                    )
                } else {
                    priceContent = t('Free')
                }
            } else {
                if (calculateBookingPrice(booking, vehicle)) {
                    priceContent = (
                        <React.Fragment>
                            <span className={classes.totalCounterLabel}>Total</span>
                            {calculateBookingPriceFormattedWeb(booking, booking.vehicle, user, props.claims?.subscription)}
                        </React.Fragment>
                    )
                } else {
                    priceContent = t('Free')
                }
            }

            return (
                <FlexView vAlignContent='center' className={classes.totalCost}>
                    {priceContent}
                </FlexView>
            )
        }
    };

    const renderBookingActions = () => {
        if (user && (isSuspended || !isVerified || !isEmailVerified)) {
            let content;
            if (isSuspended) {
                content = (
                    <div className={classes.suspendedMessage}>
                        {t('You are suspended')}
                    </div>
                );
            } else if (!isEmailVerified) {
                let bottomValue;
                switch (verificationEmailStatus) {
                    case 'none':
                        bottomValue = (
                            <Button
                                color='primary'
                                variant='outlined'
                                onClick={async () => {
                                    setVerificationEmailStatus('sending');
                                    await AuthServiceNew.resendVerificationEmail(props.location.pathname);
                                    setVerificationEmailStatus('sent');
                                }}
                            >
                                {t('Resend verification email')}
                            </Button>
                        )
                        break;
                    case 'sending':
                        bottomValue = (
                            <CircularProgress/>
                        )
                        break;
                    case 'sent':
                        bottomValue = (
                            <div>{t('Verification email sent')}</div>
                        )
                }

                content = (
                    <FlexView column hAlignContent={'center'}>
                        <div className={classes.suspendedMessage}>
                            {t('Verify email')}
                        </div>

                        {bottomValue}

                    </FlexView>
                );
            } else {
                content = (
                    <div className={classes.suspendedMessage}>
                        {t('Awaiting verification')}
                    </div>
                );
            }

            return (
                <FlexView hAlignContent='center' className={classes.bookActions}>
                    {content}
                </FlexView>
            )
        }

        if (isNewBooking) {
            if (bookingInProgress || checkingPayments) {
                return (
                    <FlexView hAlignContent='center' className={classes.bookActions}>
                        <CircularProgress className={classes.bookSpinner}/>
                    </FlexView>
                )
            } else {
                return (
                    <FlexView hAlignContent='center' className={classes.bookActions}>
                        <Button variant="contained" color="primary" className={classes.book}
                                disabled={!isNewBookingEnabled()}
                                onClick={handleOnMakeBooking}
                        >
                            {user ? t('Make booking') : t('Make booking (Must sign in)')}
                        </Button>
                    </FlexView>
                )
            }
        } else {
            if (cancelInProgress || bookingInProgress) {
                return (
                    <FlexView hAlignContent='center' className={classes.bookActions}>
                        <CircularProgress className={classes.bookSpinner}/>
                    </FlexView>
                )
            } else if (booking && booking.status === 'active' && booking.from &&
                (isBefore(new Date(), subMinutes(booking.from, 5)) || booking.previousBookingOvertimeIncident)) {

                if (editing) {
                    return (
                        <FlexView hAlignContent='center' className={classes.bookActions}>

                            <Button variant="contained" color="secondary" className={classes.book}
                                    onClick={() => setEditing(false)}>
                                {t('Cancel')}
                            </Button>

                            <Button variant="contained" color="primary" className={classes.book}
                                    disabled={!isNewBookingEnabled()}
                                    onClick={handleConfirmEdit}>
                                {t('Confirm edit')}
                            </Button>

                        </FlexView>
                    )
                } else {
                    return (
                        <FlexView hAlignContent='center' className={classes.bookActions}>

                            <Button variant="contained" color="secondary" className={classes.book}
                                    onClick={() => {
                                        props.setBookingTime(getActiveBooking()?.from, getActiveBooking()?.to);
                                        setEditing(true);
                                    }}>
                                {t('Change booking')}
                            </Button>

                            <Button variant="contained" color="secondary" className={classes.book}
                                    onClick={handleCancelBooking}>
                                {t('Cancel booking')}
                            </Button>

                        </FlexView>
                    )
                }
            }
        }
    };

    const renderSessionSection = () => {
        if (!booking || isNewBooking) return null;

        if (booking.status === BookingStatus.SESSION_ENDED) {
            return (
                <Summary booking={booking} vehicle={vehicle}/>
            )
        }

        if (booking.status === 'active' && booking.from && isAfter(new Date(), subMinutes(booking.from, 5))) {
            if (booking.previousBookingOvertimeIncident) {
                return (
                    <React.Fragment>
                        <SessionActions activeBooking={booking} isNewBooking={isNewBooking} claims={props.claims}
                                        onSessionEnded={() => fetchExistingBooking(true)}/>
                        {
                            cancelInProgress ? <CircularProgress className={classes.bookSpinner}/> :
                                <Button variant="contained" color="secondary" className={classes.book}
                                        onClick={handleCancelBooking}>
                                    {t('Cancel booking')}
                                </Button>
                        }

                    </React.Fragment>
                )
            } else {
                return (
                    <SessionActions
                        activeBooking={booking}
                        isNewBooking={isNewBooking}
                        claims={props.claims}
                        onSessionEnded={fetchExistingBooking}
                    />
                )
            }
        }

        return null;
    };

    const getDateFrom = () => {
        if (isNewBooking || editing) {
            return props.start ? formatDateFull(props.start) : t('Start time')
        } else {
            return booking && booking.from && formatDateFull(booking.from);
        }
    };

    const getDateTo = () => {
        if (isNewBooking || editing) {
            return props.end ? formatDateFull(props.end) : t('End time')
        } else {
            return booking && booking.to && formatDateFull(booking.to);
        }
    };

    const timePickers = (
        <BookingTimepickers
            hoursLimit={48}
            booking={booking}
            editingBooking={getEditingBooking()}
            start={props.start}
            end={props.end}
            dateFrom={getDateFrom()}
            dateTo={getDateTo()}
            editing={editing}
            isNewBooking={isNewBooking}
            setBookingTime={props.setBookingTime}
            otherBookings={existingBookings || []}
            maintenance={maintenancePeriods || []}
            showTimeline={!booking || booking.status !== 'active' || editing}
            periodicAvailability={periodicAvailability}
        />
    )

    const renderBookingDetails = () => {
        if (booking && booking.status === 'sessionEnded') {
            return null;
        }

        if (booking && booking.status === 'active' && booking.from && isAfter(new Date(), subMinutes(booking.from, 5))) {
            return null;
        }

        return (
            <React.Fragment>
                <FlexView vAlignContent='center' hAlignContent='center'
                          className={clsx(classes.sectionTitle, classes.bookingSectionTitle, classes.totalPriceRow)}>
                    <div>{t('Book The Vehicle')}</div>
                    {renderTotalPrice()}
                </FlexView>

                {timePickers}

                {renderBookingActions()}

                <div className={classes.separator}/>
                <CarDetails car={vehicle}/>

            </React.Fragment>
        )
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>

                <AlertDialog
                    open={alertDialogVisible}
                    onClose={() => setAlertDialogVisible(false)}
                    onConfirm={async () => {
                        setAlertDialogVisible(false);
                        setCancelInProgress(true);
                        await BookingService.cancelBooking(+bookingId);
                        setCancelInProgress(false);
                        props.history.push('/my-bookings')
                    }}
                    title={t('Cancel booking?')}
                    message={t('This will cancel your booking. Are you sure?')}
                />

                <PaymentDialog
                    open={!!vehicle && !!user && paymentDialogOpen}
                    onClose={() => setPaymentDialogOpen(false)}
                    onConfirm={handleFinishBooking}
                    booking={booking}
                />

                <BookingDoneDialog
                    open={bookingDone}
                    car={vehicle}
                    user={user}
                    onClose={handleBookingDoneDialogClose}
                    booking={booking}
                    claims={props.claims}
                />

                <OutstandingPaymentsDialog
                    open={outstandingPaymentsDialogVisible}
                    onClose={() => setOutstandingPaymentsDialogVisible(false)}
                />

                <AlertDialog
                    open={bookingFailedOpen}
                    onClose={() => setBookingFailedOpen(false)}
                    cancelText={'Ok'}
                    title={t('Booking failed')}
                    message={t('Please try again or select different time')}
                />

            </React.Fragment>
        )
    };

    return (
        <FlexView hAlignContent='center' column className={classes.component}>

            <FlexView column className={classes.info}>

                <LoggedInAs/>

                <CarHeader
                    car={vehicle}
                    user={user}
                    booking={booking}
                    loading={carLoading}
                    onTimeAdded={booking => {
                        if (booking) {
                            setLiveBooking(booking);
                            setExistingBookings([booking]);
                        }
                    }}
                />

                {
                    liveBookingLoading ?
                        <CircularProgress/>
                        :
                        <React.Fragment>
                            {renderSessionSection()}
                            <div className={classes.separator}/>
                            {renderBookingDetails()}
                        </React.Fragment>
                }

                {renderDialogs()}

            </FlexView>

            <div style={{flex: 1}}/>

            <Footer/>

        </FlexView>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        start: state.booking.start,
        end: state.booking.end,
        carId: state.booking.carId
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setSuccessSignRedirect,
            setBookingTime,
        setCarId,
        // initUpdatePhoneAndSendConfirmation,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingComponent);
