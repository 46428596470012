import api from './axios';
import AnalyticsReport from "../models/AnalyticsReport.model";

/**
 * Handles all actions related to analytics.
 */
export default class AdminAnalyticsService {

    static getAnalyticsReport = async (
        organizationId?: number,
        from?: Date,
        to?: Date
    ): Promise<AnalyticsReport> => {
        const params = [
            organizationId ? `organizationId=${organizationId}` : '',
            from ? `from=${from.toISOString()}` : '',
            to ? `to=${to.toISOString()}` : ''
        ].filter(x => !!x).join('&');

        const response = await api.get(`/admin/analytics?${params}`);
        return response.data
    };
}
