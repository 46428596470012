import {BaseModel} from "./BaseModel.model";
import {User} from "./User.model";
import {Booking} from "./Booking.model";
import StripeInvoice from "./StripeInvoice.model";

export enum InvoiceState {
    UNPROCESSED = 'unprocessed',
    NOT_PAID = 'not_paid',
    PAID = 'paid'
}

export enum InvoiceType {
    BOOKING = 'booking',
    SUBSCRIPTION_RENEWAL = 'subscriptionRenewal'
}

export interface Invoice extends BaseModel {
    amount?: number;
    booking?: Booking;
    user?: User;
    status?: InvoiceState;
    type?: string;
    // authorizationPath?: string;
    stripeId?: string;
    invoiceFullData?: StripeInvoice;
}
