import {createMuiTheme} from "@material-ui/core/styles";

export const COLORS = {
    PRIMARY: '#32a936',
    SECONDARY: '#5c5c5b',
    ORANGE: '#FB8920',
    BLUE: '#2094FF'
}

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Exo',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
        ].join(', ')
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: COLORS.PRIMARY,
            dark: COLORS.PRIMARY,

            // dark: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            contrastText: '#fff'
        },
        secondary: {
            light: COLORS.SECONDARY,
            main: COLORS.SECONDARY,
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffffff',
        },
        // error: will use the default color
        warning: {
            contrastText: '#ffffff',
            main: '#ff9800'
        }
    },
    overrides: {
        MuiContainer: {
            maxWidthLg: {
                [defaultTheme.breakpoints.up('xs')]: {
                    maxWidth: '1920px'
                },
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: '1920px'
            },
            root: {
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)'
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: defaultTheme.palette.primary.main,
                },
            },
            root: {
                '&$checked': {
                    color: defaultTheme.palette.primary.main,
                },
            }
        }
    }
});

export default theme;