import StripeInvoice, {StripeCustomer} from "../../models/StripeInvoice.model";
import BillingService from "../../services/billing.service";
import {UPDATE_SUBSCRIPTION_DONE} from "./actionTypes";

export const SET_PAYMENT_METHODS = "Set Payment Methods";
export const ADD_PAYMENT_METHOD = "Add Payment Method";
export const REMOVE_PAYMENT_METHOD = "Remove Payment Method";
export const SET_UPDATING_BILLING = "Set Updating Billing";
export const SET_INVOICES = "Set Invoices";
export const SET_STRIPE_USER = "Set Stripe User";
export const SET_STRIPE_COUPON = "Set Stripe Coupon";
export const UPDATE_INVOICE = "Update Invoice";

const setPaymentMethods = (methods: any[], error: string, loading: boolean) => {
    return {
        type: SET_PAYMENT_METHODS,
        payload: {
            paymentMethods: methods,
            paymentMethodsError: error,
            paymentMethodsLoading: loading
        }
    }
};

export const initLoadPaymentMethods = () => {
    return async (dispatch: any) => {
        dispatch(setPaymentMethods([], '', true));
        try {
            let paymentMethods = await BillingService.loadPaymentMethods();
            dispatch(setPaymentMethods(paymentMethods, '', false));
        } catch (e: any) {
            dispatch(setPaymentMethods([], e.toString(), false));
        }
    }
};

const addPaymentMethod = (error: string, loading: boolean) => {
    return {
        type: ADD_PAYMENT_METHOD,
        payload: {
            // method: method,
            addPaymentMethodError: error,
            addPaymentMethodLoading: loading
        }
    }
};

export const initAddPaymentMethod = (cardSetupResponse?: any) => {
    return async (dispatch: any) => {
        dispatch(addPaymentMethod('', true));
        try {
            const response = await BillingService.addPaymentMethod(cardSetupResponse.payment_method)
            dispatch(setPaymentMethods(response || [], '', false));
            dispatch(addPaymentMethod(!cardSetupResponse ? 'generic_error' : '', false));
        } catch (e: any) {
            dispatch(addPaymentMethod(e.toString(), false));
        }
    }
};

// const removePaymentMethod = (methodId: any, error: string, loading: boolean) => {
//     return {
//         type: REMOVE_PAYMENT_METHOD,
//         payload: {
//             methodId: methodId,
//             removePaymentMethodError: error,
//             removePaymentMethodLoading: loading
//         }
//     }
// };

// export const initRemovePaymentMethod = (claims?: AuthClaims, methodId: any) => {
//     return async dispatch => {
//         dispatch(removePaymentMethod(null, null, true));
//         try {
//             const response = await db.collection('stripe_customers').doc(user.id).collection('tokens').doc(methodId).delete();
//
//             dispatch(removePaymentMethod(methodId, null, false));
//         } catch (e: any) {
//             dispatch(removePaymentMethod(null, e.toString(), false));
//         }
//     }
// };


export const setUpdatingBilling = (updating: boolean) => {
    return {
        type: SET_UPDATING_BILLING,
        payload: updating
    }
};

const setInvoices = (invoices: StripeInvoice[], error: string, loading: boolean) => {
    return {
        type: SET_INVOICES,
        payload: {
            invoices: invoices,
            invoicesError: error,
            invoicesLoading: loading
        }
    }
};

export const initLoadInvoices = () => {
    return async (dispatch: any) => {
        dispatch(setInvoices([], '', true));
        try {
            let invoices = await BillingService.loadStripeInvoices();
            dispatch(setInvoices(invoices, '', false));
        } catch (e: any) {
            dispatch(setInvoices([], e.toString(), false));
        }
    }
};


const setStripeUser = (stripeUser?: StripeCustomer, error?: string, loading?: boolean) => {
    return {
        type: SET_STRIPE_USER,
        payload: {
            stripeUser: stripeUser,
            stripeUserError: error,
            stripeUserLoading: loading
        }
    }
};

const setStripeCoupon = (stripeCoupon: any, error: string, loading: boolean) => {
    return {
        type: SET_STRIPE_COUPON,
        payload: {
            stripeCoupon: stripeCoupon,
            stripeCouponError: error,
            stripeCouponLoading: loading
        }
    }
};

export const initLoadStripeUser = () => {
    return async (dispatch: any) => {
        dispatch(setStripeUser(undefined, '', true));
        dispatch(setStripeCoupon(undefined, '', true));
        try {
            let stripeUser = await BillingService.getStripeUser();
            dispatch(setStripeUser(stripeUser, '', false));
            dispatch(setStripeCoupon(stripeUser?.discount?.coupon, '', false));
        } catch (e: any) {
            dispatch(setStripeUser(undefined, e.toString(), false));
            dispatch(setStripeCoupon(undefined, e.toString(), false));
        }
    }
};

export const initUpdateStripeCoupon = (oldCoupon?: any, coupon?: string) => {
    return async (dispatch: any) => {
        dispatch(setStripeCoupon(oldCoupon, '', true));
        try {
            let stripeUser = await BillingService.updateStripeCoupon(coupon);
            dispatch(setStripeCoupon(stripeUser.discount && stripeUser.discount.coupon, '', false));
        } catch (e: any) {
            dispatch(setStripeCoupon(oldCoupon, e.toString(), false));
        }
    }
};

export const initChangeSubscription = (subscription: string) => {
    return async (dispatch: any) => {
        try {
            let result = await BillingService.changeSubscription(subscription);
            dispatch({
                type: UPDATE_SUBSCRIPTION_DONE,
                payload: result
            });
            return result;
        } catch (e: any) {
            return null;
        }
    }
};
