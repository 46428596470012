import React, {useState} from 'react';
import FlexView from "react-flexview";
import {CircularProgress} from "@material-ui/core";
import classes from './CarManualActions.module.scss';
import Unlock from '../../../../assets/icons/unlock.svg';
import Lock from '../../../../assets/icons/lock.svg';
import {Vehicle, VehicleType} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {useTranslation} from "react-i18next";

interface Props {
    vehicle?: Vehicle
}

export const CarManualActions = (props: Props) => {

    const t = useTranslation().t
    const [adminLockInProgress, setAdminLockInProgress] = useState(false);
    const [adminUnlockInProgress, setAdminUnlockInProgress] = useState(false);
    const [adminClimaStartInProgress, setAdminClimaStartInProgress] = useState(false);
    const [adminClimaStopInProgress, setAdminClimaStopInProgress] = useState(false);
    const [adminStatus, setAdminStatus] = useState('');

    const handleAction = async (action: 'lock' | 'unlock' | 'startClima' | 'stopClima', successStatus: string | boolean, successMessage: string, errorMessage: string, onDone?: () => void) => {
        setAdminStatus('');

        switch (action) {
            case 'lock':
                setAdminLockInProgress(true);
                break;
            case 'unlock':
                setAdminUnlockInProgress(true);
                break;
            case "startClima":
                setAdminClimaStartInProgress(true);
                break;
            case 'stopClima':
                setAdminClimaStopInProgress(true);
                break;
        }

        try {
            const response = await AdminVehicleService[action](props.vehicle?.id);

            if (response.status === successStatus) {
                setAdminStatus(successMessage);

                if (onDone) {
                    onDone();
                }
            } else {
                let message = t('Error! Somethings went wrong, please try again');
                switch (response.data.status) {
                    case 'car_does_not_have_phone_number':
                        message = t('Error! The car does not have a phone number');
                        break;
                }

                setAdminStatus(message);
            }
        } catch (e: any) {
            setAdminStatus(errorMessage);
        } finally {
            switch (action) {
                case 'lock':
                    setAdminLockInProgress(false);
                    break;
                case 'unlock':
                    setAdminUnlockInProgress(false);
                    break;
                case "startClima":
                    setAdminClimaStartInProgress(false);
                    break;
                case 'stopClima':
                    setAdminClimaStopInProgress(false);
                    break;
            }
        }
    };

    return (
        <FlexView column>
            <FlexView className={classes.manualActions}>

                <FlexView className={classes.manualAction} column hAlignContent='center'
                          onClick={() => handleAction(
                              'lock',
                              'locked',
                              t('Success! The lock signal sent. Your car should lock any moment now...'),
                              t('Error! There was some issue while locking the car, please try again.')
                          )}
                >
                    {
                        adminLockInProgress ? <CircularProgress/> :
                            <React.Fragment>
                                <img className={classes.icon} src={Lock}/>
                                <div>Lock</div>
                            </React.Fragment>
                    }
                </FlexView>

                <FlexView className={classes.manualAction} column hAlignContent='center'
                          onClick={() => handleAction(
                              'unlock',
                              'unlocked',
                              t('Success! The unlock signal sent. Your car should unlock any moment now...'),
                              t('Error! There was some issue while unlocking the car, please try again.')
                          )}
                >
                    {
                        adminUnlockInProgress ? <CircularProgress/> :
                            <React.Fragment>
                                <img className={classes.icon} src={Unlock}/>
                                <div>Unlock</div>
                            </React.Fragment>
                    }
                </FlexView>

                {
                    props.vehicle?.vehicleType === VehicleType.CAR &&
                    <FlexView className={classes.manualAction} column hAlignContent='center'
                              onClick={() => handleAction(
                                  'startClima',
                                  true,
                                  t('Success! The HVAC started'),
                                  t('Error! There was some issue while starting HVAC, please try again.')
                              )}
                    >
                        {
                            adminClimaStartInProgress ? <CircularProgress/> :
                                <React.Fragment>
                                    <img className={classes.icon} src={Lock}/>
                                    <div>Start Clima</div>
                                </React.Fragment>
                        }
                    </FlexView>
                }

                {
                    props.vehicle?.vehicleType === VehicleType.CAR &&
                    <FlexView className={classes.manualAction} column hAlignContent='center'
                              onClick={() => handleAction(
                                  'stopClima',
                                  true,
                                  t('Success! The HVAC stopped'),
                                  t('Error! There was some issue while stopping HVAC, please try again.')
                              )}
                    >
                        {
                            adminClimaStopInProgress ? <CircularProgress/> :
                                <React.Fragment>
                                    <img className={classes.icon} src={Lock}/>
                                    <div>Stop Clima</div>
                                </React.Fragment>
                        }
                    </FlexView>
                }

            </FlexView>

            {adminStatus && <div className={classes.adminStatus}>{adminStatus}</div>}

        </FlexView>
    )
};
