import {BaseModel} from "./BaseModel.model"
import {Vehicle} from "./Vehicle.model"
import {User} from "./User.model"
import {Organization} from "./Organization.model"
import {Invoice} from "./Invoice.model";

export enum BookingStatus {
    ACTIVE = 'active',
    SESSION_ENDED = 'sessionEnded'
}

export interface Booking extends BaseModel {
    vehicle?: Vehicle
    organization?: Organization
    user?: User
    from?: Date
    to?: Date
    status?: BookingStatus
    sessionEndedAt?: Date
    totalPrice?: number
    invoice?: Invoice
    endingSoonNotificationSent?: boolean
    initialFuelLevel?: number
    initialMileage?: number
    endFuelLevel?: number
    endMileage?: number
    started?: boolean
    startingSoonNotificationSent?: boolean
    totalRegularMinutes?: number
    totalRegularPrice?: number
    totalOvertimeMinutes?: number
    totalOvertimePrice?: number
    premium?: boolean
    isNoShow?: boolean
    noShowPrice?: number
    carInteractedAtLeastOnce?: boolean
    overtimeIncidentFee?: number
    overtimeWarningSent?: boolean
    previousBookingOvertimeIncident?: boolean
    vehicleReturnedAt?: Date
    pricePreMinute?: number
    dailyMax?: number
}
