import React from 'react';
import classes from './Footer.module.scss';
import FlexView from "react-flexview";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const t = useTranslation().t

    return (
        <FlexView
            className={classes.component}
            vAlignContent='bottom'
            hAlignContent='center'
            column
        >
            <Box display={'flex'} marginBottom={3}>
                <a
                    className={classes.socialMediaLinkHolder}
                    href={"https://www.instagram.com/greencarpoolnordic/"}
                    target={"_blank"}
                >
                    <InstagramIcon className={classes.socialMediaLink}/>
                </a>

                <a
                    className={classes.socialMediaLinkHolder}
                    href={"https://www.facebook.com/GreenCarpool-mobilitetsl%C3%B6sningar-101764495367129"}
                    target={"_blank"}
                >
                    <FacebookIcon className={classes.socialMediaLink}/>
                </a>

                <a
                    className={classes.socialMediaLinkHolder}
                    href={"https://www.linkedin.com/in/greencarpool-norden-ab-659053219/"}
                    target={"_blank"}
                >
                    <LinkedInIcon className={classes.socialMediaLink}/>
                </a>
            </Box>

            <FlexView className={classes.links} wrap>

                <Link to='/terms-of-service' className={classes.link}>
                    <div>{t('Terms of service')}</div>
                </Link>

                {/*<Link to='/privacy-policy' className={classes.link}>*/}
                {/*<div>{i18.t('Privacy policy')}</div>*/}
                {/*</Link>*/}

                <Link to='/faq' className={classes.link}>
                    <div>{t('FAQ')}</div>
                </Link>

                <Link to='/contact' className={classes.link}>
                    <div>{t('Contact')}</div>
                </Link>

            </FlexView>

            <div className={classes.copy}>Copyright © GreenCarPool {format(new Date(), 'yyyy')}</div>
        </FlexView>
    )
};

export default Footer;
