import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './OutstandingPaymentsDialog.module.scss';
import {RouteComponentProps, withRouter} from "react-router";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    open: boolean,
    onClose: () => void,
}

const OutstandingPaymentsDialog = (props: Props) => {

    const t = useTranslation().t
    
    return (
        <Dialog aria-labelledby="customized-dialog-title" open={props.open} onBackdropClick={props.onClose}>

            <DialogTitle id="customized-dialog-title">
                {t('Account on hold')}
            </DialogTitle>

            <DialogContent dividers>
                <div
                    className={classes.welcomeMessage}>{t(`There is some outstanding balance on you account. Please clear it before making new bookings...`)}</div>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => props.history.push('/profile#billing')} color="primary">
                    {t('Take me to Billing')}
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default withRouter(OutstandingPaymentsDialog);
