import React, {Component} from 'react'
import classes from './Dropzone.module.scss';
import clsx from 'clsx';
import Upload from '../../../assets/icons/upload.svg';
import i18 from 'i18next';

interface Props {
    disabled?: boolean,
    onFilesAdded: (files: any[]) => void,
    className?: string
}

interface State {
    hightlight: boolean
}

class Dropzone extends Component<Props, State> {
    private fileInputRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props)
        this.state = {hightlight: false}
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click()
    }

    onFilesAdded(evt: any) {
        if (this.props.disabled) return;
        const files = evt.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
    }

    onDragOver(evt: any) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({hightlight: true})
    }

    onDragLeave() {
        this.setState({hightlight: false})
    }

    onDrop(event: any) {
        event.preventDefault()

        if (this.props.disabled) return

        const files = event.dataTransfer.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({hightlight: false})
    }

    fileListToArray(list: any) {
        const array: any[] = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    render() {
        return (
            <div
                className={clsx(classes.Dropzone, this.state.hightlight ? classes.Highlight : null, this.props.className)}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
            >
                <input
                    ref={this.fileInputRef}
                    className={classes.FileInput}
                    type="file"
                    accept="image/*"
                    onChange={this.onFilesAdded}
                />
                <img
                    alt="upload"
                    className={classes.Icon}
                    src={Upload}
                />
                <span>{i18.t('Drag n Drop or Click')}</span>
            </div>
        )
    }
}

export default Dropzone
