import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Vehicle} from "../../../models/Vehicle.model";
import {Page} from "../../../models/Page";
import {Booking} from "../../../models/Booking.model";
import AdminBookingService from "../../../services/adminBooking.service";
import BookingList from "../common/BookingList.component";


interface Props {
    vehicle?: Vehicle;
}

const Bookings = ({vehicle}: Props) => {


    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState<Page<Booking>>();
    const history = useHistory();

    //region Handlers

    const fetch = async (page: number) => {
        setLoading(true);
        try {
            const response = await AdminBookingService.getAllBookings(page, 20, undefined, vehicle?.id);
            setBookings(response);
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (vehicle?.id) {
            fetch(0);
        }
    }, [vehicle?.id])

    if (!vehicle) return null;


    //endregion Handlers

    //region UI

    if (loading) {
        return <CircularProgress/>
    }

    return (
        <BookingList
            bookings={bookings}
            onBookingClick={booking => {
                history.push(`/admin/booking/${booking.id}/basic`)
            }}

            onPageChange={page => fetch(page)}
        />
    )

    //endregion UI
}

export default Bookings;