import React from 'react';
import {Box, Card, CircularProgress} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import {Page} from "../../../models/Page";
import Pagination from "../../../common/ui/Pagination/Pagination.component";
import {Vehicle} from "../../../models/Vehicle.model";
import GcpButton from "../../../common/ui/GcpButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useTranslation} from "react-i18next";

interface Props {
    loading?: boolean;
    vehicles?: Page<Vehicle>;
    onVehicleClick: (vehicle: Vehicle) => void;
    onVehicleRemove?: (vehicle: Vehicle) => void;
    onPageChange?: (page: number) => void;
    showDelete?: boolean;
}

const VehiclesList = ({loading, vehicles, onVehicleClick, onVehicleRemove, onPageChange, showDelete}: Props) => {

    const t = useTranslation().t
    
    if (loading) {
        return <CircularProgress/>
    }

    if (!vehicles?.content.length) {
        return <div>No vehicles found</div>
    }

    const header = (
        <FlexView vAlignContent='center' className={classes.header}>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Model')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Status')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('License')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Type')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Organization')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Latest known gps')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Battery')}</div>
        </FlexView>
    )

    const content = vehicles.content.map(vehicle => {
        return (
            <FlexView key={vehicle.id} onClick={() => onVehicleClick?.(vehicle)} vAlignContent='center'
                      className={classes.tableRow}>
                <FlexView className={classes.cellSmall} vAlignContent='center'>
                    <img className={classes.rowIcon} src={vehicle.image}/>
                    <div>{`${vehicle.make} ${vehicle.model}`}</div>
                </FlexView>
                <div className={classes.cellSmall}>{vehicle.status || 'inactive'}</div>
                <div className={classes.cellSmall}>{vehicle.license || '-'}</div>
                <div className={classes.cellSmall}>{vehicle.vehicleType || '-'}</div>
                <div className={classes.cellSmall}>{vehicle.organization?.name || '-'}</div>
                <div
                    className={classes.cellSmall}>{`${vehicle.lastLatitude || '-'},${vehicle.lastLongitude || '-'}`}</div>
                <div className={classes.cellSmall}>{vehicle.lastFuelLevel || '-'}%</div>

                {
                    showDelete &&
                    <GcpButton
                        variant={'contained'}
                        color={'error'}
                        size={'small'}
                        onClick={e => {
                            e.stopPropagation();
                            onVehicleRemove?.(vehicle)
                        }}
                    >
                        <DeleteForeverIcon/>
                    </GcpButton>
                }

            </FlexView>
        )
    })

    return (
        <Card>

            {header}
            {content}

            <Box style={{width: '100%'}} display={'flex'} justifyContent={'center'}>
                <Pagination
                    pages={vehicles.totalPages || 0}
                    currentPage={vehicles?.page || 0}
                    onPageChange={page => onPageChange?.(page)}
                />
            </Box>

        </Card>
    )
}

export default VehiclesList;