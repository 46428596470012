import {BaseModel} from "./BaseModel.model"
import {Organization} from "./Organization.model"
import {GeoPoint} from "./GeoPoint.model"
import {HardwareInfo} from "./HardwareInfo.model"
import {Group} from "./Group.model"

export enum VehicleType {
    CAR = 'car',
    SCOOTER = 'scooter',
    BICYCLE = 'bicycle'
}

export interface Vehicle extends BaseModel {
    organization?: Organization
    make?: string
    model?: string
    type?: string
    range?: number
    license?: string
    image?: string
    seats?: string
    bodyType?: string
    location?: GeoPoint
    rules?: any
    important?: any
    cancellation?: any
    lastLatitude?: number
    lastLongitude?: number
    lastFuelLevel?: number
    lastMileage?: number
    lastCharging?: boolean
    address?: string
    vehicleType?: VehicleType
    hardwareProvider?: string
    hardwareProviderDeviceId?: string
    hardwareInfo?: HardwareInfo
    status?: string
    regularPriceOverride?: number
    premiumPriceOverride?: number
    regularDailyMaxOverride?: number
    premiumDailyMaxOverride?: number
    group?: Group
    pricePerMinuteForUser?: number
    dailyMaxForUser?: number
}
