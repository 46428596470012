import {BaseModel} from "./BaseModel.model";
import {Group} from "./Group.model";

export enum DayOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export const DAY_OF_WEEK_DATE_FNS = {
    [DayOfWeek.MONDAY]: 1,
    [DayOfWeek.TUESDAY]: 2,
    [DayOfWeek.WEDNESDAY]: 3,
    [DayOfWeek.THURSDAY]: 4,
    [DayOfWeek.FRIDAY]: 5,
    [DayOfWeek.SATURDAY]: 6,
    [DayOfWeek.SUNDAY]: 0,
}

export interface PeriodicAvailability extends BaseModel {
    group?: Group;
    fromDay?: DayOfWeek;
    fromHours?: number;
    fromMinutes?: number;
    toHours?: number;
    toMinutes?: number;
    toDay?: DayOfWeek;
}