import {SET_BOOKING_TIME, SET_CAR,} from "./actionTypes";

export const setCarId = (carId: string) => {
    return {
        type: SET_CAR,
        payload: {
            carId
        }
    }
};


// ***** Booking creation ***** //

export const setBookingTime = (start?: Date, end?: Date) => {
    return {
        type: SET_BOOKING_TIME,
        payload: {
            start, end
        }
    }
};
