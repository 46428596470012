import React from 'react';
import FlexView from "react-flexview";
import classes from './AllViaApp.module.scss';
import Devices from '../../../assets/images/devices-all.png';
import {useTranslation} from "react-i18next";

const AllViaApp = () => {
    
    const t = useTranslation().t

    return (
        <FlexView className={classes.component} hAlignContent='center' wrap>
            <FlexView className={classes.image} hAlignContent='center' vAlignContent='center'>
                <img src={Devices}/>
            </FlexView>

            <FlexView column className={classes.text} hAlignContent='center' vAlignContent='center'>
                <div className={classes.title}>{t("Online, any device")}</div>
                <div className={classes.short}>{t("Complete the booking process online, via any device")}</div>
            </FlexView>

        </FlexView>
    )
}

export default AllViaApp;