import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {connect} from "react-redux";
import classes from './WelcomeDialog.module.scss';
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props {
    claims?: AuthClaims,
    open: boolean,
    onClose: () => void,
}

const WelcomeDialog = (props: Props) => {

    const t = useTranslation().t
    
    return (
        <Dialog aria-labelledby="customized-dialog-title" open={props.open}>

            <DialogTitle id="customized-dialog-title">
                {t('Registration done')}
            </DialogTitle>

            <DialogContent dividers>
                <div
                    className={classes.welcomeMessage}>{t('Welcome to GreenCarPool', {name: props.claims?.user?.name})}</div>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t('Ok')}
                </Button>
            </DialogActions>

        </Dialog>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(WelcomeDialog);
