import React, {useEffect, useState} from 'react';
import {Box, Button} from "@material-ui/core";
import {useSelector} from "react-redux";
import {Vehicle} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {Page} from "../../../../models/Page";
import Search from "../../../../common/ui/Search/Search";
import {Link, useHistory} from "react-router-dom";
import {AuthClaims} from "../../../../models/AuthClaims.model";
import VehiclesList from "../../common/VehiclesList.component";
import {useTranslation} from "react-i18next";

const AdminCarsList = () => {

    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState<Page<Vehicle>>();
    const [search, setSearch] = useState('')
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const history = useHistory();
    const t = useTranslation().t

    useEffect(() => {
        fetchCars(0);
    }, [claims, search]);

    const fetchCars = async (page: number) => {
        if (!claims?.user) return;

        setLoading(true);

        const vehicles = await AdminVehicleService.getAllVehicles(page, 20, undefined, undefined, search);

        setVehicles(vehicles);
        setLoading(false);
    };

    const openCar = (car?: Vehicle) => {
        if (car) {
            history.push(`/admin/vehicle/${car.id}/basic`);
        } else {
            history.push(`/admin/vehicle/new/basic`);
        }
    };

    return (
        <Box>

            <Box display={'flex'}>
                <Search
                    placeholder={t('Search by model, manufacturer, license, type (car | scooter | bicycle), address, organization name')}
                    onSearch={setSearch}
                />
                <Box m={2}/>
                <Link to="/admin/vehicle/new/basic">
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => openCar()}
                    >
                        {t('Add')}
                    </Button>
                </Link>
            </Box>

            <Box m={2}/>

            <VehiclesList
                loading={loading}
                vehicles={vehicles}
                onPageChange={fetchCars}
                onVehicleClick={openCar}
            />

        </Box>
    )
}

export default AdminCarsList;
