import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './Summary.module.scss';
import {differenceInHours, differenceInMinutes, format} from "date-fns";
import {Button, CircularProgress} from "@material-ui/core";
import {RouteComponentProps, withRouter} from "react-router";
import clsx from 'clsx';
import {Vehicle} from "../../../models/Vehicle.model";
import {Booking} from "../../../models/Booking.model";
import BookingService from "../../../services/booking.service";
import {Invoice, InvoiceState} from "../../../models/Invoice.model";
import {totalMileage} from "../../../common/booking.util";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    vehicle?: Vehicle,
    booking: Booking
}

const Summary = (props: Props) => {

    const t = useTranslation().t
    const [invoice, setInvoice] = useState<Invoice>();
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const fetchInvoice = async () => {
        if (props.booking.id) {
            // setInvoiceLoading(true);
            const invoice = await BookingService.getInvoice(props.booking.id);
            setInvoice(invoice)
            setInvoiceLoading(false);
        }
    }

    useEffect(() => {
        fetchInvoice();
    }, [props.booking]);

    if (!props.booking) return null;

    const hours = props.booking.sessionEndedAt && props.booking.from && differenceInHours(props.booking.sessionEndedAt, props.booking.from);
    const minutes = props.booking.sessionEndedAt && props.booking.from && differenceInMinutes(props.booking.sessionEndedAt, props.booking.from) % 60;
    const time = `${hours}h ${minutes}m`;

    const renderInvoice = () => {
        if (invoiceLoading) {
            return (
                <CircularProgress/>
            )
        }

        if (!invoice) {
            return (
                <div>
                    {t('Invoice not available yet')}
                </div>
            )
        }


        let discountString;
        if (invoice.invoiceFullData?.discount) {
            if (invoice.invoiceFullData.discount.coupon.amount_off) {
                discountString = t(`${invoice.invoiceFullData.discount.coupon.amount_off / 100} ${invoice.invoiceFullData.currency}`);
            } else if (invoice.invoiceFullData.discount.coupon.percent_off) {
                const valueOff = (invoice.amount || 0) * invoice.invoiceFullData.discount.coupon.percent_off / 100;
                discountString = t(`${valueOff.toFixed(2)} ${invoice.invoiceFullData.currency}`);
            }
        }

        let status = t('Not paid');
        switch (invoice.status) {
            case InvoiceState.UNPROCESSED:
                status = t('Preparing your invoice');
                break;
            case InvoiceState.NOT_PAID:
                status = t('Not paid');
                break;
            case InvoiceState.PAID:
                status = t('Paid');
                break;
        }

        return (
            <React.Fragment>

                <FlexView>
                    <div className={classes.summaryLabel}>{t('Invoice status')}:</div>
                    <div>{status}</div>
                </FlexView>

                {
                    discountString &&
                    <FlexView>
                        <div className={classes.summaryLabel}>{t('Discount')}:</div>
                        <div>{discountString}</div>
                    </FlexView>
                }

                {
                    invoice.status === InvoiceState.PAID &&
                    <FlexView>
                        <div className={classes.summaryLabel}>{t('Total paid')}:</div>
                        <div>{(invoice.invoiceFullData?.amount_paid || 0) / 100} {invoice.invoiceFullData?.currency}</div>
                    </FlexView>
                }


                {
                    invoice.invoiceFullData?.hosted_invoice_url &&
                    <FlexView>
                        <div className={classes.summaryLabel}>{t('Link')}:</div>
                        <a href={invoice.invoiceFullData.hosted_invoice_url}
                           target='_blank'>{t('Download invoice')}</a>
                    </FlexView>
                }

            </React.Fragment>
        )
    }

    return (
        <FlexView className={classes.sessionEndedInfo} hAlignContent='left' vAlignContent='center' column>

            <div className={classes.sessionEndedSummary}>{t('Summary')}</div>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Date')}:</div>
                <div>{props.booking.from && format(props.booking.from, 'yyyy-MM-dd')} - {props.booking.sessionEndedAt && format(props.booking.sessionEndedAt, 'yyyy-MM-dd')}</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Time range')}:</div>
                <div>{props.booking.from && format(props.booking.from, 'HH:mm')} - {props.booking.sessionEndedAt && format(props.booking.sessionEndedAt, 'HH:mm')}</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Vehicle')}:</div>
                <div>{props.vehicle?.make} {props.vehicle?.model} {props.vehicle?.license}</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Price')}:</div>
                <div>{props.booking.totalPrice?.toFixed(2)} kr</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Time')}:</div>
                <div>{time}</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('Total km')}:</div>
                <div>{totalMileage(props.booking).toFixed(2)}</div>
            </FlexView>

            <FlexView>
                <div className={classes.summaryLabel}>{t('CO2 Points')}:</div>
                <div>{(totalMileage(props.booking) * 110 / 1000).toFixed(2)}kg</div>
            </FlexView>

            <div
                className={clsx(classes.sessionEndedSummary, classes.sessionEndedSummarySecondary)}>{t('Invoice')}</div>

            {renderInvoice()}

            <FlexView className={classes.backToBookings} hAlignContent='center' vAlignContent='center'
                      onClick={() => props.history.push('/my-bookings')}>
                <Button variant="contained" color="primary">{t("Back to My Bookings")}</Button>
            </FlexView>

        </FlexView>
    );

};

export default withRouter(Summary);
