import {Vehicle} from "../models/Vehicle.model";

export const vehicleDescriptor = (vehicle?: Vehicle, type?: 'simple') => {
    if (!vehicle) return 'Unknown';

    switch (type) {
        case "simple":
            return `${vehicle.vehicleType} [${vehicle.id}] ${vehicle?.make} ${vehicle.model} (${vehicle.license})`
        default:
            return `${vehicle.vehicleType} [${vehicle.id}] ${vehicle?.make} ${vehicle.model} (${vehicle.license})`
    }
}