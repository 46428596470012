import { TextField, withStyles } from "@material-ui/core";

const GcpInput = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: '#000000',
            opacity: 0.1
        },
        '& .MuiInputLabel-root': {
            background: 'unset'
        }
    },
})(TextField);

export default GcpInput;
