import React, { useState } from 'react';

import { initChangeSubscription } from "../../../store/actions/billing.actions";
import { connect } from "react-redux";
import FlexView from "react-flexview";
import classes from './Subscriptions.module.scss';
import clsx from 'clsx';
import { Button, CircularProgress } from "@material-ui/core";
import { format, isAfter } from "date-fns";
import { bindActionCreators } from "redux";
import { AuthClaims } from "../../../models/AuthClaims.model";
import AlertDialog from "../../../common/ui/AlertDialog.component";
import { useTranslation } from "react-i18next";

interface Props {
    claims?: AuthClaims,
    initChangeSubscription: typeof initChangeSubscription;
    onSubscriptionChanged?: (newSubscription: string) => void;
    confirmationMode: 'dialog' | 'button';
    preselect?: 'freemium' | 'premium'
}

const Subscription = (props: Props) => {

    const t = useTranslation().t
    const [subscribingTo, setSubscribingTo] = useState('');
    const [confirmPremiumOpen, setConfirmPremiumOpen] = useState(false);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(props.preselect || '');

    if (!props.claims) {
        return null;
    }

    const subscription = props.claims.subscription;
    const subscriptionType = selectedSubscriptionType || subscription?.type;
    const subscriptionExpiration = subscription && subscription.expiration;

    const changeSubscription = async () => {
        setSubscribingTo(selectedSubscriptionType);

        try {
            await props.initChangeSubscription(selectedSubscriptionType);
            props.onSubscriptionChanged && props.onSubscriptionChanged(selectedSubscriptionType);
            setSelectedSubscriptionType('')
        } catch (e: any) {

        } finally {
            setSubscribingTo('');
        }
    };

    let subscriptionExpirationText = null;

    if (subscription && subscriptionExpiration) {
        if (isAfter(new Date(), subscriptionExpiration)) {
            subscriptionExpirationText = 'Expired';
        } else if (subscription.type === 'premium') {
            subscriptionExpirationText = `Your premium subscription will renew on ${format(subscriptionExpiration, 'dd/MM/yyyy HH:mm')}`
        } else {
            subscriptionExpirationText = `Your premium subscription is active till ${format(subscriptionExpiration, 'dd/MM/yyyy HH:mm')}`
        }
    }

    return (
        <FlexView column hAlignContent={'center'} className={classes.pricing}>
            <FlexView className={classes.pricing}>
                <FlexView className={classes.pricing} vAlignContent='center' hAlignContent='center' wrap>

                    <FlexView className={clsx(classes.price, subscriptionType === 'freemium' && classes.active)}
                              column
                              hAlignContent='center' vAlignContent='center'
                              onClick={() => {
                                  if (subscription?.type !== 'freemium') {
                                      setSelectedSubscriptionType('freemium');
                                      if (props.confirmationMode === 'dialog') {
                                          setConfirmPremiumOpen(true);
                                      }
                                  }
                              }}
                    >

                        {
                            subscribingTo === 'freemium' ? <CircularProgress/> :
                                <React.Fragment>
                                    <div className={classes.type}>Freemium</div>
                                    <div className={classes.priceValue}>0</div>
                                    <div className={classes.priceMinute}>SEK/{t('month')}</div>
                                    <div className={classes.priceValue}>2.5</div>
                                    <div className={classes.priceMinute}>SEK/minute</div>
                                </React.Fragment>
                        }

                    </FlexView>

                    <FlexView className={clsx(classes.price, subscriptionType === 'premium' && classes.active)}
                              column
                              hAlignContent='center' vAlignContent='center'
                              onClick={() => {
                                  if (subscription?.type !== 'premium') {
                                      setSelectedSubscriptionType('premium');
                                      if (props.confirmationMode === 'dialog') {
                                          setConfirmPremiumOpen(true);
                                      }
                                  }
                              }}
                    >
                        {
                            subscribingTo === 'premium' ? <CircularProgress/> :
                                <React.Fragment>
                                    <div className={classes.type}>Premium</div>
                                    <div className={classes.priceValue}>149</div>
                                    <div className={classes.priceMinute}>SEK/{t('month')}</div>
                                    <div className={classes.priceValue}>1</div>
                                    <div className={classes.priceMinute}>SEK/minute</div>
                                </React.Fragment>
                        }

                    </FlexView>

                </FlexView>
            </FlexView>

            {subscriptionExpirationText &&
            <div className={classes.subscriptionStatus}>{subscriptionExpirationText}</div>}

            {
                props.confirmationMode === 'button' && selectedSubscriptionType &&
                (
                    subscribingTo ?
                        <CircularProgress className={classes.confirmSelection}/> :
                        <Button
                            className={classes.confirmSelection}
                            variant={'contained'}
                            color={'primary'}
                            onClick={changeSubscription}
                        >
                            {t('Confirm selection')}
                        </Button>
                )

            }

            <AlertDialog
                open={confirmPremiumOpen}
                onClose={() => setConfirmPremiumOpen(false)}
                onConfirm={() => {
                    changeSubscription();
                    setConfirmPremiumOpen(false);
                }}
                title={selectedSubscriptionType === 'freemium' ? 'Freemium' : 'Premium'}
                message={t('Confirm your choice by clicking Ok')}
                confirmText={'Ok'}
            />

        </FlexView>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({initChangeSubscription}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
