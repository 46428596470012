import api from ".//axios";
import StripeInvoice, {SetupIntent, StripeCustomer} from "../models/StripeInvoice.model";
import {Subscription} from "../models/Subscription.model";
import PaymentMethod from "../models/PaymentMethod.model";

export default class BillingService {

    static loadPaymentMethods = async (): Promise<PaymentMethod[]> => {
        try {
            const response = await api.get<PaymentMethod[]>(`/profile/paymentMethods`);
            return response.data || [];
        } catch (e: any) {
        }
        return [];
    };


    static getStripeUser = async (): Promise<StripeCustomer | undefined> => {
        try {
            const response = await api.get<StripeCustomer>(`/profile/stripeInfo`);
            return response.data;
        } catch (e: any) {

        }
    };

    static updateStripeCoupon = async (coupon?: string): Promise<any> => {
        try {
            const response = await api.put<StripeCustomer>(`/profile/coupon`, {
                coupon
            });
            return response.data;
        } catch (e: any) {
        }
    };

    static loadStripeInvoices = async (): Promise<StripeInvoice[]> => {
        try {
            const response = await api.get<StripeInvoice[]>(`/profile/invoices`);
            return response.data;
        } catch (e: any) {
            return [];
        }
    };

    static changeSubscription = async (subscription: string): Promise<Subscription | undefined> => {
        try {
            const response = await api.post<Subscription>(`/profile/subscribe`, {
                type: subscription
            });
            return {
                ...response.data,
                createdAt: response?.data?.createdAt && new Date(response.data.createdAt),
                expiration: response?.data?.expiration && new Date(response.data.expiration),
            };
        } catch (e: any) {
        }
    };

    static getSetupIntent = async (): Promise<SetupIntent | undefined> => {
        try {
            const response = await api.get<SetupIntent>(`/profile/setupIntent`);
            return response.data;
        } catch (e: any) {
        }
    };

    static addPaymentMethod = async (paymentMethod?: any): Promise<PaymentMethod[] | undefined> => {
        try {
            const response = await api.post<PaymentMethod[]>(`/profile/paymentMethods`, {
                paymentMethod
            });
            return response.data;
        } catch (e: any) {
        }
    };
}
