import {applyMiddleware, combineReducers, createStore} from "redux";
import authReducer from "./reducers/auth.reducer";
import bookingReducer from "./reducers/booking.reducer";
import listingReducer from "./reducers/listing.reducer";
import historyReducer from "./reducers/history.reducer";
import billingReducer from "./reducers/billing.reducer";
import adminReducer from "./reducers/admin.reducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
    auth: authReducer,
    booking: bookingReducer,
    listing: listingReducer,
    history: historyReducer,
    billing: billingReducer,
    admin: adminReducer
});

export default rootReducer;

export const store = createStore(rootReducer, applyMiddleware(thunk));