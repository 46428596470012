import * as ReactGA from "react-ga";

export const initGA = (id: string) => {
    // if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
    // }
};

export const trackCurrentPage = () => {
    try {
        ReactGA.pageview(window.location.pathname + window.location.search);
    } catch (e: any) {
        console.log(e)
    }
};