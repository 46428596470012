import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Organization} from "../../../models/Organization.model";
import {User} from "../../../models/User.model";
import {Page} from "../../../models/Page";
import AdminUserService from "../../../services/adminUser.service";
import UsersList from "../common/UsersList.component";
import {CircularProgress} from "@material-ui/core";

const useStyle = makeStyles(_theme => ({
    content: {
        padding: 20
    },
    nameInput: {
        width: 300,
        marginTop: 20,
        marginBottom: 40
    },
    actionButton: {
        marginLeft: 20
    }
}))

interface Props {
    organization: Organization;
}

const Users = ({organization}: Props) => {

    // @ts-ignore
    const classes = useStyle();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<Page<User>>();

    //region Handlers

    const fetch = async () => {
        setLoading(true);
        try {
            const response = await AdminUserService.getAll(users?.page, users?.size, organization.id);
            setUsers(response);
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (organization.id) {
            fetch();
        }
    }, [organization.id])

    //endregion Handlers

    //region UI

    if (loading) {
        return <CircularProgress/>
    }

    return (
        <UsersList
            users={users}
            onUserClick={_ => {
            }}

            onPageChange={_page => {
            }}
        />
    )

    //endregion UI
}

export default Users;