import {LOGOUT_DONE, SET_LISTING,} from "../actions/actionTypes";
import {Vehicle} from "../../models/Vehicle.model";
import {Page} from "../../models/Page";
import GcpError from "../../models/error.enum";

export interface ListingState {
    vehicles?: Page<Vehicle>;
    loading: boolean;
    error: GcpError;
}

const initialState: ListingState = {
    loading: false,
    error: GcpError.NONE
};

const listingReducer = (state: ListingState = initialState, action: any) => {
    switch (action.type) {
        case SET_LISTING:
            return {
                ...state,
                ...action.payload
            };
        case LOGOUT_DONE:
            return {
                ...initialState
            }
    }
    return state;
};

export default listingReducer;
