import React, {useState} from 'react';
import {Group} from "../../../models/Group.model";
import {PeriodicAvailability} from "../../../models/PeriodicAvailability.model";
import {Box, Button, Container} from "@material-ui/core";
import PeriodicAvailabilityList from "../common/PeriodicAvailabilityList.component";
import PeriodicAvailabilityDialog from "./PeriodicAvailabilityDialog.component";
import {useTranslation} from "react-i18next";

interface Props {
    group: Group;
    setGroup: (group: Group) => void;
}

const PeriodicAvailabilityTab = ({group, setGroup}: Props) => {

    const [periodicAvailabilityPickerOpen, setPeriodicAvailabilityPickerOpen] = useState(false);
    const [selectedPeriodicAvailability, setSelectedPeriodicAvailability] = useState<PeriodicAvailability>();
    const t = useTranslation().t

    //region Handlers

    const savePeriodicAvailability = (periodicAvailability: PeriodicAvailability) => {
        setPeriodicAvailabilityPickerOpen(false);

        const newPeriodicAvailability = [...(group.periodicAvailability || [])];
        const index = newPeriodicAvailability.findIndex(v => v.id === periodicAvailability.id)

        if (index >= 0) {
            newPeriodicAvailability[index] = periodicAvailability
        } else {
            newPeriodicAvailability.push(periodicAvailability);
        }

        setGroup({
            ...group,
            periodicAvailability: newPeriodicAvailability
        })
    }

    const removePeriodicAvailability = () => {
        setPeriodicAvailabilityPickerOpen(false);
        setGroup({
            ...group,
            periodicAvailability: group.periodicAvailability?.filter(v => v.id !== selectedPeriodicAvailability?.id)
        })
    }

    //endregion Handlers

    //region UI

    return (
        <Container>
            <Button
                variant='contained'
                color={'primary'}
                onClick={() => {
                    setSelectedPeriodicAvailability(undefined)
                    setPeriodicAvailabilityPickerOpen(true)
                }}
            >
                {t('Add periodic availability')}
            </Button>
            <Box m={2}/>

            <PeriodicAvailabilityList
                periodicAvailability={group.periodicAvailability || []}
                onPeriodicAvailabilityClick={periodicAvailability => {
                    setSelectedPeriodicAvailability(periodicAvailability)
                    setPeriodicAvailabilityPickerOpen(true)
                }}
                showDelete
            />

            <PeriodicAvailabilityDialog
                open={periodicAvailabilityPickerOpen}
                onClose={() => setPeriodicAvailabilityPickerOpen(false)}
                periodicAvailability={selectedPeriodicAvailability}
                onSave={savePeriodicAvailability}
                onDelete={removePeriodicAvailability}
            />

        </Container>
    )

    //endregion UI
}

export default PeriodicAvailabilityTab;