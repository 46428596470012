import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, CircularProgress, Container } from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../../AdminListCommon.module.scss';
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import * as Excel from 'exceljs';
import { Booking } from "../../../../models/Booking.model";
import { AuthClaims } from "../../../../models/AuthClaims.model";
import AdminBookingService from "../../../../services/adminBooking.service";
import { Page } from "../../../../models/Page";
import BookingService from "../../../../services/booking.service";
import { vehicleDescriptor } from "../../../../common/vehicle.util";
import Search from "../../../../common/ui/Search/Search";
import BookingList from "../../common/BookingList.component";
import { isCurrentUserSuperAdmin } from "../../../../common/user.util";
import { endOfDay, startOfDay } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';

interface Props extends RouteComponentProps {
    claims?: AuthClaims;
}

const AdminBookingsList = (props: Props) => {

    const t = useTranslation().t
    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState<Page<Booking>>();
    const [exportInProgress, setExportInProgress] = useState(false);
    const [totalExported, setTotalExported] = useState(0);
    const [search, setSearch] = useState('')
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>()

    // const [vehicleFilter, setVehicleFilter] = useState<number>();
    // const [organizationFilter, setOrganizationFilter] = useState<number>();

    const fetchBookings = useCallback(async (page: number) => {
        if (!props.claims?.user) return;

        setLoading(true);

        try {
            const bookings = await AdminBookingService.getAllBookings(
                page,
                10,
                isCurrentUserSuperAdmin() ? undefined : props.claims?.organization?.id,
                undefined,
                undefined,
                search,
                fromDate && startOfDay(fromDate) || undefined,
                toDate && endOfDay(toDate) || undefined
            );
            setBookings(bookings);
        } catch (e: any) {
            console.log(e);
            setBookings(undefined);
        } finally {
            setLoading(false);
        }
    }, [fromDate, props.claims, search, toDate]);

    useEffect(() => {
        fetchBookings(0);
    }, [props.claims, search, fromDate, toDate, fetchBookings]);

    const addBookingColumns = (worksheet: Excel.Worksheet) => {
        const columns = [
            {header: t('Id'), key: 'id', width: 32},
            {header: t('From'), key: 'from', width: 15},
            {header: t('To'), key: 'to', width: 15},
            {header: t('Created at'), key: 'createdAt', width: 15},
            {header: t('Status'), key: 'status', width: 15},
            {header: t('Vehicle id'), key: 'car', width: 32},
            {header: t('Vehicle'), key: 'carName', width: 32},
            {header: t('Partner'), key: 'partner', width: 32},
            {header: t('Regular minutes'), key: 'totalRegularMinutes', width: 15},
            {header: t('Regular price'), key: 'totalRegularPrice', width: 15},
            {header: t('Overtime minutes'), key: 'totalOvertimeMinutes', width: 15},
            {header: t('Overtime price'), key: 'totalOvertimePrice', width: 15},
            {header: t('Total price'), key: 'totalPrice', width: 15},
            {header: t('Discount'), key: 'discount', width: 15},
            {header: t('Paid'), key: 'paid', width: 15},
            {header: t('Start mileage'), key: 'initialMileage', width: 15},
            {header: t('End mileage'), key: 'endMileage', width: 15},
            {header: t('Start battery level'), key: 'initialFuelLevel', width: 15},
            {header: t('End battery level'), key: 'endFuelLevel', width: 15},
            {header: t('Created with premium'), key: 'premium', width: 20},
        ];

        if (isCurrentUserSuperAdmin()) {
            columns.push({header: t('User id'), key: 'user', width: 32});
            columns.push({header: t('User name'), key: 'userName', width: 32});
            columns.push({header: t('User address'), key: 'userAddress', width: 32});
            columns.push({header: t('User city'), key: 'userCity', width: 32});
            columns.push({header: t('User country'), key: 'userCountry', width: 32});
            columns.push({header: t('User phone'), key: 'userPhone', width: 32});
        }

        worksheet.columns = columns;
    };

    const fillBookingRows = async (worksheet: Excel.Worksheet, bookings: Page<Booking>) => {
        for (let i = 0; i < (bookings?.size || 0); i++) {
            const booking = bookings.content?.[i];
            if (!booking?.id) continue;

            let discount;
            let invoice;
            try {
                invoice = await BookingService.getInvoice(booking.id);
                if (invoice?.invoiceFullData?.discount) {
                    if (invoice.invoiceFullData.discount.coupon.amount_off) {
                        discount = invoice.invoiceFullData.discount.coupon.amount_off / 100;
                    } else if (invoice.invoiceFullData.discount.coupon.percent_off) {
                        discount = (invoice.amount || 0) * invoice.invoiceFullData.discount.coupon.percent_off / 100;
                    }
                }
            } catch (e: any) {

            }

            worksheet.addRow({
                id: booking.id,
                from: booking.from,
                to: booking.sessionEndedAt || booking.to,
                createdAt: booking.createdAt,
                status: booking.status,

                car: booking.vehicle?.id,
                carName: vehicleDescriptor(booking.vehicle),

                user: booking.user,
                userName: booking.user?.name || '',
                userAddress: booking.user?.address || '',
                userCity: booking.user?.city || '',
                userCountry: booking.user?.country || '',
                userPhone: booking.user?.phone || '',

                partner: booking.organization?.name,
                totalRegularMinutes: booking.totalRegularMinutes,
                totalRegularPrice: booking.totalRegularPrice,
                totalOvertimeMinutes: booking.totalOvertimeMinutes,
                totalOvertimePrice: booking.totalOvertimePrice,
                totalPrice: booking.totalPrice,
                discount: discount,
                paid: (invoice?.invoiceFullData?.amount_paid || 0) / 100,
                initialMileage: booking.initialMileage || 0,
                endMileage: booking.initialMileage && booking.endMileage || 0,
                initialFuelLevel: booking.initialFuelLevel || 0,
                endFuelLevel: booking.initialFuelLevel && booking.endFuelLevel || 0,
                premium: booking.premium ? 'yes' : 'no',
            });
        }
    };

    const exportBookingsToXlsHandler = async () => {
        setExportInProgress(true);

        let workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Bookings');
        addBookingColumns(worksheet);

        let exporting = true;
        // let from = null;
        let total = 0;
        let page = 0;

        while (exporting) {
            const bookings = await AdminBookingService.getAllBookings(
                page,
                10,
                isCurrentUserSuperAdmin() ? undefined : props.claims?.organization?.id,
                undefined,
                undefined,
                search,
                fromDate && startOfDay(fromDate) || undefined,
                toDate && endOfDay(toDate) || undefined
            );
            await fillBookingRows(worksheet, bookings);

            if (bookings.isLastPage) {
                exporting = false;
            } else {
                page++;
                total += bookings?.content?.length || 0;
                setTotalExported(total);
            }
        }

        const buf = await workbook.xlsx.writeBuffer();

        setExportInProgress(false);

        saveAs(new Blob([buf]), 'export.xlsx');
    };

    const openBooking = (booking: Booking) => {
        props.history.push(`/admin/booking/${booking.id}`);
    };

    return (
        <Container>

            <Box display={'flex'}>

                {/*<FlexView wrap>*/}
                {/*    <VehicleFilter*/}
                {/*        id={vehicleFilter}*/}
                {/*        setId={setVehicleFilter}*/}
                {/*    />*/}

                {/*    {*/}
                {/*        props.claims?.isSuperAdmin &&*/}
                {/*        <OrganizationFilter*/}
                {/*            id={organizationFilter}*/}
                {/*            setId={setOrganizationFilter}*/}
                {/*        />*/}
                {/*    }*/}

                {/*</FlexView>*/}

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    flex={1}
                    alignItems={'flex-start'}
                >
                    <Search
                        placeholder={t('Search by user name and email, vehicle model/type/license, organization name')}
                        onSearch={setSearch}
                        disabled={exportInProgress}
                    />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <DatePicker
                                style={{marginTop: 0, marginBottom: 0, marginLeft: 10}}
                                label={t('From')}
                                maxDate={toDate}
                                value={fromDate || null}
                                inputVariant={'outlined'}
                                margin={'normal'}
                                format={'dd.MM.yyyy.'}
                                onChange={date => setFromDate(date)}
                                disabled={exportInProgress}
                                size={'small'}
                            />

                            <DatePicker
                                style={{marginTop: 0, marginBottom: 0, marginLeft: 10}}
                                label={t('To')}
                                maxDate={new Date()}
                                value={toDate || null}
                                inputVariant={'outlined'}
                                margin={'normal'}
                                format={'dd.MM.yyyy.'}
                                onChange={date => setToDate(date || null)}
                                disabled={exportInProgress}
                                size={'small'}
                            />

                        </MuiPickersUtilsProvider>
                </Box>

                <Box m={2}/>
                {
                    exportInProgress ?
                        <FlexView className={classes.exportingStatus} vAlignContent='center'>
                            <div>Exporting... {totalExported} so far...</div>
                            <CircularProgress/>
                        </FlexView>
                        :
                        <Button variant="contained" color="primary"
                                onClick={exportBookingsToXlsHandler}
                                className={classes.listActionButton}
                        >
                            {t('Export to XLS')}
                        </Button>
                }
            </Box>

            <Box m={3}/>

            <BookingList
                loading={loading}
                bookings={bookings}
                onBookingClick={openBooking}
                onPageChange={fetchBookings}
            />

        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(AdminBookingsList);
