import React from 'react';
import FlexView from "react-flexview";
import classes from './MapCarPreview.module.scss';
import clsx from 'clsx';
import {connect} from "react-redux";
import {getPriceForUser} from "../../../common/booking.util";
import {Vehicle} from "../../../models/Vehicle.model";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props {
    car?: Vehicle,
    lat: any;
    lng: any;
    onClick?: () => void;
    claims?: AuthClaims;
    onMouseLeave?: () => void;
}

const MapCarPreview = (props: Props) => {

    const t = useTranslation().t
    const price = getPriceForUser(props.car)

    return (
        <FlexView
            className={clsx(classes.component)}
            onClick={props.onClick}
            onMouseLeave={props.onMouseLeave}
        >

            {/* Car image */}
            <img className={classes.carImage} src={props.car?.image}/>

            {/* Details and price */}
            <FlexView column className={classes.details}>
                <div className={classes.carModel}>{props.car?.make} {props.car?.model}</div>
                <div>{props.car?.range}km</div>
                {
                    price > 0 ?
                        <div className={classes.price}>
                            {getPriceForUser(props.car, props.claims?.user, props.claims?.subscription)} kr/min
                        </div>
                        :
                        <div className={classes.price}>
                            {t('Free')}
                        </div>
                }
            </FlexView>

        </FlexView>
    );
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims
    }
};

export default connect(mapStateToProps)(MapCarPreview);
