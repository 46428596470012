import firebase from "firebase";
import {User} from "../models/User.model";
import {AuthClaims} from "../models/AuthClaims.model";
import api from './axios';
import {myFirebase} from "./firebase";

/**
 * Handles all actions related to authentication and profile update.
 */
export default class AuthServiceNew {

    static token: string | undefined;

    static tokenLogin = async (token?: string): Promise<AuthClaims | undefined> => {
        if (!token) return;

        const response = await api.post<AuthClaims>(`/auth/login`, {token});
        console.log('Token login response', response);
        if (response.data) {
            AuthServiceNew.token = response.data.token;
        }
        return {
            ...response.data,
            subscription: {
                ...response.data.subscription,
                createdAt: response?.data?.subscription?.createdAt && new Date(response.data.subscription.createdAt),
                expiration: response?.data?.subscription?.expiration && new Date(response.data.subscription.expiration),
            }
        };
    }

    /**
     * Signup using email and password
     * @param email
     * @param password
     * @param name
     */
    static signUp = async (email: string, password: string, name: string): Promise<AuthClaims | undefined> => {
        // Firebase authentication
        const response = await myFirebase.auth().createUserWithEmailAndPassword(email, password);
        await response.user?.updateProfile({displayName: name});

        // GCP auth
        const token = await myFirebase.auth().currentUser?.getIdToken();
        console.log(token);
        const claims = await AuthServiceNew.tokenLogin(token);

        return claims;
    };

    /**
     * Sign in using email and password
     * @param email
     * @param password
     */
    static signIn = async (email: string, password: string): Promise<AuthClaims | undefined> => {
        // Firebase authentication
        await myFirebase.auth().signInWithEmailAndPassword(email, password);

        // GCP auth
        const token = await myFirebase.auth().currentUser?.getIdToken();
        console.log(token);
        const claims = await AuthServiceNew.tokenLogin(token);

        return claims;
    };

    /**
     * Facebook and Google signup
     * @param providerId
     */
    static signInSocial = async (providerId: string): Promise<AuthClaims | undefined> => {
        let provider;
        switch (providerId) {
            case 'Google':
                provider = new firebase.auth.GoogleAuthProvider();
                break;
            case 'Facebook':
                provider = new firebase.auth.FacebookAuthProvider();
                break;
            default:
                return;
        }

        // Firebase auth (popup)
        provider.setCustomParameters({
            'display': 'popup'
        });
        await firebase.auth().signInWithPopup(provider);

        // GCP auth
        const token = await myFirebase.auth().currentUser?.getIdToken();
        console.log(token);
        const claims = await AuthServiceNew.tokenLogin(token);

        return claims;
    };

    /**
     * Sends password reset instructions
     * @param email
     */
    static resetPassword = async (email: string): Promise<void> => {
        return myFirebase.auth().sendPasswordResetEmail(email);
    };

    static async logout() {
        await myFirebase.auth().signOut();
        AuthServiceNew.token = '';
    }

    // /**
    //  * Fetch existing user using id
    //  * @param userId
    //  */
    // static loadUser = async (userId: string): Promise<User> => {
    //     const user = await getUserData(userId);
    //
    //     if (user) {
    //         user.subscription = await getUserSubscriptionData(userId);
    //     }
    //
    //     return user;
    // };
    //
    /**
     * Updates user fields and returns updated data
     * @param user
     */
    static updateInfo = async (user: User): Promise<User> => {
        const response = await api.put<User>('/profile', user);
        return response.data;
    }

    static requestChangeUserEmail = async (email: string) => {
        const response = await api.post<{ status: 'success' | 'error' }>('/profile/requestChangeUserEmail', {email});
        return response.data;
    }

    static confirmChangeUserEmail = async (code: string) => {
        const response = await api.post<User>('/profile/confirmChangeUserEmail', {code});
        return response.data;
    }

    //
    // static startObservingUser = (userId: string) => {
    //     AuthService.unsubscribeFromUser = getUserRef(userId).onSnapshot(async snapshot => {
    //         const user = getUserDataFromRef(snapshot);
    //         if (user) {
    //             user.subscription = await getUserSubscriptionData(userId);
    //         }
    //         store.dispatch({type: SET_USER, payload: user})
    //     });
    // }
    //
    // static stopObservingUser = () => {
    //     AuthService.unsubscribeFromUser && AuthService.unsubscribeFromUser();
    // }


    static async subscribe(type: string) {
        const response = await api.post<any>(`/profile/subscribe`, {
            type
        });
        return response.data;
    };

    static async requestData(action: string) {
        const response = await api.post<any>(`/profile/requestData`, {
            action
        });
        return response.data;
    };

    static async resendVerificationEmail(redirectTo?: string) {
        const response = await api.post<boolean>(`/auth/resendVerificationEmail`, {
            redirectTo
        });
        return response.data;
    };

    static verifyEmail = async (code?: string): Promise<AuthClaims | undefined> => {
        if (!code) return;

        const response = await api.post<AuthClaims>(`/auth/verifyEmail`, {code});
        console.log('Email verification response', response);
        if (response.data) {
            AuthServiceNew.token = response.data.token;
        }
        return {
            ...response.data,
            subscription: {
                ...response.data.subscription,
                createdAt: response?.data?.subscription?.createdAt && new Date(response.data.subscription.createdAt),
                expiration: response?.data?.subscription?.expiration && new Date(response.data.subscription.expiration),
            }
        };
    }

    static loginAsAnotherUser = async (user: User): Promise<AuthClaims | undefined> => {
        if (!user) return;

        const response = await api.post<AuthClaims>(`/auth/loginAsUser`, {userId: user.id});
        console.log('Login as another user', response);
        if (response.data) {
            AuthServiceNew.token = response.data.token;
        }
        return {
            ...response.data,
            subscription: {
                ...response.data.subscription,
                createdAt: response?.data?.subscription?.createdAt && new Date(response.data.subscription.createdAt),
                expiration: response?.data?.subscription?.expiration && new Date(response.data.subscription.expiration),
            }
        };
    }
}
