import {ADD_HISTORY_BOOKING, LOGOUT_DONE, SET_HISTORY_BOOKINGS,} from "../actions/actionTypes";
import {Booking} from "../../models/Booking.model";
import GcpError from "../../models/error.enum";

export interface HistoryState {
    bookings: Booking[];
    loadingBookings: boolean;
    bookingsError: GcpError;
    hasMoreBookings: boolean;

    // overview: HistoryOverview;
    // loadingOverview: boolean;
    // overviewError: GcpError;
    //
    // activeBookings: Booking[];
    // activeBookingsLoading: boolean;
    // activeBookingsLoadingError: GcpError;
}

const initialState: HistoryState = {
    bookings: [],
    loadingBookings: false,
    bookingsError: GcpError.NONE,
    hasMoreBookings: false,

    // overview: {
    //     totalMinutes: 0,
    //     totalSpent: 0,
    //     totalMileage: 0,
    //     totalCO2: 0
    // },
    // loadingOverview: false,
    // overviewError: GcpError.NONE,
    //
    // activeBookings: [],
    // activeBookingsLoading: false,
    // activeBookingsLoadingError: GcpError.NONE
};

const historyReducer = (state: HistoryState = initialState, action: any) => {
    let newBookings;

    switch (action.type) {
        case SET_HISTORY_BOOKINGS:
            const {bookings, append, loadingBooking, bookingsError, hasMoreBookings} = action.payload;
            if (append) {
                newBookings = [...state.bookings, ...bookings];
            } else {
                newBookings = bookings;
            }

            return {
                ...state,
                bookingsError,
                loadingBooking,
                bookings: newBookings,
                hasMoreBookings
            };

        // case SET_HISTORY_OVERVIEW:
        //     return {
        //         ...state,
        //         ...action.payload
        //     };

        // case SET_ACTIVE_BOOKINGS:
        //     return {
        //         ...state,
        //         ...action.payload
        //     };

        // case SET_CANCEL_BOOKING_DONE:
        //     let newHistoryBookings = state.bookings.filter(b => b.id !== action.payload);
        //     let newActiveBookings = state.activeBookings.filter(b => b.id !== action.payload);
        //
        //     return {
        //         ...state,
        //         bookings: newHistoryBookings,
        //         activeBookings: newActiveBookings
        //     };


        case ADD_HISTORY_BOOKING:
            newBookings = [...state.bookings];
            if (!newBookings.find(b => b.id === action.payload.id)) {
                newBookings.unshift(action.payload);
            }
            return {
                ...state,
                bookings: newBookings
            };

        case LOGOUT_DONE:
            return {
                ...initialState
            }
    }
    return state;
};

export default historyReducer;
