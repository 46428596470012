import React, {useEffect} from "react";
import FlexView from 'react-flexview';
import classes from './Landing.module.scss';
import HowItWorks from "../HowItWorks/HowItWorks.component";
import AboutUs from "../AboutUs/AboutUs.component";
import Pricing from "../Pricing/Pricing.component";
import AllViaApp from "../AllViaApp/AllViaApp.component";
import UnlockViaAppOrSms from "../UnlockViaAppOrSms/UnlockViaAppOrSms.component";
import LandingHeader from "../LandingHeader/LandingHeader.component";
import ContactForm from "../../contact/ContactForm/ContactForm.component";
import Footer from "../../../common/ui/Footer/Footer.component";
import AboutUsSecond from "../AboutUsSecond/AboutUsSecond.component";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

const Landing = () => {

    useEffect(() => {
        trackCurrentPage()
    }, [])

    const t = useTranslation().t

    return (
        <FlexView className='component' column vAlignContent='top' hAlignContent='center'>
            <LandingHeader/>
            <HowItWorks/>
            <AboutUs/>
            <AboutUsSecond/>
            <Pricing/>
            <AllViaApp/>
            <UnlockViaAppOrSms/>
            <FlexView className={classes.titleHolder} hAlignContent='center'>
                <div className={classes.title}>
                    {t('Contact Us')}
                </div>
            </FlexView>
            <ContactForm/>
            <Footer/>
        </FlexView>
    )
};

export default Landing;
