import api from './axios';
import {Organization} from "../models/Organization.model";
import {Page} from "../models/Page";

/**
 * Handles all actions related to vehicles.
 */
export default class AdminOrganizationService {

    static convertToWebFormat(organization?: Organization): Organization {
        if (!organization) return {};

        return {
            ...organization,
            createdAt: organization.createdAt && new Date(organization.createdAt),
            updatedAt: organization.updatedAt && new Date(organization.updatedAt),
        }
    }

    static getAll = async (page?: number, size?: number, search?: string): Promise<Page<Organization>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            search ? `search=${search}` : '',
        ].filter(x => !!x).join('&');


        const response = await api.get<Page<Organization>>(`/admin/organization?${params}`);

        return {
            ...response.data,
            content: response.data.content?.map(AdminOrganizationService.convertToWebFormat)
        }
    };

    static get = async (id: number): Promise<Organization> => {
        const response = await api.get(`/admin/organization/${id}`);
        return AdminOrganizationService.convertToWebFormat(response.data);
    }

    static add = async (organization: Organization): Promise<Organization> => {
        const response = await api.post(`/admin/organization`, organization);
        return AdminOrganizationService.convertToWebFormat(response.data);
    }

    static update = async (organization: Organization): Promise<Organization> => {
        const response = await api.put(`/admin/organization/${organization.id}`, organization);
        return AdminOrganizationService.convertToWebFormat(response.data);
    }
}
