import React from 'react';
import FlexView from "react-flexview";
import classes from './AboutUsSecond.module.scss';
import GreenMobility2 from '../../../assets/images/green-mobility-2.jpg';
import {useTranslation} from "react-i18next";

const AboutUsSecond = () => {

    const t = useTranslation().t

    return (
        <FlexView className={classes.component} hAlignContent='center' wrap>

            <img className={classes.image} src={GreenMobility2}/>

            <FlexView column className={classes.text} hAlignContent='center' vAlignContent='center'>
                <div
                    className={classes.short}>{t("We at GreenCarPool are convinced that vehicle sharing is the new, smart and modern way to have a vehicle")}</div>
                <div
                    className={classes.long}>{t("We at GreenCarPool are convinced that vehicle sharing is the new, smart and modern way to have a vehicle details")}</div>
            </FlexView>

        </FlexView>
    )
}

export default AboutUsSecond;
