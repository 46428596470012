import React from 'react';
import FlexView from "react-flexview";
import GoogleLogo from '../../../assets/icons/google-icon.svg';
import classes from './GoogleButton.module.scss';

const GoogleButton = (props: any) => (
    <FlexView className={classes.component} {...props}>
        <img className={classes.logo} src={GoogleLogo} />
        <div>Sign in with Google</div>
    </FlexView>
);

export default GoogleButton;
