import React, {Component} from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe,} from 'react-stripe-elements';
import {Button, CircularProgress} from "@material-ui/core";
import i18 from "i18next";
import BillingService from "../../services/billing.service";

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

class _SplitFieldsForm extends Component<any> {

    state = {
        errorMessage: '',
        setupInProgress: false,

    };

    element: any;

    handleChange = (event: any) => {
        if (event.error) {
            this.setState({errorMessage: event.error.message});
        }
    };

    handleSubmit = async (event: any) => {
        event.preventDefault();
        if (this.props.stripe) {

            try {

                this.setState({
                    setupInProgress: true,
                    errorMessage: null,
                });

                const setupIntent = await BillingService.getSetupIntent()

                const result = await this.props.stripe.handleCardSetup(setupIntent?.client_secret);

                if (!result || !result.setupIntent) {
                    this.setState({
                        setupInProgress: false,
                        errorMessage: i18.t('The card is declined by the bank. Please use another card.')
                    });
                } else {
                    if (this.props.handleResult) {
                        this.props.handleResult(result.setupIntent);
                    }
                }
            } catch (e: any) {
                this.setState({
                    setupInProgress: false,
                    errorMessage: e?.toString()
                });
            }

        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    handleReady = (element: any) => {
        this.element = element;
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="split-form">
                    <label>
                        {i18.t('Card number')}
                        <CardNumberElement
                            onReady={this.handleReady}
                            {...createOptions()}
                            onChange={this.handleChange}
                        />
                    </label>
                    <label>
                        {i18.t('Expiration date')}
                        <CardExpiryElement
                            {...createOptions()}
                            onChange={this.handleChange}
                        />
                    </label>
                </div>
                <div className="split-form">
                    <label>
                        {i18.t('CVC')}
                        <CardCVCElement {...createOptions()} onChange={this.handleChange}/>
                    </label>
                    {/*<label>*/}
                    {/*Postal code*/}
                    {/*<input*/}
                    {/*name="name"*/}
                    {/*type="text"*/}
                    {/*placeholder="94115"*/}
                    {/*className="StripeElement"*/}
                    {/*required*/}
                    {/*/>*/}
                    {/*</label>*/}
                </div>
                <div className="error" role="alert">
                    {this.state.errorMessage}
                </div>

                {
                    this.state.setupInProgress ?
                        <CircularProgress/> :
                        <Button type="submit" variant='contained' style={{width: '100%'}} color="primary">
                            {i18.t('Save')}
                        </Button>
                }
            </form>
        );
    }
}

export const SplitFieldsForm = injectStripe(_SplitFieldsForm);
