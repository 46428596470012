import React, {useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from "./Booking.module.scss";
import {BrowserView, MobileView} from "react-device-detect";
import {TextField} from "@material-ui/core";
import {addDays, addHours, differenceInHours, differenceInMinutes, format, isAfter, parseISO} from "date-fns";
import FromTo from "../../../assets/icons/from-to.svg";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LeftArrow from "../../../assets/icons/left-arrow.svg";
import RightArrow from "../../../assets/icons/right-arrow.svg";
import Timeline from "../Timeline/Timeline.component";
import {isPhone} from "../../../common/ui.util";
import {Booking} from "../../../models/Booking.model";
import {Maintenance} from "../../../models/Maintenance.model";
import {multiplePeriodsOverlap, convertPeriodicAvailabilityAsMaintenance} from "../../../common/booking.util";
import {PeriodicAvailability} from "../../../models/PeriodicAvailability.model";
import {useTranslation} from "react-i18next";

interface Props {
    booking?: Booking;
    editingBooking?: Booking;
    dateFrom?: string,
    dateTo?: string,
    start?: Date;
    end?: Date;
    isNewBooking: boolean;
    editing: boolean;
    setBookingTime: (start?: Date, end?: Date) => void;
    showTimeline?: boolean;
    otherBookings: Booking[];
    maintenance: Maintenance[];
    hoursLimit?: number;
    periodicAvailability?: PeriodicAvailability[]
}

const BookingTimepickers = (props: Props) => {

    const t = useTranslation().t
    
    const [dates, setDates] = useState([
        new Date(),
        addDays(new Date(), 1),
        addDays(new Date(), 2),
        addDays(new Date(), 3),
        addDays(new Date(), 4),
        addDays(new Date(), 5),
        addDays(new Date(), 6),
    ]);

    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [selectingFrom, setSelectingFrom] = useState(false);
    const [maxBookingTimeWarningVisible, setMaxBookingTimeWarningVisible] = useState(false);

    const periodicAvailabilityTimeRanges: Maintenance[] = convertPeriodicAvailabilityAsMaintenance(dates[0], dates[5], 21, props.periodicAvailability)

    const openDatePicker = (from: boolean) => {
        if (props.isNewBooking || props.editing) {
            setSelectingFrom(from);
            setTimeout(() => setDatePickerVisible(true), 0);
        }
    };

    const handleDatePickerClose = () => {
        setDatePickerVisible(false);
    };

    const handleDatePick = (v?: Date | null, selectingFrom?: boolean) => {
        const pickedDate = v || undefined;

        const newStart = selectingFrom ? pickedDate : props.start;
        let newEnd = selectingFrom ? props.end : pickedDate;

        if (newStart && newEnd) {
            if (isAfter(newStart, newEnd)) {
                newEnd = undefined;
            }

            if (props.hoursLimit && newStart && newEnd && differenceInHours(newEnd, newStart) > props.hoursLimit) {
                newEnd = addHours(newStart, props.hoursLimit);
                setMaxBookingTimeWarningVisible(true);
            }
        }

        props.setBookingTime(newStart, newEnd);

        setDatePickerVisible(false);
        setSelectingFrom(false);

        if (selectingFrom) {
            setNewDates(pickedDate);
        }
    };

    const handlePickStart = (date: Date) => {
        props.setBookingTime(date);
    };

    const handlePickProgress = (start?: Date, end?: Date) => {
        if (start && end && props.hoursLimit) {
            if (differenceInMinutes(end, start) > props.hoursLimit * 60) {
                end = addHours(start, props.hoursLimit);
                setMaxBookingTimeWarningVisible(true);
            }
        }
        props.setBookingTime(start, end);
    };

    const setNewDates = (startingDate?: Date) => {
        if (!startingDate) return;

        const newDates = [
            startingDate,
            addDays(startingDate, 1),
            addDays(startingDate, 2),
            addDays(startingDate, 3),
            addDays(startingDate, 4),
            addDays(startingDate, 5),
            addDays(startingDate, 6),
        ];
        setDates(newDates);
    };

    return (
        <React.Fragment>
            <FlexView className={classes.pickers} vAlignContent='top' hAlignContent='center'>

                <FlexView column style={{flexGrow: 1}} hAlignContent='center'>
                    <FlexView wrap className={classes.chosenRange} vAlignContent='center'>
                        <BrowserView viewClassName={classes.inputWrapperWeb}>
                            <div className={classes.time} onClick={() => openDatePicker(true)}>
                                {props.dateFrom}
                            </div>
                        </BrowserView>
                        <MobileView viewClassName={classes.inputWrapperMobile}>
                            <label
                                className={classes.nativeDateInputLabel} htmlFor="startDate">
                                {props.dateFrom}
                            </label>
                            <TextField
                                id="startDate"
                                label={''}
                                type="datetime-local"
                                defaultValue={props.start}
                                className={classes.nativeDateInput}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    handleDatePick(event.target.value ? parseISO(event.target.value) : null, true);
                                }}
                            />
                        </MobileView>

                        <img className={classes.timeFromTo} src={FromTo}/>

                        <BrowserView viewClassName={classes.inputWrapperWeb}>
                            <div className={classes.time} onClick={() => openDatePicker(false)}>
                                {props.dateTo}
                            </div>
                        </BrowserView>
                        <MobileView viewClassName={classes.inputWrapperMobile}>
                            <label
                                className={classes.nativeDateInputLabel} htmlFor="endDate">
                                {props.dateTo}
                            </label>
                            <TextField
                                id="endDate"
                                label={t('')}
                                type="datetime-local"
                                defaultValue={props.end}
                                className={classes.nativeDateInput}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    handleDatePick(event.target.value ? parseISO(event.target.value) : null, false);
                                }}
                            />
                        </MobileView>
                    </FlexView>
                    <FlexView vAlignContent='center' className={classes.overlapError}>
                        {
                            multiplePeriodsOverlap(props.otherBookings, props.booking) &&
                            <div>{t('Your times overlap with other bookings. Please check availability and pick another times...')}</div>
                        }
                        {maxBookingTimeWarningVisible && <div>{t('Current booking limit is 48h')}</div>}
                    </FlexView>
                </FlexView>

                {/*{renderTotalPriceDesktop()}*/}

            </FlexView>

            {
                props.showTimeline &&
                <FlexView vAlignContent='center'>
                    <div className={classes.availability}>{t('Availability')}</div>

                    <FlexView className={classes.timelineModifyDates} vAlignContent='center'>
                        {
                            isAfter(dates[0], new Date())
                            &&
                            <img className={classes.timelineArrow} src={LeftArrow}
                                 onClick={() => setNewDates(addDays(dates[0], -1))}
                            />
                        }


                        <div
                            className={classes.timelineCurrentDate}>{format(dates[0], 'dd.MM.')}</div>

                        <img className={classes.timelineArrow} src={RightArrow}
                             onClick={() => setNewDates(addDays(dates[0], 1))}
                        />
                    </FlexView>
                </FlexView>
            }

            {
                props.showTimeline &&
                <FlexView className={classes.timeline}>
                    <Timeline dates={isPhone() ? dates.slice(0, 4) : dates}
                              bookings={props.otherBookings}
                              maintenance={[...props.maintenance, ...periodicAvailabilityTimeRanges]}
                              newBooking={props.editing ? props.editingBooking : props.booking}
                              onPickStart={handlePickStart}
                              onPickProgress={handlePickProgress}
                              interactive={!isPhone() && (props.isNewBooking || props.editing)}
                              time={isPhone() ? 'sparse' : 'full'}
                    />
                </FlexView>
            }

            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <DateTimePicker
                    style={{display: 'none'}}
                    open={datePickerVisible}
                    autoOk
                    ampm={false}
                    disablePast
                    value={selectingFrom ? props.start || new Date() : props.end || new Date()}
                    minDate={selectingFrom ? null : props.start || new Date()}
                    // maxDate={selectingFrom ? props.end : null}
                    label="From"
                    onChange={date => handleDatePick(date, selectingFrom)}
                    onClose={handleDatePickerClose}
                />

            </MuiPickersUtilsProvider>

        </React.Fragment>
    )
}

export default BookingTimepickers;