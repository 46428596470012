import api from './axios';
import {Booking} from "../models/Booking.model";
import {Page} from "../models/Page";

/**
 * Handles all actions related to vehicles.
 */
export default class AdminBookingService {

    static convertToWebFormat(booking?: Booking): Booking | undefined {
        if (!booking) return;

        return {
            ...booking,
            createdAt: booking.createdAt && new Date(booking.createdAt),
            updatedAt: booking.updatedAt && new Date(booking.updatedAt),
            from: booking.from && new Date(booking.from),
            to: booking.to && new Date(booking.to),
            sessionEndedAt: booking.sessionEndedAt && new Date(booking.sessionEndedAt),
            vehicleReturnedAt: booking.vehicleReturnedAt && new Date(booking.vehicleReturnedAt)
        }
    }

    static getAllBookings = async (
        page?: number,
        size?: number,
        organizationId?: number,
        vehicleId?: number,
        userId?: number,
        search?: string,
        from?: Date,
        to?: Date
    ): Promise<Page<Booking>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            organizationId ? `organizationId=${organizationId}` : '',
            vehicleId ? `vehicleId=${vehicleId}` : '',
            userId ? `userId=${userId}` : '',
            search ? `search=${search}` : '',
            from ? `from=${from.toISOString()}` : '',
            to ? `to=${to.toISOString()}` : ''
        ].filter(x => !!x).join('&');

        const response = await api.get(`/admin/booking?${params}`);

        return {
            ...response.data,
            content: response.data.content?.map(AdminBookingService.convertToWebFormat)
        }
    };

    static getBooking = async (id: number) => {
        const response = await api.get(`/admin/booking/${id}`);
        return AdminBookingService.convertToWebFormat(response.data);
    };

    static updateBooking = async (booking?: Booking): Promise<Booking | undefined> => {
        const response = await api.put<Booking>(`/admin/booking/${booking?.id}`, {
            booking
        });
        return AdminBookingService.convertToWebFormat(response.data);
    };
}
