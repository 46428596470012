import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Dialog} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import {useSelector} from "react-redux";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {Vehicle} from "../../../models/Vehicle.model";
import {Page} from "../../../models/Page";
import AdminVehicleService from "../../../services/adminVehicle.service";
import Search from "../../../common/ui/Search/Search";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean;
    onClose?: () => void;
    onPick?: (vehicle: Vehicle) => void;
}

const VehiclePicker = ({open, onClose, onPick}: Props) => {

    const t = useTranslation().t
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState<Page<Vehicle>>();
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);

    useEffect(() => {
        fetchVehicles();
    }, [claims]);

    const fetchVehicles = async () => {
        if (!claims?.user) return;
        setLoading(true);
        const vehicles = await AdminVehicleService.getAllVehicles(0, 1000);
        setVehicles(vehicles);
        setLoading(false);
    };

    const renderList = () => {
        if (loading) {
            return (
                <FlexView className={classes.listLoading} hAlignContent={"center"}>
                    <CircularProgress/>
                </FlexView>
            )
        }

        if (vehicles?.content?.length) {
            return (
                <FlexView column className={clsx('container', classes.list)}>

                    <FlexView vAlignContent='center' className={classes.header}>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Model')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('License')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Type')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>Organization</div>
                    </FlexView>

                    {
                        vehicles?.content?.map(car => {
                            return (
                                <FlexView onClick={() => onPick?.(car)} key={car.id} vAlignContent='center'
                                          className={classes.tableRow}>
                                    <FlexView className={classes.cellSmall} vAlignContent='center'>
                                        <img className={classes.rowIcon} src={car.image}/>
                                        <div>{`${car.make} ${car.model}`}</div>
                                    </FlexView>
                                    <div className={classes.cellSmall}>{car.license || '-'}</div>
                                    <div className={classes.cellSmall}>{car.vehicleType || '-'}</div>
                                    <div className={classes.cellSmall}>{car.organization?.name || '-'}</div>
                                </FlexView>
                            )
                        })
                    }
                </FlexView>
            );
        } else {
            return <div>No vehicles found</div>
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <Box p={3}>
                <Search/>
                <Box m={2}/>
                {renderList()}
            </Box>
        </Dialog>
    )
}

export default VehiclePicker;
