import React from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './Marker.module.scss';
import clsx from 'clsx';
import VehicleTypeCarGreen from '../../../assets/icons/vehicle-type-car-green.svg';
import VehicleTypeScooterOrange from '../../../assets/icons/vehicle-type-scooter-orange.svg';
import VehicleTypeBicycleBlue from '../../../assets/icons/vehicle-type-bicycle-blue.svg';
import {VehicleType} from "../../../models/Vehicle.model";

interface Props {
    text: string;
    selected: boolean;
    lat: any;
    lng: any;
    onClick: () => void;
    type: VehicleType;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const Marker = (props: Props) => {

    const handleClick = (event: any) => {
        event.stopPropagation();
        props.onClick();
    };

    let mainClass;
    let icon;

    switch (props.type) {
        case VehicleType.CAR:
            mainClass = classes.car;
            icon = VehicleTypeCarGreen;
            break;
        case VehicleType.SCOOTER:
            mainClass = classes.scooter;
            icon = VehicleTypeScooterOrange;
            break;
        case VehicleType.BICYCLE:
            mainClass = classes.bicycle;
            icon = VehicleTypeBicycleBlue;
            break;

    }

    return (
        <FlexView
            className={clsx(classes.component, mainClass)}
            hAlignContent='center'
            vAlignContent='center'
            onClick={handleClick}
        >

            <img
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseEnter}
                className={classes.icon}
                src={icon}
            />

            {/*<div className={classes.carName}>{props.text}</div>*/}
        </FlexView>
    );
};

export default Marker;