import React, {useState} from 'react';
import FlexView from "react-flexview";
import {format} from "date-fns";
import {CircularProgress} from "@material-ui/core";
import classes from './Billing.module.scss';
import Download from '../../../assets/icons/download.svg';
import Premium from '../../../assets/icons/premium.svg';
import Car from '../../../assets/icons/car-make-grey.svg';
import {RouteComponentProps, withRouter} from "react-router";
import StripeInvoice from "../../../models/StripeInvoice.model";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    invoice: StripeInvoice;
}

const InvoicePreview = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [download, setDownload] = useState(false);

    // ***** Handlers ***** //

    const getInvoiceForBooking = async (invoice: StripeInvoice) => {
        if (!invoice.hosted_invoice_url) return;

        setDownload(true);

        try {
            window.open(invoice.hosted_invoice_url, '_blank')
            // await FileSaver.saveAs(invoice.invoice_pdf);
        } catch (e: any) {
            console.log("Error while downloading the invoice...");
        } finally {
            setDownload(false);
        }
    };

    // ***** Render ***** //

    let invoiceImage = Car;
    const type = props.invoice.metadata && props.invoice.metadata.type || '';
    switch (type) {
        case 'subscriptionRenewal':
            invoiceImage = Premium;
            break;
        case 'booking':
        default:
            invoiceImage = Car;
            break;
    }

    return (
        <FlexView className={classes.invoice} vAlignContent='center'>

            <img className={classes.invoiceTypeIcon} src={invoiceImage}/>

            <FlexView column>
                <div> {format(new Date(props.invoice.created * 1000), 'dd/MM/yyyy HH:mm')}</div>
                <div className={classes.invoiceId}>{props.invoice.amount_due / 100} {props.invoice.currency}</div>
            </FlexView>

            <FlexView className={classes.invoiceStatusHolder}>
                <div>{props.invoice.paid ? t('Paid') : t('Not paid')}</div>
            </FlexView>

            <FlexView className={classes.invoiceDownloadHolder} vAlignContent='center' hAlignContent='center'>
                {
                    download
                        ?
                        <CircularProgress size={25}/>
                        :
                        props.invoice.hosted_invoice_url ? <img src={Download} className={classes.invoiceDownload}
                                                                onClick={() => getInvoiceForBooking(props.invoice)}/> : null
                }
            </FlexView>

        </FlexView>
    )
};


export default withRouter(InvoicePreview);
