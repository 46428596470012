import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Typography, withStyles} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlexView from "react-flexview";
import classes from './FAQ.module.scss';
import clsx from 'clsx';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Footer from "../../../common/ui/Footer/Footer.component";
import GcpInput from "../../../common/ui/GcpInput";
import FaqService from "../../../services/faq.service";
import {FaqItem} from "../../../models/FaqItem.model";
import {isCurrentUserSuperAdmin} from "../../../common/user.util";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
        maxWidth: 800,
        marginBottom: 10,
        border: '1px solid #e9e9e9',
        boxShadow: 'none',
        background: 'white',
        borderRadius: 4,
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            boxShadow: '0 0 13px rgba(0, 0, 0, 0.14)',
            marginTop: 0,
            marginBottom: 16
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        // minHeight: 56,
        // '&$expanded': {
        //     minHeight: 56,
        // },
        fontWeight: 300,

    },
    content: {
        // '&$expanded': {
        //     margin: '12px 0',
        // },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((_: any) => ({
    root: {
        color: 'rgba(0, 0, 0, 0.57)',
        // padding: theme.spacing(2),
        flexDirection: 'column'
    },
}))(MuiExpansionPanelDetails);


const FAQ = () => {

    // ***** State ***** //

    const {t, i18n} = useTranslation()
    const [data, setData] = useState<FaqItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [query, setQuery] = useState('');

    const currentLanguage = i18n.language || 'sv';

    // ***** Effects ***** //

    const loadFaq = async () => {
        try {
            let faq = await FaqService.getFaq();
            setData(faq || []);
            setDirty(false);
        } catch (e: any) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const saveFaq = async () => {
        try {
            await FaqService.updateFaq(data);
            setDirty(false);
        } catch (e: any) {
            console.log(e);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        loadFaq();
    }, [false]);

    useEffect(() => {
        trackCurrentPage()
    }, [])

    // ***** Render ***** //

    const getQuestionLocalized = (faq: FaqItem) => {
        const question = faq && faq['question'];
        // @ts-ignore
        const questionLocalized = question[currentLanguage] || question['sv'] || '';
        return questionLocalized.split('\\n').join('\n');
    };

    const getAnswerLocalized = (faq: FaqItem) => {
        const answer = faq && faq['answerNew'];
        // @ts-ignore
        let answerLocalized = [];
        if (answer?.[currentLanguage]?.length) {
            answerLocalized = answer[currentLanguage];
        } else if (answer?.['sv']?.length) {
            answerLocalized = answer['sv'];
        }

        // return answerLocalized.split('\\n').join('\n');
        // @ts-ignore
        return answerLocalized?.map(part => {
            switch (part.type) {
                case "text":
                    return (<Typography>{part.value}</Typography>)
                case "image":
                    return (
                        <img src={part.value} className={classes.answerImage}/>
                    )
                case "video":
                    return (
                        <video width="100%'" height="400" controls className={classes.answerImage}>
                            <source src={part.value}/>
                            Your browser does not support the video tag.
                        </video>
                    )

            }
        })
    };

    const renderRegularContent = () => {
        if (loading) {
            return (
                <CircularProgress/>
            )
        }

        let dataToRender = data;
        if (query) {
            const queryClean = query.toLowerCase().trim();
            dataToRender = data.filter(item => {
                let matchFound = false;
                Object.keys(item.question || {}).forEach(lang => {
                    const match = item.question?.[lang]?.toLowerCase().includes(queryClean) || false;
                    matchFound = matchFound || match;
                });

                Object.keys(item.answerNew || {}).forEach(lang => {
                    const answerParts = item.answerNew?.[lang] || [];
                    answerParts.filter(answerPart => answerPart.type === 'text').forEach(answerPart => {
                        const match = answerPart?.value?.toLowerCase().includes(queryClean) || false;
                        matchFound = matchFound || match;
                    })
                });

                return matchFound;
            })
        }

        return (
            dataToRender.map((item, index) => (
                <ExpansionPanel key={query ? 'q' + index : index} expanded={query ? true : undefined}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>{getQuestionLocalized(item)}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{whiteSpace: 'pre-line'}}>
                        {getAnswerLocalized(item)}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))
        )
    }

    const renderGcpInputQuestion = (item: FaqItem, lang: string, elementIndex: number) => {
        return (
            <GcpInput
                className={classes.faqInput}
                value={item.question?.[lang]}
                multiline
                onChange={t => {
                    const newItem: FaqItem = {
                        ...item,
                        question: {
                            ...item.question,
                            [lang]: t.target.value
                        }
                    };
                    setDirty(true);
                    const newData = [...data];
                    newData[elementIndex] = newItem;
                    setData(newData);
                }}
            />
        )
    }

    const renderGcpInputQuestionAnswer = (item: FaqItem, answerIndex: number, lang: string, elementIndex: number) => {
        return (
            <FlexView className={classes.faqInputRow}>
                <div>{item.answerNew?.[lang]?.[answerIndex]?.type}</div>
                <GcpInput
                    className={classes.faqInput}
                    value={item.answerNew?.[lang]?.[answerIndex].value}
                    multiline
                    onChange={t => {
                        const newParts = [...(item.answerNew?.[lang] || [])];
                        newParts[answerIndex] = {
                            ...newParts[answerIndex],
                            value: t.target.value
                        }

                        const newItem: FaqItem = {
                            ...item,
                            answerNew: {
                                ...item.answerNew,
                                [lang]: newParts
                            }
                        };
                        setDirty(true);
                        const newData = [...data];
                        newData[elementIndex] = newItem;
                        setData(newData);
                    }}
                />
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => removeQuestionAnswer(item, lang, answerIndex, elementIndex)}
                >Delete</Button>
            </FlexView>
        )
    }

    const removeQuestionAnswer = (item: FaqItem, lang: string, answerIndex: number, elementIndex: number) => {
        const newParts = [...(item.answerNew?.[lang] || [])];
        newParts.splice(answerIndex, 1);

        const newItem: FaqItem = {
            ...item,
            answerNew: {
                ...item.answerNew,
                [lang]: newParts
            }
        };
        setDirty(true);
        const newData = [...data];
        newData[elementIndex] = newItem;
        setData(newData);
    }

    const addQuestionAnswer = (item: FaqItem, lang: string, type: 'text' | 'image' | 'video', elementIndex: number) => {
        const newParts = [...(item.answerNew?.[lang] || [])];
        newParts.push({
            type,
            value: ''
        })

        const newItem: FaqItem = {
            ...item,
            answerNew: {
                ...item.answerNew,
                [lang]: newParts
            }
        };
        setDirty(true);
        const newData = [...data];
        newData[elementIndex] = newItem;
        setData(newData);
    }


    const move = (index: number, direction: 1 | -1) => {
        const item = data[index];
        const swapWith = data[index + direction];
        const newData = [...data];
        newData[index] = swapWith;
        newData[index + direction] = item;
        setData(newData);
        setDirty(true);
    }

    const removeFaq = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        setDirty(true);
    }

    const renderEditingContent = () => {
        if (loading) {
            return (
                <CircularProgress/>
            )
        }

        return (
            data.map((item, index) => (
                <FlexView column className={classes.faqEditWrapper}>

                    <FlexView className={classes.editingButtons}>
                        {
                            index > 0 &&
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                onClick={() => move(index, -1)}
                            >Up</Button>
                        }

                        {
                            index < data.length - 1 &&
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                onClick={() => move(index, 1)}
                            >Down</Button>
                        }

                        {
                            <Button
                                color={'secondary'}
                                variant={'contained'}
                                onClick={() => removeFaq(index)}
                            >Delete</Button>
                        }
                    </FlexView>

                    <FlexView vAlignContent={'center'} className={classes.faqInputRow}>
                        <div className={classes.faqLabel}>Question English</div>
                        {renderGcpInputQuestion(item, 'en', index)}
                    </FlexView>
                    <FlexView vAlignContent={'center'} className={classes.faqInputRow}>
                        <div className={classes.faqLabel}>Question Swedish</div>
                        {renderGcpInputQuestion(item, 'sv', index)}
                    </FlexView>

                    <FlexView vAlignContent={'top'}>
                        <div className={classes.faqLabel}>Answer English</div>

                        <FlexView column>
                            {
                                item.answerNew?.['en']?.map((_, partIndex) => renderGcpInputQuestionAnswer(item, partIndex, 'en', index))
                            }

                            <FlexView className={classes.editingButtons}>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'en', 'text', index)}
                                >+Text</Button>

                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'en', 'image', index)}
                                >+Image</Button>

                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'en', 'video', index)}
                                >+Video</Button>

                            </FlexView>
                        </FlexView>
                    </FlexView>

                    <FlexView vAlignContent={'top'}>
                        <div className={classes.faqLabel}>Answer Swedish</div>

                        <FlexView column>
                            {
                                item.answerNew?.['sv'].map((_, partIndex) => renderGcpInputQuestionAnswer(item, partIndex, 'sv', index))
                            }

                            <FlexView className={classes.editingButtons}>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'sv', 'text', index)}
                                >+Text</Button>

                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'sv', 'image', index)}
                                >+Image</Button>

                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => addQuestionAnswer(item, 'sv', 'video', index)}
                                >+Video</Button>

                            </FlexView>
                        </FlexView>
                    </FlexView>

                </FlexView>
            ))
        )
    }

    return (
        <FlexView className={clsx('component', classes.component)} hAlignContent='center' column>
            <div className={classes.title}>{t('FAQ')}</div>

            {
                isCurrentUserSuperAdmin() &&
                <FlexView className={classes.editingButtons}>
                    <Button
                        color={!editing && 'primary' || undefined}
                        variant={'contained'}
                        onClick={() => setEditing(false)}
                    >View</Button>

                    <Button
                        color={editing && 'primary' || undefined}
                        variant={'contained'}
                        onClick={() => setEditing(true)}
                    >Edit</Button>
                </FlexView>
            }

            {
                !editing &&
                <GcpInput
                    className={classes.search}
                    variant={'outlined'}
                    value={query}
                    margin={'normal'}
                    label={t('Search')}
                    onChange={(event) => setQuery(event.target.value)}
                />
            }

            <FlexView hAlignContent={'center'} column className={classes.faqHolder}>
                {editing ? renderEditingContent() : renderRegularContent()}
            </FlexView>

            {
                isCurrentUserSuperAdmin() && editing &&
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => {
                        setData([...(data || []), {
                            question: {
                                en: '',
                                sv: ''
                            },
                            answerNew: {
                                en: [],
                                sv: []
                            }
                        }])
                    }}
                >Add new FAQ</Button>
            }

            {
                isCurrentUserSuperAdmin() && dirty &&
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={saveFaq}
                >Save</Button>
            }

            <Footer/>

        </FlexView>
    )
};


export default FAQ;