import React from 'react';
import classes from './CarDetails.module.scss';
import CarSpec from "../CarSpec/CarSpec.component";
import FlexView from "react-flexview/lib/FlexView";
import {Vehicle} from "../../../models/Vehicle.model";
import {useTranslation} from "react-i18next";

interface Props {
    car?: Vehicle
}

const CarDetails = (props: Props) => {

    const {t, i18n} = useTranslation()
    
    if (!props.car) return null;

    return (
        <FlexView column hAlignContent='center'>
            <div className={classes.sectionTitle}>{t('Info')}</div>
            <CarSpec car={props.car}/>

            <div className={classes.sectionTitle}>{t('Rules')}</div>
            <div className={classes.rules}>
                {props.car.rules && props.car.rules[i18n.language]}
            </div>

            <div className={classes.sectionTitle}>{t('Important notes')}</div>
            <div className={classes.importantNotes}>
                {props.car.important && props.car.important[i18n.language]}
            </div>

            <div className={classes.sectionTitle}>{t('Cancellation')}</div>
            <div className={classes.cancellation}>
                {props.car.cancellation && props.car.cancellation[i18n.language]}
            </div>
        </FlexView>
    )

};

export default CarDetails;
