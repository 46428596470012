import React, {useState} from "react";
import {Button, CircularProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import FlexView from "react-flexview";
import classes from './SignupForm.module.scss';
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import {
    emailValidator,
    isFormValid,
    minLengthValidator,
    requiredValidator,
    validateFormElement
} from '../../../common/validators.util';
import clsx from "clsx";
import GcpInput from "../../../common/ui/GcpInput";
import {connect} from "react-redux";
import {initSignin, initSignup, initSocialLogin, setAuthError} from "../../../store/actions/auth.actions";
import {Link} from "react-router-dom";
import GoogleButton from "../../../common/ui/GoogleButton/GoogleButton.component";
import AlertDialog from "../../../common/ui/AlertDialog.component";
import {isUserInfoFull} from "../../../common/user.util";
import {bindActionCreators} from "redux";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    error: string;
    claims?: AuthClaims,
    signInProgress: boolean,
    redirectToOnSignin: string,

    initSignup: typeof initSignup;
    initSignin: typeof initSignin;
    initSocialLogin: typeof initSocialLogin;
    setAuthError: typeof setAuthError;

    signupForm: boolean;
}

type formKey = 'name' | 'email' | 'password';

const SignupForm = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [form, setForm] = useState({
        name: {
            label: t('Name'),
            placeholder: 'ex. John Smith',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: props.signupForm && [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                    (value: string) => minLengthValidator(value, 2, t('Name should be at least ... characters long', {length: 2}))
                ]
            }
        },
        email: {
            label: t('Email'),
            placeholder: 'ex. john.smith@example.com',
            type: 'email',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                    (value: string) => emailValidator(value, t('Please enter valid email address'))
                ]
            }
        },
        password: {
            label: 'Password',
            placeholder: '',
            type: 'password',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                    (value: string) => minLengthValidator(value, 6, t('Password should be at least ... characters long', {length: 6}))
                ]
            }
        },
    });

    const [alertDialogVisible, setAlertDialogVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');

    // ***** Handlers ***** //

    const inputHandler = (fieldId: formKey, value: any) => {
        const newFormField = {
            ...form[fieldId],
            value: value,
            validation: {
                ...form[fieldId].validation,
                error: ''
            }
        };
        setForm({
            ...form,
            [fieldId]: newFormField
        });
    };

    const confirmHandler = async () => {
        validateForm();
        if (isFormValid(form)) {
            if (props.signupForm) {
                setConfirmAction('Email');
                setAlertDialogVisible(true);
            } else {
                props.initSignin(form.email.value, form.password.value);
            }
        }
    };

    const onStartSocialLogin = (snName: string) => {
        setConfirmAction(snName);
        setAlertDialogVisible(true);
    };

    const onConfirmToS = () => {
        switch (confirmAction) {
            case 'Email':
                props.signupForm ?
                    props.initSignup(form.name.value, form.email.value, form.password.value) :
                    props.initSignin(form.email.value, form.password.value);
                break;
            case 'Google':
                props.initSocialLogin('Google');
                break;
            case 'Facebook':
                props.initSocialLogin('Facebook');
                break;
        }
    };

    const hideSnackbar = () => {
        props.setAuthError('');
    };

    const validateForm = () => {
        const newForm: any = {};
        // @ts-ignore
        Object.keys(form).forEach((key: formKey) => {
            let newFormElement = {...form[key]};
            validateFormElement(newFormElement);
            newForm[key] = newFormElement;
        });

        setForm(newForm);
    };

    const mapErrorToString = (error: string) => {
        switch (error) {
            case 'auth/email-already-in-use':
                return t('This email address is already in use!');
            case 'auth/account-exists-with-different-credential':
                return t('You already have an account created using different login type!');
            default:
                return t('Ooops, something went wrong. Please try again');
        }
    };

    // ***** Render ***** //

    const renderSnackBar = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={!!props.error}
                autoHideDuration={5000}
                onClose={hideSnackbar}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={mapErrorToString(props.error)}
                />
            </Snackbar>
        );
    };

    const renderForm = () => {
        let keys = ['email', 'password'];
        if (props.signupForm) {
            keys.unshift('name');
        }
        // @ts-ignore
        return keys.map((key: formKey) => {
            const formElement = form[key];
            return (
                <GcpInput
                    error={!!formElement.validation.error}
                    key={key}
                    className={classes.signupElement}
                    label={formElement.label}
                    placeholder={formElement.placeholder}
                    margin="normal"
                    type={formElement.type}
                    // variant="outlined"
                    disabled={props.signInProgress}
                    value={formElement.value}
                    helperText={formElement.validation.error}
                    onChange={(event) => inputHandler(key, event.target.value)}
                />
            )
        });
    };

    const renderAlertDialog = () => (
        <AlertDialog
            open={alertDialogVisible}
            onClose={() => setAlertDialogVisible(false)}
            onConfirm={() => {
                onConfirmToS();
                setAlertDialogVisible(false);
            }}
            title={t('Accept ToS?')}
            message={t('By continuing, you are indicating that you accept our Terms of Service and Privacy Policy.')}
        />
    );

    const redirect = () => {
        if (props.claims?.user) {
            if (isUserInfoFull(props.claims.user)) {
                if (props.redirectToOnSignin) {
                    return <Redirect to={props.redirectToOnSignin}/>
                } else {
                    return <Redirect to={"/find-car"}/>
                }
            } else {
                return <Redirect to={"/auth/license"}/>
            }
        }
        return null;
    };

    return (

        <React.Fragment>

            <FlexView column className={clsx('container', classes.signupForm)} hAlignContent='center'>
                <div>{t('Using social login')}</div>
                <FlexView wrap className={classes.socialLogin}>
                    <GoogleButton onClick={() => onStartSocialLogin('Google')}/>
                    {/*<FacebookButton onClick={() => onStartSocialLogin('Facebook')}/>*/}
                </FlexView>

                <div className={classes.orDivider}>{t('Or')}</div>

                {renderForm()}

                <div className={classes.tos}
                     dangerouslySetInnerHTML={{__html: t('By continuing, you are indicating that you accept our Terms of Service and Privacy Policy.')}}>
                </div>

                {
                    props.signInProgress ?
                        <CircularProgress className={classes.submitButton}/>
                        :
                        <Button variant="contained" color="primary" className={classes.submitButton}
                                onClick={confirmHandler}>
                            {props.signupForm ? t('Sign up') : t('Sign in')}
                        </Button>
                }

                {
                    props.signupForm ?
                        <Link to='/auth/signin' className={classes.existingUser}>
                            <div>{t('Existing user? Sign in here.')}</div>
                        </Link>
                        :
                        <Link to='/auth/forgot-password' className={classes.existingUser}>
                            <div>{t('Forgot password')}</div>
                        </Link>
                }
            </FlexView>

            {renderSnackBar()}

            {renderAlertDialog()}

            {redirect()}

        </React.Fragment>
    );
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        error: state.auth.error,
        signInProgress: state.auth.signInProgress,
        redirectToOnSignin: state.auth.redirectToOnSignin,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initSignup,
        initSignin,
        initSocialLogin,
        setAuthError
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupForm));
