import api from './axios';
import {Page} from "../models/Page";
import { Group } from '../models/Group.model';

/**
 * Handles all actions related to vehicles.
 */
export default class AdminGroupService {

    static convertToWebFormat(group?: Group): Group {
        if (!group) return {};

        return {
            ...group,
            createdAt: group.createdAt && new Date(group.createdAt),
            updatedAt: group.updatedAt && new Date(group.updatedAt),
        }
    }

    static getAll = async (page?: number, size?: number, search?: string): Promise<Page<Group>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            search ? `search=${search}` : '',
        ].filter(x => !!x).join('&');

        const response = await api.get<Page<Group>>(`/admin/group?${params}`);

        return {
            ...response.data,
            content: response.data.content?.map(AdminGroupService.convertToWebFormat)
        }
    };

    static get = async (id: number): Promise<Group> => {
        const response = await api.get(`/admin/group/${id}`);
        return AdminGroupService.convertToWebFormat(response.data);
    }

    static add = async (group: Group): Promise<Group> => {
        const response = await api.post(`/admin/group`, group);
        return AdminGroupService.convertToWebFormat(response.data);
    }

    static update = async (group: Group): Promise<Group> => {
        const response = await api.put(`/admin/group/${group.id}`, group);
        return AdminGroupService.convertToWebFormat(response.data);
    }
}
