import {LOGOUT_DONE, SET_BOOKING_TIME, SET_CAR,} from "../actions/actionTypes";

export interface BookingState {
    carId?: string,
    start?: Date;
    end?: Date;
}

const initialState: BookingState = {};

const bookingReducer = (state: BookingState = initialState, action: any) => {
    switch (action.type) {

        // Car loading
        case SET_CAR:
            const newState = {
                ...state,
                ...action.payload
            };

            if (action.payload.carId != state.carId) {
                newState.start = null;
                newState.end = null;
            }

            return newState;

        case SET_BOOKING_TIME:
            return {
                ...state,
                ...action.payload
            };

        case LOGOUT_DONE:
            return {
                ...initialState
            }
    }
    return state;
};

export default bookingReducer;
