import api from './axios';
import {FaqItem} from "../models/FaqItem.model";


export default class FaqService {

    static getFaq = async (): Promise<FaqItem[]> => {
        const response = await api.get<FaqItem[]>(`/faq`);
        return response.data;
    };

    static updateFaq = async (faq: FaqItem[]): Promise<FaqItem[]> => {
        const response = await api.post<FaqItem[]>(`/faq`, {faq: faq});
        return response.data;
    };

}
