import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GcpButton from "../../../../common/ui/GcpButton";
import classes from "../../users/AdminUser/AdminUser.module.scss";
import {RepeatEvent} from "../../../../models/CalendarEvent.model";
import GcpInput from "../../../../common/ui/GcpInput";
import {VehicleCalendarEvent} from "../../../../models/VehicleCalendarEvent.model";
import {addHours, isAfter} from "date-fns";
import {useTranslation} from "react-i18next";

interface Props {
    event?: VehicleCalendarEvent,
    open: boolean,
    onClose: () => void,
    onRemove: () => void,
    onSave: (vehicleEvent: VehicleCalendarEvent) => void
}

const EventDialog = (props: Props) => {

    const [vehicleEvent, setVehicleEvent] = useState<VehicleCalendarEvent>({})
    const t = useTranslation().t
    const [tmpDays, setTmpDays] = useState<number>()
    const [tmpHours, setTmpHours] = useState<number>()
    const [tmpMinutes, setTmpMinutes] = useState<number>()

    useEffect(() => {
        setVehicleEvent(props.event || {})
    }, [props.open])

    const renderReminderRules = () => {
        return (
            <Box>
                <div style={{marginBottom: 10, marginTop: 20}}>{t('Reminders')}</div>
                {
                    vehicleEvent?.reminders?.map(reminder => {
                        return (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                style={{marginBottom: 10}}
                                alignItems={'center'}
                            >
                                <div style={{width: 100}}>{reminder.daysBefore || 0}d</div>
                                <div style={{width: 100}}>{reminder.hoursBefore || 0}h</div>
                                <div style={{width: 100}}>{reminder.minutesBefore || 0}m</div>
                                <GcpButton
                                    variant={'contained'}
                                    color={'error'}
                                    onClick={() => {
                                        setVehicleEvent({
                                            ...vehicleEvent,
                                            reminders: vehicleEvent?.reminders?.filter(r => r !== reminder)
                                        })
                                    }}
                                >
                                    {t('Remove')}
                                </GcpButton>
                            </Box>
                        )
                    })
                }

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    style={{marginBottom: 10}}
                    alignItems={'center'}
                >
                    <GcpInput
                        label={t('Days before')}
                        value={tmpDays || ''}
                        type={'number'}
                        onChange={e => setTmpDays(+e.target.value || 0)}
                    />

                    <GcpInput
                        label={t('Hours before')}
                        value={tmpHours || ''}
                        type={'number'}
                        onChange={e => setTmpHours(+e.target.value || 0)}
                    />

                    <GcpInput
                        label={t('Minutes before')}
                        value={tmpMinutes || ''}
                        type={'number'}
                        onChange={e => setTmpMinutes(+e.target.value || 0)}
                    />

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            setVehicleEvent({
                                ...vehicleEvent,
                                reminders: [...(vehicleEvent?.reminders || []), {
                                    minutesBefore: tmpMinutes || 0,
                                    hoursBefore: tmpHours || 0,
                                    daysBefore: tmpDays || 0
                                }]
                            })
                            setTmpMinutes(0)
                            setTmpHours(0)
                            setTmpDays(0)
                        }}
                    >
                        {t('Add reminder')}
                    </Button>
                </Box>


            </Box>
        )
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={"md"}
        >

            <DialogTitle>{t('Vehicle event')}</DialogTitle>

            <Box display={'flex'} flexDirection={'column'} style={{padding: 30}}>

                <TextField
                    label={t('Description')}
                    value={vehicleEvent?.description}
                    margin={'normal'}
                    rows={2}
                    multiline
                    onChange={e => setVehicleEvent({
                        ...vehicleEvent,
                        description: e.target.value
                    })}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <DateTimePicker
                        style={{marginBottom: 20}}
                        value={vehicleEvent.from}
                        label={"From"}
                        ampm={false}
                        onChange={date => setVehicleEvent({
                            ...vehicleEvent,
                            from: date || undefined,
                            to: date && (!vehicleEvent.to || isAfter(date, vehicleEvent.to)) ? addHours(date, 1) : vehicleEvent.to
                        })}
                    />

                    <DateTimePicker
                        value={vehicleEvent?.to}
                        label={"To"}
                        ampm={false}
                        minDate={vehicleEvent.from}
                        onChange={date => setVehicleEvent({
                            ...vehicleEvent,
                            to: date || undefined
                        })}
                    />

                </MuiPickersUtilsProvider>

                <FormControl
                    className={classes.adminOfPartner}
                    margin="normal"
                >
                    <InputLabel htmlFor="key">{t('Repeat period')}</InputLabel>
                    <Select
                        value={vehicleEvent?.repeat || ''}
                        onChange={e => setVehicleEvent({
                            ...vehicleEvent,
                            repeat: e.target.value as RepeatEvent
                        })}
                    >
                        <MenuItem key={RepeatEvent.ONCE} value={RepeatEvent.ONCE}>{t('Once')}</MenuItem>)
                        <MenuItem key={RepeatEvent.DAILY} value={RepeatEvent.DAILY}>{t('Daily')}</MenuItem>)
                        <MenuItem key={RepeatEvent.WEEKLY} value={RepeatEvent.WEEKLY}>{t('Weekly')}</MenuItem>)
                        <MenuItem key={RepeatEvent.MONTHLY} value={RepeatEvent.MONTHLY}>{t('Monthly')}</MenuItem>)
                        <MenuItem key={RepeatEvent.YEARLY} value={RepeatEvent.YEARLY}>{t('Yearly')}</MenuItem>)
                    </Select>
                </FormControl>

                {renderReminderRules()}

            </Box>


            <DialogActions>

                {
                    vehicleEvent?.id &&
                    <GcpButton
                        color="error"
                        variant={'contained'}
                        onClick={props.onRemove}
                        style={{marginRight: 20}}
                    >
                        {t('Remove')}
                    </GcpButton>
                }

                <Button
                    disabled={!vehicleEvent?.description || !vehicleEvent.from || !vehicleEvent.to || !vehicleEvent.repeat}
                    color="primary"
                    variant={'contained'}
                    autoFocus
                    onClick={() => props.onSave(vehicleEvent!)}
                >
                    {t('Save')}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default EventDialog

