import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {DayOfWeek, PeriodicAvailability} from "../../../models/PeriodicAvailability.model";
import GcpButton from "../../../common/ui/GcpButton";

interface Props {
    periodicAvailability?: PeriodicAvailability;
    open: boolean;
    onClose?: () => void;
    onSave?: (periodicAvailability: PeriodicAvailability) => void;
    onDelete?: () => void;
}

const PeriodicAvailabilityDialog = ({open, onClose, periodicAvailability, onSave, onDelete}: Props) => {

    const [periodicAvailabilityLocal, setPeriodicAvailabilityLocal] = useState<PeriodicAvailability>({});

    useEffect(() => {
        if (open) {
            setPeriodicAvailabilityLocal(periodicAvailability || {})
        }
    }, [open])

    const renderInput = (label: string, field: 'fromDay' | 'toDay' | 'fromHours' | 'fromMinutes' | 'toHours' | 'toMinutes', options: any[]) => (
        <FormControl
            margin="normal"
            style={{margin: 10}}
        >
            <InputLabel htmlFor="key">{label}</InputLabel>
            <Select
                style={{width: 150}}
                value={periodicAvailabilityLocal[field]}
                onChange={(event) => setPeriodicAvailabilityLocal({
                    ...periodicAvailabilityLocal,
                    [field]: event.target.value
                })}
            >
                {
                    options.map(option => (
                        <MenuItem
                            key={option.key}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )

    const weekdayOptions = [
        {key: DayOfWeek.MONDAY, value: DayOfWeek.MONDAY, label: DayOfWeek.MONDAY},
        {key: DayOfWeek.TUESDAY, value: DayOfWeek.TUESDAY, label: DayOfWeek.TUESDAY},
        {key: DayOfWeek.WEDNESDAY, value: DayOfWeek.WEDNESDAY, label: DayOfWeek.WEDNESDAY},
        {key: DayOfWeek.THURSDAY, value: DayOfWeek.THURSDAY, label: DayOfWeek.THURSDAY},
        {key: DayOfWeek.FRIDAY, value: DayOfWeek.FRIDAY, label: DayOfWeek.FRIDAY},
        {key: DayOfWeek.SATURDAY, value: DayOfWeek.SATURDAY, label: DayOfWeek.SATURDAY},
        {key: DayOfWeek.SUNDAY, value: DayOfWeek.SUNDAY, label: DayOfWeek.SUNDAY},
    ]

    const hourOptions = []
    for (let i = 0; i < 24; i++) {
        hourOptions.push({key: i, value: i, label: i})
    }

    const minuteOptions = []
    for (let i = 0; i < 60; i++) {
        minuteOptions.push({key: i, value: i, label: i})
    }


    return (
        <Dialog open={open} onClose={onClose}>
            <Box p={3}>

                <Box>
                    {renderInput('From day', 'fromDay', weekdayOptions)}
                    {renderInput('To day', 'toDay', weekdayOptions)}
                    <Box m={2}/>
                    {renderInput('From hours', 'fromHours', hourOptions)}
                    {renderInput('From minutes', 'fromMinutes', minuteOptions)}
                    <Box m={2}/>
                    {renderInput('To hours', 'toHours', hourOptions)}
                    {renderInput('To minutes', 'toMinutes', minuteOptions)}
                </Box>

                <Box display={'flex'} style={{width: '100%', marginTop: 20}} justifyContent={'space-between'}>

                    {
                        periodicAvailability &&
                        <GcpButton
                            color={'error'}
                            variant={'outlined'}
                            onClick={() => onDelete?.()}
                        >
                            Delete
                        </GcpButton>
                    }

                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => onSave?.(periodicAvailabilityLocal)}
                    >
                        Save
                    </Button>
                </Box>

            </Box>
        </Dialog>
    )
}

export default PeriodicAvailabilityDialog;
