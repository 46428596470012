import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import {Box, Button, Card, CircularProgress, Typography} from "@material-ui/core";
import GcpInput from "../../../../common/ui/GcpInput";
import classesCommon from '../../AdminEntityCommon.module.scss';
import clsx from 'clsx';
import classes from './AdminCarAdditionalInfo.module.scss';
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {Vehicle} from "../../../../models/Vehicle.model";
import {useTranslation} from "react-i18next";

interface Props {
    vehicle?: Vehicle,
    onUpdate: (vehicle: Vehicle) => void;
}

export const AdminCarAdditionalInfo = (props: Props) => {

    const t = useTranslation().t
    // const [section, setSection] = useState('');
    const [language, setLanguge] = useState('en');
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [cancellation, setCancellation] = useState<any>({});
    const [rules, setRules] = useState<any>({});
    const [important, setImportant] = useState<any>({});
    const [status, setStatus] = useState('');
    const [regularOverride, setRegularOverride] = useState<string>()
    const [premiumOverride, setPremiumOverride] = useState<string>()
    const [regularDailyMaxOverride, setRegularDailyMaxOverride] = useState<string>()
    const [premiumDailyMaxOverride, setPremiumDailyMaxOverride] = useState<string>()
    const [savingOverrides, setSavingOverrides] = useState(false)

    useEffect(() => {
        setCancellation(props.vehicle?.cancellation);
        setRules(props.vehicle?.rules);
        setImportant(props.vehicle?.important);
        setRegularOverride(props.vehicle?.regularPriceOverride ? `${props.vehicle?.regularPriceOverride}` : '')
        setPremiumOverride(props.vehicle?.premiumPriceOverride ? `${props.vehicle?.premiumPriceOverride}` : '')
        setRegularDailyMaxOverride(props.vehicle?.regularDailyMaxOverride ? `${props.vehicle?.regularDailyMaxOverride}` : '')
        setPremiumDailyMaxOverride(props.vehicle?.premiumDailyMaxOverride ? `${props.vehicle?.premiumDailyMaxOverride}` : '')
    }, [props.vehicle]);

    // const toggleAdditionalInfoSection = (newSection: string) => {
    //     if (section === newSection) {
    //         setSection('');
    //     } else {
    //         setSection(newSection);
    //     }
    // };

    const submitAdditionalInfoHandler = async () => {
        if (!props.vehicle?.id) return;

        setStatus('');
        setSavingInProgress(true);

        try {
            const vehicle = await AdminVehicleService.update({
                ...props.vehicle,
                cancellation: cancellation,
                rules: rules,
                important: important
            });
            props.onUpdate(vehicle);
            setStatus(t('Saved!'));
        } catch (e: any) {
            setStatus(e.message);
        }

        setSavingInProgress(false);
    };

    const handleSaveOverride = async () => {
        try {
            setSavingOverrides(true)
            const updatedVehicle: Vehicle = {...props.vehicle}

            if (regularOverride && !isNaN(parseFloat(regularOverride))) {
                updatedVehicle.regularPriceOverride = parseFloat(regularOverride)
            } else {
                updatedVehicle.regularPriceOverride = undefined
            }

            if (premiumOverride && !isNaN(parseFloat(premiumOverride))) {
                updatedVehicle.premiumPriceOverride = parseFloat(premiumOverride)
            } else {
                updatedVehicle.premiumPriceOverride = undefined
            }

            if (regularDailyMaxOverride && !isNaN(parseFloat(regularDailyMaxOverride))) {
                updatedVehicle.regularDailyMaxOverride = parseFloat(regularDailyMaxOverride)
            } else {
                updatedVehicle.regularDailyMaxOverride = undefined
            }

            if (premiumDailyMaxOverride && !isNaN(parseFloat(premiumDailyMaxOverride))) {
                updatedVehicle.premiumDailyMaxOverride = parseFloat(premiumDailyMaxOverride)
            } else {
                updatedVehicle.premiumDailyMaxOverride = undefined
            }

            const response = await AdminVehicleService.update(updatedVehicle)
            props.onUpdate(response)
        } catch (e: any) {
            console.log(e)
        } finally {
            setSavingOverrides(false)
        }
    }

    const renderContent = () => (
        <FlexView column className={clsx(classesCommon.form)} hAlignContent={'center'}>

            <FlexView className={classes.additionalInfoLanguages}>

                <FlexView
                    className={clsx(classes.additionalInfoLanguage, language === 'en' ? classes.selected : null)}
                    onClick={() => setLanguge('en')}
                    hAlignContent={'center'}
                >
                    <div>English</div>
                </FlexView>

                <FlexView
                    className={clsx(classes.additionalInfoLanguage, language === 'sv' ? classes.selected : null)}
                    onClick={() => setLanguge('sv')}
                    hAlignContent={'center'}
                >
                    <div>Swedish</div>
                </FlexView>

                <FlexView
                    className={clsx(classes.additionalInfoLanguage, language === 'sr' ? classes.selected : null)}
                    onClick={() => setLanguge('sr')}
                    hAlignContent={'center'}
                >
                    <div>Serbian</div>
                </FlexView>

            </FlexView>

            <div
                // onClick={() => toggleAdditionalInfoSection('rules')}
                className={clsx(classes.additionalInfoTitle, classes.selected)}
            >
                {t('Rules')}
            </div>
            <GcpInput
                className={classes.additionalInfoInput}
                multiline={true}
                rows={10}
                value={rules && rules[language] || ''}
                onChange={(e) => setRules({
                    ...rules,
                    [language]: e.target.value
                })}
            />

            <div
                // onClick={() => toggleAdditionalInfoSection('important')}
                className={clsx(classes.additionalInfoTitle, classes.selected)}
            >
                {t('Important notes')}
            </div>
            <GcpInput
                className={classes.additionalInfoInput}
                multiline={true}
                rows={10}
                value={important && important[language] || ''}
                onChange={(e) => setImportant({
                    ...important,
                    [language]: e.target.value
                })}
            />

            <div
                // onClick={() => toggleAdditionalInfoSection('cancellation')}
                className={clsx(classes.additionalInfoTitle, classes.selected)}
            >
                {t('Cancellation policy')}
            </div>
            <GcpInput
                className={classes.additionalInfoInput}
                multiline={true}
                rows={10}
                value={cancellation && cancellation[language] || ''}
                onChange={(e) => setCancellation({
                    ...cancellation,
                    [language]: e.target.value
                })}
            />

            {
                savingInProgress ?
                    <CircularProgress className={classesCommon.submitButton}/>
                    :
                    <Button variant="contained" color="primary"
                            className={classesCommon.submitButton}
                            onClick={submitAdditionalInfoHandler}>
                        {t('Save')}
                    </Button>
            }

            <div>{status}</div>

        </FlexView>
    );

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Typography>{t('Price override')}</Typography>
            <Card>
                <Box p={2} display={'flex'} flexDirection={'column'} >
                    <Box flexWrap={'wrap'}>
                        <GcpInput
                            label={t('Regular price override')}
                            margin="normal"
                            type={'number'}
                            value={regularOverride}
                            style={{width: 400, marginLeft: 10, marginRight: 10}}
                            onChange={(event) => setRegularOverride(event.target.value)}
                        />

                        <GcpInput
                            label={t('Premium price override')}
                            margin="normal"
                            type={'number'}
                            value={premiumOverride}
                            onChange={(event) => setPremiumOverride(event.target.value)}
                            style={{width: 400, marginLeft: 10, marginRight: 10}}
                        />

                        <GcpInput
                            label={t('Regular daily max override')}
                            margin="normal"
                            type={'number'}
                            value={regularDailyMaxOverride}
                            onChange={(event) => setRegularDailyMaxOverride(event.target.value)}
                            style={{width: 400, marginLeft: 10, marginRight: 10}}
                        />

                        <GcpInput
                            label={t('Premium daily max override')}
                            margin="normal"
                            type={'number'}
                            value={premiumDailyMaxOverride}
                            onChange={(event) => setPremiumDailyMaxOverride(event.target.value)}
                            style={{width: 400, marginLeft: 10, marginRight: 10}}
                        />
                    </Box>

                    {
                        savingOverrides ? <CircularProgress/> :
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{width: 'fit-content'}}
                                onClick={handleSaveOverride}
                            >
                                {t('Save')}
                            </Button>
                    }

                </Box>
            </Card>

            <Typography>{t('More info')}</Typography>
            <Card className={classes.sectionHolder}>
                {renderContent()}
            </Card>
        </Box>
    );

};
