import React from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './CarSpec.module.scss';
import Battery from '../../../assets/icons/battery-grey.svg';
import CarMake from '../../../assets/icons/car-make-grey.svg';
import Persons from '../../../assets/icons/persons-grey.svg';
import ElectricStation from '../../../assets/icons/electric-station.svg';
import clsx from 'clsx';
import {Vehicle} from "../../../models/Vehicle.model";
import {useTranslation} from "react-i18next";

interface Props {
    car?: Vehicle
}

const CarSpec = (props: Props) => {
    
    const t = useTranslation().t
    
    if (!props.car) return null;

    return (
        <FlexView className={clsx(classes.detailsContainer, props.car.vehicleType === 'scooter' && classes.scooter)}
                  vAlignContent='center'>
            <FlexView column hAlignContent='center'>
                <img className={classes.icon} src={Battery}/>
                <div>{Math.floor(props.car?.lastFuelLevel || 0) || '- '}%</div>
                <div className={classes.detailLabel}>{t('Battery')}</div>
            </FlexView>
            {
                props.car.vehicleType === 'car' &&
                <FlexView column hAlignContent='center'>
                    <img className={classes.icon} src={Persons}/>
                    <div>{props.car.seats || '-'}</div>
                    <div className={classes.detailLabel}>{t('Seats')}</div>
                </FlexView>
            }
            {
                props.car.vehicleType === 'car' &&
                <FlexView column hAlignContent='center'>
                    <img className={classes.icon} src={CarMake}/>
                    <div>{t(props.car?.bodyType || '') || '-'}</div>
                    <div className={classes.detailLabel}>{t('Body')}</div>
                </FlexView>
            }
            <FlexView column hAlignContent='center'>
                <img className={classes.icon} src={ElectricStation}/>
                <div>{props.car.range || '- '}km</div>
                <div className={classes.detailLabel}>{t('Range')}</div>
            </FlexView>
        </FlexView>
    );
};

export default CarSpec;
