import {Box, Grid} from "@material-ui/core";
import classes from "./DamageReportDialog.module.scss";
import clsx from "clsx";
import {DamageReportType} from "../../../models/DamageReport.model";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    damageReportType?: DamageReportType
    setDamageReportType: (damageReportType: DamageReportType) => void
}

const DamageReportTypePicker = (props: Props) => {

    const t = useTranslation().t
    
    return (
        <Box className={classes.damageTypeHolder}>
            <Grid container spacing={2}>

                <Grid item>
                    <Box
                        className={clsx(classes.damageReportType, props.damageReportType === DamageReportType.DIRTY && classes.selected)}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => props.setDamageReportType(DamageReportType.DIRTY)}
                    >
                        <div>{t('Dirty')}</div>
                    </Box>
                </Grid>

                <Grid item>
                    <Box
                        className={clsx(classes.damageReportType, props.damageReportType === DamageReportType.DAMAGE && classes.selected)}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => props.setDamageReportType(DamageReportType.DAMAGE)}
                    >
                        <div>{t('Damage')}</div>
                    </Box>
                </Grid>

                <Grid item>
                    <Box
                        className={clsx(classes.damageReportType, props.damageReportType === DamageReportType.ACCIDENT && classes.selected)}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => props.setDamageReportType(DamageReportType.ACCIDENT)}
                    >
                        <div>{t('Accident')}</div>
                    </Box>
                </Grid>

                <Grid item>
                    <Box
                        className={clsx(classes.damageReportType, props.damageReportType === DamageReportType.OTHER && classes.selected)}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => props.setDamageReportType(DamageReportType.OTHER)}
                    >
                        <div>{t('Other')}</div>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default DamageReportTypePicker