export const requiredValidator = (value: string, error: string = 'This is a required field'): string => {
    return value ? '' : error
};

export const emailValidator = (value: string, error: string = 'Invalid email address'): string => {
    const validEmailRegex =
        // eslint-disable-next-line
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    return validEmailRegex.test(value) ? '' : error;
};

export const minLengthValidator = (value: string, minLength: number, error: string): string => {
    return value && value.length >= minLength ? '' : error;
};

export const isFormValid = (form: any, explicitValidationField?: string[]): boolean => {
    let valid = true;
    Object.keys(form).forEach(key => {
        if (!explicitValidationField || explicitValidationField.find(field => field === key)) {
            valid = valid && !form[key].validation.error;
        }
    });
    return valid;
};

export const validateFormElement = (formElement: any) => {
    if (formElement.validation && formElement.validation.validators) {
        let error = '';

        formElement.validation.validators.forEach((validator: any) => {
            error = error || validator(formElement.value);
        });

        formElement.validation.error = error;
    }

    return formElement;
};

export const validateForm = (form: any) => {
    const newForm: any = {};
    Object.keys(form).forEach(key => {
        newForm[key] = validateFormElement({...form[key]});
    });

    return newForm;
};
