import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import FlexView from "react-flexview";
import classes from './ForgotPassword.module.scss';
import {RouteComponentProps} from "react-router";
import {emailValidator, requiredValidator} from '../../../common/validators.util';
import clsx from "clsx";
import GcpInput from "../../../common/ui/GcpInput";
import AuthService from "../../../services/auth.service";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps {
}

const ForgotPassword = (props: Props) => {

    // ***** Fields ***** //

    const t = useTranslation().t
    const [form, setForm] = useState({
        email: {
            label: t('Email'),
            placeholder: 'ex. john.smith@example.com',
            type: 'email',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: string) => requiredValidator(value, t('This is a required field')),
                    (value: string) => emailValidator(value, t('Please enter valid email address'))
                ]
            }
        },
    });

    const [passwordResetDone, setPasswordResetDone] = useState(false);
    const [passwordResetInProgress, setPasswordResetInProgress] = useState(false);
    const [passwordResetError, setPasswordResetError] = useState(false);

    useEffect(() => {
        trackCurrentPage()
    }, [])

    // ***** Handlers ***** //

    const inputHandler = (fieldId: 'email', value: any) => {
        const newFormField = {
            ...form[fieldId],
            value: value,
            validation: {
                ...form[fieldId].validation,
                error: ''
            }
        };
        setForm({
            ...form,
            [fieldId]: newFormField
        });
    };

    const confirmHandler = async () => {
        try {
            setPasswordResetDone(false);
            setPasswordResetInProgress(true);
            setPasswordResetError(false);

            await AuthService.resetPassword(form.email.value);

            setPasswordResetDone(true);
        } catch (e: any) {
            setPasswordResetError(e.code);
        } finally {
            setPasswordResetInProgress(false);
        }
    };

    const hideSnackbar = () => {
        // props.setAuthError('');
    };

    // const validateForm = () => {
    //     const newForm: any = {};
    //     Object.keys(form).forEach(key => {
    //         let newFormElement = {...form[key]};
    //         validateFormElement(newFormElement);
    //         newForm[key] = newFormElement;
    //     });
    //
    //     setForm(newForm);
    // };

    // ***** Render ***** //

    const renderSnackBar = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={!!passwordResetError}
                autoHideDuration={5000}
                onClose={hideSnackbar}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={passwordResetError}
                />
            </Snackbar>
        );
    };


    const renderForm = () => {
        const formElement = form['email'];
        return (
            <React.Fragment>
                <GcpInput
                    error={!!formElement.validation.error}
                    key={'email'}
                    className={classes.signinElement}
                    label={formElement.label}
                    placeholder={formElement.placeholder}
                    margin="normal"
                    type={formElement.type}
                    // variant="outlined"
                    value={formElement.value}
                    helperText={formElement.validation.error}
                    onChange={(event) => inputHandler('email', event.target.value)}
                />

                {
                    passwordResetInProgress ?
                        <CircularProgress className={classes.submitButton}/>
                        :
                        <Button variant="contained" color="primary" className={classes.submitButton}
                                onClick={confirmHandler}>
                            {t('Reset my password')}
                        </Button>
                }
            </React.Fragment>
        );
    };

    const renderSuccess = () => {
        return (
            <React.Fragment>
                <div>{t('Password reset instructions were sent.', {email: form.email.value})}</div>

                <Button variant="contained" color="primary" className={classes.submitButton}
                        onClick={() => props.history.goBack()}>
                    {t('Back to login')}
                </Button>
            </React.Fragment>
        )
    };

    return (
        <FlexView column className='component' vAlignContent='top' hAlignContent='center'>

            <div className={classes.title}>{t('Forgot password')}</div>

            <FlexView column className={clsx('container', classes.signinForm)} hAlignContent='center'>
                {passwordResetDone ? renderSuccess() : renderForm()}
            </FlexView>

            {renderSnackBar()}

        </FlexView>
    );
};

export default ForgotPassword;
