import React, {useEffect, useState} from 'react';
import i18 from "i18next";
import {isFormValid, minLengthValidator, requiredValidator, validateFormElement} from "../../../common/validators.util";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {initLogout, initUpdateInfo, setUpdateInfo} from "../../../store/actions/auth.actions";
import GcpInput from "../../../common/ui/GcpInput";
import FlexView from "react-flexview";
import {Box, Button, CircularProgress, Dialog, Typography} from "@material-ui/core";
import classes from './UserInfo.module.scss';
import {bindActionCreators} from "redux";
import {connect, useDispatch} from "react-redux";
import clsx from 'clsx';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import {AuthClaims} from "../../../models/AuthClaims.model";
import Dropzone from "../../../common/ui/Dropzone/Dropzone.component";
import {myFirebase} from "../../../services/firebase";
import {v4 as uuidv4} from "uuid";
import AuthServiceNew from "../../../services/auth.service";
import GcpError from "../../../models/error.enum";
import {useHistory} from "react-router-dom";

interface Props {
    claims?: AuthClaims;
    updateInfoInProgress: boolean,
    initUpdateInfo: typeof initUpdateInfo;
}

const UserInfo = (props: Props) => {

    // ***** State ***** //

    const [form, setForm] = useState<any>();
    const [dirty, setDirty] = useState(false);
    const [licenceImageLocal, setLicenceImageLocal] = useState<File>();
    const [licenceUploadInProgress, setLicenceUploadInProgress] = useState(false);
    const [licenceDropzoneError, setLicenceDropzoneError] = useState(false);
    const [showDropzone, setShowDropzone] = useState(false);
    const [changeEmailOpen, setChangeEmailOpen] = useState(false)
    const [changeEmailInput, setChangeEmailInput] = useState('')
    const [codeInput, setCodeInput] = useState('')
    const [changeEmailRequestStatus, setChangeEmailRequestStatus] = useState<'' | 'sending' | 'sent' | 'confirming' | 'changed'>('')
    const dispatch = useDispatch()
    const history = useHistory()
    const isPasswordLogin = myFirebase.auth().currentUser?.providerData?.[0]?.providerId === 'password'

    const fillUserForm = () => {
        const user = props.claims?.user;
        if (props.claims?.user) {
            setShowDropzone(!props.claims.user.licenseUrl);
            setForm({
                name: {
                    label: i18.t('Name'),
                    placeholder: 'ex. John Smith',
                    type: 'text',
                    value: user?.name || '',
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, i18.t('This is a required field')),
                            (value: string) => minLengthValidator(value, 2, i18.t('Name should be at least ... characters long', {length: 2}))
                        ]
                    }
                },
                address: {
                    label: i18.t('Address'),
                    placeholder: 'ex. Valley Rd. 123',
                    type: 'text',
                    value: user?.address || '',
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, i18.t('This is a required field')),
                        ]
                    }
                },
                city: {
                    label: i18.t('City'),
                    placeholder: 'Stockholm',
                    type: 'text',
                    value: user?.city || '',
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, i18.t('This is a required field')),
                        ]
                    }
                },
                country: {
                    label: i18.t('Country'),
                    placeholder: 'ex. Sweden',
                    type: 'text',
                    value: user?.country || '',
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, i18.t('This is a required field')),
                        ]
                    }
                },
                phone: {
                    label: 'Phone',
                    placeholder: '+46 06 123456',
                    type: 'phone',
                    value: user?.phone || '',
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, i18.t('This is a required field')),
                            (value: string) => {
                                if (parsePhoneNumberFromString(value)?.isValid()) {
                                    return '';
                                } else {
                                    return i18.t('Phone number is not valid')
                                }
                            }
                        ]
                    }
                },
            })
        }
    };

    // ***** Effects ***** //

    useEffect(() => {
        if (props.claims?.user) {
            fillUserForm();
        }
    }, [false, props.claims]);

    if (!form) return null;

    // ***** Handlers ***** //

    const pickPhotoHandler = (files: any) => {
        setLicenceImageLocal(files[0]);
        setLicenceDropzoneError(false);
        setDirty(true);
        setShowDropzone(false);
    };

    const remotePhotoHandler = () => {
        setLicenceImageLocal(undefined);
        setShowDropzone(true);
    }

    const uploadLicence = async () => {
        if (!licenceImageLocal) return;

        const uploadRef = myFirebase.storage().ref(`${uuidv4()}-${licenceImageLocal.name}`);
        await uploadRef.put(licenceImageLocal);
        return await uploadRef.getDownloadURL();
    };

    const inputHandler = (fieldId: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
        if (typeof e === 'string') {
            const newFormField = {
                ...form[fieldId],
                value: e,
                validation: {
                    ...form[fieldId].validation,
                    error: ''
                }
            };
            setForm({
                ...form,
                [fieldId]: newFormField
            });
            setDirty(true);
        }
    };

    const validateForm = () => {
        let keys = ['name'];
        keys.push('address');
        keys.push('city');
        keys.push('country');
        keys.push('phone');

        const newForm: any = {};
        keys.forEach(key => {
            let newFormElement = {...form[key]};
            validateFormElement(newFormElement);
            newForm[key] = newFormElement;
        });

        // if (newForm.password.value && newForm.retypePassword.value
        //     && !newForm.retypePassword.validation.error && newForm.password.value !== newForm.retypePassword.value) {
        //     newForm.retypePassword.validation.error = "Passwords don't match!";
        // }

        setForm(newForm);
    };

    const updateInfoHandler = async () => {
        validateForm();
        if (isFormValid(form) && (props.claims?.user?.licenseUrl || licenceImageLocal)) {
            let licenseUrl = props.claims?.user?.licenseUrl;
            if (licenceImageLocal) {
                setLicenceUploadInProgress(true);
                licenseUrl = await uploadLicence();
                setLicenceUploadInProgress(false);
            }

            const phoneNumberParsed = parsePhoneNumberFromString(form.phone.value)?.format('E.164');
            await props.initUpdateInfo({
                id: props.claims?.user?.id,
                name: form.name.value,
                address: form.address.value,
                city: form.city.value,
                country: form.country.value,
                phone: phoneNumberParsed,
                licenseUrl: licenseUrl
            });
            setLicenceImageLocal(undefined);
            setDirty(false);
        }
    };

    // ***** Render ***** //

    const renderDropzone = () => (
        <Dropzone
            className={clsx(classes.dropzone, licenceDropzoneError && classes.dropzoneError)}
            onFilesAdded={pickPhotoHandler}
        />
    );

    const renderLicence = () => (
        <FlexView column className={classes.licencePreview} vAlignContent='center' hAlignContent='center'>

            <img className={classes.licencePhoto}
                 src={licenceImageLocal ? URL.createObjectURL(licenceImageLocal) : props.claims?.user?.licenseUrl}/>

            {
                !licenceUploadInProgress &&
                <Button
                    className={classes.removePhoto}
                    color="primary"
                    onClick={remotePhotoHandler}
                    // disabled={uiDisabled}
                >
                    {i18.t('Change')}
                </Button>
            }
        </FlexView>
    );

    const renderForm = () => {
        let keys = ['name'];
        keys.push('address');
        keys.push('city');
        keys.push('country');
        keys.push('phone');

        return keys.map(key => {
            const formElement = form[key];
            switch (formElement.type) {
                case 'phone':
                    return (
                        <React.Fragment>
                            <div style={{paddingTop: 15}}/>
                            <MaterialUiPhoneNumber
                                error={!!formElement.validation.error}
                                key={key}
                                className={classes.signupElement}
                                label={formElement.label}
                                margin="normal"
                                placeholder={formElement.placeholder}
                                defaultCountry={'se'}
                                disabled={props.updateInfoInProgress}
                                value={formElement.value}
                                fullWidth
                                helperText={formElement.validation.error}
                                onChange={value => inputHandler(key, value)}/>
                        </React.Fragment>
                    );
                default:
                    return (
                        <GcpInput
                            error={!!formElement.validation.error}
                            key={key}
                            className={classes.signupElement}
                            label={formElement.label}
                            placeholder={formElement.placeholder}
                            margin="normal"
                            type={formElement.type}
                            // variant="outlined"
                            disabled={props.updateInfoInProgress}
                            value={formElement.value}
                            helperText={formElement.validation.error}
                            onChange={(event) => inputHandler(key, event.target.value)}
                        />
                    )
            }
        });
    }

    return (
        <FlexView column className={clsx('container', classes.form)} hAlignContent='center'>

            <div className={classes.licenceLabel}>
                {i18.t('License')}
            </div>
            {
                showDropzone ? renderDropzone() : renderLicence()
            }

            {
                licenceUploadInProgress &&
                <FlexView
                    className={classes.imageUploadProgressHolder}
                    hAlignContent='center'
                    vAlignContent='center'
                >
                    <CircularProgress/>
                </FlexView>
            }

            {renderForm()}
            {
                props.updateInfoInProgress ?
                    <CircularProgress className={classes.submitButton}/>
                    :
                    <Button
                        disabled={!dirty && !showDropzone}
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        onClick={updateInfoHandler}
                    >
                        {i18.t('Update info')}
                    </Button>
            }

            <Box
                className={classes.emailSection}
                width={'100%'}
                marginTop={3}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography>{props.claims?.user?.email}</Typography>

                {
                    isPasswordLogin &&
                    <Button
                        color={'primary'}
                        onClick={() => setChangeEmailOpen(true)}
                    >
                        {i18.t('Change email')}
                    </Button>
                }

            </Box>

            <Dialog open={changeEmailOpen} onClose={() => setChangeEmailOpen(false)}>
                <Box p={3}>
                    {
                        (changeEmailRequestStatus === '' || changeEmailRequestStatus === 'sending') &&
                        <Box
                            width={300}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                        >
                            <Typography style={{marginBottom: 10}}>{i18.t('Please enter a new email')}</Typography>
                            <GcpInput
                                // error={!!formElement.validation.error}
                                className={classes.emailChangeInput}
                                label={i18.t('New email')}
                                placeholder={i18.t('New email')}
                                value={changeEmailInput}
                                disabled={changeEmailRequestStatus === 'sending'}
                                // helperText={formElement.validation.error}
                                onChange={(event) => setChangeEmailInput(event.target.value)}
                            />

                            {
                                changeEmailRequestStatus === 'sending' ?
                                    <CircularProgress style={{marginTop: 20}}/> :
                                    <Button
                                        style={{marginTop: 20}}
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={async () => {
                                            setChangeEmailRequestStatus('sending')
                                            const response = await AuthServiceNew.requestChangeUserEmail(changeEmailInput)
                                            if (response.status === 'success') {
                                                setChangeEmailRequestStatus('sent')
                                            } else {
                                                setChangeEmailRequestStatus('')
                                            }
                                        }}
                                    >
                                        {i18.t('Send verification code')}
                                    </Button>
                            }

                        </Box>
                    }

                    {
                        (changeEmailRequestStatus === 'sent' || changeEmailRequestStatus === 'confirming') &&
                        <Box
                            width={300}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                        >
                            <Typography
                                style={{marginBottom: 10}}>{i18.t(`Verification code sent`, {email: changeEmailInput})}</Typography>
                            <GcpInput
                                // error={!!formElement.validation.error}
                                className={classes.emailChangeInput}
                                label={i18.t('Verification code')}
                                placeholder={i18.t('Verification code')}
                                value={codeInput}
                                disabled={changeEmailRequestStatus === 'confirming'}
                                // helperText={formElement.validation.error}
                                onChange={(event) => setCodeInput(event.target.value)}
                            />

                            {
                                changeEmailRequestStatus === 'confirming' ?
                                    <CircularProgress style={{marginTop: 20}}/> :
                                    <Button
                                        style={{marginTop: 20}}
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={async () => {
                                            setChangeEmailRequestStatus('sending')
                                            const response = await AuthServiceNew.confirmChangeUserEmail(codeInput)
                                            if (!!response) {
                                                setChangeEmailRequestStatus('changed')
                                                dispatch(setUpdateInfo(response, false, GcpError.NONE))
                                            } else {
                                                setChangeEmailRequestStatus('')
                                            }
                                        }}
                                    >
                                        {i18.t('Change email')}
                                    </Button>
                            }

                        </Box>
                    }

                    {changeEmailRequestStatus === 'changed' &&
                    <Box
                        width={300}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography style={{marginBottom: 10}}>{i18.t('Email changed')}</Typography>
                        <Button
                            style={{marginTop: 20}}
                            color={'primary'}
                            variant={'contained'}
                            onClick={async () => {
                                setChangeEmailOpen(false)
                                await dispatch(initLogout())
                                history.push('/auth/signin')
                            }}
                        >
                            {i18.t('Done')}
                        </Button>
                    </Box>
                    }

                </Box>
            </Dialog>

        </FlexView>
    )
};


const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        error: state.auth.error,
        updateInfoInProgress: state.auth.updateInfoInProgress,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initUpdateInfo,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
