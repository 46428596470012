import api from './axios';
import {DamageReport, DamageReportType} from "../models/DamageReport.model";
import {Page} from "../models/Page";

/**
 * Handles all actions related to vehicles.
 */
export default class AdminDamageReportService {

    static convertToWebFormat(damageReport?: DamageReport): DamageReport {
        if (!damageReport) return {};

        return {
            ...damageReport,
            createdAt: damageReport.createdAt && new Date(damageReport.createdAt),
            updatedAt: damageReport.updatedAt && new Date(damageReport.updatedAt),
        }
    }

    static getAll = async (
        page?: number,
        size?: number,
        organizationId?: number,
        vehicleId?: number,
        search?: string,
        damageReportType?: DamageReportType
    ): Promise<Page<DamageReport>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            organizationId ? `organizationId=${organizationId}` : '',
            vehicleId ? `vehicleId=${vehicleId}` : '',
            search ? `search=${search}` : '',
            damageReportType ? `damageReportType=${damageReportType}` : '',
        ].filter(x => !!x).join('&');

        const response = await api.get<Page<DamageReport>>(`/admin/damageReport?${params}`);

        return {
            ...response.data,
            content: response.data.content?.map(AdminDamageReportService.convertToWebFormat)
        }
    };
}
