import ReactPaginate from "react-paginate";
import React from "react";
import classes from './Pagination.module.scss';

interface Props {
    pages: number,
    currentPage: number,
    onPageChange: (page: number) => void;
}

const Pagination = (props: Props) => {
    return (
        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={classes.break}
            pageCount={props.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data: any) => {
                props.onPageChange(data.selected)
            }}
            forcePage={props.currentPage}
            containerClassName={classes.container}
            // subContainerClassName={'pages pagination'}
            activeClassName={classes.activeClass}
        />
    )
}

export default Pagination;