import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Container} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import Pagination from "../../../common/ui/Pagination/Pagination.component";
import {Page} from "../../../models/Page";
import {Group} from "../../../models/Group.model";
import {useSelector} from "react-redux";
import AdminGroupService from "../../../services/adminGroup.service";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {Link, useHistory} from 'react-router-dom';
import Search from "../../../common/ui/Search/Search";
import {useTranslation} from "react-i18next";


const AdminGroupsList = () => {

    //region State

    const t = useTranslation().t
    const [groups, setGroups] = useState<Page<Group>>();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('')
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const history = useHistory();

    //endregion

    //region Handlers

    const fetch = async (page: number) => {
        try {
            setLoading(true)
            const groups = await AdminGroupService.getAll(page, 10, search);
            setGroups(groups);
        } catch (e: any) {
            console.log(e);
            setGroups(undefined);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetch(0);
    }, [claims, search]);

    const open = async (group: Group) => {
        history.push(`/admin/group/${group.id}`);
    };

    //endregion Handlers

    //region UI

    const actionsBar = (
        <Box display={'flex'} className={classes.actionsBar}>
            <Search
                placeholder={t('Search by group name, organization name')}
                onSearch={setSearch}
            />
            <Box m={2} />
            <Link to="/admin/group/new">
                <Button color={'primary'} variant={'contained'}>
                    {t('Add')}
                </Button>
            </Link>
        </Box>
    )

    const renderList = () => {
        if (loading) {
            return (
                <FlexView className={classes.listLoading} hAlignContent={"center"}>
                    <CircularProgress/>
                </FlexView>
            )
        }

        if (!groups?.content?.length) {
            return (<div>No groups found</div>)
        }

        return (
            <FlexView column className={clsx('container', classes.list)}>
                <FlexView vAlignContent='center' className={classes.header}>
                    <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Name')}</div>
                    <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Visibility')}</div>
                    <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Organization')}</div>
                </FlexView>

                {
                    groups.content.map(group => {
                        return (
                            <FlexView key={group.id} onClick={() => open(group)}
                                      vAlignContent='center'
                                      className={classes.tableRow}>
                                <div className={classes.cellLarge}>{group.name}</div>
                                <div className={classes.cellLarge}>{group.visibilityLevel}</div>
                                <div className={classes.cellLarge}>{group.organizations?.map(o => o.name)?.join(', ') || '-'}</div>
                            </FlexView>
                        )
                    })
                }

                <Pagination
                    pages={groups.totalPages || 0}
                    currentPage={groups?.page || 0}
                    onPageChange={fetch}
                />

            </FlexView>
        )
    }

    return (
        <Container>
            {actionsBar}
            {renderList()}
        </Container>
    )

    //endregion UI
}

export default AdminGroupsList;