import React, {useEffect} from 'react';

import {initAddPaymentMethod, initLoadPaymentMethods, setUpdatingBilling} from "../../../store/actions/billing.actions";
import {connect} from "react-redux";
import FlexView from "react-flexview";
import classes from './PaymentMethods.module.scss';
import {Button, CircularProgress} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {SplitFieldsForm} from "../../../common/ui/CardInputForm.component";
import Amex from '../../../assets/icons/cards/amex.svg';
import Diners from '../../../assets/icons/cards/diners.svg';
import Discover from '../../../assets/icons/cards/discover.svg';
import Generic from '../../../assets/icons/cards/generic.svg';
import JCB from '../../../assets/icons/cards/jcb.svg';
import Mastercard from '../../../assets/icons/cards/mastercard.svg';
import Unionpay from '../../../assets/icons/cards/unionpay.svg';
import Visa from '../../../assets/icons/cards/visa.svg';
import {Elements} from 'react-stripe-elements';
import {AuthClaims} from "../../../models/AuthClaims.model";
import PaymentMethod from "../../../models/PaymentMethod.model";
import {useTranslation} from "react-i18next";

interface Props {
    claims?: AuthClaims;

    paymentMethods: PaymentMethod[],
    paymentMethodsError: string,
    paymentMethodsLoading: boolean,

    addPaymentMethodError: string,
    addPaymentMethodLoading: boolean,
    updatingBilling: boolean,

    initLoadPaymentMethods: typeof initLoadPaymentMethods,
    initAddPaymentMethod: typeof initAddPaymentMethod,
    setUpdatingBilling: typeof setUpdatingBilling

}

const PaymentMethods = (props: Props) => {
    
    const t = useTranslation().t
    
    useEffect(() => {
        if (props.claims?.user) {
            props.initLoadPaymentMethods();
        }
    }, [props.claims?.user]);

    const updateBillingHandler = () => {
        props.setUpdatingBilling(true);
    };

    const handleAddCreditCard = (cardInfo: any) => {
        props.initAddPaymentMethod(cardInfo);
        // this.props.onConfirm();
    };

    if (props.paymentMethodsLoading || props.addPaymentMethodLoading) {
        return <CircularProgress/>;
    }

    if (props.updatingBilling) {
        return (
            <Elements>
                <SplitFieldsForm handleResult={handleAddCreditCard}/>
            </Elements>
        )
    }

    if (props.paymentMethods.length) {
        const paymentMethod = props.paymentMethods[0];

        let cardLogo = Generic;
        const cardBrand = paymentMethod.card?.brand?.toLowerCase();
        // American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown
        switch (cardBrand) {
            case 'american express':
                cardLogo = Amex;
                break;
            case 'diners club':
                cardLogo = Diners;
                break;
            case 'discover':
                cardLogo = Discover;
                break;
            case 'jcb':
                cardLogo = JCB;
                break;
            case 'mastercard':
                cardLogo = Mastercard;
                break;
            case 'unionpay':
                cardLogo = Unionpay;
                break;
            case 'visa':
                cardLogo = Visa;
                break;
        }

        return (
            <FlexView column className={classes.cardInfo}>
                <FlexView vAlignContent='center'>
                    <img src={cardLogo} className={classes.cardLogo}/>
                    <FlexView column>
                        <div>**** **** **** {paymentMethod?.card?.last4}</div>
                        <div>{paymentMethod?.card?.exp_month}/{paymentMethod?.card?.exp_year}</div>
                    </FlexView>
                </FlexView>

                <Button variant="contained" color="primary" className={classes.submitButton}
                        onClick={updateBillingHandler}>
                    {t('Change')}
                </Button>

            </FlexView>
        )
    } else {
        return (
            <Button variant="contained" color="primary" className={classes.submitButton}
                    onClick={updateBillingHandler}>
                {t('Add')}
            </Button>
        )
    }
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,

        paymentMethods: state.billing.paymentMethods,
        paymentMethodsError: state.billing.paymentMethodsError,
        paymentMethodsLoading: state.billing.paymentMethodsLoading,

        addPaymentMethodError: state.billing.addPaymentMethodError,
        addPaymentMethodLoading: state.billing.addPaymentMethodLoading,
        updatingBilling: state.billing.updatingBilling,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initAddPaymentMethod,
        initLoadPaymentMethods,
        setUpdatingBilling
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
