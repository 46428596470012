import React from 'react';
import FlexView from "react-flexview";
import classes from './CarPreview.module.scss';
import clsx from 'clsx';
import {fillLoaderContent} from "../../../common/ui.util";

const CarPreviewLoader = () => {

    const details = (
        <FlexView className={classes.detailsContainer} vAlignContent='center'>
            <FlexView column hAlignContent='center'>
                <div className={clsx(classes.icon, 'pulse')}/>
                {fillLoaderContent(50, 15)}
            </FlexView>
            <FlexView column hAlignContent='center'>
                <div className={clsx(classes.icon, 'pulse')}/>
                {fillLoaderContent(50, 15)}
            </FlexView>
            <FlexView column hAlignContent='center'>
                <div className={clsx(classes.icon, 'pulse')}/>
                {fillLoaderContent(50, 15)}
            </FlexView>
        </FlexView>
    );

    return (
        <FlexView column className={clsx(classes.component)}>
            <FlexView style={{width: '100%'}}>
                <div className={clsx(classes.carImage, 'pulse')}/>
                <FlexView column className={classes.rightSection}>
                    <FlexView className={classes.header} vAlignContent='center'>
                        {fillLoaderContent(150, 25)}
                        <div className={clsx(classes.info)}>
                            {fillLoaderContent(20, 20)}
                        </div>
                    </FlexView>
                    {
                        window.innerWidth >= 768 ? details : null
                    }
                    <div className={classes.price}>
                        {fillLoaderContent(50, 15)}
                    </div>
                </FlexView>
            </FlexView>
            {window.innerWidth < 768 ? details : null}
        </FlexView>
    );
};

export default CarPreviewLoader;
