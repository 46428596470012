import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography
} from "@material-ui/core";
import GcpInput from "../../../common/ui/GcpInput";
import {makeStyles} from "@material-ui/core/styles";
import {Group, GroupVisibilityLevel} from "../../../models/Group.model";
import {Organization} from "../../../models/Organization.model";
import AdminOrganizationService from "../../../services/adminOrganization.service";
import {useSelector} from "react-redux";
import {AuthClaims} from "../../../models/AuthClaims.model";
import GcpButton from "../../../common/ui/GcpButton";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    content: {
        padding: 20,
        display: 'flex'
    },
    nameInput: {
        width: 300,
        marginTop: 20,
        marginBottom: 40
    },
    actionButton: {
        marginLeft: 20
    }
}))

interface Props {
    group: Group;
    setGroup: (group: Group) => void;
}

const BasicInfo = ({group, setGroup}: Props) => {

    const [tmpOrg, setTmpOrg] = useState<Organization>()
    const classes = useStyle();
    const [organizations, setOrganizations] = useState<(undefined | Organization)[]>([]);
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const t = useTranslation().t

    //region Handlers

    useEffect(() => {
        if (!claims) return;

        const fetchOrganization = async () => {
            const response: Organization[] = (await AdminOrganizationService.getAll(0, 1000)).content;
            setOrganizations(response || []);
        }
        fetchOrganization();
    }, [claims]);

    const addTmpOrgToOrgs = () => {
        if (!tmpOrg) return;

        setGroup({
            ...group,
            organizations: [...group.organizations || [], tmpOrg]
        })
        setTmpOrg(undefined)
    }

    const removeOrganization = (organization: Organization) => {
        setGroup({
            ...group,
            organizations: group.organizations?.filter(org => org.id !== organization.id)
        })
    }

    //endregion Handlers

    const orgsToShow = organizations?.filter(o => !group.organizations?.find(groupOrg => groupOrg.id === o?.id))

    return (
        <Card className={classes.content}>
            <Box display={'flex'} flexDirection={'column'}>
                <GcpInput
                    className={classes.nameInput}
                    placeholder={t('Group name')}
                    value={group?.name}
                    onChange={e => setGroup?.({...group, name: e.target.value})}
                />

                <FormControl
                    className={classes.nameInput}
                    margin="normal"
                >
                    <InputLabel htmlFor="key">{t('Visibility level')}</InputLabel>
                    <Select
                        value={group.visibilityLevel}
                        onChange={(event) => setGroup({
                            ...group,
                            visibilityLevel: event.target.value as GroupVisibilityLevel
                        })}
                    >
                        <MenuItem
                            key={GroupVisibilityLevel.PUBLIC}
                            value={GroupVisibilityLevel.PUBLIC}>
                            {t('Public with availability periods')}
                        </MenuItem>

                        <MenuItem
                            key={GroupVisibilityLevel.ORGANIZATION}
                            value={GroupVisibilityLevel.ORGANIZATION}>
                            {t('Organization private')}
                        </MenuItem>

                        <MenuItem
                            key={GroupVisibilityLevel.HIDDEN}
                            value={GroupVisibilityLevel.HIDDEN}>
                            {t('Hidden')}
                        </MenuItem>

                    </Select>
                </FormControl>

                {
                    group.visibilityLevel !== GroupVisibilityLevel.HIDDEN &&
                    <Box>

                        {
                            group.organizations?.map(organization => (
                                <Box key={organization.id} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Typography style={{minWidth: 300}}>{organization.name}</Typography>
                                    <GcpButton
                                        style={{marginLeft: 30}}
                                        color={"error"}
                                        variant={'text'}
                                        onClick={() => removeOrganization(organization)}
                                    >
                                        {t('Remove organization')}
                                    </GcpButton>
                                </Box>
                            ))
                        }

                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <FormControl
                                className={classes.nameInput}
                                margin="normal"
                            >
                                <InputLabel htmlFor="key">{t('Organization')}</InputLabel>
                                <Select
                                    value={tmpOrg?.id || 0}
                                    onChange={(event) => {
                                        setTmpOrg(organizations.find(o => o?.id === event.target.value))
                                    }}
                                >
                                    {
                                        orgsToShow.map(organization => {
                                            return (
                                                <MenuItem key={organization?.id}
                                                          value={organization?.id}>({organization?.id}) {organization?.name || '-'}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Button
                                disabled={!tmpOrg}
                                variant={'contained'}
                                color={'primary'}
                                onClick={addTmpOrgToOrgs}
                            >
                                {t('Add organization')}
                            </Button>
                        </Box>
                    </Box>

                }

                {
                    !!group.organizations?.length &&
                    <FormControlLabel
                        control={<Switch
                            color={'primary'}
                            checked={group.groupUsersHaveFreeRides || false}
                            onChange={e => setGroup({
                                ...group,
                                groupUsersHaveFreeRides: e.target.checked
                            })}
                        />}
                        label={t('User from these groups get free rides')}
                    />
                }

            </Box>

        </Card>
    )
}

export default BasicInfo;