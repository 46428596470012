import {makeStyles} from "@material-ui/core";

export const useListStyle = makeStyles(theme => ({
    topBar: {
        height: 70,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    search: {
      marginLeft: 10,
      marginRight: 10,
      width: 500
    },
    header: {
        height: 38,
        background: '#f3f2f7',
        borderBottom: '1px solid #F3F2F7'
    },
    headerText: {
        fontSize: 12,
        color: '#6e6b7b'
    },
    row: {
        height: 60,
        borderBottom: '1px solid #f0f0f0'
    },
    cell: {
        paddingLeft: 10,
        width: 100,
        height: '100%',
        cursor: 'pointer',
        fontSize: 12
    },
    cellS: {
        width: 50
    },
    cellL: {
        width: 200
    },
    loaderContainer: {
      height: 200
    },
    breadcrumbs: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    actionButtonHolder: {
        marginLeft: 'auto'
    },
    avatar: {
        width: 32,
        height: 32,
        marginRight: 10,
        fontSize: 14
    },
    footer: {
        height: 60,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    pageSize: {
        marginLeft: 10
    }
}))

export const useBreadcrumbsStyle = makeStyles(theme => ({
    breadcrumbs: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    actionButtonHolder: {
        marginLeft: 'auto'
    }
}))

export const useFilterStyles = makeStyles(_theme => ({
    container: {
        width: 300
    },
}))