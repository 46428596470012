import api from './axios';
import {Vehicle, VehicleType} from "../models/Vehicle.model";
import axios from 'axios';
import {Maintenance} from "../models/Maintenance.model";
import {Page} from "../models/Page";
import {VehicleCalendarEvent} from "../models/VehicleCalendarEvent.model";

/**
 * Handles all actions related to vehicles.
 */
export default class VehicleService {

    static convertMaintenanceToWebFormat = (maintenance: Maintenance): Maintenance => {
        if (!maintenance) return {};

        return {
            ...maintenance,
            createdAt: maintenance.createdAt && new Date(maintenance.createdAt),
            from: maintenance.from && new Date(maintenance.from),
            to: maintenance.to && new Date(maintenance.to)
        }
    }

    static convertEventToWebFormat = (event: VehicleCalendarEvent): VehicleCalendarEvent => {
        if (!event) return {};

        return {
            ...event,
            createdAt: event.createdAt && new Date(event.createdAt),
            from: event.from && new Date(event.from),
            to: event.to && new Date(event.to)
        }
    }

    /**
     * Gets all "active" vehicles, that is, the one visible to users...
     */
    static getAllActiveVehicles = async (vehicleType?: VehicleType, page?: number, size?: number): Promise<Page<Vehicle>> => {
        const params = [
            vehicleType ? `vehicleType=${vehicleType}` : '',
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
        ].filter(x => !!x).join('&');

        const response = await api.get<Page<Vehicle>>(`/listing/list?${params}`);
        return response.data;
    };

    /**
     * Gets single vehicle by id
     * @param id
     */
    static getVehicle = async (id: number): Promise<Vehicle> => {
        const response = await api.get(`/vehicle/${id}`);
        return response.data;
    };

    /**
     * Gets all future maintenance periods for the car
     * @param id
     */
    static getUnavailablePeriods = async (id: number, excludeBookingId?: number): Promise<Maintenance[]> => {
        const params = [
            `dateFrom=${new Date().toISOString()}`,
            excludeBookingId ? `excludeBookingId=${excludeBookingId}` : '',
        ].filter(x => !!x).join('&');

        const response = await api.get<Maintenance[]>(`/vehicle/${id}/unavailablePeriods?${params}`);
        return (response.data?.map(VehicleService.convertMaintenanceToWebFormat).filter(m => !!m) || []) as Maintenance[];
    };

    static getUserLocationByIp = async () => {
        const response = await axios.get(`https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IP_STACK_KEY}`);
        return {
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            countryName: response.data.country_name,
            cityName: response.data.city,
            countryCallingCode: response.data.location && response.data.location.calling_code,
            countryCode: response.data.country_code,
            success: response.data.success
        }
    };
}
