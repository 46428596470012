import React, {useEffect, useState} from 'react';
import classes from './AdminCarCalendar.module.scss';
import {Box, Card, Container} from "@material-ui/core";
import {Booking} from "../../../../models/Booking.model";
import {Maintenance} from "../../../../models/Maintenance.model";
import {Vehicle} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {Calendar, dateFnsLocalizer} from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import {COLORS} from "../../../../styles/theme";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import MaintenanceDialog from "./MaintenanceDialog.component";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import BuildIcon from '@material-ui/icons/Build';
import GcpButton from "../../../../common/ui/GcpButton";
import EventDialog from "./EventDialog.component";
import {VehicleCalendarEvent} from '../../../../models/VehicleCalendarEvent.model';
import {RepeatEvent} from "../../../../models/CalendarEvent.model";
import {addDays, addMonths, addWeeks, addYears} from "date-fns";
import {useTranslation} from "react-i18next";

const locales = {
    'en-US': require('date-fns/locale/en-US'),
}

const useEventBodyStyle = makeStyles({
    container: {},
    title: {
        color: 'white'
    }
})

const VehicleCalendarEventCell = ({event}: { event: any }) => {
    const classes = useEventBodyStyle()

    return (
        <span className={classes.container}>
            <div className={classes.title}>{event.title}</div>
            {event.desc && ':  ' + event.desc}
        </span>
    )
}

interface Props {
    vehicle?: Vehicle
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const ColorCodes = {
    booking: {
        main: COLORS.PRIMARY,
    },
    maintenance: {
        main: COLORS.ORANGE
    },
    event: {
        main: '#1C7DDB'
    }
}

export const AdminCarCalendar = (props: Props) => {

    const [bookings, setBookings] = useState<Booking[]>([]);
    const [maintenances, setMaintenances] = useState<Maintenance[]>([]);
    const [calendarEvents, setCalendarEvents] = useState<VehicleCalendarEvent[]>([]);

    const [selectedMaintenance, setSelectedMaintenance] = useState<Maintenance>();
    const [maintenanceDialogOpen, setMaintenanceDialogOpen] = useState(false);

    const [selectedVehicleEvent, setSelectedVehicleEvent] = useState<VehicleCalendarEvent>();
    const [vehicleEventDialogOpen, setVehicleEventDialogOpen] = useState(false);

    const history = useHistory()
    const t = useTranslation().t

    const events: any[] = []

    maintenances.forEach(maintenance => {
        events.push({
            start: maintenance.from,
            end: maintenance.to,
            title: t('Maintenance'),
            type: 'maintenance',
            entity: maintenance
        })
    })

    bookings.forEach(booking => {
        events.push({
            start: booking.from,
            end: booking.to,
            title: `Booking #${booking.id}`,
            type: 'booking',
            entity: booking
        })
    })

    calendarEvents.forEach(calendarEvent => {
        switch (calendarEvent.repeat) {
            case RepeatEvent.ONCE:
                events.push({
                    start: calendarEvent.from,
                    end: calendarEvent.to,
                    title: calendarEvent.description,
                    type: 'event',
                    entity: calendarEvent
                })
                break;
            case RepeatEvent.DAILY:
                for (let i = 0; i <= 365; i++) {
                    events.push({
                        start: addDays(calendarEvent.from!, i),
                        end: addDays(calendarEvent.to!, i),
                        title: calendarEvent.description,
                        type: 'event',
                        entity: calendarEvent
                    })
                }
                break;
            case RepeatEvent.WEEKLY:
                for (let i = 0; i <= 56; i++) {
                    events.push({
                        start: addWeeks(calendarEvent.from!, i),
                        end: addWeeks(calendarEvent.to!, i),
                        title: calendarEvent.description,
                        type: 'event',
                        entity: calendarEvent
                    })
                }
                break;
            case RepeatEvent.MONTHLY:
                for (let i = 0; i <= 12; i++) {
                    events.push({
                        start: addMonths(calendarEvent.from!, i),
                        end: addMonths(calendarEvent.to!, i),
                        title: calendarEvent.description,
                        type: 'event',
                        entity: calendarEvent
                    })
                }
                break;
            case RepeatEvent.YEARLY:
                for (let i = 0; i <= 1; i++) {
                    events.push({
                        start: addYears(calendarEvent.from!, i),
                        end: addYears(calendarEvent.to!, i),
                        title: calendarEvent.description,
                        type: 'event',
                        entity: calendarEvent
                    })
                }
                break;

        }

    })


    const fetchBookings = async () => {
        const bookings = await AdminVehicleService.getAllBookingsForVehicle(props.vehicle);
        setBookings(bookings);
    };

    const fetchMaintenance = async () => {
        const maintenance = await AdminVehicleService.getAllMaintenancesForVehicle(props.vehicle);
        setMaintenances(maintenance || []);
    };

    const fetchCalendarEvents = async () => {
        const events = await AdminVehicleService.getAllEventsForVehicle(props.vehicle);
        setCalendarEvents(events || []);
    };

    useEffect(() => {
        fetchBookings();
        fetchMaintenance();
        fetchCalendarEvents();
    }, []);

    const handleAddMaintenance = async (maintenance: Maintenance) => {
        if (maintenance.id) {
            const updatedMaintenance = await AdminVehicleService.updateMaintenance(props.vehicle, maintenance);
            const updatedMaintenances = [...(maintenances || [])]
            updatedMaintenances[updatedMaintenances.findIndex(m => m.id === updatedMaintenance.id)] = updatedMaintenance
            setMaintenances(updatedMaintenances)
        } else {
            const newMaintenance = await AdminVehicleService.addMaintenance(props.vehicle, maintenance);
            setMaintenances([...(maintenances || []), newMaintenance]);
        }
        setMaintenanceDialogOpen(false)
    };

    const handleRemoveMaintenance = async (maintenance: Maintenance) => {
        await AdminVehicleService.removeMaintenance(props.vehicle, maintenance);
        setMaintenances(maintenances.filter(m => m.id !== maintenance.id))
        setMaintenanceDialogOpen(false)
    };

    return (
        <Container className={classes.component}>

            <Card style={{marginBottom: 20, padding: 10}}>
                <Box>

                    <GcpButton
                        color={'warning'}
                        variant={'contained'}
                        style={{marginRight: 20}}
                        onClick={() => {
                            setSelectedMaintenance(undefined)
                            setMaintenanceDialogOpen(true)
                        }}
                    >
                        <BuildIcon/>
                        {t('Add maintenance')}
                    </GcpButton>

                    <GcpButton
                        color={'info'}
                        variant={'contained'}
                        onClick={() => {
                            setSelectedVehicleEvent(undefined)
                            setVehicleEventDialogOpen(true)
                        }}
                    >
                        <AddAlertIcon/>
                        {t('Add event/reminder')}
                    </GcpButton>

                </Box>
            </Card>

            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                style={{height: "100vh"}}
                components={{
                    event: VehicleCalendarEventCell
                }}
                min={new Date()}
                eventPropGetter={(event, _start, _end, _isSelected) => {
                    let style;
                    switch (event.type) {
                        case 'booking':
                            style = {
                                backgroundColor: ColorCodes.booking.main,
                                color: 'white',
                            }
                            break;
                        case 'maintenance':
                            style = {
                                backgroundColor: ColorCodes.maintenance.main,
                                color: 'white',
                            }
                            break;
                        case 'event':
                            style = {
                                backgroundColor: ColorCodes.event.main,
                                color: 'white',
                            }
                            break;
                    }

                    return {
                        style
                    }
                }}
                onSelectEvent={event => {
                    switch (event.type) {
                        case 'booking':
                            history.push(`/admin/booking/${event.entity.id}`)
                            break;
                        case 'maintenance':
                            setSelectedMaintenance(event.entity)
                            setMaintenanceDialogOpen(true)
                            break;
                        case 'event':
                            setSelectedVehicleEvent(event.entity)
                            setVehicleEventDialogOpen(true)
                            break;
                    }
                }}
            />

            <MaintenanceDialog
                maintenance={selectedMaintenance}
                open={maintenanceDialogOpen}
                onClose={() => setMaintenanceDialogOpen(false)}
                onRemove={() => handleRemoveMaintenance(selectedMaintenance!)}
                onSave={handleAddMaintenance}
            />

            <EventDialog
                event={selectedVehicleEvent}
                open={vehicleEventDialogOpen}
                onClose={() => setVehicleEventDialogOpen(false)}
                onRemove={async () => {
                    await AdminVehicleService.removeEvent(props.vehicle, selectedVehicleEvent)
                    setCalendarEvents(calendarEvents.filter(e => e.id !== selectedVehicleEvent?.id))
                    setVehicleEventDialogOpen(false)
                }}
                onSave={async event => {
                    if (event.id) {
                        const updatedEvent = await AdminVehicleService.updateEvent(props.vehicle, event)
                        const newEvents = [...calendarEvents]
                        newEvents[newEvents.findIndex(e => e.id === updatedEvent.id)] = updatedEvent
                        setCalendarEvents(newEvents)
                    } else {
                        const addedEvent = await AdminVehicleService.addEvent(props.vehicle, event)
                        setCalendarEvents([...calendarEvents, addedEvent])
                    }

                    setVehicleEventDialogOpen(false)
                }}
            />

        </Container>
    );

};
