import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import Subscription from "../../Profile/Subscription/Subscriptions.component";
import FlexView from "react-flexview";
import classes from './SelectPackage.module.scss';
import Footer from "../../../common/ui/Footer/Footer.component";
import WelcomeDialog from "../WelcomeDialog/WelcomeDialog.component";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps {
    claims?: AuthClaims,
    redirectToOnSignin: string;
}

const SelectPackage = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [welcomeDialogVisible, setWelcomeDialogVisible] = useState(false);
    const [subscriptionUiVisible, setSubscriptionUiVisible] = useState(true);

    useEffect(() => {
        if (process.env.REACT_APP_PREMIUM_SUBSCRIPTIONS_ENABLED === 'false') {
            setWelcomeDialogVisible(true);
            setSubscriptionUiVisible(false);
        }
    }, [false]);

    useEffect(() => {
        trackCurrentPage()
    }, [])

    // ***** Handlers ***** //

    const onSubscriptionSelected = () => {
        setWelcomeDialogVisible(true);
    };

    const welcomeDialogCloseHandler = () => {
        setWelcomeDialogVisible(false);

        if (props.redirectToOnSignin) {
            props.history.push(props.redirectToOnSignin);
        } else {
            props.history.push('/find-car');
        }
    };

    // ***** Render ***** //

    return (

        <FlexView className={classes.component} column hAlignContent='center'>

            {
                subscriptionUiVisible &&
                <FlexView className={classes.content} column hAlignContent='center'>
                    <div className={classes.title}>{t('Choose membership')}</div>
                    <div
                        className={classes.subtitle}>{t('You can change or cancel subscription at any time!')}</div>

                    <Subscription onSubscriptionChanged={onSubscriptionSelected} confirmationMode={'button'} preselect={'freemium'}/>
                </FlexView>
            }

            <WelcomeDialog open={welcomeDialogVisible} onClose={welcomeDialogCloseHandler}/>

            <div style={{flex: 1}}/>
            <Footer/>

        </FlexView>
    )
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        redirectToOnSignin: state.auth.redirectToOnSignin,
    }
};

export default connect(mapStateToProps)(SelectPackage);
