import React, {Component} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import {Button, CircularProgress} from "@material-ui/core";
import i18 from "i18next";
import classes from './SessionActions.module.scss';

import Lock from '../../../assets/icons/lock-red.svg';
import Unlock from '../../../assets/icons/unlock-green.svg';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    isAfter,
    isBefore,
    subDays,
    subHours,
    subMinutes
} from "date-fns";
import clsx from 'clsx';
import SessionEndConfirmationDialog from "../SessionEndConfirmationDialog/SessionEndConfirmationDialog.component";
import {Booking} from "../../../models/Booking.model";
import BookingService from "../../../services/booking.service";
import {AuthClaims} from "../../../models/AuthClaims.model";

interface Props {
    activeBooking?: Booking;
    isNewBooking: boolean;
    claims?: AuthClaims;
    onSessionEnded: () => void;
}

class SessionActions extends Component<Props> {

    state = {
        unlockInProgress: false,
        lockInProgress: false,
        endSessionInProgress: false,
        endSessionDialogOpen: false,

        message: '',
        tick: 0
    };

    // @ts-ignore
    interval: Timeout;

    componentDidMount() {
        this.interval = setInterval(() => this.setState({tick: this.state.tick++}), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleSessionEnd = () => {
        this.setState({endSessionDialogOpen: false});

        this.handleAction(
            'endSession',
            'sessionEnded',
            i18.t('The session is ended'),
            i18.t('Error while ending the session, please try again.'),
            this.props.onSessionEnded,
        );
    };

    handleAction = async (action: 'lock' | 'unlock' | 'endSession', successStatus: string, successMessage: string, errorMessage: string, onDone?: () => void, additionalProps?: any) => {
        this.setState({
            [`${action}InProgress`]: true,
            message: ''
        });
        try {
            const actionExecutor = BookingService[action];
            let response = await actionExecutor(this.props.activeBooking?.id, additionalProps);

            if (response.status === successStatus) {
                this.setState({
                    message: successMessage
                });

                if (onDone) {
                    onDone();
                }
            } else {
                let message = i18.t('Somethings went wrong, please try again');
                switch (response.status) {
                    case 'booking_not_active':
                        message = i18.t('The booking is still not active');
                        break;
                    case 'not_inside_geofenced_area':
                        message = i18.t('The session cannot be ended until the vehicle is returned');
                        break;
                    case 'not_turned_off':
                        message = i18.t('Oops, you have to shut down the engine before you can end the booking');
                        break;
                }

                this.setState({
                    message: message
                });
            }
        } catch (e: any) {
            this.setState({
                message: errorMessage,
            });
        } finally {
            this.setState({
                [`${action}InProgress`]: false
            });
        }
    };

    getTimeString(startDate: Date, endDate: Date) {
        let d, h, m, s;

        d = differenceInDays(endDate, startDate);
        if (d > 0) {
            endDate = subDays(endDate, d);
        }

        h = differenceInHours(endDate, startDate);
        if (h > 0) {
            endDate = subHours(endDate, h);
        }

        m = differenceInMinutes(endDate, startDate);
        if (m > 0) {
            endDate = subMinutes(endDate, m);
        }

        s = differenceInSeconds(endDate, startDate);

        return `${d.toString().padStart(2, '0')}d ` +
            `${h.toString().padStart(2, '0')}h ` +
            `${m.toString().padStart(2, '0')}m ` +
            `${s.toString().padStart(2, '0')}s`;
    }

    renderSessionTimer() {
        if (!this.props.activeBooking?.from || isBefore(new Date(), this.props.activeBooking.from)) return;

        const isOvertime = this.props.activeBooking.to && isAfter(new Date(), this.props.activeBooking.to);

        let text;
        let timeValue;
        if (isOvertime) {
            timeValue = this.props.activeBooking.to && this.getTimeString(this.props.activeBooking.to, new Date()) || '-';
            text = i18.t('Session overtime');
        } else {
            timeValue = this.props.activeBooking.to && this.getTimeString(new Date(), this.props.activeBooking.to) || '-';
            text = i18.t('Remaining time');
        }

        return (
            <FlexView className={clsx(classes.sessionTimer, isOvertime && classes.overtime)}>
                <div className={classes.timerTimeLabel}>{text}</div>
                <div className={classes.timerTimeValue}>{timeValue}</div>
            </FlexView>
        )
    }

    render() {
        if (this.props.isNewBooking || !this.props.activeBooking) return null;

        const loadingInProgress = this.state.endSessionInProgress || this.state.lockInProgress || this.state.unlockInProgress;

        const disabled =
            !this.props.activeBooking.from ||
            isBefore(new Date(), subMinutes(this.props.activeBooking.from, 5)) ||
            this.props.activeBooking.status !== 'active' ||
            loadingInProgress;

        let disabledText = '';
        if (disabled && !loadingInProgress) {
            disabledText = i18.t('The vehicle can be unlocked only during the duration of the booking, not before or after.');
        }

        return (
            <FlexView column hAlignContent='center'>

                {this.renderSessionTimer()}

                <FlexView className={classes.sessionActionsContainer} hAlignContent='center' vAlignContent='center'
                          wrap>
                    <FlexView column hAlignContent='center' className={classes.unlockSection}
                              onClick={() => this.handleAction('unlock', 'unlocked', i18.t('The vehicle is unlocked'), i18.t('Error while unlocking the vehicle, please try again.'))}
                    >

                        <img src={Unlock} className={classes.lockIcon}/>

                        {!this.state.unlockInProgress &&
                        <Button
                            color="primary"
                            disabled={disabled}
                        >
                            {i18.t('Unlock the vehicle')}
                        </Button>
                        }

                        {this.state.unlockInProgress && <CircularProgress/>}

                    </FlexView>

                    <FlexView column hAlignContent='center' className={classes.unlockSection}
                              onClick={() => this.handleAction('lock', 'locked', i18.t('The vehicle is locked'), i18.t('Error while locking the car, please try again.'))}
                    >

                        <img src={Lock} className={classes.lockIcon}/>

                        {!this.state.lockInProgress &&
                        <Button
                            color="primary"
                            disabled={disabled}
                        >
                            {i18.t('Lock the vehicle')}
                        </Button>
                        }

                        {this.state.lockInProgress && <CircularProgress/>}

                    </FlexView>

                    <FlexView column hAlignContent='center' className={clsx(classes.unlockSection, classes.endSession)}
                              onClick={() => this.setState({endSessionDialogOpen: true})}
                              disabled={disabled}
                              vAlignContent='center'
                    >

                        {
                            !this.state.endSessionInProgress && <div>{i18.t('End booking')}</div>
                        }

                        {this.state.endSessionInProgress && <CircularProgress/>}

                    </FlexView>

                </FlexView>

                {this.state.message && <div className={classes.unlockMessage}>{this.state.message}</div>}

                {disabledText && <div className={classes.unlockMessage}>{disabledText}</div>}

                <SessionEndConfirmationDialog
                    open={this.state.endSessionDialogOpen}
                    onClose={() => this.setState({
                        endSessionDialogOpen: false,
                        endSessionDialogForce: false
                    })}
                    onConfirm={this.handleSessionEnd}
                />

            </FlexView>
        )
    }

}

export default SessionActions;
