import React from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import {Page} from "../../../models/Page";
import {User} from "../../../models/User.model";
import Pagination from "../../../common/ui/Pagination/Pagination.component";
import {Box, Card, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";

interface Props {
    loading?: boolean;
    users?: Page<User>;
    onUserClick: (user: User) => void;
    onPageChange: (page: number) => void;
}

const UsersList = ({users, onUserClick, onPageChange, loading}: Props) => {

    const t = useTranslation().t
    
    if (loading) {
        return <CircularProgress />
    }

    if (!users?.size) {
        return <div>No users found</div>
    }

    const header = (
        <FlexView vAlignContent='center' className={classes.header}>
            <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Name')}</div>
            <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Email')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Phone')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Status')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Verification')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Organization')}</div>
        </FlexView>
    )

    const content = users.content.map(user => {
        return (
            <FlexView key={user.id} onClick={() => onUserClick?.(user)} vAlignContent='center'
                      className={classes.tableRow}>
                <div className={classes.cellLarge}>{user.name}</div>
                <div className={classes.cellLarge}>{(user.email || "").substring(0, 40)}</div>
                <div className={classes.cellSmall}>{user.phone}</div>
                <div
                    className={classes.cellSmall}>{user.suspended ? t('Suspended') : t('Active')}</div>
                <div
                    className={classes.cellSmall}>{user.verified ? t('Verified') : t('Not verified')}</div>
                <div className={classes.cellSmall}>{user.organization?.name}</div>
            </FlexView>
        )
    })

    if (loading) {
        return <CircularProgress/>
    }

    return (
        <Card>

            {header}
            {content}

            <Box style={{width: '100%'}} display={'flex'} justifyContent={'center'}>
                <Pagination
                    pages={users.totalPages || 0}
                    currentPage={users?.page || 0}
                    onPageChange={onPageChange}
                />
            </Box>

        </Card>
    )
}

export default UsersList;