import {
    AUTHORIZATION_DONE,
    AUTHORIZATION_IN_PROGRESS,
    LOGOUT_DONE,
    SET_AUTH_ERROR,
    SET_LANGUAGE,
    SET_LOGGED_IN_AS_ANOTHER_USER,
    SET_SUCCESSFUL_SIGN_REDIRECT,
    SET_USER,
    TOKEN_SIGNIN_DONE,
    TOKEN_SIGNIN_IN_PROGRESS,
    UPDATE_INFO,
    UPDATE_SUBSCRIPTION_DONE
} from "../actions/actionTypes";
import GcpError from "../../models/error.enum";
import {AuthClaims} from "../../models/AuthClaims.model";

export interface AuthState {
    claims?: AuthClaims,
    loggedInAsAnotherUser?: boolean;
    error: GcpError,

    tokenSigninInProgress: boolean,
    tokenSigninTried: boolean,
    signInProgress: boolean,

    updateInfoInProgress: boolean;
    updateInfoError: GcpError;

    redirectToOnSignin: string,

    language: string,
}

const initialState: AuthState = {
    error: GcpError.NONE,
    tokenSigninInProgress: false,
    tokenSigninTried: false,
    signInProgress: false,

    updateInfoInProgress: false,
    updateInfoError: GcpError.NONE,

    redirectToOnSignin: '',
    language: localStorage.getItem('i18nextLng') || 'sv'
};

const authReducer = (state: AuthState = initialState, action: any): AuthState => {
    switch (action.type) {

        // ***** Auth ***** //

        case AUTHORIZATION_IN_PROGRESS:
            return {
                ...state,
                signInProgress: action.payload
            };

        case AUTHORIZATION_DONE:
            return {
                ...state,
                ...action.payload,
                signInProgress: false
            };

        case TOKEN_SIGNIN_IN_PROGRESS:
            return {
                ...state,
                tokenSigninTried: true,
                tokenSigninInProgress: true,
            };

        case TOKEN_SIGNIN_DONE:
            return {
                ...state,
                tokenSigninInProgress: false,
            };

        case SET_AUTH_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case SET_USER:
            return {
                ...state,
                claims: {
                    ...state.claims,
                    user: action.payload
                }
            };

        case SET_LOGGED_IN_AS_ANOTHER_USER:
            return {
                ...state,
                loggedInAsAnotherUser: action.payload
            }

        // ***** Misc ***** //

        case SET_SUCCESSFUL_SIGN_REDIRECT:
            return {
                ...state,
                redirectToOnSignin: action.payload
            };

        // ***** Profile ***** //

        case UPDATE_INFO:
            return {
                ...state,
                updateInfoInProgress: action.payload.updateInfoInProgress,
                updateInfoError: action.payload.updateInfoError,
                claims: {
                    ...state.claims,
                    user: action.payload.user || state.claims?.user
                }
            };

        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };

        case UPDATE_SUBSCRIPTION_DONE:
            return {
                ...state,
                claims: {
                    ...state.claims,
                    subscription: action.payload
                }
            };

        // ***** Logout ***** //

        case LOGOUT_DONE:
            return {
                ...initialState,
                signInProgress: false,
            };
    }
    return state;
};

export default authReducer;
