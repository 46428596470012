import React, {useEffect, useState} from 'react';
import {isFormValid, validateForm} from "../../../../common/validators.util";
import FlexView from "react-flexview";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import GcpInput from "../../../../common/ui/GcpInput";
import classesCommon from '../../AdminEntityCommon.module.scss';
import clsx from 'clsx';
import classes from './AdminCarExternalForm.module.scss';
import {RouteComponentProps, withRouter} from "react-router";
import {CarManualActions} from "../../common/CarManulActions/CarManualActions.component";
import {CarStatus} from "../CarStatus/CarStatus.component";
import {Vehicle, VehicleType} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import TelemetryModel from "../../../../models/Telemetry.model";
import {format} from 'date-fns';
import {useTranslation} from "react-i18next";
import SmartcarService from "../../../../services/smartcar.service";

interface Props extends RouteComponentProps {
    vehicle?: Vehicle,
    onUpdate: (vehicle: Vehicle) => void;
}

type formFields =
    'type'
    | 'latitude'
    | 'longitude'
    | 'fuelLevel'
    | 'charging'
    | 'mileage'
    | 'phone'
    | 'deviceuuid'
    | 'ignition'
    | 'vin'
    | 'autoPreheat';

const AdminCarExternalForm = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t

    const [form, setForm] = useState({

        type: {
            label: t('Type'),
            placeholder: 'ex. demo',
            type: 'select',
            value: 'demo',
            options: [
                {value: 'demo', text: 'Demo'},
                {value: 'innova', text: 'Innova'},
                {value: 'moc', text: 'MoC'},
                {value: 'linka', text: 'Linka'},
                {value: 'smartcar', text: 'Smartcar'},
                {value: 'mocsmartcar', text: 'MoC + Smartcar'},
            ],
            validation: {
                error: '',
                validators: []
            }
        },
        latitude: {
            label: t('Latitude'),
            placeholder: 'ex. 44.1241421',
            type: 'number',
            value: '',
            validation: {
                error: '',
                validators: [
                    // (value) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        longitude: {
            label: t('Longitude'),
            placeholder: 'ex. 44.1241421',
            type: 'number',
            value: '',
            validation: {
                error: '',
                validators: [
                    // (value) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        fuelLevel: {
            label: t('Battery level'),
            placeholder: '0 - 100',
            type: 'number',
            value: '',
            validation: {
                error: '',
                validators: [
                    // (value) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        charging: {
            label: t('Battery charging'),
            placeholder: 'ex. i3',
            type: 'select',
            value: true,
            options: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ],
            validation: {
                error: '',
                validators: []
            }
        },
        mileage: {
            label: t('Mileage'),
            placeholder: 'km',
            type: 'number',
            value: '',
            validation: {
                error: '',
                validators: [
                    // (value) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        phone: {
            label: t('Phone'),
            placeholder: '+46731234567',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: []
            }
        },
        deviceuuid: {
            label: t('Device UUID'),
            placeholder: 'a9325f634982437',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: [
                    // (value) => requiredValidator(value, t('This is a required field')),
                ]
            }
        },
        ignition: {
            label: t('Is car on (Ignition = YES)'),
            placeholder: 'ex. Yes',
            type: 'select',
            value: false,
            options: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ],
            validation: {
                error: '',
                validators: []
            }
        },
        vin: {
            label: t('Vin'),
            placeholder: 'ABC123',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: []
            }
        },
        autoPreheat: {
            label: t('Preheat car 10 min before booking'),
            placeholder: 'ex. Yes',
            type: 'select',
            value: false,
            options: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ],
            validation: {
                error: '',
                validators: []
            }
        },
    });

    const [loading, setLoading] = useState(false);
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [status, setStatus] = useState('');

    // Telemetry
    const [latestTelemetry, setLatestTelemetry] = useState<TelemetryModel>();
    const [telemetryStatus, setTelemetryStatus] = useState<'' | 'loading' | 'error' | 'loaded'>('');

    // Device info
    // const [latestDeviceInfo, setLatestDeviceInfo] = useState<any>();
    const [deviceInfoStatus, setDeviceInfoStatus] = useState<'' | 'loading' | 'error' | 'loaded'>('');
    const [ignitionVoltageLocal, setIgnitionVoltageLocal] = useState<string>();
    const [ignitionVoltageStatus, setIgnitionVoltageStatus] = useState<'saving' | '' | 'error'>('');
    const [heartbeatUrlLocal, setHeartbeatUrlUrlLocal] = useState('');
    const [heartbeatUrlStatus, setHeartbeatUrlUrlStatus] = useState<'saving' | '' | 'error'>('');
    const [gpsSamplingFrequencyLocal, setGpsSamplingFrequencyLocal] = useState<string>();
    const [gpsSamplingFrequencyStatus, setGpsSamplingFrequencyStatus] = useState<'saving' | '' | 'error'>('');


    // ***** Effects ***** //

    useEffect(() => {
        if (!props.vehicle) return;

        try {
            setForm({
                type: {
                    ...form.type,
                    value: props.vehicle.hardwareProvider || 'demo'
                },
                latitude: {
                    ...form.latitude,
                    value: `${props.vehicle.hardwareInfo?.location?.latitude || 0}`
                },
                longitude: {
                    ...form.longitude,
                    value: `${props.vehicle.hardwareInfo?.location?.longitude || 0}`
                },
                fuelLevel: {
                    ...form.fuelLevel,
                    value: `${props.vehicle.hardwareInfo?.fuelLevel || 100}`
                },
                charging: {
                    ...form.charging,
                    value: props.vehicle.hardwareInfo?.charging || true
                },
                mileage: {
                    ...form.mileage,
                    value: `${props.vehicle.hardwareInfo?.mileage || 0}`
                },
                phone: {
                    ...form.phone,
                    value: props.vehicle.hardwareInfo?.phone || ''
                },
                deviceuuid: {
                    ...form.deviceuuid,
                    value: props.vehicle.hardwareProviderDeviceId || ''
                },
                ignition: {
                    ...form.ignition,
                    value: props.vehicle.hardwareInfo?.ignition || false
                },
                vin: {
                    ...form.vin,
                    value: props.vehicle.hardwareInfo?.vin || ''
                },
                autoPreheat: {
                    ...form.autoPreheat,
                    value: props.vehicle.hardwareInfo?.autoPreheat || false
                },
            });
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [props.vehicle]);

    // ***** Handlers ***** //

    const inputHandler = (fieldId: 'type' | 'latitude' | 'longitude' | 'fuelLevel' | 'charging' | 'mileage' | 'phone' | 'deviceuuid' | 'ignition' | 'vin' | 'autoPreheat', value: any) => {
        const newFormField = {
            ...form[fieldId],
            value: value,
            validation: {
                ...form[fieldId].validation,
                error: ''
            }
        };
        const newForm = {
            ...form,
            [fieldId]: newFormField
        };

        setForm(newForm);
    };

    const submitHandler = async () => {
        const validatedForm = validateForm(form);
        setForm(validatedForm);
        if (isFormValid(validatedForm, getFieldsForExternalType(form.type.value))) {
            await saveCar(validatedForm);
        }
    };

    const saveCar = async (form: any) => {
        setSavingInProgress(true);
        try {
            const vehicle = await AdminVehicleService.update({
                ...props.vehicle,
                hardwareProvider: form.type.value,
                hardwareProviderDeviceId: form.deviceuuid.value || '',
                hardwareInfo: {
                    ...props.vehicle?.hardwareInfo,
                    location: {
                        latitude: +form.latitude.value,
                        longitude: +form.longitude.value
                    },
                    fuelLevel: form.fuelLevel.value,
                    charging: form.charging.value,
                    mileage: form.mileage.value,
                    phone: form.phone.value || '',
                    ignition: form.ignition.value,
                    vin: form.vin.value || '',
                    autoPreheat: form.autoPreheat.value
                }
            });

            props.onUpdate(vehicle);
            setStatus('Saved!');
        } catch (e: any) {
            setStatus('Error while saving! ' + e.message);
        } finally {
            setSavingInProgress(false);
        }
    };

    const loadTelemetry = async () => {
        setTelemetryStatus('loading');
        try {
            const response = await AdminVehicleService.getTelemetry(props.vehicle?.id);
            setLatestTelemetry(response);
            setTelemetryStatus('loaded');
        } catch (e: any) {
            setTelemetryStatus('error');
        }
    }

    const loadDeviceInfo = async () => {
        setDeviceInfoStatus('loading');
        try {
            const response = await AdminVehicleService.getDeviceInfo(props.vehicle?.id);
            // setLatestDeviceInfo(response.data);
            setIgnitionVoltageLocal(response.ignitionVoltage ? `${response.ignitionVoltage}` : '0');
            setHeartbeatUrlUrlLocal(response.heartbeatUrl || '');
            setGpsSamplingFrequencyLocal(response.gpsTrackingFrequencySeconds ? `${response.gpsTrackingFrequencySeconds}` : '0');
            setDeviceInfoStatus('loaded');
        } catch (e: any) {
            setDeviceInfoStatus('error');
        }
    }

    const updateDeviceInfo = async (propertyName: string, data?: any) => {
        return await AdminVehicleService.setupDevice(props.vehicle?.id, {
            properties: [
                {name: propertyName, data: data}
            ]
        });
    }

    const updateIgnitionVoltage = async () => {
        const saveVoltage = ignitionVoltageLocal && parseFloat(ignitionVoltageLocal) || 13;
        if (isNaN(saveVoltage) || saveVoltage < 0) {
            setIgnitionVoltageStatus('error');
            return;
        }

        setIgnitionVoltageStatus('saving');
        try {
            await updateDeviceInfo('ignitionVoltage', saveVoltage)
            setIgnitionVoltageStatus('');
        } catch (e: any) {
            setIgnitionVoltageStatus('error');
        }
    }

    const updateHeartbeatUrl = async () => {
        setHeartbeatUrlUrlStatus('saving');
        try {
            await updateDeviceInfo('heartbeat')
            setHeartbeatUrlUrlStatus('');
            loadDeviceInfo();
        } catch (e: any) {
            setHeartbeatUrlUrlStatus('error');
        }
    }

    const updateGpsFrequency = async () => {
        const saveFrequency = gpsSamplingFrequencyLocal && parseFloat(gpsSamplingFrequencyLocal) || 0;
        if (isNaN(saveFrequency) || saveFrequency < 0) {
            setGpsSamplingFrequencyStatus('error');
            return;
        }

        setGpsSamplingFrequencyStatus('saving');
        try {
            await updateDeviceInfo('gpstracking', saveFrequency)
            setGpsSamplingFrequencyStatus('');
        } catch (e: any) {
            setGpsSamplingFrequencyStatus('error');
        }
    }

    // ***** Render ***** //

    const getFieldsForExternalType = (type: string): formFields[] => {
        let fields: formFields[] = [];
        switch (type) {
            case 'demo':
                fields = ['latitude', 'longitude', 'fuelLevel', 'charging', 'mileage', 'ignition'];
                break;
            case 'innova':
                fields = ['phone']
                break;
            case 'moc':
            case 'mocsmartcar':
            case 'linka':
                fields = ['deviceuuid'];
                break;
            default:
                fields = [];
        }

        if (props.vehicle?.vehicleType === VehicleType.CAR) {
            fields.push('vin', 'autoPreheat');
        }

        return fields;
    };

    const renderFormElement = (key: formFields) => {
        const formElement: any = form[key];
        switch (formElement.type) {
            case 'select':
                return (
                    <FormControl className={classesCommon.formElement}
                                 margin="normal"
                    >
                        <InputLabel htmlFor="key">{formElement.label}</InputLabel>
                        <Select
                            value={formElement.value}
                            onChange={(event) => inputHandler(key, event.target.value)}
                            inputProps={{
                                name: key,
                                id: key,
                            }}
                        >
                            {
                                formElement.options.map((option: any) => <MenuItem key={option.value}
                                                                                   value={option.value}>{option.text}</MenuItem>)
                            }

                        </Select>
                    </FormControl>
                );
            default:
                return (
                    <GcpInput
                        error={!!formElement.validation.error}
                        key={key}
                        className={classesCommon.formElement}
                        label={formElement.label}
                        placeholder={formElement.placeholder}
                        margin="normal"
                        type={formElement.type}
                        // variant="outlined"
                        disabled={savingInProgress}
                        value={formElement.value}
                        helperText={formElement.validation.error}
                        onChange={(event) => inputHandler(key, event.target.value)}
                    />
                )
        }
    };

    const renderTelemetry = () => {
        return (
            <Card
                className={classesCommon.form}
            >
                <div className={classesCommon.sectionTitle}>{t('Car telemetry')}</div>

                <FlexView
                    className={classes.telemetryInfo}
                    hAlignContent={'center'}
                    vAlignContent={'center'}
                >
                    {
                        telemetryStatus === 'loading' && <CircularProgress/>
                    }

                    {
                        telemetryStatus === 'loaded' &&
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Latitude')}:</div>
                                    <div>{latestTelemetry?.latitude}</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Longitude')}:</div>
                                    <div>{latestTelemetry?.longitude}</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Fuel level')}:</div>
                                    <div>{latestTelemetry?.fuelLevel}%</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Mileage')}:</div>
                                    <div>{latestTelemetry?.mileage?.toFixed(2)}km</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Charging')}</div>
                                    <div>{latestTelemetry?.charging ? t('Yes') : t('No')}</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>Ignition</div>
                                    <div>{latestTelemetry?.ignition ? t('Yes') : t('No')}</div>
                                </FlexView>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FlexView className={classes.infoElement}>
                                    <div className={classes.infoLabel}>{t('Last location time')}</div>
                                    <div>{latestTelemetry?.lastLocationTime && format(new Date(latestTelemetry.lastLocationTime), 'dd/MM/yyyy HH:mm') || '-'}</div>
                                </FlexView>
                            </Grid>

                        </Grid>
                    }

                    {
                        telemetryStatus === '' &&
                        <div>{t('Press Load latest telemetry to load data')}</div>
                    }

                    {
                        telemetryStatus === 'error' &&
                        <div className={classes.telemetryInfo}>{t('Telemetry could not be loaded')}</div>
                    }
                </FlexView>

                <Button variant="contained" color="primary"
                        className={classesCommon.submitButton}
                        onClick={loadTelemetry}
                >
                    {t('Load latest telemetry')}
                </Button>
            </Card>
        )
    }

    const renderDeviceInfo = () => {
        return (
            <Card
                className={classesCommon.form}
            >
                <div className={classesCommon.sectionTitle}>{t('Car device info')}</div>

                <FlexView
                    className={clsx(classes.telemetryInfo)}
                    hAlignContent={'center'}
                    vAlignContent={'center'}
                >
                    {
                        deviceInfoStatus === 'loading' && <CircularProgress/>
                    }

                    {
                        deviceInfoStatus === 'loaded' &&
                        <FlexView style={{width: '100%'}} wrap>

                            {
                                props.vehicle?.vehicleType === VehicleType.CAR &&
                                <FlexView style={{width: '100%'}} vAlignContent={'center'}>
                                    <div className={classes.infoLabel}>{t('Ignition voltage')}:</div>
                                    <GcpInput
                                        value={ignitionVoltageLocal}
                                        className={classes.carInfoInput}
                                        onChange={e => setIgnitionVoltageLocal(e.target.value)}
                                        error={ignitionVoltageStatus === "error"}
                                        helperText={ignitionVoltageStatus === "error" && t('The entered value could not be saved')}
                                    />

                                    {
                                        ignitionVoltageStatus === "saving" ?
                                            <CircularProgress/> :
                                            <Button variant="contained" color="primary"
                                                // className={classesCommon.submitButton}
                                                    onClick={updateIgnitionVoltage}
                                            >
                                                {t('Save')}
                                            </Button>
                                    }

                                </FlexView>
                            }


                            <FlexView style={{width: '100%'}} vAlignContent={'center'}>
                                <div className={classes.infoLabel}>{t('Hearthbeat url')}:</div>
                                <GcpInput
                                    disabled
                                    value={heartbeatUrlLocal}
                                    className={classes.carInfoInput}
                                    onChange={e => setHeartbeatUrlUrlLocal(e.target.value)}
                                    error={heartbeatUrlStatus === "error"}
                                    helperText={heartbeatUrlStatus === "error" && t('The heartbeat url cannot be reset')}
                                />

                                {
                                    heartbeatUrlStatus === "saving" ?
                                        <CircularProgress/> :
                                        <Button variant="contained" color="primary"
                                            // className={classesCommon.submitButton}
                                                onClick={updateHeartbeatUrl}
                                        >
                                            {t('Setup')}
                                        </Button>
                                }

                                {/*<div>{latestDeviceInfo?.telemetry?.ignitionVoltage}</div>*/}
                            </FlexView>

                            {
                                props.vehicle?.vehicleType === VehicleType.SCOOTER &&
                                <FlexView style={{width: '100%'}} vAlignContent={'center'}>
                                    <div
                                        className={classes.infoLabel}>{t('Gps sampling frequency seconds (0 for default)')}:
                                    </div>
                                    <GcpInput
                                        value={gpsSamplingFrequencyLocal}
                                        className={classes.carInfoInput}
                                        onChange={e => setGpsSamplingFrequencyLocal(e.target.value)}
                                        error={gpsSamplingFrequencyStatus === "error"}
                                        helperText={gpsSamplingFrequencyStatus === "error" && t('The entered value could not be saved')}
                                    />

                                    {
                                        gpsSamplingFrequencyStatus === "saving" ?
                                            <CircularProgress/> :
                                            <Button variant="contained" color="primary"
                                                // className={classesCommon.submitButton}
                                                    onClick={updateGpsFrequency}
                                            >
                                                {t('Save')}
                                            </Button>
                                    }

                                </FlexView>
                            }

                        </FlexView>
                    }

                    {
                        deviceInfoStatus === '' &&
                        <div>{t('Press Load device info to load data')}</div>
                    }

                    {
                        deviceInfoStatus === 'error' &&
                        <div className={classes.telemetryInfo}>{t('Device info could not be loaded')}</div>
                    }

                </FlexView>

                <Button variant="contained" color="primary"
                        className={classesCommon.submitButton}
                        onClick={loadDeviceInfo}
                >
                    {t('Load device info')}
                </Button>
            </Card>
        )
    }

    const renderVehicleManagement = () => {
        return (
            <Card
                className={classesCommon.form}
            >
                <div className={classesCommon.sectionTitle}>{t('Manual vehicle management')}s</div>

                <CarManualActions vehicle={props.vehicle}/>

            </Card>
        )
    }

    const renderVehicleActivation = () => {
        return (
            <Card
                className={classesCommon.form}
            >
                <div className={classesCommon.sectionTitle}>{t('Vehicle status')}</div>

                <CarStatus
                    vehicle={props.vehicle}
                    onUpdate={props.onUpdate}
                />

            </Card>
        )
    }

    return (
        <FlexView column className={classes.sectionHolder} hAlignContent='center'>

            {
                loading ?
                    <CircularProgress/>
                    :
                    <Card
                        className={classesCommon.form}
                    >

                        <div className={classesCommon.sectionTitle}>{t('Device settings')}</div>

                        {renderFormElement('type')}

                        <Box m={2}/>

                        <Grid container spacing={2}>
                            {getFieldsForExternalType(form.type.value).map(key => (
                                <Grid key={key} item xs={12} sm={6} md={4}>
                                    {renderFormElement(key)}
                                </Grid>
                            ))}
                        </Grid>

                        {
                            (form.type.value === 'smartcar' || form.type.value === 'mocsmartcar') &&
                                <Box marginTop={3} marginBottom={3}>
                                    <Typography>Smart car setup</Typography>

                                    <Typography>Access token: {props.vehicle?.hardwareInfo?.accessToken}</Typography>
                                    <Typography>
                                        Expiration:
                                        {props.vehicle?.hardwareInfo?.accessTokenExpiration ?
                                            format( new Date(props.vehicle?.hardwareInfo?.accessTokenExpiration), 'yyyy-MM-dd HH:mm:ss') : ''
                                        }
                                    </Typography>
                                    <Typography>Refresh token: {props.vehicle?.hardwareInfo?.refreshToken}</Typography>
                                    <Typography>
                                        Refresh token expiration:
                                        {props.vehicle?.hardwareInfo?.refreshTokenExpiration ?
                                            format( new Date(props.vehicle?.hardwareInfo?.refreshTokenExpiration), 'yyyy-MM-dd HH:mm:ss') : ''
                                        }
                                    </Typography>

                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        disabled={!props.vehicle?.hardwareInfo?.vin}
                                        onClick={async () => {
                                            try {
                                                const response = await SmartcarService.generateToken(props.vehicle?.hardwareInfo?.vin!)
                                                props.onUpdate({
                                                    ...props.vehicle,
                                                    hardwareInfo: {
                                                        ...props.vehicle?.hardwareInfo,
                                                        accessToken: response.accessToken,
                                                        accessTokenExpiration: response.expiration,
                                                        refreshToken: response.refreshToken,
                                                        refreshTokenExpiration: response.refreshExpiration
                                                    }
                                                })
                                                console.log(response)
                                            } catch (e) {
                                                alert('Failed to obtain auth token')
                                            }

                                        }}
                                    >
                                        {t('Generate auth token')}
                                        {!props.vehicle?.hardwareInfo?.vin && t('**Enter VIN**')}
                                    </Button>
                                </Box>
                        }

                        <Box m={2}/>

                        {
                            savingInProgress ? <CircularProgress className={classesCommon.submitButton}/> :
                                <Button variant="contained" color="primary"
                                        className={classesCommon.submitButton}
                                        onClick={submitHandler}>
                                    {t('Save')}
                                </Button>
                        }

                        <div className={classes.status}>{status}</div>

                    </Card>
            }

            {renderVehicleActivation()}

            {renderTelemetry()}

            {renderDeviceInfo()}

            {renderVehicleManagement()}

        </FlexView>
    );

};

export default withRouter(AdminCarExternalForm);
