import React from 'react';
import FlexView from "react-flexview";
import classes from './Pricing.module.scss';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";

const Pricing = () => {

    const t = useTranslation().t
    const regularSubscriptionFee = +(process.env.REACT_APP_REGULAR_SUBSCRIPTION_FEE || 0);
    const regularPrice = +(process.env.REACT_APP_REGULAR_PRICE || 0);
    const premiumSubscriptionFee = +(process.env.REACT_APP_PREMIUM_SUBSCRIPTION_FEE || 0);
    const premiumPrice = +(process.env.REACT_APP_PREMIUM_PRICE || 0);
    const regularDailyMax = +(process.env.REACT_APP_REGULAR_DIALY_MAX || 0);
    const premiumDailyMax = +(process.env.REACT_APP_PREMIUM_DIALY_MAX || 0);

    return (
        <FlexView className={classes.component} column hAlignContent='center'>
            <div className={classes.title}>{t('Simple pricing, no surprises')}</div>

            <FlexView className={classes.pricing} vAlignContent='center' hAlignContent='center' wrap>
                <FlexView className={classes.price} column hAlignContent='center' vAlignContent='center'>
                    <div className={classes.type}>Freemium</div>
                    <div className={classes.priceValue}>{regularSubscriptionFee}</div>
                    <div className={classes.priceMinute}>SEK/{t('month')}</div>
                    <div className={classes.priceValue}>{regularPrice}</div>
                    <div className={classes.priceMinute}>{t('SEK/minute')}</div>
                    <div className={classes.maxPrice}>{t('Max daily price', {price: regularDailyMax})}</div>
                </FlexView>

                <FlexView className={clsx(classes.price, classes.premium)} column hAlignContent='center'
                          vAlignContent='center'>
                    <div className={classes.type}>Premium</div>
                    <div className={classes.priceValue}>{premiumSubscriptionFee}</div>
                    <div className={classes.priceMinute}>SEK/{t('month')}</div>
                    <div className={classes.priceValue}>{premiumPrice}</div>
                    <div className={classes.priceMinute}>{t('SEK/minute')}</div>
                    <div className={classes.maxPrice}>{t('Max daily price', {price: premiumDailyMax})}</div>
                </FlexView>
            </FlexView>

            <div
                className={classes.priceChangeNote}>{t('Simple pricing, just pay for the time')}</div>

        </FlexView>
    )
};

export default Pricing;
