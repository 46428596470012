import React, {useEffect} from "react";
import FlexView from "react-flexview";
import classes from './Signin.module.scss';
import Footer from "../../../common/ui/Footer/Footer.component";
import SignupForm from "../SignupForm/SignupForm.component";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

const Signin = () => {

    const t = useTranslation().t

    useEffect(() => {
        trackCurrentPage()
    }, [])
    
    return (
        <FlexView column className='component' vAlignContent='top' hAlignContent='center'>

            <div className={classes.title}>{t('Sign in')}</div>

            <SignupForm signupForm={false}/>

            <Footer/>

        </FlexView>
    );
};

export default Signin;
