import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, Checkbox, CircularProgress, debounce, TextField, Typography} from "@material-ui/core";
import {useListStyle} from "./list.style";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Pagination} from "@material-ui/lab";
import {BaseModel} from "../../../models/BaseModel.model";
import clsx from "clsx";
import {GeneralSet} from "../../generalSet";
import {useTranslation} from "react-i18next";


export interface ListColumn {
    name: string,
    id: string,
    size?: 's' | 'm' | 'l'
}

interface Props<T extends BaseModel> {
    items?: T[]
    columns: ListColumn[]
    renderCell: (item: T, column: ListColumn) => JSX.Element | null

    size: number,
    onSizeChange: (size: number) => void

    page: number,
    onPageChange: (page: number) => void
    totalPages?: number
    totalElements?: number

    onItemClick: (item: T, index: number) => void
    onAdd?: () => void

    loading?: boolean
    multiselect?: boolean

    placeholder?: string;
    onSearch?: (query: string) => void;

    selected?: GeneralSet<T>
    onSelect?: (item: T) => void
    onSelectAll?: () => void
}

const GcpList = <T extends BaseModel>(props: Props<T>) => {

    const classes = useListStyle()
    const t = useTranslation().t

    const from = (props.page - 1) * props.size + 1
    const to = from + (props.items?.length || 0) - 1
    const showingText = t(`Showing list items`, {
        from,
        to,
        total: props.totalElements
    })

    const [searchQuery, setSearchQuery] = useState('');

    const notifySearch = useRef(debounce((query: string) => props.onSearch?.(query), 1000)).current;

    useEffect(() => {
        notifySearch(searchQuery);
    }, [searchQuery]);


    const addButton = (
        <Button
            onClick={props.onAdd}
            color={'primary'}
            variant={'contained'}
        >
            {t('Add')}
        </Button>
    )

    const search = (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes.topBar}
        >
            <Typography variant={'body1'}>
                {!props.loading && props.items?.length && showingText}
            </Typography>

            <Box display={'flex'} alignItems={'center'}>
                <TextField
                    className={classes.search}
                    placeholder={props.placeholder || 'Search'}
                    variant={'outlined'}
                    size={'small'}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
                {props.onAdd && addButton}
            </Box>
        </Box>
    )

    const header = (
        <Box display={'flex'} className={classes.header}>

            {props.multiselect && <Checkbox
                checked={!!props.selected?.size() && props.selected.size() === props.items?.length}
                onChange={props.onSelectAll}
            />}

            {
                props.columns.map(column => {
                    let extraClass = ''
                    switch (column.size) {
                        case "s":
                            extraClass = classes.cellS
                            break
                        case "l":
                            extraClass = classes.cellL
                            break
                    }

                    return (
                        <Box key={column.id} className={clsx(classes.cell, extraClass)} display={'flex'}
                             alignItems={'center'}>
                            <div className={classes.headerText}>{column.name}</div>
                        </Box>
                    )
                })
            }

        </Box>
    )

    const itemsList = props.items?.map((item, index) => {
        return (
            <Box
                key={item.id}
                display={'flex'}
                className={classes.row}
                onClick={() => props.onItemClick(item, index)}
            >

                {
                    props.multiselect &&
                    <Checkbox
                        checked={props.selected?.has(item)}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        onChange={() => props.onSelect?.(item)}
                    />
                }

                {
                    props.columns.map(column => {
                        let extraClass = ''
                        switch (column.size) {
                            case "s":
                                extraClass = classes.cellS
                                break
                            case "l":
                                extraClass = classes.cellL
                                break
                        }

                        return (
                            <Box className={clsx(classes.cell, extraClass)} display={'flex'} alignItems={'center'}>
                                {props.renderCell(item, column)}
                            </Box>
                        )
                    })
                }

            </Box>
        )
    })

    const footer = (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={classes.footer}>

            <Box display={'flex'} alignItems={'center'}>
                <Typography variant={"body1"}>{t('Show number of items')}</Typography>
                <Select
                    variant={'standard'}
                    labelId="page-size-label"
                    value={props.size}
                    className={classes.pageSize}
                    onChange={event => props.onSizeChange(event.target.value as number)}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </Box>

            <Pagination
                page={props.page}
                count={props.totalPages}
                onChange={(_e, page) => props.onPageChange(page)}
                color="primary"
            />

        </Box>
    )

    const loader = (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} className={classes.loaderContainer}>
            <CircularProgress/>
        </Box>
    )

    return (
        <Card>
            {search}
            {header}
            {props.loading ? loader : itemsList}
            {footer}
        </Card>
    )
}

export default GcpList