import VehicleService from "../../services/vehicle.service";
import {SET_LISTING} from "./actionTypes";
import {Vehicle, VehicleType} from "../../models/Vehicle.model";
import {Page} from "../../models/Page";
import GcpError from "../../models/error.enum";

/**
 * Async action - loads all active vehicles
 * TODO IMPROVEMENT - As the system grows we should use map center + zoom with geohasing to limit queries
 */
export const initLoadListing = (vehicleType?: VehicleType, page?: number, size?: number) => {
    return async (dispatch: any) => {
        dispatch(setListing(undefined, true, GcpError.NONE));
        try {
            const cars = await VehicleService.getAllActiveVehicles(vehicleType, page, size);
            dispatch(setListing(cars, false, GcpError.NONE));
        } catch (e: any) {
            console.error(e);
            dispatch(setListing(undefined, false, GcpError.FAIL));
        }
    }
};

/**
 * Stores vehicles and loading status
 * @param vehicles
 * @param loading
 * @param error
 */
export const setListing = (vehicles?: Page<Vehicle>, loading?: boolean, error?: GcpError) => {
    return {
        type: SET_LISTING,
        payload: {
            vehicles,
            loading,
            error
        }
    }
};
