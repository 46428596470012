import {BaseModel} from "./BaseModel.model";
import {Organization} from "./Organization.model";

export enum PermissionLevel {
    REGULAR = 'REGULAR',
    ADMIN = 'ADMIN'
}

export interface User extends BaseModel {
    organization?: Organization | null;
    accessLevel?: PermissionLevel;
    name?: string;
    email?: string;
    emailVerified?: boolean;
    socialLogin?: boolean;
    phone?: string;
    suspended?: boolean;
    verified?: boolean;
    country?: string;
    city?: string;
    address?: string;
    licenseUrl?: string;
    internalNote?: string;
}