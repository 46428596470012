import React from 'react';
import FlexView from "react-flexview";
import classes from './UnlockViaAppOrSms.module.scss';
import License from '../../../assets/images/license.png';
import {useTranslation} from "react-i18next";

const UnlockViaDrivingLicence = () => {

    const t = useTranslation().t
    
    return (
        <FlexView className={classes.component} hAlignContent='center' wrap>

            <FlexView column className={classes.text} hAlignContent='center' vAlignContent='center'>
                <div className={classes.title}>{t('Your phone is your key')}</div>
                <div className={classes.short}>{t('Use your phone to unlock the car (via App or SMS)')}</div>
            </FlexView>

            <FlexView className={classes.image} hAlignContent='center' vAlignContent='center'>
                <img src={License}/>
            </FlexView>

        </FlexView>
    )
}

export default UnlockViaDrivingLicence;