import React, {Component} from 'react';
import FlexView from "react-flexview";
import Logo from '../../../assets/icons/logo-full.svg';
import classes from './Header.module.scss';
import Menu from '../../../assets/icons/menu.svg';
import {Link, NavLink} from "react-router-dom";
import {CircularProgress, Drawer} from "@material-ui/core";
import {connect} from "react-redux";
import {setLanguage, setSuccessSignRedirect} from "../../../store/actions/auth.actions";
import i18 from 'i18next';
import UK from '../../../assets/icons/flag-uk.svg';
import Sweden from '../../../assets/icons/flag-sweden.svg';
import {AuthClaims} from "../../../models/AuthClaims.model";
import {isCurrentUserAnyAdmin} from "../../../common/user.util";

// import {Role} from "../../../model/role.model";

interface Props {
    claims?: AuthClaims,
    language: string,
    tokenSigninInProgress: boolean,
    setRedirectOnSign: (redirectTo: string) => void,
    setLanguage: (langauge: string) => void
}

interface State {
    drawerOpen: boolean;
}

class Header extends Component<Props, State> {

    state = {
        drawerOpen: false
    };

    // ***** Handlers ***** //

    toggleDrawer = (open: boolean) => {
        this.setState({drawerOpen: open});
    };

    handleLanguageChange = async (languageCode: string) => {
        await i18.changeLanguage(languageCode);
        this.props.setLanguage(languageCode);
    };

    // ***** Render ***** //

    getProfileText = () => {
        const fullName = this.props.claims?.user?.name;
        const nameSplit = fullName && fullName.split(' ') || [];
        const firstName = nameSplit.length > 0 && nameSplit[0] || 'User';
        return i18.t('My page', {name: firstName});
    };

    renderNavlinks() {
        const links = [];

        links.push(
            <NavLink to={'/find-car'}
                     className={classes.menuItem}
                     onClick={() => this.toggleDrawer(false)}
                     activeClassName={classes.active}>
                {i18.t('Book The Vehicle')}
            </NavLink>
        );

        links.push(
            <NavLink to={'/faq'}
                     onClick={() => this.toggleDrawer(false)}
                     className={classes.menuItem}
                     activeClassName={classes.active}>
                {i18.t('FAQ')}
            </NavLink>
        );

        links.push(
            <NavLink to={'/contact'}
                     onClick={() => this.toggleDrawer(false)}
                     className={classes.menuItem}
                     activeClassName={classes.active}>
                {i18.t('Contact')}
            </NavLink>
        );

        if (this.props.tokenSigninInProgress) {
            links.push(<CircularProgress style={{width: 20, height: 20}} color="secondary"/>);
        } else {
            if (!this.props.claims) {
                links.push(
                    <NavLink to={'/auth/signup'}
                             onClick={() => {
                                 this.props.setRedirectOnSign('/find-car');
                                 this.toggleDrawer(false)
                             }}
                             className={classes.menuItem}
                             activeClassName={classes.active}>
                        {i18.t('Sign up')}
                    </NavLink>
                );

                links.push(
                    <NavLink to={'/auth/signin'}
                             onClick={() => {
                                 this.props.setRedirectOnSign('/find-car');
                                 this.toggleDrawer(false)
                             }
                             }
                             className={classes.menuItem}
                             activeClassName={classes.active}>
                        {i18.t('Sign in')}
                    </NavLink>
                );
            } else {
                links.push(
                    <NavLink to={'/my-bookings'}
                             onClick={() => this.toggleDrawer(false)}
                             className={classes.menuItem}
                             activeClassName={classes.active}>
                        {i18.t('My Bookings')}
                    </NavLink>
                );

                if (isCurrentUserAnyAdmin()) {
                    links.push(
                        <NavLink to={'/admin/bookings'}
                                 onClick={() => this.toggleDrawer(false)}
                                 className={classes.menuItem}
                                 activeClassName={classes.active}>
                            {i18.t('Admin')}
                        </NavLink>
                    );
                }

                links.push(
                    <NavLink to={'/profile'}
                             onClick={() => this.toggleDrawer(false)}
                             className={classes.menuItem}
                             activeClassName={classes.active}>
                        {this.getProfileText()}
                    </NavLink>
                );
            }
        }

        return links;
    }

    render() {
        return (
            <React.Fragment>
                <FlexView className={classes.component} vAlignContent='center'>
                    <Link to='/'><img src={Logo} alt="Logo" className={classes.logo}/></Link>

                    <FlexView className={classes.desktopLanguageChange}>
                        <img className={classes.languageFlag} src={Sweden}
                             onClick={() => this.handleLanguageChange('sv')}/>
                        <img className={classes.languageFlag} src={UK} onClick={() => this.handleLanguageChange('en')}/>
                    </FlexView>

                    <FlexView vAlignContent='center' className={classes.menu}>
                        {this.renderNavlinks()}
                    </FlexView>
                    <img src={Menu} alt="Menu" className={classes.hamburger} onClick={() => this.toggleDrawer(true)}/>
                </FlexView>

                <Drawer anchor="right" open={this.state.drawerOpen} onClose={() => this.toggleDrawer(false)}>
                    <FlexView column hAlignContent='left' className={classes.drawerMenu}>
                        {this.renderNavlinks()}

                        <FlexView className={classes.mobileLanguageChange}>
                            <img className={classes.languageFlag} src={Sweden}
                                 onClick={() => this.handleLanguageChange('sv')}/>
                            <img className={classes.languageFlag} src={UK}
                                 onClick={() => this.handleLanguageChange('en')}/>
                        </FlexView>

                    </FlexView>
                </Drawer>
            </React.Fragment>
        )
    }
}

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        language: state.auth.language,
        tokenSigninInProgress: state.auth.tokenSigninInProgress,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setRedirectOnSign: (redirectTo: string) => dispatch(setSuccessSignRedirect(redirectTo)),
        setLanguage: (langauge: string) => dispatch(setLanguage(langauge)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
