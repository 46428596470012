import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import initLocalization from "./common/localization";
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import theme from "./styles/theme";
import {store} from "./store/rootReducer";
import {StripeProvider} from 'react-stripe-elements';
import dotenv from 'dotenv'
import {Box, CircularProgress} from "@material-ui/core";

dotenv.config();

initLocalization().then(_ => {

    class Index extends React.Component {

        // store = createStore(rootReducer, applyMiddleware(thunk));

        state = {
            stripe: null
        };

        componentDidMount() {
            if (window['Stripe']) {
                this.setState({stripe: window['Stripe'](process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')});
            } else {
                document.querySelector('#stripe-js')?.addEventListener('load', () => {
                    // Create Stripe instance once Stripe.js loads
                    this.setState({stripe: window['Stripe'](process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')});
                });
            }
        }

        render() {
            return (
                <Provider store={store}>
                    <StripeProvider stripe={this.state.stripe}>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>
                                <Suspense fallback={<Box
                                    width={'100vw'}
                                    height={'100vh'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    <CircularProgress/>
                                </Box>}>
                                    <App/>
                                </Suspense>
                            </ThemeProvider>
                        </BrowserRouter>
                    </StripeProvider>
                </Provider>
            )
        }
    }

    ReactDOM.render(<Index/>, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();

});
