import React from 'react';
import classes from "../Listing/Listing.module.scss";
import {Button} from "@material-ui/core";
import FlexView from "react-flexview";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {initTokenSignin, setAuthorizationDone, setLoggedInAsAnotherUser} from "../../../store/actions/auth.actions";
import {myFirebase} from "../../../services/firebase";
import AuthService from "../../../services/auth.service";
import GcpError from "../../../models/error.enum";
import {AuthClaims} from "../../../models/AuthClaims.model";

interface Props {
    claims?: AuthClaims;
    loggedInAsAnotherUser: boolean;
    setLoggedInAsAnotherUser: typeof setLoggedInAsAnotherUser;
    setAuthorizationDone: typeof setAuthorizationDone;
    initTokenSignin: typeof initTokenSignin;
}

const LoggedInAs = (props: Props) => {
    if (!props.loggedInAsAnotherUser) return null;

    return (
        <FlexView
            style={{
                alignSelf: 'center',
                justifyContent: 'space-around',
                maxWidth: '600px',
                width: '100%',
                border: '1px solid red',
                borderRadius: 4
            }}
            vAlignContent='center'
        >
            <div>Viewing UI as {props.claims?.user?.name}</div>
            <Button className={classes.removePhoto} color="primary"
                    onClick={async () => {
                        const token = await myFirebase.auth().currentUser?.getIdToken();
                        const claims = await AuthService.tokenLogin(token);
                        props.setAuthorizationDone(claims, GcpError.NONE);
                        props.setLoggedInAsAnotherUser(false);
                    }}>Cancel</Button>
        </FlexView>
    )
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        loggedInAsAnotherUser: state.auth.loggedInAsAnotherUser,
        claims: state.auth.claims,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setLoggedInAsAnotherUser,
        setAuthorizationDone,
        initTokenSignin
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInAs);
