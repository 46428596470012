import React, {useEffect, useRef, useState} from 'react';
import {Box, Card, debounce, TextField} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    placeholder?: string;
    onSearch?: (query: string) => void;
    classes?: {
        root?: string
    },
    disabled?: boolean
}

const useStyles = makeStyles({
    component: {
        width: '100%',
        height: 'fit-content',
        position: 'relative'
    },
    input: {
        flex: 1,
    },
    searchIcon: {
        position: 'absolute',
        right: '20px'
    }
});

const Search = (props: Props) => {

    const [searchQuery, setSearchQuery] = useState('');
    const classes = useStyles()

    const notifySearch = useRef(debounce((query: string) => props.onSearch?.(query), 1000)).current;

    useEffect(() => {
        notifySearch(searchQuery);
    }, [searchQuery]);

    return (
        <Card className={classes.component}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <TextField
                    variant={'outlined'}
                    classes={{
                        root: classes.input
                    }}
                    placeholder={props.placeholder || 'Search'}
                    size={'small'}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value as string)}
                    disabled={props.disabled}
                />

                <SearchIcon className={classes.searchIcon}/>
            </Box>
        </Card>
    )
};

export default Search;