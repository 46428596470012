import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {connect} from "react-redux";
import classes from './OverrideBookingTimeDialog.module.scss';
import {AuthClaims} from "../../../../models/AuthClaims.model";
import BookingTimepickers from "../../../booking/Booking/BookingTimepickers.component";
import {Booking} from "../../../../models/Booking.model";
import FlexView from "react-flexview";
import VehicleService from "../../../../services/vehicle.service";
import {Maintenance} from "../../../../models/Maintenance.model";
import AdminBookingService from "../../../../services/adminBooking.service";
import {multiplePeriodsOverlap, formatDateFull} from "../../../../common/booking.util";
import {useTranslation} from "react-i18next";

interface Props {
    claims?: AuthClaims,
    open: boolean,
    onClose: () => void,
    booking?: Booking,
    onUpdated: (booking: Booking) => void;
}

const OverrideBookingTimeDialog = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [start, setStart] = useState<Date | undefined>();
    const [end, setEnd] = useState<Date | undefined>();
    const [unavailablePeriods, setUnavailablePeriods] = useState<Maintenance[]>([]);
    const [updating, setUpdating] = useState(false);

    const getDateFrom = () => {
        return start ? formatDateFull(start) : t('Start time')
    };

    const getDateTo = () => {
        return end ? formatDateFull(end) : t('End time')
    };

    const updatedBooking = start && end && {
        ...props.booking,
        from: start,
        to: end
    } || undefined;

    // ***** Lifecycle ***** //

    const fetchUnavailablePeriods = async () => {
        const unavailablePeriods = await VehicleService.getUnavailablePeriods(props.booking?.vehicle?.id!, props.booking?.id);
        setUnavailablePeriods(unavailablePeriods || []);
    }

    useEffect(() => {
        if (props.open && props.booking) {
            setStart(props.booking.from);
            setEnd(props.booking.to);
            fetchUnavailablePeriods();
        }
    }, [props.open]);

    // ***** Handlers ***** //

    const updateBooking = async () => {
        try {
            setUpdating(true);
            const response = await AdminBookingService.updateBooking(updatedBooking);
            if (response) {
                props.onUpdated(response);
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setUpdating(false);
        }
    }

    // ***** UI ***** //

    return (
        <Dialog aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={props.open}>

            <DialogTitle id="customized-dialog-title">
                {t('Override booking times')}
            </DialogTitle>

            <DialogContent dividers>

                <FlexView column hAlignContent={'center'}>

                    <BookingTimepickers
                        booking={props.booking}
                        editingBooking={updatedBooking}
                        start={start}
                        end={end}
                        dateFrom={getDateFrom()}
                        dateTo={getDateTo()}
                        editing={true}
                        isNewBooking={false}
                        setBookingTime={(from, to) => {
                            setStart(from);
                            setEnd(to);
                        }}
                        otherBookings={[]}
                        maintenance={unavailablePeriods}
                        showTimeline={true}
                    />

                    <div className={classes.overlapWarning}>
                        {
                            multiplePeriodsOverlap(unavailablePeriods, updatedBooking) && ' New times overlap with some existing bookings or maintenances!!!'
                        }
                    </div>

                </FlexView>

            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t('Cancel')}
                </Button>

                {
                    updating ? <CircularProgress/> :
                        <Button onClick={updateBooking} color="primary">
                            {t('Change time')}
                        </Button>
                }

            </DialogActions>

        </Dialog>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(OverrideBookingTimeDialog);
