import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean,
    onClose: (e?: any) => void,
    onConfirm?: (e?: any) => void,
    title: string,
    message: string,
    confirmText?: string,
    cancelText?: string
}

const AlertDialog = (props: Props) => {
    const t = useTranslation().t

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{__html: props.message}} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        {props.cancelText || t('No')}
                    </Button>

                    {
                        props.onConfirm &&
                        <Button onClick={props.onConfirm} color="primary" autoFocus>
                            {props.confirmText || t('Yes')}
                        </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AlertDialog;
