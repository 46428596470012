import React, {useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './CarHeader.module.scss';
import AddTime from '../../../assets/icons/add-time.svg';
import ReportDamage from '../../../assets/icons/report-damage.svg';
import {Button, CircularProgress, Snackbar, SnackbarContent} from '@material-ui/core';
import {isBefore} from "date-fns";
import AddTimeDialog from "../AddTimeDialog/AddTimeDialog.component";
import HeaderMobileActionsDialog from "../HeaderMobileActionsDialog/HeaderMobileActionsDialog.component";
import {Vehicle} from "../../../models/Vehicle.model";
import {Booking, BookingStatus} from "../../../models/Booking.model";
import BookingService from "../../../services/booking.service";
import DamageReportDialog from "../DamageReportDialog/DamageReportDialog.component";
import {User} from "../../../models/User.model";
import {useTranslation} from "react-i18next";

interface Props {
    car?: Vehicle,
    booking?: Booking,
    loading: boolean;
    onTimeAdded: (booking?: Booking) => void;
    user?: User
    // onLoadBooking: ()
}

const CarHeader = (props: Props) => {

    const t = useTranslation().t
    const [damageReportOpen, setDamageReportOpen] = useState(false);
    const [addMoreTimeOpen, setAddMoreTimeOpen] = useState(false);
    const [requestingMoreTime, setRequestingMoreTime] = useState(false);
    const [requestMoreStatus, setRequestMoreStatus] = useState('');
    const [mobileActionsVisible, setMobileActionsVisible] = useState(false);

    if (props.loading) {
        return (
            <FlexView className={classes.header} vAlignContent='center' hAlignContent='center'>
                <CircularProgress/>
            </FlexView>
        )
    }

    if (!props.car) return null;

    const canAddMoreTime = props.booking && props.booking.status === BookingStatus.ACTIVE && props.booking.to && isBefore(new Date(), props.booking.to) || false;

    const addMoreTimeHandler = async (time: number) => {
        setAddMoreTimeOpen(false);
        setRequestingMoreTime(true);
        const response = await BookingService.requestMoreTime(props.booking, time);
        switch (response.status) {
            case 'added':
                setRequestMoreStatus(t('Time added'));
                props.onTimeAdded(response.booking);
                break;
            case 'overlap':
                setRequestMoreStatus(t('We could not extend your booking, because it would overlap with other upcoming ones'));
                break;
            default:
                setRequestMoreStatus(t('We could not extend your booking'));
                break;
        }
        setRequestingMoreTime(false);
    };

    return (
        <FlexView className={classes.header} vAlignContent='center'>

            <Button variant="contained" color="primary"
                    className={classes.moreActionsButton}
                    onClick={() => setMobileActionsVisible(true)}
            >
                {t('More')}
            </Button>

            <img className={classes.carImage} src={props.car.image}/>

            <FlexView column style={{marginRight: 'auto'}} className={classes.carModelType}>
                {
                    props.car.address ?
                        <React.Fragment>
                            <div className={classes.mainTitle}>{props.car.address}</div>
                            <div className={classes.subtitle}>{props.car.make} {props.car.model}</div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className={classes.mainTitle}>{props.car.make} {props.car.model}</div>
                            <div className={classes.subtitle}>{t(props.car?.type || '')}</div>
                        </React.Fragment>
                }
            </FlexView>

            {
                canAddMoreTime &&
                <FlexView
                    hAlignContent='center'
                    column
                    className={classes.headerAction}
                    onClick={() => setAddMoreTimeOpen(true)}
                >
                    {
                        requestingMoreTime && <CircularProgress size={30} className={classes.headerActionIcon}/> ||
                        <img className={classes.headerActionIcon} src={AddTime}/>
                    }

                    <div>{t('Add time')}</div>
                </FlexView>
            }

            {
                <FlexView
                    hAlignContent='center'
                    column
                    className={classes.headerAction}
                    onClick={() => setDamageReportOpen(true)}
                >
                    <img className={classes.headerActionIcon} src={ReportDamage}/>
                    <div>{t('Report damage')}</div>
                </FlexView>
            }

            <HeaderMobileActionsDialog
                open={mobileActionsVisible}
                onClose={() => setMobileActionsVisible(false)}
                canAddMoreTime={canAddMoreTime}
                onConfirm={action => {
                    setMobileActionsVisible(false);
                    switch (action) {
                        case 'add_time':
                            setAddMoreTimeOpen(true);
                            break;
                        case 'report_damage':
                            setDamageReportOpen(true);
                            break
                    }
                }}
            />


            <DamageReportDialog
                open={damageReportOpen}
                onClose={() => setDamageReportOpen(false)}
                user={props.user}
                car={props.car}
                booking={props.booking}
            />

            <AddTimeDialog
                open={addMoreTimeOpen}
                onClose={() => setAddMoreTimeOpen(false)}
                onConfirm={addMoreTimeHandler}
            />

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={!!requestMoreStatus}
                autoHideDuration={5000}
                onClose={() => setRequestMoreStatus('')}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={requestMoreStatus}
                />
            </Snackbar>

        </FlexView>
    )
};

export default CarHeader;
