import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './DamageReportDialog.module.scss';
import FlexView from "react-flexview";
import Dropzone from "../../../common/ui/Dropzone/Dropzone.component";
import {v4 as uuidv4} from 'uuid';
import {RouteComponentProps, withRouter} from "react-router";
import {User} from "../../../models/User.model";
import {Vehicle} from "../../../models/Vehicle.model";
import {Booking} from "../../../models/Booking.model";
import {DamageReport, DamageReportType} from "../../../models/DamageReport.model";
import {myFirebase} from "../../../services/firebase";
import DamageReportService from "../../../services/damageReport.service";
import {vehicleDescriptor} from "../../../common/vehicle.util";
import {connect} from "react-redux";
import {AuthClaims} from "../../../models/AuthClaims.model";
import DamageReportTypePicker from "./DamageReportTypePicker.component";
import {isCurrentUserSuperAdmin} from "../../../common/user.util";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    claims?: AuthClaims;
    open: boolean;
    onClose: () => void;
    booking?: Booking;
    user?: User;
    car?: Vehicle;
    previewMode?: boolean;
    report?: DamageReport;
}

const DamageReportDialog = (props: Props) => {

    const t = useTranslation().t
    const [images, setImages] = useState<File[]>([]);
    const [message, setMessage] = useState('');
    const [damageReportType, setDamageReportType] = useState<DamageReportType>(DamageReportType.OTHER);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        setDamageReportType(DamageReportType.OTHER)
    }, [props.open])

    const addFile = (files: File[]) => {
        setImages([...images, ...files]);
    };

    const removeImage = (file: File) => {
        setImages(images.filter(image => image !== file));
    };

    const saveReport = async () => {
        setSending(true);
        const uploaded = [];

        for (let i = 0; i < images.length; i++) {
            let image = images[i];
            try {
                const uuid = uuidv4();
                const uploadRef = myFirebase.storage().ref(`${uuid}-${image.name}`);
                await uploadRef.put(image);
                const downloadUrl = await uploadRef.getDownloadURL();
                uploaded.push(downloadUrl);
            } catch (e: any) {
                console.log(e);
            }
        }

        try {
            await DamageReportService.create({
                vehicle: props.car,
                user: props.user,
                images: uploaded,
                message: message,
                damageType: damageReportType,
                booking: props.booking
            })
            props.onClose();
        } catch (e: any) {
            console.log(e);
        } finally {
            setSending(false);
        }

    };

    const previewImage = (image: string) => {
        if (!props.previewMode) return;
        window.open(image, "_blank");
    };

    const renderImages: any[] = props.previewMode ? props.report && props.report.images || [] : images;

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={props.open} fullWidth>
            <DialogTitle id="customized-dialog-title">
                {t('Report damage')}
            </DialogTitle>

            <DialogContent dividers>

                {props.previewMode && <div>{t('Vehicle')}</div>}

                {
                    props.previewMode &&
                    <FlexView className={classes.infoRow}>
                        <div>
                            {vehicleDescriptor(props.report?.vehicle)}
                        </div>
                        {
                            props.report?.vehicle?.id &&
                            <div
                                className={classes.infoCTA}
                                onClick={() => props.history.push(`/admin/cars/${props.report?.vehicle?.id}/basic`)}
                            >
                                {t('View')}
                            </div>
                        }
                    </FlexView>
                }

                {props.previewMode && <div>{t('Booking')}</div>}

                {
                    props.previewMode &&
                    <FlexView className={classes.infoRow}>
                        <div>
                            {props.report?.booking?.id || '-'}
                        </div>
                        {
                            props.report?.booking &&
                            <div
                                className={classes.infoCTA}
                                onClick={() => props.history.push(`/admin/booking/${props.report?.booking?.id}`)}
                            >
                                {t('View')}
                            </div>
                        }
                    </FlexView>
                }

                {props.previewMode && <div>{t('User')}</div>}

                {
                    props.previewMode && isCurrentUserSuperAdmin() &&
                    <FlexView className={classes.infoRow}>
                        <div>
                            {props.report?.user?.name || '-'}
                        </div>
                        {
                            props.report?.user?.id &&
                            <div
                                className={classes.infoCTA}
                                onClick={() => props.history.push(`/admin/user/${props.report?.user?.id}`)}
                            >
                                {t('View')}
                            </div>
                        }
                    </FlexView>
                }

                <div>{t('Damage type')}</div>

                {
                    props.previewMode && isCurrentUserSuperAdmin() &&
                    <FlexView className={classes.infoRow}>
                        <div>{props.report?.damageType || '-'}</div>
                    </FlexView>
                }

                {
                    !props.previewMode &&
                    <DamageReportTypePicker
                        damageReportType={damageReportType}
                        setDamageReportType={setDamageReportType}
                    />
                }

                <div>{t('Message')}</div>

                <textarea
                    className={classes.message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={props.previewMode}
                >{props.report && props.report.message}</textarea>

                <div className={classes.photosTitle}>{t('Photos')}</div>

                <FlexView className={classes.photosContainer}>

                    {
                        renderImages.map(image => (
                            <FlexView className={classes.photoHolder} key={image.name}>

                                <img
                                    onClick={() => previewImage(image)}
                                    className={classes.photo}
                                    src={props.previewMode ? image : URL.createObjectURL(image)}
                                />

                                {
                                    !props.previewMode &&
                                    <FlexView
                                        className={classes.removePhoto}
                                        hAlignContent='center'
                                        vAlignContent='center'
                                        onClick={() => removeImage(image)}
                                    >
                                        <div>×</div>
                                    </FlexView>
                                }

                            </FlexView>
                        ))
                    }

                    {!props.previewMode &&
                    <Dropzone
                        className={classes.photoHolder}
                        disabled={false}
                        onFilesAdded={addFile}
                    />}

                </FlexView>

            </DialogContent>

            <DialogActions>

                {
                    sending ? <CircularProgress/> :
                        <React.Fragment>
                            <Button onClick={props.onClose} color="primary">
                                {t('Cancel')}
                            </Button>

                            {!props.previewMode &&
                            <Button onClick={saveReport} color="primary">
                                {t('Report')}
                            </Button>
                            }
                        </React.Fragment>
                }

            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(withRouter(DamageReportDialog));
