import {isAfter} from "date-fns";
import {PermissionLevel, User} from "../models/User.model";
import {Subscription} from "../models/Subscription.model";
import {store} from "../store/rootReducer";

export const isUserInfoFull = (user?: User): boolean => {
    if (!user) return false;

    return !!(user.licenseUrl && user.address && user.city && user.country);
};

export const hasPremiumSubscription = (subscription?: Subscription): boolean => {
    return !!(subscription && subscription.expiration && isAfter(subscription.expiration, new Date()));
};

export const isCurrentUserSuperAdmin = (): boolean => {
    const claims = store?.getState()?.auth?.claims
    return !!claims?.isSuperAdmin
}

export const isCurrentPartnerAdmin = (): boolean => {
    const claims = store?.getState()?.auth?.claims
    return !!claims?.organization && claims?.user?.accessLevel === PermissionLevel.ADMIN
}

export const isCurrentUserAnyAdmin = (): boolean => {
    return isCurrentPartnerAdmin() || isCurrentUserSuperAdmin()
}