import React, {useState} from 'react';
import FlexView from "react-flexview";
import classes from './ContactForm.module.scss';
import {
    emailValidator,
    isFormValid,
    minLengthValidator,
    requiredValidator,
    validateForm
} from "../../../common/validators.util";
import GcpInput from "../../../common/ui/GcpInput";
import {Button, CircularProgress} from "@material-ui/core";
import PublicService from "../../../services/public.service";
import {useTranslation} from "react-i18next";

type FormField = 'name' | 'email' | 'phone' | 'message';

const ContactForm = () => {

    // ***** Status ***** //

    const t = useTranslation().t
    const [form, setForm] = useState({
        name: {
            label: t('Name'),
            placeholder: 'ex. John Smith',
            type: 'text',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: any) => requiredValidator(value, t('This is a required field')),
                    (value: any) => minLengthValidator(value, 2, t(`Name should be at least X characters long`, {length: 2}))
                ]
            },
            multiline: false,
            rows: 1
        },
        email: {
            label: t('Email'),
            placeholder: 'ex. john.smith@example.com',
            type: 'email',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: any) => requiredValidator(value, t('This is a required field')),
                    (value: any) => emailValidator(value, t('Invalid email address')),
                ]
            },
            multiline: false,
            rows: 1
        },
        phone: {
            label: t('Phone'),
            placeholder: 'ex. 050 1231 214',
            type: 'phone',
            value: '',
            validation: {
                error: '',
                validators: [
                    (value: any) => requiredValidator(value, t('This is a required field')),
                ]
            },
            multiline: false,
            rows: 1
        },
        message: {
            label: t('Message'),
            placeholder: t('How may we help?'),
            type: 'text',
            value: '',
            multiline: true,
            rows: 2,
            rowsMax: 4,
            validation: {
                error: '',
                validators: [
                    (value: any) => requiredValidator(value, t('This is a required field')),
                ]
            },
        },
    });

    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [sendingStatus, setSendingStatus] = useState('');

    // ***** Handler ***** //

    const inputHandler = (fieldId: FormField, value: any) => {
        const newFormField = {
            ...form[fieldId],
            value: value,
            validation: {
                ...form[fieldId].validation,
                error: ''
            }
        };

        setForm({
            ...form,
            [fieldId]: newFormField
        });
    };

    const sendMessage = async () => {
        const validatedForm = validateForm(form);
        setForm(validatedForm);

        if (isFormValid(validatedForm)) {
            try {
                setSendingStatus('');
                setSendingInProgress(true);

                const response = await PublicService.sendContactMessage(
                    form.name.value,
                    form.phone.value,
                    form.email.value,
                    form.message.value,
                );

                setSendingStatus(response.status);
            } catch (e: any) {
                setSendingStatus('fail');
            } finally {
                setSendingInProgress(false);
            }
        }
    };

    const renderFormElem = (key: FormField, className: string) => {
        const formElement = form[key];
        return (
            <GcpInput
                error={!!formElement.validation.error}
                key={key}
                className={className}
                label={formElement.label}
                placeholder={formElement.placeholder}
                margin="normal"
                type={formElement.type}
                multiline={formElement.multiline}
                rows={formElement.rows}
                // variant="outlined"
                disabled={sendingInProgress}
                value={formElement.value}
                helperText={formElement.validation.error}
                onChange={(event) => inputHandler(key, event.target.value)}
            />
        )
    };

    // ***** Render ***** //

    return (
        <FlexView className={classes.content} hAlignContent='center'>
            {/*<FlexView className={classes.contactFormHolder} hAlignContent='center' vAlignContent='center'>*/}
            <FlexView className={classes.signupForm} column hAlignContent='center'>
                <FlexView className={classes.signupFormTop} wrap>
                    {renderFormElem('name', classes.signupSmall)}
                    {renderFormElem('email', classes.signupSmall)}
                    {renderFormElem('phone', classes.signupSmall)}
                </FlexView>
                <FlexView className={classes.signupFormTop}>
                    {renderFormElem('message', classes.signupElement)}
                </FlexView>
                <FlexView className={classes.submitButtonHolder}>
                    {
                        sendingInProgress ?
                            <CircularProgress/>
                            :
                            <Button variant="contained" color="primary" className={classes.submitButton}
                                    onClick={sendMessage}
                            >
                                {t('Send')}
                            </Button>
                    }

                </FlexView>

                {
                    sendingStatus
                        ?
                        <div className={classes.statusText}>
                            {sendingStatus === 'ok' ? t('Your message was sent') : t('Sending failed')}
                        </div>
                        : null
                }

            </FlexView>
        </FlexView>
    )
};

export default ContactForm;
