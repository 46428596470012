import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import classes from './AdminUser.module.scss';
import Dropzone from "../../../../common/ui/Dropzone/Dropzone.component";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl, FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import {format} from "date-fns";
import InvoicePreview from "../../../Profile/InvoicePreview/InvoicePreview.component";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import {PermissionLevel, User} from "../../../../models/User.model";
import AdminUserService from "../../../../services/adminUser.service";
import {myFirebase} from "../../../../services/firebase";
import {AuthClaims} from "../../../../models/AuthClaims.model";
import AlertDialog from "../../../../common/ui/AlertDialog.component";
import {Organization} from "../../../../models/Organization.model";
import AdminOrganizationService from "../../../../services/adminOrganization.service";
import StripeInvoice from "../../../../models/StripeInvoice.model";
import HistoryOverview from "../../../../models/HistoryOverview.model";
import {Subscription} from "../../../../models/Subscription.model";
import {setAuthorizationDone, setLoggedInAsAnotherUser} from "../../../../store/actions/auth.actions";
import AuthServiceNew from "../../../../services/auth.service";
import GcpError from "../../../../models/error.enum";
import Bookings from "./Bookings.component";
import {useHistory, useParams} from "react-router-dom";
import GcpButton from "../../../../common/ui/GcpButton";
import Overview from "../../../history/MyBookings/Overview.component";
import SendMessageDialog from "../SendMessageDialog/SendMessageDialog.component";
import {isEqual} from 'lodash'
import {isCurrentUserSuperAdmin} from "../../../../common/user.util";
import {useTranslation} from "react-i18next";

interface Props {
    setLoggedInAsAnotherUser: typeof setLoggedInAsAnotherUser;
    setAuthorizationDone: typeof setAuthorizationDone;
    claims?: AuthClaims
}

const AdminUser = (props: Props) => {

    const t = useTranslation().t
    // const [id, setId] = useState<string>();
    // @ts-ignore
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false)
    const [originalUser, setOriginalUser] = useState<User>();
    const [user, setUser] = useState<User>();
    const [userOverview, setUserOverview] = useState<HistoryOverview>();
    const [userSubscription, setUserSubscription] = useState<Subscription>();
    const [licenceImage, setLicenceImage] = useState(null);
    const [addingNewLicence, setAddingNewLicence] = useState(false);
    const [licenceUploadInProgress, setLicenceUploadInProgress] = useState(false);
    const [exportInProgress, setExportInProgress] = useState(false);
    const [suspendInProgress, setSuspendInProgress] = useState(false);
    const [verificationInProgress, setVerificationInProgress] = useState(false);
    const [invoices, setInvoices] = useState<StripeInvoice[]>([]);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deletingInProgress, setDeletingInProgress] = useState(false);
    const [organizations, setOrganizations] = useState<(undefined | Organization)[]>([]);
    const [sendMessageOpen, setSendMessageOpen] = useState(false)
    const [tab, setTab] = useState(0);
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const isDirty = !isEqual(user, originalUser)

    const saveChanges = async () => {
        if (!user) return
        try {
            setSaving(true)
            const updatedUser = await AdminUserService.update(user);
            setUser(updatedUser);
            setOriginalUser(updatedUser)
        } catch (e: any) {
            console.log(e);
        } finally {
            setSaving(false)
        }
    }

    const handleChange = async (files: any) => {
        const localImage = files[0];
        setLicenceImage(localImage);
        setLicenceUploadInProgress(true);
        setAddingNewLicence(false);

        const uploadRef = myFirebase.storage().ref(localImage.name);
        await uploadRef.put(localImage);
        const downloadUrl = await uploadRef.getDownloadURL();

        setUser({
            ...user,
            licenseUrl: downloadUrl
        })

        setLicenceImage(null);
        setLicenceUploadInProgress(false);
    };

    const removePhoto = () => {
        setLicenceImage(null);
        setAddingNewLicence(true);
    };

    const logInAsUser = async () => {
        if (!user) return;

        const loggedInUser = await AuthServiceNew.loginAsAnotherUser(user)
        if (loggedInUser) {
            props.setAuthorizationDone(loggedInUser, GcpError.NONE);
        }
        props.setLoggedInAsAnotherUser(true);
        history.push('/find-car');
    };

    const deleteUser = () => {
        setDeleteDialogVisible(true);
    }

    const executeDeleteUser = async () => {
        try {
            setDeletingInProgress(true);
            const deleted = await AdminUserService.delete(user);
            if (deleted) {
                history.goBack();
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setDeletingInProgress(false);
        }
    }

    useEffect(() => {
        const id = params.id;
        if (id) {
            loadUser(id);
        }
    }, [claims]);


    const loadUser = async (id: string) => {
        setLoading(true);

        if (!claims) return;

        let user;
        try {
            let organizations: (Organization | undefined)[] = [undefined];

            if (isCurrentUserSuperAdmin()) {
                const response = await AdminOrganizationService.getAll();
                organizations = [undefined, ...response.content];
            }
            setOrganizations(organizations);

            user = await AdminUserService.get(+id);
            const subscription = await AdminUserService.getSubscription(+id);
            setUserSubscription(subscription);

            setUser(user);
            setOriginalUser(user)

            const overview = await AdminUserService.getOverview(+id);
            setUserOverview(overview);
        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setLoading(false);
        }

        try {
            const invoices = await AdminUserService.getAllInvoicesForUser(user);
            setInvoices(invoices);
        } catch (e: any) {
            console.log(e);
        }
    };

    const exportToXls = async () => {
        setExportInProgress(true);

        try {
            await AdminUserService.exportDataToXls(user);
        } catch (e: any) {
            console.log(e);
        } finally {
            setExportInProgress(false);
        }
    };

    const changeVerification = async (verified: boolean) => {
        setVerificationInProgress(true);

        try {
            const updatedUser = await AdminUserService.update({
                ...user,
                verified: verified
            });
            setUser(updatedUser);
        } catch (e: any) {
            console.log(e);
        } finally {
            setVerificationInProgress(false);
        }
    };

    const changeSuspension = async (suspended: boolean) => {
        setSuspendInProgress(true);

        try {
            const updatedUser = await AdminUserService.update({
                ...user,
                suspended: suspended
            });
            setUser(updatedUser);
        } catch (e: any) {
            console.log(e);
        } finally {
            setSuspendInProgress(false);
        }
    };

    const renderDropzone = () => {
        return (
            <Dropzone className={classes.dropzone} onFilesAdded={handleChange}/>
        )
    };

    const renderLicence = () => {
        let license = user?.licenseUrl;
        if (licenceImage) {
            license = URL.createObjectURL(licenceImage);
        }
        return (
            <FlexView column className={classes.licencePreview} vAlignContent='center' hAlignContent='center'>
                <img className={classes.licencePhoto}
                     src={license}/>
                {
                    licenceUploadInProgress
                        ?
                        <CircularProgress/>
                        :
                        <Button className={classes.removePhoto} color="primary"
                                onClick={removePhoto}>Change</Button>
                }
            </FlexView>
        )
    };


    if (!user) {
        return null;
    }

    // TODO
    // const getRentHoursFormatted = (overview?: HistoryOverview) => {
    //     if (!overview) return 0;
    //     const minutesTotal = overview.totalMinutes || 0;
    //     const hours = Math.floor(minutesTotal / 60);
    //     const minutes = Math.floor(minutesTotal - hours * 60);
    //     return `${hours}:h ${minutes}:m`
    // };

    const renderBasicInfo = () => (
        <React.Fragment>

            <Card className={classes.container}>

                {isDirty && <Typography>Save changes before doing any actions!</Typography>}

                <Box display={'flex'} flexWrap={'wrap'}>

                    {
                        verificationInProgress ? <CircularProgress/> :
                            <Button
                                disabled={isDirty}
                                variant="contained"
                                color="primary"
                                style={{margin: '0 10px 10px 0'}}
                                onClick={() => changeVerification(!user.verified)}
                            >
                                {user.verified ? t('Unverify User') : t('Verify User')}
                            </Button>
                    }


                    {
                        exportInProgress ? <CircularProgress/> :
                            <Button
                                disabled={isDirty}
                                variant="contained"
                                color="primary"
                                style={{margin: '0 10px 10px 0'}}
                                onClick={exportToXls}
                            >
                                {t('Export data')}
                            </Button>
                    }

                    <Button
                        disabled={isDirty}
                        variant="contained"
                        color="primary"
                        style={{margin: '0 10px 10px 0'}}
                        onClick={logInAsUser}
                    >
                        {t('Log in as user')}
                    </Button>

                    <Button
                        disabled={isDirty}
                        variant="contained"
                        color="primary"
                        style={{margin: '0 10px 10px 0'}}
                        onClick={() => setSendMessageOpen(true)}
                    >
                        {t('Send message')}
                    </Button>

                    {
                        suspendInProgress ? <CircularProgress/> :
                            <GcpButton
                                disabled={isDirty}
                                variant="contained"
                                color="warning"
                                style={{margin: '0 10px 10px 0'}}
                                onClick={() => changeSuspension(!user.suspended)}
                            >
                                {user.suspended ? t('Unsuspend user') : t('Suspend user')}
                            </GcpButton>
                    }

                    {
                        deletingInProgress ? <CircularProgress/> :
                            <GcpButton
                                disabled={isDirty}
                                variant="contained"
                                color="error"
                                style={{margin: '0 10px 10px 0'}}
                                onClick={deleteUser}
                            >
                                {t('Delete user')}
                            </GcpButton>
                    }

                </Box>
            </Card>

            <Card className={classes.container} style={{marginTop: 20}}>
                <Box display={'flex'} flexWrap={'wrap'}>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>Id</div>
                        <div className={classes.value}>{user.id}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Name')}</div>
                        <div className={classes.value}>{user.name}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Email')}</div>
                        <div className={classes.value}>{user.email}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Phone')}</div>
                        <div className={classes.value}>{user.phone}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Address')}</div>
                        <div className={classes.value}>{user.address}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('City')}</div>
                        <div className={classes.value}>{user.city}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Country')}</div>
                        <div className={classes.value}>{user.country}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Member from')}</div>
                        <div className={classes.value}>
                            {user.createdAt && format(user.createdAt, 'dd-MM-yyy') || '-'}
                        </div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Status')}</div>
                        <div className={classes.value}>{user.suspended ? t('Suspended') : t('Active')}</div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Verification')}</div>
                        <div className={classes.value}>
                            {user.verified ? t('Verified') : t('Not verified')}
                        </div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Subscription')}</div>
                        <div className={classes.value}>
                            {userSubscription && userSubscription.type || 'freemium'}
                        </div>
                    </FlexView>

                    <FlexView column className={classes.infoSection}>
                        <div className={classes.label}>{t('Premium expiration')}</div>
                        <div className={classes.value}>
                            {userSubscription && userSubscription.expiration && format(userSubscription.expiration, 'dd-MM-yyyy HH:mm') || '-'}
                        </div>
                    </FlexView>

                </Box>
            </Card>

            <Overview
                overview={userOverview}
                signInProgress={loading}
                overviewLoading={loading}
                overviewLoadingError={''}
                showSpending
            />

            <Card className={classes.container} style={{marginTop: 20}}>
                <Box>
                    {!addingNewLicence && (licenceImage || user.licenseUrl) ? renderLicence() : renderDropzone()}
                </Box>
            </Card>

            <Box m={2}></Box>

            <Card>
                <Box p={2}>
                    <Typography>{t('Internal note')}</Typography>
                    <TextField
                        style={{width: '100%'}}
                        rows={15}
                        multiline
                        variant={'outlined'}
                        value={user.internalNote || ''}
                        onChange={e => setUser({...user, internalNote: e.target.value})}
                        disabled={saving}
                    />
                </Box>
            </Card>

        </React.Fragment>
    )

    const renderBookings = () => (
        <FlexView wrap className={classes.bookingListHolder} hAlignContent='left'>
            <Bookings user={user}/>
        </FlexView>
    )

    const renderInvoices = () => (
        <Card className={classes.invoicesList}>
            {invoices.map(invoice => {
                return (
                    <InvoicePreview invoice={invoice}/>
                )
            })}
        </Card>
    )

    const renderOrganization = () => (
        <Card className={classes.container}>
            {
                isCurrentUserSuperAdmin() &&
                <FormControl
                    className={classes.adminOfPartner}
                    margin="normal"
                >
                    <InputLabel htmlFor="key">{t('Organization')}</InputLabel>
                    <Select
                        value={user.organization?.id || 0}
                        onChange={(event) => {
                            setUser({
                                ...user,
                                organization: organizations.find(o => o?.id === event.target.value) || null
                            });
                        }}
                    >
                        {
                            organizations.map((option: any) => <MenuItem key={option?.id || 0}
                                                                         value={option?.id || 0}>{option?.name || t('None')}</MenuItem>)
                        }

                    </Select>
                </FormControl>
            }

            <FormControl
                className={classes.adminOfPartner}
                margin="normal"
            >
                <InputLabel htmlFor="key">{t('Permission level')}</InputLabel>
                <Select
                    disabled={user.email === props.claims?.user?.email}
                    value={user.accessLevel || PermissionLevel.REGULAR}
                    onChange={(event) => {
                        setUser({
                            ...user,
                            accessLevel: event.target.value as PermissionLevel
                        });
                    }}
                >
                    <MenuItem key={PermissionLevel.REGULAR} value={PermissionLevel.REGULAR}>{t('Regular level')}</MenuItem>)
                    <MenuItem key={PermissionLevel.ADMIN} value={PermissionLevel.ADMIN}>{t('Admin level')}</MenuItem>)
                </Select>
                {
                    user.email === props.claims?.user?.email &&
                    <FormLabel style={{marginTop: 20, color: 'red'}}>{t('You cannot change permission level for yourself')}</FormLabel>
                }
            </FormControl>

        </Card>
    )

    const tabs = (
        <Box
            style={{marginBottom: 20}}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Card>
                <Tabs
                    textColor={"primary"}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_, t) => setTab(t)}
                >
                    <Tab label={t('Basic info')} tabIndex={0}/>
                    <Tab label={t('Bookings')} tabIndex={1}/>
                    <Tab label={t('Invoices')} tabIndex={2}/>
                    <Tab label={t('Organization')} tabIndex={3}/>
                </Tabs>
            </Card>

            {
                isDirty && !saving &&
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={saveChanges}
                >
                    Save
                </Button>
            }

            {saving && <CircularProgress/>}

        </Box>
    )

    return (
        <Box>

            {tabs}
            {tab === 0 && renderBasicInfo()}
            {tab === 1 && renderBookings()}
            {tab === 2 && renderInvoices()}
            {tab === 3 && renderOrganization()}

            <SendMessageDialog
                open={sendMessageOpen}
                onClose={() => setSendMessageOpen(false)}
                users={[user]}
            />

            <AlertDialog
                open={deleteDialogVisible}
                onClose={(e) => {
                    e && e.stopPropagation();
                    setDeleteDialogVisible(false);
                }}
                onConfirm={(e) => {
                    e && e.stopPropagation();
                    setDeleteDialogVisible(false);
                    executeDeleteUser();
                }}
                title={t('Delete user?')}
                message={t('This will remove user and his data, and cancel any upcoming bookings')}
            />

        </Box>
    )
}


const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setLoggedInAsAnotherUser,
        setAuthorizationDone
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);