import {
    AUTHORIZATION_DONE,
    AUTHORIZATION_IN_PROGRESS,
    LOGOUT_DONE,
    SET_AUTH_ERROR,
    SET_LANGUAGE,
    SET_LOGGED_IN_AS_ANOTHER_USER,
    SET_SUCCESSFUL_SIGN_REDIRECT,
    TOKEN_SIGNIN_DONE,
    TOKEN_SIGNIN_IN_PROGRESS,
    UPDATE_INFO,
} from "./actionTypes";
import AuthService from "../../services/auth.service";
import {User} from "../../models/User.model";
import GcpError from "../../models/error.enum";
import {store} from "../rootReducer";
import {myFirebase} from "../../services/firebase";
import {AuthClaims} from "../../models/AuthClaims.model";

const setAuthorizationInProgress = (inProgress: boolean) => {
    return {
        type: AUTHORIZATION_IN_PROGRESS,
        payload: inProgress
    }
};

export const setAuthorizationDone = (claims?: AuthClaims, error?: GcpError) => {
    return {
        type: AUTHORIZATION_DONE,
        payload: {claims, error},
    }
};

const setTokenSignInDone = () => {
    return {
        type: TOKEN_SIGNIN_DONE,
    }
};

const setTokenSignInInProgress = () => {
    return {
        type: TOKEN_SIGNIN_IN_PROGRESS,
    }
};

export const setAuthError = (error: string) => {
    return {
        type: SET_AUTH_ERROR,
        payload: error
    }
};

export const setLogoutDone = () => {
    return {
        type: LOGOUT_DONE,
    }
};


export const setLoggedInAsAnotherUser = (loggedInAsAnotherUser?: boolean) => {
    return {
        type: SET_LOGGED_IN_AS_ANOTHER_USER,
        payload: loggedInAsAnotherUser
    }
};

// ***** SignUp ***** //

export const initSignup = (name: string, email: string, password: string) => {
    return async (dispatch: any) => {
        dispatch(setAuthorizationInProgress(true));
        try {
            const claims = await AuthService.signUp(email, password, name);
            // AuthService.startObservingUser(user.id);
            dispatch(setAuthorizationDone(claims, GcpError.NONE));
        } catch (e: any) {
            dispatch(setAuthorizationDone(undefined, e.code));
        }
    }
};

// ***** SignIn ***** //

export const initSignin = (email: string, password: string) => {
    return async (dispatch: any) => {
        dispatch(setAuthorizationInProgress(true));
        try {
            const claims = await AuthService.signIn(email, password);
            // AuthService.startObservingUser(user.id);
            dispatch(setAuthorizationDone(claims, GcpError.NONE));
        } catch (e: any) {
            console.log(e);
            dispatch(setAuthorizationDone(undefined, e.code));
        }
    }
};

// ***** Social ***** //

export const initSocialLogin = (providerId: string) => {
    return async (dispatch: any) => {
        dispatch(setAuthorizationInProgress(true));
        try {
            const claims = await AuthService.signInSocial(providerId);
            // AuthService.startObservingUser(user.id);
            dispatch(setAuthorizationDone(claims, GcpError.NONE));
        } catch (e: any) {
            console.log(e);
            dispatch(setAuthorizationDone(undefined, e.code));
        }
    }
};

// ***** Existing user ***** //

export const initTokenSignin = () => {
    return async (dispatch: any) => {
        dispatch(setTokenSignInInProgress());
        try {
            myFirebase.auth().onAuthStateChanged(async response => {
                if (response !== null && !store.getState().auth.signInProgress) {
                    // TODO - double login fix
                    dispatch(setAuthorizationInProgress(true));
                    const token = await myFirebase.auth().currentUser?.getIdToken();
                    if (token) {
                        try
                        {
                            const claims = await AuthService.tokenLogin(token);
                            if (claims) {
                                dispatch(setAuthorizationDone(claims, GcpError.NONE));
                            } else {
                                dispatch(setAuthorizationDone(undefined, GcpError.NONE));
                            }
                        } catch (e: any) {
                            dispatch(setAuthorizationDone(undefined, GcpError.NONE));
                        }
                    } else {
                        dispatch(setAuthorizationDone(undefined, GcpError.NONE));
                    }
                } else {
                    dispatch(setAuthorizationDone(undefined, GcpError.NONE));
                }
                dispatch(setTokenSignInDone());
            });
        } catch (e: any) {
            console.log(e);
            dispatch(setTokenSignInDone());
        }
    }
};


export const initLogout = () => {
    return async (dispatch: any) => {
        await myFirebase.auth().signOut();
        await AuthService.logout();
        // AuthService.stopObservingUser();
        dispatch(setLogoutDone());
    }
};

// ***** Misc ***** //

export const setSuccessSignRedirect = (redirectTo: string) => {
    return {
        type: SET_SUCCESSFUL_SIGN_REDIRECT,
        payload: redirectTo
    }
};

// ***** Update info ***** //

export const setUpdateInfo = (user?: User, updateInfoInProgress?: boolean, updateInfoError?: GcpError) => {
    return {
        type: UPDATE_INFO,
        payload: {
            user,
            updateInfoInProgress,
            updateInfoError
        }
    }
};

export const initUpdateInfo = (user: User) => {
    return async (dispatch: any) => {
        dispatch(setUpdateInfo(undefined, true, GcpError.NONE));

        try {
            const response = await AuthService.updateInfo(user);
            dispatch(setUpdateInfo(response, false, GcpError.NONE));
        } catch (e: any) {
            console.log(e);
            dispatch(setUpdateInfo(undefined, false, GcpError.FAIL));
        }
    }
};

// export const initUpdatePhoneAndSendConfirmation = (user?: User, phone?: string, bookingId?: string) => {
//     return async (dispatch: any) => {
//         dispatch(setUpdateInfo(user, true, GcpError.NONE));
//
//         try {
//             // const response = await AuthService.updateInfo({
//             //     id: user.id,
//             //     phone: phone
//             // });
//
//             await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/sendUnlockCodeToBookingOwner`, {
//                 "bookingId": bookingId
//             });
//
//             // dispatch(setUpdateInfo(response, false, GcpError.NONE));
//         } catch (e: any) {
//             dispatch(setUpdateInfo(undefined, false, GcpError.FAIL));
//         }
//
//     };
// };

export const setLanguage = (language: string) => {
    return {
        type: SET_LANGUAGE,
        payload: language
    }
};
