import React, {useEffect, useState} from 'react';
import {Box, Button, Card, Tab, Tabs} from "@material-ui/core";
import {Organization} from "../../../models/Organization.model";
import {useSelector} from "react-redux";
import AdminOrganizationService from "../../../services/adminOrganization.service";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useHistory, useParams} from 'react-router-dom';
import BasicInfo from "./BasicInfo.component";
import Users from "./Users.component";
import Vehicles from "./Vehicles.component";
import Bookings from "./Bookings.component";
import {useTranslation} from "react-i18next";


const AdminOrganization = () => {

    //region State

    const [organization, setOrganization] = useState<Organization>({});
    const [loading, setLoading] = useState(false);
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const params = useParams<{ id: string }>();
    const isNew = params.id === 'new';
    const [tab, setTab] = useState(0);
    const history = useHistory()
    const t = useTranslation().t

    //endregion

    //region Handlers

    const fetch = async () => {
        if (isNew) return;

        try {
            setLoading(true)
            const organization = await AdminOrganizationService.get(+params.id);
            setOrganization(organization);
        } catch (e: any) {
            console.log(e);
            setOrganization({});
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetch();
    }, [claims, params.id]);

    //endregion Handlers

    //region UI

    const tabs = (
        <Box
            style={{marginBottom: 20}}
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'row'}
        >
            <Card style={{width: 'fit-content'}}>
                <Tabs
                    textColor={"primary"}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_, t) => setTab(t)}
                >
                    <Tab label={t('Basic info')} tabIndex={0}/>
                    {!isNew && <Tab label={t('Users')} tabIndex={1}/>}
                    {!isNew && <Tab label={t('Vehicles')} tabIndex={2}/>}
                    {!isNew && <Tab label={t('Bookings')} tabIndex={3}/>}
                </Tabs>
            </Card>

            {
                // tab === 1 &&
                // <Button
                //     variant={'contained'}
                //     color={'primary'}
                // >
                //     Add user
                // </Button>
            }

            {
                tab === 2 &&
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => history.push(`/admin/vehicle/new/basic?organizationId=${organization.id}`)}
                >
                    Add vehicle
                </Button>
            }
        </Box>
    )

    return (
        <Box>
            {tabs}

            {
                tab === 0 &&
                <BasicInfo
                    organization={organization}
                    setOrganization={setOrganization}
                    loading={loading}
                />
            }

            {
                tab === 1 &&
                <Users
                    organization={organization}
                />
            }

            {
                tab === 2 &&
                <Vehicles
                    organization={organization}
                />
            }

            {
                tab === 3 &&
                <Bookings
                    organization={organization}
                />
            }

        </Box>
    )

    //endregion UI
}

export default AdminOrganization;