import React from "react";

export const fillLoaderContent = (width: string | number, height: string | number, borderRadius: string | number = 4) => {
    return <div className={'pulse'} style={{width, height, borderRadius}}/>
};

export function isPhone() {
    return window.innerWidth < 768;
}

export function width() {
    return window.innerWidth;
}
