// Auth
export const AUTHORIZATION_IN_PROGRESS = "Authorization in progress";
export const AUTHORIZATION_DONE = "Set Authorization done";
export const SET_USER = "Set User";
export const SET_AUTH_ERROR = "Set Auth Error";
export const SET_LOGGED_IN_AS_ANOTHER_USER = "Set Logged In As Another User";

export const TOKEN_SIGNIN_DONE = "Token Signin Done";
export const TOKEN_SIGNIN_IN_PROGRESS = "Token Signin In Progress";

export const LOGOUT_DONE = "Logout Done";

export const SET_SUCCESSFUL_SIGN_REDIRECT = "Set Successful sign redirect";

export const UPDATE_INFO = "Update Info";

export const UPDATE_SUBSCRIPTION_DONE = "Update Subscription Done";

export const SET_LANGUAGE = "Set Language";

// Booking (new or existing)
export const SET_CAR_LOADING = "Set Car Loading";
export const SET_CAR = "Set Car";

export const SET_EXISTING_BOOKINGS_LOADING = "Set Existing Bookings Loading";
export const SET_EXISTING_BOOKINGS = "Set Existing Bookings";
export const SET_MAINTENANCE = "Set Maintenance";

export const SET_BOOKING_TIME = "Set Booking Time";

export const SET_BOOKING_IN_PROGRESS = "Set Booking In Progress";
export const SET_BOOKING_DONE = "Set Booking Done";

export const SET_CANCEL_BOOKING_IN_PROGRESS = "Set Cancel Booking In Progress";
export const SET_CANCEL_BOOKING_DONE = "Set Cancel Booking Done";

export const SET_CURRENT_BOOKING_SESSION_ENDED = "Set Current Booking Session Ended";


// Listing
export const SET_LISTING = "Set Listing";

// History
export const SET_ACTIVE_BOOKINGS = "Set Active Bookings";
export const SET_HISTORY_BOOKINGS = "Set History Bookings";
export const SET_HISTORY_OVERVIEW = "Set History Overview";
export const ADD_HISTORY_BOOKING = 'Add History Booking';

// Admin
export const SET_VIEW_AS_PARTNER = "Set View As Partner";
