import React, {useState} from 'react';
import {Group} from "../../../models/Group.model";
import {Vehicle} from "../../../models/Vehicle.model";
import VehiclesList from "../common/VehiclesList.component";
import {Box, Button, Container} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import VehiclePicker from "../common/VehiclePicker.component";
import {useTranslation} from "react-i18next";

interface Props {
    group: Group;
    setGroup: (group: Group) => void;
}

const Vehicles = ({group, setGroup}: Props) => {

    const [vehiclePickerOpen, setVehiclePickerOpen] = useState(false);
    const history = useHistory();
    const t = useTranslation().t

    //region Handlers

    const addVehicle = (vehicle: Vehicle) => {
        const newVehicles = [...(group.vehicles || [])];
        if (!newVehicles.find(v => v.id === vehicle.id)) {
            newVehicles.push(vehicle);
        }
        setVehiclePickerOpen(false);
        setGroup({
            ...group,
            vehicles: newVehicles
        })
    }

    const removeVehicle = (vehicle: Vehicle) => {
        setVehiclePickerOpen(false);
        setGroup({
            ...group,
            vehicles: group.vehicles?.filter(v => v.id !== vehicle.id)
        })
    }

    //endregion Handlers

    //region UI

    return (
        <Container>
            <Button
                variant='contained'
                color={'primary'}
                onClick={() => setVehiclePickerOpen(true)}
            >
                {t('Add vehicle')}
            </Button>
            <Box m={2}/>
            <VehiclesList
                vehicles={{content: group.vehicles || []}}
                onVehicleClick={vehicle => {
                    history.push(`/admin/vehicle/${vehicle.id}/basic`)
                }}
                showDelete
                onVehicleRemove={removeVehicle}
                // onPageChange={page => fetch(page)}
            />

            <VehiclePicker
                open={vehiclePickerOpen}
                onClose={() => setVehiclePickerOpen(false)}
                onPick={addVehicle}
            />

        </Container>
    )

    //endregion UI
}

export default Vehicles;