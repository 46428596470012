import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './MyBookings.module.scss';
import {connect} from "react-redux";
import BookingPreview from "../BookingPreview/BookingPreview.component";
import BookingPreviewLoader from "../BookingPreview/BookingPreviewLoader.component";
import {Button} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import Footer from "../../../common/ui/Footer/Footer.component";
import {bindActionCreators} from "redux";
import BookingService from "../../../services/booking.service";
import {Booking} from "../../../models/Booking.model";
import HistoryService from "../../../services/history.service";
import {AuthClaims} from "../../../models/AuthClaims.model";
import HistoryOverview from "../../../models/HistoryOverview.model";
import GcpError from "../../../models/error.enum";
import LoggedInAs from "../../listing/LoggedInAs/LoggedInAs.component";
import Overview from "./Overview.component";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps {
    claims?: AuthClaims;
    signInProgress: boolean;

    // bookings?: Booking[];
    // loadingBookings: boolean;
    // bookingsError: GcpError;
    // hasMoreBookings: boolean;

    // initLoadHistory: typeof initLoadHistory;
    // addNewPastBooking: typeof addNewPastBooking;
}


const MyBookings = (props: Props) => {

    const t = useTranslation().t
    const [finishedBookings, setFinishedBookings] = useState<Booking[]>([]);
    const [finishedBookingsLoading, setFinishedBookingsLoading] = useState(false);
    const [finishedBookingsLoadingError, setFinishedBookingsLoadingError] = useState(GcpError.NONE);
    const [hasMoreBookings, setHasMoreBookings] = useState(false);
    const [finishedBookingsLastPage, setFinishedBookingsLastPage] = useState(0);

    const [liveBookings, setLiveBookings] = useState<Booking[]>([]);
    const [liveBookingsLoading, setLiveBookingsLoading] = useState(false);
    const [liveBookingsLoadingError] = useState(GcpError.NONE);

    const [overview, setHistoryOverview] = useState<HistoryOverview>();
    const [overviewLoading, setOverviewLoading] = useState(false);
    const [overviewLoadingError] = useState(GcpError.NONE);

    const [tick, setTick] = useState<any>(0);

    const user = props.claims?.user;

    // ***** Effects ***** //

    const processNewActiveBookings = async (newLiveBookings: Booking[]) => {
        const oldLiveBookings = liveBookings || [];
        const removedBookings = oldLiveBookings.filter(oldLiveBooking => !newLiveBookings.find(newLiveBooking => newLiveBooking.id === oldLiveBooking.id));

        for (let removedBooking of removedBookings) {
            const updatedBooking = await BookingService.getOne(removedBooking.id!);
            setFinishedBookings([updatedBooking, ...finishedBookings]);
        }
    }

    const fetchOverview = async () => {
        setOverviewLoading(true);
        const overview = await HistoryService.getUserOverview();
        setHistoryOverview(overview);
        setOverviewLoading(false);
    }

    const fetchActiveBookings = async () => {
        if (!user?.id) return;

        // setLiveBookingsLoading(true);
        const bookings = await HistoryService.getAllActiveBookings();
        processNewActiveBookings(bookings);
        setLiveBookings(bookings);
        setLiveBookingsLoading(false);
    }

    const fetchFinishedBookings = async () => {
        if (!user?.id) return;

        setFinishedBookingsLoading(true);
        try {
            const bookings = await HistoryService.getAllFinishedBookings(0, 18);
            setFinishedBookings(bookings?.content || []);
            setFinishedBookingsLoadingError(GcpError.NONE);
            setFinishedBookingsLastPage(bookings.page || 0);
            setHasMoreBookings(!bookings.isLastPage);
        } catch (e: any) {
            setFinishedBookings([]);
            setFinishedBookingsLoadingError(GcpError.FAIL);
        }
        setFinishedBookingsLoading(false);
    }

    useEffect(() => {
        if (user?.id) {
            // props.initLoadHistory(user.id, new Date(), 20, false);
            fetchFinishedBookings();

            // Live/active bookings - refresh the whole list on any data update
            // fetchActiveBookings();
            fetchOverview();
        }
    }, [props.claims]);

    const loadMore = async () => {
        if (!user?.id) return;

        try {
            const bookings = await HistoryService.getAllFinishedBookings(finishedBookingsLastPage + 1, 18);
            setFinishedBookings([...finishedBookings, ...(bookings.content || [])]);
            setFinishedBookingsLastPage(bookings.page || 0);
            setHasMoreBookings(!bookings.isLastPage);
        } catch (e: any) {
            setHasMoreBookings(false);
        }
    };

    useEffect(() => {
        fetchActiveBookings();
        setTimeout(() => setTick(tick + 1), 5000);
    }, [tick]);

    useEffect(() => {
        trackCurrentPage()
    }, [])

    // ***** Handlers ***** //

    const cancelBooking = async (booking?: Booking) => {
        if (booking?.id) {
            const deletedBooking = await BookingService.cancelBooking(booking.id);
            if (deletedBooking) {
                setLiveBookings(liveBookings.filter(b => b.id !== deletedBooking.id));
            }
        }
    };

    // ***** Bookings ***** //

    const bookingsLoading = props.signInProgress || finishedBookingsLoading;

    const renderBookings = () => {
        if (finishedBookingsLoadingError) {
            return (
                <FlexView
                    className={classes.errorHolder}
                    hAlignContent='center'
                    vAlignContent='center'
                >
                    <div>{t("We couldn't load your bookings...")}</div>
                </FlexView>
            )
        }

        if (!bookingsLoading && !finishedBookings.length) {
            return (
                <FlexView column className={classes.noBookings} hAlignContent='center'>
                    <div>{t("You haven't made any bookings yet...")}</div>
                    {/*<Button variant="contained" color="primary" className={classes.makeBooking}*/}
                    {/*        onClick={() => props.history.push('/find-car')}>*/}
                    {/*    {t('Make booking')}*/}
                    {/*</Button>*/}
                </FlexView>
            )
        }

        return finishedBookings?.map(booking => <BookingPreview booking={booking} key={booking.id}/>);
    };

    const activeBookingsLoading = props.signInProgress || liveBookingsLoading;

    const renderActiveBookings = () => {
        if (liveBookingsLoadingError) {
            return (
                <FlexView
                    className={classes.errorHolder}
                    hAlignContent='center'
                    vAlignContent='center'
                >
                    <div>{t("We couldn't load your bookings...")}</div>
                </FlexView>
            )
        }

        if (!liveBookingsLoading && !liveBookings.length) {
            return (
                <FlexView column className={classes.noBookings} hAlignContent='center'>
                    <div>{t("No upcoming or active bookings")}</div>
                    <Button variant="contained" color="primary" className={classes.makeBooking}
                            onClick={() => props.history.push('/find-car')}>
                        {t('Make booking')}
                    </Button>
                </FlexView>
            )
        }

        return liveBookings.map(booking => <BookingPreview
            onCancel={() => cancelBooking(booking)}
            booking={booking} key={booking.id}
        />);
    };

    const renderBookingLoaders = () => {
        const loaders = [...Array(3)];
        return loaders.map((_, index) => <BookingPreviewLoader key={index}/>);
    };

    // ***** Render ***** //

    return (
        <FlexView className={classes.component} column>
            <LoggedInAs/>

            <Overview
                overview={overview}
                signInProgress={props.signInProgress}
                overviewLoading={overviewLoading}
                overviewLoadingError={overviewLoadingError}
            />


            <div className={classes.sectionTitle}>{t('Active and upcoming')}</div>
            <FlexView wrap className={classes.bookingsHolder} vAlignContent={'top'}>
                {activeBookingsLoading && renderBookingLoaders()}
                {!activeBookingsLoading && renderActiveBookings()}
            </FlexView>

            <div className={classes.sectionTitle}>{t('Past bookings')}</div>
            <FlexView wrap className={classes.bookingsHolder} vAlignContent={'top'}>
                {renderBookings()}
                {bookingsLoading && renderBookingLoaders()}

                {
                    !bookingsLoading && hasMoreBookings && !finishedBookingsLoadingError &&
                    <FlexView className={classes.loadMoreHolder} hAlignContent={'center'}>
                        <Button variant="contained" color="primary" className={classes.makeBooking}
                                onClick={loadMore}>
                            {t('Load more')}
                        </Button>
                    </FlexView>
                }

            </FlexView>

            <div style={{flex: 1}}/>

            <Footer/>

        </FlexView>
    )
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        signInProgress: state.auth.signInProgress,
        //
        // bookings: state.history.bookings,
        // loadingBookings: state.history.loadingBookings,
        // bookingsError: state.history.bookingsError,
        // hasMoreBookings: state.history.hasMoreBookings,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        // initLoadHistory,
        // addNewPastBooking
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBookings);
