import {Booking, BookingStatus} from "../models/Booking.model";
import api from './axios';
import BookingService from "./booking.service";
import HistoryOverview from "../models/HistoryOverview.model";
import {Page} from "../models/Page";

/**
 * Handles all actions related to user's past booking and overview
 */
export default class HistoryService {

    static getAllActiveBookings = async (): Promise<Booking[]> => {
        const response = await api.get<Page<Booking>>(`/booking?status=${BookingStatus.ACTIVE}&page=0&size=1000`);
        return response.data?.content?.map(BookingService.convertToWebFormat) || [];
    };

    /**
     * Gets all finished user bookings,
     * ordered by creation date descending
     */
    static getAllFinishedBookings = async (page?: number, size?: number): Promise<Page<Booking>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            `status=${BookingStatus.SESSION_ENDED}`
        ].filter(x => !!x).join('&');

        const response = await api.get<Page<Booking>>(`/booking?${params}`);

        return {
            ...response.data,
            content: response.data?.content?.map(BookingService.convertToWebFormat)
        }

    };

    /**
     * Gets user overview data
     */
    static getUserOverview = async (): Promise<HistoryOverview> => {
        const response = await api.get<HistoryOverview>(`/profile/overview`);
        return response.data
    }
}
