import React, {useState} from 'react';
import {Box, Button, Card, CircularProgress} from "@material-ui/core";
import GcpInput from "../../../common/ui/GcpInput";
import {makeStyles} from "@material-ui/core/styles";
import {Organization} from "../../../models/Organization.model";
import AdminOrganizationService from "../../../services/adminOrganization.service";
import {useHistory, useParams} from "react-router-dom";
import GcpButton from "../../../common/ui/GcpButton";
import AlertDialog from "../../../common/ui/AlertDialog.component";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    content: {
        padding: 20
    },
    nameInput: {
        width: 300,
        marginTop: 20,
        marginBottom: 40
    },
    actionButton: {
        marginLeft: 20
    }
}))

interface Props {
    loading?: boolean;
    organization: Organization;
    setOrganization: (organization: Organization) => void;
}

const BasicInfo = ({organization, setOrganization, loading}: Props) => {

    const t = useTranslation().t
    const canSave = !!organization.name;
    const classes = useStyle();
    const [saving, setSaving] = useState(false);
    const params = useParams<{ id: string }>();
    const isNew = params.id === 'new';
    const history = useHistory();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    //region Handlers

    const save = async () => {
        setSaving(true);
        try {
            if (isNew) {
                const newOrganization = await AdminOrganizationService.add(organization);
                setOrganization(newOrganization);
                history.replace(`/admin/organization/${newOrganization.id}`)
            } else {
                const newOrganization = await AdminOrganizationService.update(organization);
                setOrganization(newOrganization);
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setSaving(false);
        }
    }

    //endregion Handlers


    const renderActions = () => {
        if (loading || saving) return <CircularProgress/>;

        if (isNew) {
            return (
                <Box>
                    <Button disabled={!canSave} variant={'contained'} color={"primary"} onClick={save}>
                        {t('Save')}
                    </Button>
                </Box>
            )
        }

        return (
            <Box>

                <Button
                    disabled={!canSave}
                    variant={'contained'}
                    color={"primary"}
                    onClick={save}
                >
                    Save
                </Button>

                <GcpButton
                    className={classes.actionButton}
                    variant={'contained'}
                    color={"error"}
                    onClick={() => setDeleteDialogVisible(true)}
                >
                    {t('Delete')}
                </GcpButton>

            </Box>
        )
    }

    return (
        <Card className={classes.content}>
            <GcpInput
                className={classes.nameInput}
                placeholder={'Organization name'}
                value={organization?.name}
                onChange={e => setOrganization?.({...organization, name: e.target.value})}
            />

            {renderActions()}

            <AlertDialog
                open={deleteDialogVisible}
                onClose={() => {
                    setDeleteDialogVisible(false);
                }}
                onConfirm={() => {
                    setDeleteDialogVisible(false);
                    // deleteOrganization();
                }}
                title={t('Delete organization?')}
                message={t(`This will remove organization and all of it's data`)}
            />

        </Card>
    )
}

export default BasicInfo;