import React from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './BookingPreview.module.scss';
import clsx from 'clsx';
import {fillLoaderContent} from "../../../common/ui.util";

const BookingPreviewLoader = () => (
    <FlexView column className={clsx('container', classes.booking)}>
        <FlexView className={classes.header} vAlignContent={'center'}>
            <div className={clsx(classes.image, 'pulse')}/>
            <div className={classes.title}>
                {fillLoaderContent(100, 20)}
            </div>

            <FlexView column hAlignContent='center' className={classes.action}>
                {fillLoaderContent(30, 30)}
            </FlexView>

            <FlexView column hAlignContent='center' className={classes.action}>
                {fillLoaderContent(30, 30)}
            </FlexView>

        </FlexView>

        <FlexView className={classes.footer} vAlignContent={'center'}>
            <FlexView column className={classes.dates}>
                {fillLoaderContent(90, 10)}
                {fillLoaderContent(90, 10)}
            </FlexView>
            <div className={classes.price}>
                {fillLoaderContent(50, 30)}
            </div>
        </FlexView>
    </FlexView>
);

export default BookingPreviewLoader;
