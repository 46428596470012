import React from 'react';
import {Box, Card, CircularProgress} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import {Page} from "../../../models/Page";
import Pagination from "../../../common/ui/Pagination/Pagination.component";
import {Booking, BookingStatus} from "../../../models/Booking.model";
import {format, isAfter} from "date-fns";
import {vehicleDescriptor} from "../../../common/vehicle.util";
import {useTranslation} from "react-i18next";

interface Props {
    loading?: boolean;
    bookings?: Page<Booking>;
    onBookingClick: (booking: Booking) => void;
    onPageChange: (page: number) => void;
}

const BookingList = ({loading, bookings, onBookingClick, onPageChange}: Props) => {

    const t = useTranslation().t

    if (loading) {
        return <CircularProgress/>
    }

    if (!bookings?.content.length) {
        return <div>{t('No bookings found')}</div>
    }

    const header = (
        <FlexView vAlignContent='center' className={classes.header}>
            <div className={clsx(classes.cellXXS, classes.headerCell)}>{t('Id')}</div>
            <div className={clsx(classes.cellGrow, classes.headerCell)}>{t('User')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('From')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('To')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Status')}</div>
            <div className={clsx(classes.cellGrow, classes.headerCell)}>{t('Vehicle')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Organization')}</div>
        </FlexView>
    )

    const content = bookings.content.map(booking => {
        let statusText = booking.status || '-';
        if (booking.to && booking.status === BookingStatus.ACTIVE) {
            if (isAfter(new Date(), booking.to)) {
                statusText += ' (OVERTIME)'
            } else if (booking.from && isAfter(booking.from, new Date())) {
                statusText = 'notStarted'
            }
        }

        return (
            <FlexView key={booking.id} vAlignContent='center' className={classes.tableRow}
                      onClick={() => onBookingClick(booking)}>
                <div className={classes.cellXXS}>
                    {booking.id}
                </div>
                <div className={classes.cellGrow}>
                    {booking.user?.name || '-'}
                </div>
                <div className={classes.cellSmall}>
                    {booking.from && format(booking.from, 'yyyy/MM/dd HH:mm')}
                </div>
                <div className={classes.cellSmall}>
                    {booking.to && format(booking.to, 'yyyy/MM/dd HH:mm')}
                </div>
                <div className={classes.cellSmall}>
                    {statusText}
                </div>
                <div className={classes.cellGrow}>
                    {vehicleDescriptor(booking.vehicle)}
                </div>
                <div className={classes.cellSmall}>
                    {booking.organization?.name || '-'}
                </div>
            </FlexView>
        )
    })

    return (
        <Card>
            {header}
            {content}

            <Box style={{width: '100%'}} display={'flex'} justifyContent={'center'}>
                <Pagination
                    pages={bookings.totalPages || 0}
                    currentPage={bookings?.page || 0}
                    onPageChange={onPageChange}
                />
            </Box>

        </Card>
    )
}

export default BookingList;