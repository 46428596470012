import {Booking} from "../models/Booking.model";
import api from './axios';
import {Invoice} from "../models/Invoice.model";

/**
 * Handles all actions related to booking.
 */
export default class BookingService {

    static convertToWebFormat(booking?: Booking): Booking {
        if (!booking) return {};

        return {
            ...booking,
            createdAt: booking.createdAt && new Date(booking.createdAt),
            updatedAt: booking.updatedAt && new Date(booking.updatedAt),
            from: booking.from && new Date(booking.from),
            to: booking.to && new Date(booking.to),
            sessionEndedAt: booking.sessionEndedAt && new Date(booking.sessionEndedAt),
            vehicleReturnedAt: booking.vehicleReturnedAt && new Date(booking.vehicleReturnedAt)
        }
    }

    static async getOne(id: number) {
        const response = await api.get<Booking>(`/booking/${id}`);
        return BookingService.convertToWebFormat(response.data);
    }

    static async getInvoice(id: number) {
        const response = await api.get<Invoice>(`/booking/${id}/invoice`);
        // return BookingService.convertToWebFormat(response.data);
        return response.data;
    }

    static async create(booking: Booking) {
        const response = await api.post<Booking>(`/booking`, booking);
        return BookingService.convertToWebFormat(response.data);
    }

    /**
     * Update booking time
     * @param booking
     * @param from
     * @param to
     */
    static async updateBooking(booking?: Booking, from?: Date, to?: Date): Promise<Booking | undefined> {
        if (!booking || !from || !to) return;

        const response = await api.put<Booking>(`/booking/${booking?.id}/changeTime`, {
            from,
            to
        });
        return BookingService.convertToWebFormat(response.data);
    };


    /**
     * Cancels a booking.
     */
    static async cancelBooking(bookingId?: number) {
        const response = await api.delete<Booking>(`/booking/${bookingId}`);
        return BookingService.convertToWebFormat(response.data);
    };

    /**
     * Lock vehicle
     */
    static async lock(bookingId?: number, props?: any) {
        const response = await api.post<any>(`/booking/${bookingId}/lock`, props);
        return response.data;
    };

    /**
     * Unlock vehicle
     */
    static async unlock(bookingId?: number, props?: any) {
        const response = await api.post<any>(`/booking/${bookingId}/unlock`, props);
        return response.data;
    };

    /**
     * End session vehicle
     */
    static async endSession(bookingId?: number, props?: any) {
        const response = await api.post<any>(`/booking/${bookingId}/endSession`, props);
        return response.data;
    };

    /**
     * Tried to add specified amount of time to a booking.
     */
    static async requestMoreTime(booking?: Booking, additionalMinutes?: number): Promise<{ status: string, booking?: Booking }> {
        if (!booking?.id || !additionalMinutes || additionalMinutes <= 0) {
            return {
                status: 'fail'
            }
        }

        try {
            const response = await api.put<{ status: string, booking?: Booking }>(`/booking/${booking.id}/addMoreTime`, {
                minutes: additionalMinutes
            });

            return {
                ...response.data,
                booking: BookingService.convertToWebFormat(response.data.booking)
            }
        } catch (e: any) {
            return {
                status: 'fail'
            }
        }
    };
}
