import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './AddTimeDialog.module.scss';
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    onClose: () => void,
    onConfirm: (minutes: number) => void
}

const AddTimeDialog = (props: Props) => {

    const t = useTranslation().t
    
    return (
        <Dialog aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={'sm'}
                onBackdropClick={props.onClose}
        >

            <DialogTitle id="customized-dialog-title">
                {t('Add time')}
            </DialogTitle>

            <DialogContent dividers className={classes.container}>

                <Button onClick={() => props.onConfirm(30)} color="primary" variant='contained' className={classes.minutes}>
                    {t('X minutes', {minutes: 30})}
                </Button>

                <Button onClick={() => props.onConfirm(60)} color="primary" variant='contained' className={classes.minutes}>
                    {t('X minutes', {minutes: 60})}
                </Button>

            </DialogContent>

            <DialogActions className={classes.container}>
                <Button onClick={props.onClose} color="primary">
                    {t('close')}
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default AddTimeDialog;
