import React from 'react';
import FlexView from "react-flexview";
import classes from './CarPreview.module.scss';
import clsx from 'clsx';
import Battery from '../../../assets/icons/battery-grey.svg';
import CarMake from '../../../assets/icons/car-make-grey.svg';
import ElectricStation from '../../../assets/icons/electric-station.svg';
import Persons from '../../../assets/icons/persons-grey.svg';
import {isPhone} from "../../../common/ui.util";
import {getPriceForUser} from "../../../common/booking.util";
import {connect} from "react-redux";
import {Vehicle, VehicleType} from "../../../models/Vehicle.model";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props {
    car: Vehicle,
    claims?: AuthClaims,
}

const CarPreview = (props: Props) => {

    const t = useTranslation().t
    const bookingUser = props.claims?.user;
    const price = getPriceForUser(props.car)

    const details = (
        <FlexView
            className={clsx(classes.detailsContainer, props.car.vehicleType !== VehicleType.CAR && classes.scooter)}
            vAlignContent='center'>

            {/* Last known fuel level */}
            <FlexView column hAlignContent='center'>
                <img className={classes.icon} src={Battery}/>
                <div>{Math.floor(props.car?.lastFuelLevel || 0) || '- '}%</div>
            </FlexView>

            {/* Number of seats */}
            {
                props.car.vehicleType === VehicleType.CAR &&
                <FlexView column hAlignContent='center'>
                    <img className={classes.icon} src={Persons}/>
                    <div>{props.car.seats || '-'}</div>
                </FlexView>
            }

            {/* Body type */}
            {
                props.car.vehicleType === VehicleType.CAR &&
                <FlexView column hAlignContent='center'>
                    <img className={classes.icon} src={CarMake}/>
                    <div>{t(props.car.bodyType || '') || '-'}</div>
                </FlexView>
            }

            {/* Range */}
            <FlexView column hAlignContent='center'>
                <img className={classes.icon} src={ElectricStation}/>
                <div>{props.car.range || '- '}km</div>
            </FlexView>

        </FlexView>
    );

    return (
        <FlexView column className={clsx(classes.component)}>
            <FlexView style={{width: '100%'}}>

                {/* Car image */}
                <img className={classes.carImage} src={props.car.image}/>

                <FlexView column className={classes.rightSection}>

                    {/* Car type */}
                    <FlexView className={classes.header} vAlignContent='center'>
                        <FlexView column>
                            {props.car.type && <div className={classes.carType}>{t(props.car.type)}</div>}
                            {!props.car.address &&
                            <div className={classes.carModel}>{props.car.make} {props.car.model}</div>}
                            {props.car.address && <div className={classes.address}>{props.car.address}</div>}
                            {props.car.address &&
                            <div className={classes.carModelSmall}>{props.car.make} {props.car.model}</div>}
                        </FlexView>
                        {/*<img className={classes.info} src={Info}/>*/}
                    </FlexView>

                    {/* Details (on desktop) */}
                    {!isPhone() ? details : null}

                    {/* Price */}
                    {
                        price > 0 ?
                            <div className={classes.price}>
                                {getPriceForUser(props.car, bookingUser, props.claims?.subscription)} kr/min
                            </div>
                            :
                            <div className={classes.price}>
                                {t('Free')}
                            </div>
                    }


                </FlexView>

            </FlexView>

            {/* Details (on phone) */}
            {isPhone() ? details : null}

        </FlexView>
    );
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(CarPreview);
