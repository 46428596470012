import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import classes from "./Listing.module.scss";
import CarPreview from "../CarPreview/CarPreview.component";
import {Switch} from "@material-ui/core";
import clsx from 'clsx';
import MapCarPreview from "../MapCarPreview/MapCarPreview.component";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import GoogleMap from 'google-map-react';
import Marker from "../Marker/Marker.component";
import {isPhone} from "../../../common/ui.util";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import {initLoadListing} from "../../../store/actions/listing.actions";
import CarPreviewLoader from "../CarPreview/CarPreviewLoader.component";
import {haversineDistance} from "../../../common/location.util";
import {bindActionCreators} from "redux";
import {ListingState} from "../../../store/reducers/listing.reducer";
import VehicleService from "../../../services/vehicle.service";
import VehicleTypeCarGreen from '../../../assets/icons/vehicle-type-car-green.svg';
import VehicleTypeCarWhite from '../../../assets/icons/vehicle-type-car-white.svg';
import VehicleTypeScooterWhite from '../../../assets/icons/vehicle-type-scooter-white.svg';
import VehicleTypeScooterOrange from "../../../assets/icons/vehicle-type-scooter-orange.svg";
import VehicleTypeBikeWhite from '../../../assets/icons/vehicle-type-bicycle-white.svg';
import VehicleTypeBikeBlue from "../../../assets/icons/vehicle-type-bicycle-blue.svg";
import {Vehicle, VehicleType} from "../../../models/Vehicle.model";
import {GeoPoint} from "../../../models/GeoPoint.model";
import LoggedInAs from "../LoggedInAs/LoggedInAs.component";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps, ListingState {
    google: any,
    signInProgress: boolean,
    initLoadListing: typeof initLoadListing;
}

const Listing = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [filterByVehicleType, setFilterByVehicleType] = useState<VehicleType>();

    const [showMap, setShowMap] = useState(false);
    const [showVerticalList, setShowVerticalList] = useState(true);
    const [showHorizontalList, setShowHorizontalList] = useState(false);
    const [hoveredCarList, setHoveredCarList] = useState<Vehicle>();
    const [hoveredCarListMap, setHoveredCarMap] = useState<Vehicle>();
    // @ts-ignore
    const [hovering, setHovering] = useState(false);
    // @ts-ignore
    const [activeMarker, setActiveMarker] = useState(null);
    const [mapActiveCar, setMapActiveCar] = useState<Vehicle>();

    // const [browserLocationStatus, setBrowserLocationStatus] = useState('');
    // const [browserLocation, setBrowserLocation] = useState<{ latitude: number, longitude: number }>();

    const [ipLocationStatus, setIpLocationStatus] = useState('loading');
    const [ipLocation, setIpLocation] = useState({});

    // const [geofenceWarningCar, setGeofenceWarningCar] = useState<Vehicle>();

    // ***** Effects ***** //

    const loadIpLocation = async () => {
        setIpLocationStatus('loading');
        try {
            const result = await VehicleService.getUserLocationByIp();
            if (result.success === false) {
                setIpLocationStatus('error');
                return;
            }
            setIpLocation({
                latitude: result.latitude,
                longitude: result.longitude
            });
            setIpLocationStatus('loaded');
        } catch (e: any) {
            setIpLocationStatus('error');
        }
    };

    // Trigger on component create
    useEffect(() => {
        // Try to get user location and use it to
        // sort vehicles by distance
        // if (window.navigator.geolocation) {
        //     window.navigator.geolocation.getCurrentPosition(position => {
        //         const {latitude, longitude} = position.coords;
        //         setBrowserLocationStatus('loaded');
        //         setBrowserLocation({latitude, longitude});
        //     }, error => {
        //         console.log(error);
        //         // setBrowserLocationStatus('error');
        //         loadIpLocation();
        //     }, {
        //         enableHighAccuracy: true,
        //         maximumAge: 30 * 60 * 1000 // 30 minutes
        //     });
        // } else {
        // loadIpLocation();
        // }

        loadIpLocation();
    }, []);

    useEffect(() => {
        trackCurrentPage()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (isPhone()) {
                setShowVerticalList(true);
                setShowMap(false);
            } else {
                setShowVerticalList(true);
                setShowMap(true);
            }

        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (ipLocationStatus !== 'loading') {
            props.initLoadListing(filterByVehicleType, 0, 100);
        }
    }, [filterByVehicleType, ipLocationStatus]);

    // ***** Handlers ***** //

    // @ts-ignore
    /**
     * Handle map view "switch".
     * In desktop mode we either show list + map or only list.
     * In phone mode we show either map or list.
     * @param _
     * @param checked
     */
    const handleMapShowChange = (_: any, checked: boolean) => {
        setShowMap(checked);
        if (isPhone()) {
            setShowVerticalList(!checked);
        }
    };

    /**
     * Shows hover UI (car name)
     * @param car
     */
    const handleCarHoverListEnter = (car: Vehicle) => {
        setHoveredCarList(car);
        setHovering(true);
        setMapActiveCar(undefined);
    };

    const handleCarHoverMapEnter = (car: Vehicle) => {
        setHoveredCarMap(car);
        setHovering(true);
        setMapActiveCar(undefined);
    };

    /**
     * Hides hover UI (car name)
     */
    const handleCarHoverListExit = () => {
        setHovering(false);
        setHoveredCarList(undefined);
    };

    const handleCarHoverMapExit = () => {
        setHovering(false);
        setHoveredCarMap(undefined);
    };

    // const handleMarkerHover = (car: Vehicle) => {
    //     // if (hoveredMarker !== car) {
    //     //      setHoveredMarker(car);
    //     // }
    // };

    /**
     * Show full car map preview
     * @param car
     */
    const onMarkerClick = (car: Vehicle) => {
        setMapActiveCar(car);
        setHoveredCarMap(isPhone() ? car : undefined);
        setShowHorizontalList(isPhone() && showMap)
    };

    // @ts-ignore
    /**
     * Handles "click outside" car preview on map
     * @param props
     */
    const onMapClicked = (_: any) => {
        if (mapActiveCar) {
            setActiveMarker(null);
            setHoveredCarMap(undefined);
            setMapActiveCar(undefined);
            setShowHorizontalList(false);
        }
    };

    /**
     * On mobile, where we are showing only one preview
     * at the time, swipe left-right means going through
     * sorted car list up and down
     * @param args
     */
    const handleCarouselChange = (args: any) => {
        setMapActiveCar(getCarsSortedByUserDistance()[args]);
        setHoveredCarList(getCarsSortedByUserDistance()[args]);
    };

    /**
     * Car position in the sorted list
     */
    const getSelectedCarIndex = () => {
        if (!mapActiveCar) {
            return -1;
        }
        return getCarsSortedByUserDistance().findIndex(c => c.id === mapActiveCar.id);
    };

    /**
     * If not user location is loaded (eg. user did not
     * give location permissions) we fallback to ip location
     * and if that is not working we fall back to Stockholm, Sweden.
     */
    const getUserLocation = (): GeoPoint => {
        // if (browserLocationStatus === 'loaded' && browserLocation) {
        //     return browserLocation;
        // }

        if (ipLocationStatus === 'loaded' && ipLocation) {
            return ipLocation;
        }

        // Stockholm
        return {
            latitude: 59.329923,
            longitude: 18.067851
        }
    };

    /**
     * Opens booking UI when user clicks on the car
     * @param car
     */
    const handleStartBooking = (car: Vehicle) => {
        // const userLocation = getUserLocation();
        // if (haversineDistance(
        //     [car.location?.latitude, car.location?.longitude],
        //     [userLocation.latitude, userLocation.longitude],
        // ) > 30) {
        //     setGeofenceWarningCar(car);
        // } else {
        props.history.push(`/car/${encodeURI((car.make + '-' + car.model).replace(' ', '-'))}/${car.id}`);
        // }
    };

    /**
     * Sorts vehicles from nearest to furthest by using haversine distance
     * between user and vehicles.
     * TODO IMPROVEMENT - Fallback to IP location mapping
     */
    const getCarsSortedByUserDistance = () => {
        if (!props?.vehicles?.content?.length) {
            return [];
        }

        const userLocation = getUserLocation();

        const sorted = [...props.vehicles.content];
        sorted.sort((carA, carB) => {
            const carADistance = haversineDistance([carA.location?.latitude, carA.location?.longitude], [userLocation?.latitude, userLocation?.longitude], false);
            const carBDistance = haversineDistance([carB.location?.latitude, carB.location?.longitude], [userLocation?.latitude, userLocation?.longitude], false);
            return carADistance - carBDistance;
        });

        return sorted;
    };

    // ***** Render ****** //

    const renderCars = () => {
        const carPreviewStyle: any = showMap ? '' : classes.carPreviewNoMap;

        return getCarsSortedByUserDistance().map(car => {
            // const forceHover = mapActiveCar === car ? classes.forceHover : '';
            return (
                <div key={car.id}
                     className={clsx(classes.carPreview, carPreviewStyle)}
                     onMouseEnter={() => handleCarHoverListEnter(car)}
                     onMouseLeave={() => handleCarHoverListExit()}
                     onClick={() => handleStartBooking(car)}
                >
                    <CarPreview car={car}/>
                </div>
            )
        });
    };

    const renderCarLoaders = () => {
        const loaders: any[] = [...Array(3)];
        return loaders.map((_, index) => (
            <div className={classes.carPreview}>
                <CarPreviewLoader key={index}/>
            </div>
        ));
    };

    const calculateMapCenter = () => {
        if (hoveredCarList) {
            // If user hover on the car in the list
            return covertToGMapCoordinates(hoveredCarList?.location);
        } else if (mapActiveCar) {
            // If user clicked on a car on the map
            return covertToGMapCoordinates(mapActiveCar?.location);
        } else if (getCarsSortedByUserDistance().length) {
            // Nearest car
            const nearestCar = getCarsSortedByUserDistance()[0];
            return covertToGMapCoordinates({
                latitude: nearestCar.location?.latitude || 59.329923,
                longitude: nearestCar.location?.longitude || 18.067851
            });
        } else {
            return {lat: 59.329923, lng: 18.067851}
        }
    };

    const covertToGMapCoordinates = (carLocation?: GeoPoint) => {
        return {
            lat: carLocation?.latitude || 59.329923,
            lng: carLocation?.longitude || 18.067851
        }
    };

    const renderMap = () => {
        if (!showMap || props.loading || props.signInProgress) {
            return null;
        }

        return (
            <FlexView className={classes.mapHolder}>

                {
                    isPhone() &&
                    <FlexView hAlignContent={'center'} className={classes.mapVehicleTypeHolder}>
                        {renderVehicleTypePicker()}
                    </FlexView>
                }

                <GoogleMap
                    onClick={onMapClicked}
                    // onChildMouseLeave={() => hoveredCar && handleCarHoverExit()}
                    bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY || ''}}
                    defaultCenter={{lat: 59.329923, lng: 18.067851}}
                    defaultZoom={14}
                    // zoom={mapActiveCar ? 16 : 14}
                    center={calculateMapCenter()}
                    yesIWantToUseGoogleMapApiInternals
                >

                    {
                        getCarsSortedByUserDistance().reverse().map(car => {
                            if ((mapActiveCar === car || hoveredCarList === car || hoveredCarListMap === car) && !isPhone()) {
                                // User clicked on car -> show full preview
                                return <MapCarPreview
                                    car={car}
                                    lat={car.location?.latitude}
                                    lng={car.location?.longitude}
                                    onClick={() => handleStartBooking(car)}
                                    onMouseLeave={() => handleCarHoverMapExit()}
                                />
                            } else {
                                // Otherwise show marker
                                return <Marker
                                    onClick={() => onMarkerClick(car)}
                                    type={car.vehicleType || VehicleType.CAR}
                                    onMouseEnter={() => handleCarHoverMapEnter(car)}
                                    onMouseLeave={() => handleCarHoverMapExit()}
                                    key={car.id}
                                    text={car.model || ''}
                                    lat={car.location?.latitude}
                                    lng={car.location?.longitude}
                                    selected={car === hoveredCarList}
                                />
                            }
                        })
                    }

                </GoogleMap>

                {
                    showHorizontalList ?
                        <FlexView className={classes.horizontalListHolder}>
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                selectedItem={getSelectedCarIndex()}
                                onChange={handleCarouselChange}
                                className={classes.listInner}>
                                {renderCars()}
                            </Carousel>
                        </FlexView> : null
                }

            </FlexView>
        )
    };

    const listHolderStyle: any = {};
    if (!showMap) {
        listHolderStyle.width = '100vw';
    }

    const shouldShowLoaders = props.loading || props.signInProgress || ipLocationStatus === 'loading';

    const renderVehicleTypePicker = () => {
        return (
            <FlexView vAlignContent={'center'} hAlignContent={'center'} className={classes.vehicleTypePicker}>

                <FlexView
                    className={clsx(classes.vehicleTypeOption, !filterByVehicleType && classes.selected)}
                    vAlignContent={'center'}
                    onClick={() => setFilterByVehicleType(undefined)}
                >
                    <div className={classes.vehicleTypeOptionName}>{t('All vehicles')}</div>
                </FlexView>

                <div className={classes.vehicleTypeDivider}/>

                <FlexView
                    className={clsx(classes.vehicleTypeOption, classes.car, filterByVehicleType === VehicleType.CAR && classes.selectedCar)}
                    vAlignContent={'center'}
                    onClick={() => setFilterByVehicleType(VehicleType.CAR)}
                >
                    <img className={classes.vehicleTypeIcon}
                         src={filterByVehicleType === VehicleType.CAR ? VehicleTypeCarWhite : VehicleTypeCarGreen}/>
                    <div className={classes.vehicleTypeOptionSubcategoryName}>{t('Cars')}</div>
                </FlexView>

                <FlexView
                    className={clsx(classes.vehicleTypeOption, classes.scooter, filterByVehicleType === VehicleType.SCOOTER && classes.selectedScooter)}
                    vAlignContent={'center'}
                    onClick={() => setFilterByVehicleType(VehicleType.SCOOTER)}>
                    <img className={classes.vehicleTypeIcon}
                         src={filterByVehicleType === VehicleType.SCOOTER ? VehicleTypeScooterWhite : VehicleTypeScooterOrange}/>
                    <div className={classes.vehicleTypeOptionSubcategoryName}>{t('Scooters')}</div>
                </FlexView>

                <FlexView
                    className={clsx(classes.vehicleTypeOption, classes.bicycle, filterByVehicleType === VehicleType.BICYCLE && classes.selectedBicycle)}
                    vAlignContent={'center'}
                    onClick={() => setFilterByVehicleType(VehicleType.BICYCLE)}>
                    <img className={classes.vehicleTypeIcon}
                         src={filterByVehicleType === VehicleType.BICYCLE ? VehicleTypeBikeWhite : VehicleTypeBikeBlue}/>
                    <div className={classes.vehicleTypeOptionSubcategoryName}>{t('Bikes')}</div>
                </FlexView>

            </FlexView>
        )
    }

    return (
        <FlexView column>

            {/* Header */}
            <FlexView className={classes.options} vAlignContent='center'>

                {!isPhone() && renderVehicleTypePicker()}

                <div className={classes.carsFoundTitle}>
                    {
                        shouldShowLoaders ?
                            t('Looking for vehicles...', {number: getCarsSortedByUserDistance().length}) :
                            t('Vehicles found', {number: getCarsSortedByUserDistance().length})
                    }
                </div>

                <FlexView
                    vAlignContent='center'>
                    <div>{t('Show Map')}</div>
                    <Switch
                        checked={showMap}
                        onChange={handleMapShowChange}
                    />
                </FlexView>

            </FlexView>

            {/* List and map */}
            <FlexView>

                {
                    showVerticalList &&
                    <FlexView className={classes.listHolder} style={listHolderStyle} column>

                        {isPhone() && renderVehicleTypePicker()}

                        <FlexView wrap
                                  className={classes.listInner}
                                  style={{top: isPhone() ? '40px' : 0}}
                                  vAlignContent='top'
                        >
                            <LoggedInAs/>
                            {shouldShowLoaders ? renderCarLoaders() : renderCars()}
                        </FlexView>
                    </FlexView>

                }

                {renderMap()}

            </FlexView>

            {/*<GeofenceWarningDialog*/}
            {/*    open={!!geofenceWarningCar}*/}
            {/*    onClose={() => setGeofenceWarningCar(undefined)}*/}
            {/*    onConfirm={() => {*/}
            {/*        props.history.push(`/car/${encodeURI((geofenceWarningCar?.make + '-' + geofenceWarningCar?.model).replace(' ', '-'))}/${geofenceWarningCar?.id}`);*/}
            {/*    }}*/}
            {/*/>*/}

        </FlexView>
    )
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        vehicles: state.listing.vehicles,
        loading: state.listing.loading,
        error: state.listing.error,

        signInProgress: state.auth.signInProgress,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initLoadListing,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
