import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {Maintenance} from "../../../../models/Maintenance.model";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GcpButton from "../../../../common/ui/GcpButton";
import {useTranslation} from "react-i18next";

interface Props {
    maintenance?: Maintenance,
    open: boolean,
    onClose: () => void,
    onRemove: () => void,
    onSave: (maintenance: Maintenance) => void
}

const MaintenanceDialog = (props: Props) => {

    const t = useTranslation().t
    const [maintenance, setMaintenance] = useState<Maintenance>({})

    useEffect(() => {
        setMaintenance(props.maintenance || {})
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >

            <DialogTitle>Maintenance period</DialogTitle>

            <Box display={'flex'} flexDirection={'column'} style={{padding: 30}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <DateTimePicker
                        style={{marginBottom: 20}}
                        value={maintenance.from || new Date()}
                        label={t("From")}
                        onChange={date => setMaintenance({...maintenance, from: date || undefined})}
                    />

                    <DateTimePicker
                        value={maintenance.to || new Date()}
                        label={t("To")}
                        onChange={date => setMaintenance({...maintenance, to: date || undefined})}
                    />

                </MuiPickersUtilsProvider>

            </Box>


            <DialogActions>

                {
                    maintenance?.id &&
                    <GcpButton
                        color="error"
                        variant={'contained'}
                        onClick={props.onRemove}
                        style={{marginRight: 20}}
                    >
                        {t("Remove")}
                    </GcpButton>
                }

                <Button
                    color="primary"
                    variant={'contained'}
                    autoFocus
                    onClick={() => props.onSave(maintenance!)}
                >
                    {t('Save')}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default MaintenanceDialog

