import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import classesCommon from '../../AdminEntityCommon.module.scss';
import classes from './AdminBooking.module.scss';
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import {Button, CircularProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import {format, isAfter, subMinutes} from "date-fns";
import {CarManualActions} from "../../common/CarManulActions/CarManualActions.component";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Booking} from "../../../../models/Booking.model";
import {AuthClaims} from "../../../../models/AuthClaims.model";
import AdminBookingService from "../../../../services/adminBooking.service";
import {setAuthorizationDone, setLoggedInAsAnotherUser} from '../../../../store/actions/auth.actions';
import AuthServiceNew from "../../../../services/auth.service";
import GcpError from "../../../../models/error.enum";
import BookingService from "../../../../services/booking.service";
import OverrideBookingTimeDialog from "../OverrideBookingTimeDialog/OverrideBookingTimeDialog.component";
import {isCurrentUserSuperAdmin} from "../../../../common/user.util";
import {useTranslation} from "react-i18next";

interface Params {
    id: string;
}

interface Props extends RouteComponentProps<Params> {
    claims?: AuthClaims;
    setLoggedInAsAnotherUser: typeof setLoggedInAsAnotherUser;
    setAuthorizationDone: typeof setAuthorizationDone;
}

const AdminBooking = (props: Props) => {

    const t = useTranslation().t
    const [error, setError] = useState('');
    // const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState<Booking>();
    const [forceEndStatus, setForceEndStatus] = useState<'ending' | 'error' | ''>('');
    const [overrideBookingTimesVisible, setOverrideBookingTimesVisible] = useState(false);

    useEffect(() => {
        loadBooking(+props.match.params.id);
    }, [props.claims]);

    const loadBooking = async (id: number) => {
        try {
            // setLoading(true);
            const booking = await AdminBookingService.getBooking(id);
            setBooking(booking);
        } catch (e: any) {
            console.error(e);
            setError(e.message);
        } finally {
            // setLoading(false);
        }
    }

    const handleForceEnd = async () => {
        setForceEndStatus('ending');
        try {
            const response = await BookingService.endSession(booking?.id, {force: true});
            if (response?.status === 'sessionEnded') {
                loadBooking(booking?.id!);
                setForceEndStatus('');
            } else {
                setForceEndStatus('error');
            }
        } catch (e: any) {
            setForceEndStatus('error');
        }
    }

    const renderForceEndAction = () => {
        if (forceEndStatus === 'ending') {
            return <CircularProgress/>
        }

        if (booking?.status === 'active' && booking.from && isAfter(new Date(), subMinutes(booking.from, 5))) {
            return (
                <Button
                    color="primary"
                    variant='contained'
                    className={classes.actionButton}
                    onClick={handleForceEnd}

                >
                    {t('Force session end')}
                </Button>
            )
        }

        return null;
    }

    return (
        <FlexView hAlignContent='center'>

            <FlexView column className={classesCommon.component} hAlignContent='center'>

                <FlexView column hAlignContent='center' className={classes.sectionHolder}>
                    <div className={classesCommon.sectionTitle}>{t('Booking info')}</div>

                    <FlexView wrap className={clsx('container', classes.container)} hAlignContent='left'>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('From')}</div>
                            <div className={classes.value}>
                                {booking?.from && format(booking.from, 'yyyy/MM/dd HH:mm')}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('To')}</div>
                            <div className={classes.value}>
                                {booking?.to && format(booking.to, 'yyyy/MM/dd HH:mm')}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Session ended at')}</div>
                            <div className={classes.value}>
                                {booking?.sessionEndedAt && format(booking.sessionEndedAt, 'yyyy/MM/dd HH:mm')}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Status')}</div>
                            <div className={classes.value}>
                                {booking?.status || '-'}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Interacted at least once')}</div>
                            <div className={classes.value}>
                                {booking?.carInteractedAtLeastOnce ? 'yes' : 'no'}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Initial mileage')}</div>
                            <div className={classes.value}>
                                {booking?.initialMileage || '-'}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('End mileage')}</div>
                            <div className={classes.value}>
                                {booking?.endMileage || '-'}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Is no show')}</div>
                            <div className={classes.value}>
                                {booking?.isNoShow ? 'yes' : 'no'}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Price per minute')}</div>
                            <div className={classes.value}>
                                {booking?.pricePreMinute}kr
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Daily max')}</div>
                            <div className={classes.value}>
                                {booking?.dailyMax}kr
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Booked as premium')}</div>
                            <div className={classes.value}>
                                {booking?.premium ? 'yes': 'no'}
                            </div>
                        </FlexView>

                    </FlexView>

                    {
                        isCurrentUserSuperAdmin() &&
                        <FlexView column hAlignContent='center' className={classes.sectionHolder}>
                            <div className={classesCommon.sectionTitle}>{t('Booking admin actions')}</div>

                            <FlexView wrap className={clsx('container', classes.container)} hAlignContent='center'>

                                <Button color="primary" variant='contained' className={classes.actionButton}
                                        onClick={async () => {
                                            const loggedInUser = await AuthServiceNew.loginAsAnotherUser(booking?.user!)
                                            if (loggedInUser) {
                                                props.setAuthorizationDone(loggedInUser, GcpError.NONE);
                                            }
                                            props.setLoggedInAsAnotherUser(true);
                                            props.history.push(`/my-bookings/q/${booking?.vehicle?.id}/${booking?.id}`)
                                        }}>
                                    {t('Manage booking as user')}
                                </Button>

                                {
                                    booking?.status !== 'sessionEnded' &&
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        className={classes.actionButton}
                                        onClick={() => setOverrideBookingTimesVisible(true)}
                                    >
                                        {t('Override booking times')}
                                    </Button>
                                }

                                {renderForceEndAction()}

                            </FlexView>
                        </FlexView>

                    }

                </FlexView>

                {
                    isCurrentUserSuperAdmin() &&
                    <FlexView column hAlignContent='center' className={classes.sectionHolder}>
                        <div className={classesCommon.sectionTitle}>{t('User')}</div>

                        <FlexView wrap className={clsx('container', classes.container)} hAlignContent='left'>

                            <FlexView column className={classes.bookingInfoSection}>
                                <div className={classes.label}>Id</div>
                                <div className={classes.value}>{booking?.user?.id}</div>
                            </FlexView>

                            <FlexView column className={classes.bookingInfoSection}>
                                <div className={classes.label}>{t('Name')}</div>
                                <div className={classes.value}>{booking?.user?.name}</div>
                            </FlexView>

                            <FlexView column className={classes.bookingInfoSection}>
                                <div className={classes.label}>{t('Email')}</div>
                                <div className={classes.value}>{booking?.user?.email}</div>
                            </FlexView>

                            <FlexView column className={classes.bookingInfoSection}>
                                <div className={classes.label}>{t('Phone')}</div>
                                <div className={classes.value}>{booking?.user?.phone}</div>
                            </FlexView>

                        </FlexView>

                        <Button color="primary" className={classes.actionButton}
                                onClick={() => props.history.push(`/admin/user/${booking?.user?.id}`)}>
                            {t('See profile')}
                        </Button>

                    </FlexView>
                }

                <FlexView column hAlignContent='center' className={classes.sectionHolder}>
                    <div className={classesCommon.sectionTitle}>{t('Vehicle')}</div>

                    <FlexView wrap className={clsx('container', classes.container)} hAlignContent='left'>
                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>Id</div>
                            <div className={classes.value}>{booking?.vehicle?.id}</div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('Model')}</div>
                            <div className={classes.value}>
                                {booking?.vehicle?.make} {booking?.vehicle?.model}
                            </div>
                        </FlexView>

                        <FlexView column className={classes.bookingInfoSection}>
                            <div className={classes.label}>{t('License')}</div>
                            <div className={classes.value}>{booking?.vehicle?.license}</div>
                        </FlexView>
                        {/*<div className={classes.label}>Price (per min)</div>*/}
                        {/*<div className={classes.value}>{car.price}</div>*/}

                    </FlexView>

                    <Button color="primary" className={classes.actionButton}
                            onClick={() => props.history.push(`/admin/cars/${booking?.vehicle?.id}/basic`)}>
                        {t('See vehicle details')}
                    </Button>

                </FlexView>

                <FlexView column hAlignContent='center' className={classes.sectionHolder}>
                    <div className={classesCommon.sectionTitle}>{t('Misc')}</div>

                    <FlexView wrap className={clsx('container', classes.container)} hAlignContent='center'>

                        <CarManualActions vehicle={booking?.vehicle}/>

                    </FlexView>
                </FlexView>

                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    open={!!error}
                    autoHideDuration={60000}
                    onClose={() => setError('')}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={error}
                    />
                </Snackbar>

                <OverrideBookingTimeDialog
                    booking={booking}
                    open={overrideBookingTimesVisible}
                    onClose={() => setOverrideBookingTimesVisible(false)}
                    onUpdated={booking => {
                        setBooking(booking);
                        setOverrideBookingTimesVisible(false);
                    }}
                />

            </FlexView>

        </FlexView>
    )
}


const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setLoggedInAsAnotherUser,
        setAuthorizationDone
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBooking);
