import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import clsx from 'clsx';
import classes from './AuthorizePayment.module.scss';
import {RouteComponentProps} from "react-router";
import {Button, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../../services/googleAnalytics";

interface Params {
    invoiceId: string;
    clientSecret: string;
    paymentMethodId: string;
}

interface Props extends RouteComponentProps<Params> {
}

const AuthorizePayment = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [tryNumber, setTryNumber] = useState(1);
    const [authorizationStatus, setAuthorizationStatus] = useState('');
    const [authorizationInProgress, setAuthorizationInProgress] = useState(false);

    // ***** Effects ***** //

    useEffect(() => {
        trackCurrentPage()
    }, [])

    useEffect(() => {
        if (window['Stripe']) {
            const stripe = window['Stripe'](process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
            authorizeViaStripe(stripe);
        } else {
            document.querySelector('#stripe-js')?.addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                const stripe = window['Stripe'](process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
                authorizeViaStripe(stripe);
            });
        }
    }, [tryNumber]);

    // ***** Handlers ***** //

    const authorizeViaStripe = (stripe: any) => {
        setAuthorizationInProgress(true);

        // const invoiceId = props.match.params.invoiceId;
        const clientSecret = props.match.params.clientSecret;
        const paymentMethodId = props.match.params.paymentMethodId

        stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId
        }).then(function (result: any) {
            setAuthorizationInProgress(false);

            if (result.error) {
                setAuthorizationStatus('error');
                console.log(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setAuthorizationStatus('success');
                }
            }
        });
    };

    const retryAuthorization = () => {
        setTryNumber(tryNumber + 1);
    };

    const goToProfile = () => {
        props.history.push('/profile');
    };

    const goToLandingPage = () => {
        props.history.push('/');
    };

    // ***** Render ***** //

    return (
        <FlexView className={clsx('component', classes.component)} hAlignContent='center' column>
            <div className={classes.title}>{t('Payment validation')}</div>

            {
                authorizationInProgress && <CircularProgress/>
            }

            {
                !authorizationInProgress && authorizationStatus === 'error' &&
                <React.Fragment>
                    <div
                        className={classes.authorizationErrorMessage}>{t('Authorization failed, please try again.')}</div>
                    <Button variant="contained" color="primary" className={classes.submitButton}
                            onClick={retryAuthorization}>
                        {t('Try Again')}
                    </Button>
                </React.Fragment>
            }


            {
                !authorizationInProgress && authorizationStatus === 'error' &&
                <React.Fragment>
                    <div
                        className={classes.updatePaymentsMessage}>{t('If you are unable to authorize payment, please update payment methods')}</div>
                    <Button variant="contained" color="primary" className={classes.submitButton}
                            onClick={goToProfile}>
                        {t('Update payment methods')}
                    </Button>
                </React.Fragment>
            }

            {
                !authorizationInProgress && authorizationStatus === 'success' &&
                <React.Fragment>
                    <div
                        className={classes.updatePaymentsMessage}>{t('Success!')}</div>
                    <Button variant="contained" color="primary" className={classes.submitButton}
                            onClick={goToLandingPage}>
                        {t('Go to Landing page')}
                    </Button>
                </React.Fragment>
            }

        </FlexView>
    )
};

export default AuthorizePayment;
