import React, {useEffect, useState} from 'react';
import {isFormValid, requiredValidator, validateForm} from "../../../../common/validators.util";
import Dropzone from "../../../../common/ui/Dropzone/Dropzone.component";
import FlexView from "react-flexview";
import {Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import GcpInput from "../../../../common/ui/GcpInput";
import classesCommon from '../../AdminEntityCommon.module.scss';
import clsx from 'clsx';
import classes from './AdminCarBasicForm.module.scss';
import {RouteComponentProps, withRouter} from "react-router";
import {v4 as uuidv4} from 'uuid';
import {myFirebase} from '../../../../services/firebase';
import {Organization} from "../../../../models/Organization.model";
import {Vehicle, VehicleType} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {AuthClaims} from "../../../../models/AuthClaims.model";
import AdminOrganizationService from "../../../../services/adminOrganization.service";
import {isCurrentUserSuperAdmin} from "../../../../common/user.util";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    vehicle?: Vehicle,
    onUpdate: (vehicle: Vehicle) => void;
    claims?: AuthClaims;
}

const AdminCarBasicForm = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [type, setType] = useState<VehicleType | undefined>();

    const isCar = type === VehicleType.CAR;
    // const isScooter = type === VehicleType.SCOOTER;
    // const isBicycle = type === VehicleType.BICYCLE;

    const [form, setForm] = useState<any>();

    const [savingInProgress, setSavingInProgress] = useState(false);
    const [status, setStatus] = useState('');
    const [image, setImage] = useState<any>();
    const [localImage, setLocalImage] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [organizations, setOrganizations] = useState<(undefined | Organization)[]>([]);

    // ***** Effects ***** //

    const createFormField = (field: 'model' | 'make' | 'address' | 'latitude' | 'longitude' | 'range' | 'license' | 'seats' | 'bodyType' | 'type' | 'partner', value: any) => {
        switch (field) {
            case "model":
                return {
                    label: t('Model'),
                    placeholder: 'ex. i3',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "make":
                return {
                    label: t('Brand'),
                    placeholder: 'ex. Tesla',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "address":
                return {
                    label: t('Address'),
                    placeholder: 'ex. Street A 24, City',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            // (value) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "latitude":
                return {
                    label: t('Latitude'),
                    placeholder: 'ex. 44.1241421',
                    type: 'number',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "longitude":
                return {
                    label: t('Longitude'),
                    placeholder: 'ex. 44.1241421',
                    type: 'number',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "range":
                return {
                    label: t('Range'),
                    placeholder: 'ex. 240',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "license":
                return {
                    label: t('License plate'),
                    placeholder: 'ex. SK-124-22',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => isCar && requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "seats":
                return {
                    label: t('Seats'),
                    placeholder: 'ex. 5',
                    type: 'text',
                    value: value,
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => isCar && requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "bodyType":
                return {
                    label: t('Body type'),
                    placeholder: 'ex. sedan',
                    type: 'select',
                    value: value || 'sedan',
                    options: [
                        {value: 'sedan', text: 'Sedan'},
                        {value: 'hatchback', text: 'Hatchback'},
                    ],
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => isCar && requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "type":
                return {
                    label: t('Type'),
                    placeholder: 'ex. i3',
                    type: 'select',
                    value: value || 'fully_electric',
                    options: [
                        {value: 'fully_electric', text: 'Fully Electric'},
                        {value: 'hybrid', text: 'Hybrid'},
                    ],
                    validation: {
                        error: '',
                        validators: [
                            (value: string) => requiredValidator(value, t('This is a required field')),
                        ]
                    }
                }
            case "partner":
                return {
                    label: t('Organization'),
                    placeholder: 'Organization name',
                    type: 'select',
                    value: value,
                    options: [],
                    validation: {
                        error: '',
                        validators: []
                    }
                }
        }
    }

    const fetchData = async () => {
        let organizations: (Organization | undefined)[] = [undefined]
        if (isCurrentUserSuperAdmin()) {
            const response: Organization[] = (await AdminOrganizationService.getAll(0, 1000)).content;
            organizations = [undefined, ...response];
            setOrganizations(organizations);
        }

        if (!props.vehicle) return;

        // if (props.carId === 'new') return;

        const vehicleType = props.vehicle.vehicleType;
        setType(vehicleType);

        const newForm: any = {};
        newForm.model = createFormField('model', props.vehicle.model);
        newForm.make = createFormField('make', props.vehicle.make);
        newForm.address = createFormField('address', props.vehicle.address);
        newForm.latitude = createFormField('latitude', props.vehicle.location?.latitude);
        newForm.longitude = createFormField('longitude', props.vehicle.location?.longitude);
        newForm.range = createFormField('range', props.vehicle.range);

        if (vehicleType === VehicleType.CAR) {
            newForm.license = createFormField('license', props.vehicle.license);
            newForm.seats = createFormField('seats', props.vehicle.seats);
            newForm.bodyType = createFormField('bodyType', props.vehicle.bodyType);
            newForm.type = createFormField('type', props.vehicle.type);
        }

        if (isCurrentUserSuperAdmin()) {
            newForm.partner = {
                ...createFormField('partner', props.vehicle.organization?.id),
                options: organizations.map(o => {
                    return {
                        value: o?.id,
                        text: o?.name || 'No partner'
                    }
                })
            }
        }

        setForm(newForm);

        setImage(props.vehicle.image);
    };

    useEffect(() => {
        fetchData();
    }, [props.vehicle]);

    // ***** Handlers ***** //

    const setNewVehicleType = (vehicleType: VehicleType) => {
        setType(vehicleType);

        const newForm: any = {};
        newForm.model = createFormField('model', '');
        newForm.make = createFormField('make', '');
        newForm.address = createFormField('address', '');
        newForm.latitude = createFormField('latitude', '');
        newForm.longitude = createFormField('longitude', '');
        newForm.range = createFormField('range', '');

        if (vehicleType === 'car') {
            newForm.license = createFormField('license', '');
            newForm.seats = createFormField('seats', '');
            newForm.bodyType = createFormField('bodyType', '');
            newForm.type = createFormField('type', '');
        }

        newForm.partner = {
            ...createFormField('partner', props.claims?.organization?.id),
            options: organizations.map(o => {
                return {
                    value: o?.id,
                    text: o?.name || 'No partner'
                }
            })
        }

        setForm(newForm);
    }

    const inputHandler = (fieldId: string, value: any) => {
        const newFormField = {
            ...form[fieldId],
            value: value,
            validation: {
                ...form[fieldId].validation,
                error: ''
            }
        };
        const newForm = {
            ...form,
            [fieldId]: newFormField
        };

        setForm(newForm);
    };

    const submitHandler = async () => {
        const validatedForm = validateForm(form);
        setForm(validatedForm);
        setImageError(!image);

        if (isFormValid(validatedForm) && image) {
            await saveCar(validatedForm);
        }
    };

    const handleChange = (files: any) => {
        setImage(files[0]);
        setImageError(false);
        setLocalImage(true);
    };

    const saveCar = async (form: any) => {
        setSavingInProgress(true);
        try {
            let tmpImage = image;
            if (localImage) {
                const uuid = uuidv4();
                const uploadRef = myFirebase.storage().ref(`${uuid}-${image.name}`);
                await uploadRef.put(image);
                const downloadUrl = await uploadRef.getDownloadURL();
                tmpImage = downloadUrl;
            }

            let vehicle: Vehicle = {
                ...props.vehicle,
                vehicleType: type,
                model: form.model?.value,
                make: form.make?.value,
                address: form.address?.value,
                location: {
                    latitude: +form.latitude.value,
                    longitude: +form.longitude.value
                },
                range: form.range?.value,
                image: tmpImage,
                organization: form.partner.value ? organizations.find(o => o?.id === form.partner.value) : undefined,
            };

            if (type === 'car') {
                vehicle = {
                    ...vehicle,
                    license: form.license?.value,
                    seats: form.seats?.value,
                    bodyType: form.bodyType?.value,
                    type: form.type?.value,
                }
            }

            if (!props.vehicle) {
                vehicle = {
                    ...vehicle,
                    hardwareProvider: 'demo',
                    hardwareInfo: {
                        location: {
                            latitude: +form.latitude.value,
                            longitude: +form.longitude.value
                        },
                        fuelLevel: 100,
                        batteryCharging: true,
                        phone: '+123'
                    }
                }
            }

            if (!props.vehicle) {
                const addedVehicle = await AdminVehicleService.add(vehicle);
                // props.onUpdate(addedVehicle);
                props.history.replace(`/admin/vehicle/${addedVehicle.id}/basic`);
            } else {
                const updatedVehicle = await AdminVehicleService.update(vehicle);
                props.onUpdate(updatedVehicle);
            }

            setStatus('Saved!');
            setLocalImage(false);
            setImage(tmpImage);
        } catch (e: any) {
            setStatus('Error while saving! ' + e.message);
            console.log(e);
        } finally {
            setSavingInProgress(false);
        }
    };

    const removePhoto = () => {
        setImage(null);
    };

    // ***** Render ***** //

    const renderDropzone = () => {
        return (
            <Dropzone className={clsx(classesCommon.dropzone, imageError && classes.imageError)}
                      onFilesAdded={handleChange}/>
        )
    };

    const renderImage = () => {
        return (
            <FlexView column className={classesCommon.imagePreviewContainer} vAlignContent='center'
                      hAlignContent='center'>
                <img className={clsx('container', classesCommon.image)}
                     src={localImage ? URL.createObjectURL(image) : image}/>
                {/*<div>{this.state.licenceImage.name} </div>*/}
                <Button className={classesCommon.removePhoto} color="primary" onClick={removePhoto}>{t('Change')}</Button>
            </FlexView>
        )
    };

    const renderForm = () => {
        if (!form) return null;

        let keys = Object.keys(form);
        if (!isCurrentUserSuperAdmin()) {
            keys = keys.filter(k => k !== 'partner');
        }

        return keys.map(key => {
            const formElement = form[key];
            switch (formElement.type) {
                case 'select':
                    return (
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classesCommon.formElement}
                                         margin="normal"
                            >
                                <InputLabel htmlFor="key">{formElement.label}</InputLabel>
                                <Select
                                    value={formElement.value}
                                    onChange={(event) => inputHandler(key, event.target.value)}
                                    inputProps={{
                                        name: key,
                                        id: key,
                                    }}
                                >
                                    {
                                        formElement.options.map((option: any) => <MenuItem key={option.value}
                                                                                           value={option.value}>{option.text}</MenuItem>)
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                    );
                default:
                    return (
                        <Grid item xs={12} sm={6} md={4}>
                            <GcpInput
                                error={!!formElement.validation.error}
                                key={key}
                                className={classesCommon.formElement}
                                label={formElement.label}
                                placeholder={formElement.placeholder}
                                margin="normal"
                                type={formElement.type}
                                // variant="outlined"
                                disabled={savingInProgress}
                                value={formElement.value}
                                helperText={formElement.validation.error}
                                onChange={(event) => inputHandler(key, event.target.value)}
                            />
                        </Grid>
                    )
            }
        });
    };

    const renderTypePicker = () => {
        if (type) return null;

        return (
            <FlexView>
                <FlexView onClick={() => setNewVehicleType(VehicleType.CAR)} className={classes.typePicker}
                          hAlignContent={'center'}
                          vAlignContent={'center'}>
                    <div>Car</div>
                </FlexView>
                <FlexView onClick={() => setNewVehicleType(VehicleType.SCOOTER)} className={classes.typePicker}
                          hAlignContent={'center'}
                          vAlignContent={'center'}>
                    <div>Scooter</div>
                </FlexView>
                <FlexView onClick={() => setNewVehicleType(VehicleType.BICYCLE)} className={classes.typePicker}
                          hAlignContent={'center'}
                          vAlignContent={'center'}>
                    <div>Bicycle</div>
                </FlexView>
            </FlexView>
        )
    }

    const renderContent = () => {
        if (!type) return null;

        return (
            <Box>

                {image ? renderImage() : renderDropzone()}

                <Grid container spacing={4}>
                    {renderForm()}
                </Grid>

                <Box m={3}/>

                {
                    savingInProgress ? <CircularProgress className={classesCommon.submitButton}/> :
                        <Button variant="contained" color="primary"
                                className={classesCommon.submitButton}
                                onClick={submitHandler}>
                            {t('Save')}
                        </Button>
                }

                <div className={classes.status}>{status}</div>

            </Box>
        )
    }

    return (
        <Card>
            <Box p={2}>
                {renderTypePicker()}
                {renderContent()}
            </Box>
        </Card>
    );

};

export default withRouter(AdminCarBasicForm);
