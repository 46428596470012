import React from 'react';
import Chart from "chart.js";
// @ts-ignore
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Props {
    data: {
        label: string,
        value: any
    }[],
    colors: string[]
    cutoutPercentage?: number
}

class DoughnutChart extends React.Component<Props> {

    chartRef: any;
    myChart: any;

    constructor(props: Props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate() {
        this.myChart.data.labels = this.getLabels();
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.data.datasets[0].backgroundColor = this.props.colors;
        this.myChart.options.cutoutPercentage = this.props.cutoutPercentage || 90
        this.myChart.update();
    }

    getPadding() {
        return window.innerWidth < 700 ? 5 : 50
    }

    getLabels() {
        if (window.innerWidth < 700) {
            return this.props.data.map(_ => '')
        } else {
            return this.props.data.map(d => d.label)
        }
    }

    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'doughnut',
            plugins: [ChartDataLabels],
            data: {
                labels: this.getLabels(),
                datasets: [{
                    data: this.props.data.map(d => d.value),
                    backgroundColor: this.props.colors,
                    datalabels: {
                        align: 'end',
                        offset: 10
                    }
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: this.getPadding(),
                        right: this.getPadding(),
                        top: this.getPadding(),
                        bottom: this.getPadding()
                    }
                },
                cutoutPercentage: this.props.cutoutPercentage || 90,
                plugins: {
                    datalabels: {
                        color: function(context) {
                            return context.dataset.backgroundColor as string;
                        },
                        font: {
                            size: 10
                        },
                        formatter: function(_value: any, context: any) {
                            return context.chart.data.labels[context.dataIndex];
                        }
                    }
                }
            }
        });
    }

    render() {
        return <canvas width={'100%'} height={'100%'} ref={this.chartRef}/>;
    }
}

export default DoughnutChart