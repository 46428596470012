import i18next from "i18next";
// import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translations_en from '../assets/translations/en.json';
import translations_sv from '../assets/translations/sv.json';

export const initLocalization = async () => {
    return i18next
        // .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: "en",
            debug: false,

            // have a common namespace used around the full app
            ns: ["common"],
            defaultNS: "common",

            keySeparator: false, // we use content as keys

            interpolation: {
                escapeValue: false
            },
            resources: {
                en: {
                    common: translations_en
                },
                sv: {
                    common: translations_sv
                }
            },
        })
};

export default initLocalization;