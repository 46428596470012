import React, {useState} from 'react';
import FlexView from "react-flexview/lib/FlexView";
import classes from './BookingPreview.module.scss';
import clsx from 'clsx';
import {RouteComponentProps, withRouter} from "react-router";
import {Button, CircularProgress} from "@material-ui/core";
import {calculateBookingPrice, calculateBookingPriceFormattedWeb, formatDateFull} from "../../../common/booking.util";
import {connect} from "react-redux";
import {isAfter, isBefore, subMinutes} from "date-fns";
import AlertDialog from "../../../common/ui/AlertDialog.component";
import {Booking, BookingStatus} from "../../../models/Booking.model";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";
import {InvoiceState} from "../../../models/Invoice.model";

interface Props extends RouteComponentProps {
    booking?: Booking,
    claims?: AuthClaims,
    onCancel?: () => void
}

const BookingPreview = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    // const [downloading, setDownloading] = useState();
    const [cancelling, setCancelling] = useState(false);
    const [alertDialogVisible, setAlertDialogVisible] = useState(false);

    const user = props.claims?.user;

    const modelMake = encodeURI(
        `${props.booking?.vehicle?.make}-${props.booking?.vehicle?.model}`.replace(' ', '-')
    );

    const upcoming = props.booking?.status === 'active' && props.booking?.from && isBefore(new Date(), subMinutes(props.booking.from, 5));
    const active = props.booking?.status === 'active' && props.booking?.from && isAfter(new Date(), subMinutes(props.booking.from, 5));

    // ***** Handler ***** //

    const cancelBooking = async () => {
        setAlertDialogVisible(false);
        try {
            setCancelling(true);
            props.onCancel && (await props.onCancel());
        } catch (e: any) {
            console.log("Error while cancelling...", e);
        } finally {
            setCancelling(true);
        }
    };

    // ***** Render ***** //

    // let invoice = null;
    // if (downloading) {
    //     invoice = (
    //         <React.Fragment>
    //             <CircularProgress size={20} style={{marginBottom: '5px'}}/>
    //             <div>{t('Invoice')}</div>
    //         </React.Fragment>
    //     );
    // } else if (props.booking.status === 'sessionEnded') {
    //     invoice = (
    //         <React.Fragment>
    //             <img src={Download} onClick={downloadInvoiceHandler}/>
    //             <div>{t('Invoice')}</div>
    //         </React.Fragment>
    //     );
    // }

    const header = (
        <FlexView className={classes.header} vAlignContent={'center'}>
            <img className={classes.image} src={props.booking?.vehicle?.image}/>

            <FlexView column className={classes.title}>
                {
                    !!props.booking?.vehicle?.address &&
                    <div className={classes.titleLarge}>{props.booking?.vehicle?.address}</div>
                }
                <div className={props.booking?.vehicle?.address ? classes.titleSmall : classes.titleLarge}>
                    {props.booking?.vehicle?.make} {props.booking?.vehicle?.model}
                </div>
            </FlexView>

            {
                upcoming && !cancelling &&
                <Button variant="contained" color="primary" className={classes.book}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setAlertDialogVisible(true);
                        }}
                >
                    {t('Cancel upcoming booking')}
                </Button>
            }

            {cancelling && <CircularProgress/>}

            {
                active &&
                <div
                >
                    {t('Active')}
                </div>
            }

            {/*<FlexView column hAlignContent='center' className={classes.action}>*/}
            {/*<img src={Info}/>*/}
            {/*<div>{t('Details')}</div>*/}
            {/*</FlexView>*/}

            {/*{*/}
            {/*    props.booking.status === 'sessionEnded' &&*/}
            {/*    <FlexView*/}
            {/*        column*/}
            {/*        hAlignContent='center'*/}
            {/*        vAlignContent='center'*/}
            {/*        className={classes.action}*/}
            {/*    >*/}
            {/*        {invoice}*/}
            {/*    </FlexView>*/}
            {/*}*/}

        </FlexView>
    );

    let totalPriceText = '0'
    if (props.booking?.status === BookingStatus.ACTIVE) {
        if (calculateBookingPrice(props.booking, props.booking.vehicle)) {
            totalPriceText = calculateBookingPriceFormattedWeb(props.booking, props.booking.vehicle, user, props.claims?.subscription) + 'kr'
        } else {
            totalPriceText = t('Free')
        }
    } else if (props.booking?.status === BookingStatus.SESSION_ENDED) {
        if (props.booking.invoice?.status === InvoiceState.NOT_PAID || props.booking?.invoice?.status === InvoiceState.PAID) {
            totalPriceText = ((props.booking?.invoice?.invoiceFullData?.amount_paid || 0) / 100)?.toFixed(2) + 'kr'
        } else if (props.booking.totalPrice) {
            totalPriceText = props.booking?.totalPrice?.toFixed(2) + 'kr'
        } else {
            totalPriceText = t('Free')
        }
    }

    const bookingInfo = (
        <FlexView className={classes.footer} vAlignContent={'center'}>
            <FlexView column className={classes.dates}>
                <div>{t('From')}: {formatDateFull(props.booking?.from)}</div>
                <div>{t('To')}: {
                    formatDateFull(
                        props.booking?.status === BookingStatus.SESSION_ENDED && props.booking?.sessionEndedAt ?
                            props.booking.sessionEndedAt :
                            props.booking?.to)
                }</div>
            </FlexView>

            <div className={classes.price}>
                {totalPriceText}
            </div>

        </FlexView>
    );

    return (
        <FlexView
            key={props.booking?.id}
            column
            className={clsx('container', classes.booking, active && classes.activeBooking)}
            onClick={() => props.history.push(`/my-bookings/${modelMake}/${props.booking?.vehicle?.id}/${props.booking?.id}`)}
        >

            {header}
            {bookingInfo}

            <AlertDialog
                open={alertDialogVisible}
                onClose={(e) => {
                    e && e.stopPropagation();
                    setAlertDialogVisible(false)
                }}
                onConfirm={(e) => {
                    e && e.stopPropagation();
                    cancelBooking();
                }}
                title={t('Cancel booking?')}
                message={t('This will cancel your booking. Are you sure?')}
            />

        </FlexView>
    );
};

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims
    }
};

export default connect(mapStateToProps)(withRouter(BookingPreview));
