import React from 'react';
import FlexView from "react-flexview";
import classes from './HowItWorks.module.scss';
import Confirm from '../../../assets/icons/confirm-circle-green.svg';
import Search from '../../../assets/icons/search-green.svg';
import Drive from '../../../assets/icons/drive-green.svg';
import {useTranslation} from "react-i18next";

const HowItWorks = () => {

    const t = useTranslation().t
 
    return (
        <FlexView className={classes.component} column hAlignContent='center'>
            <div className={classes.title}>{t('How it works')}</div>

            <FlexView wrap className={classes.steps}>

                <FlexView className={classes.step} column hAlignContent='center' vAlignContent='top'>
                    <img src={Search} className={classes.icon}/>
                    <div className={classes.stepName}>{t('Become a member')}</div>
                    <div className={classes.stepDescription}>{t('Become a member details')}</div>
                </FlexView>

                <FlexView className={classes.step} column hAlignContent='center' vAlignContent='top'>
                    <img src={Confirm} className={classes.icon}/>
                    <div className={classes.stepName}>{t('Book')}</div>
                    <div className={classes.stepDescription}>{t('Book details')}</div>
                </FlexView>

                <FlexView className={classes.step} column hAlignContent='center' vAlignContent='top'>
                    <img src={Drive} className={classes.icon}/>
                    <div className={classes.stepName}>{t('Drive')}</div>
                    <div className={classes.stepDescription}>{t('Drive details')}</div>
                </FlexView>

            </FlexView>

        </FlexView>
    )
}

export default HowItWorks;