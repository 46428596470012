import React, { useEffect, useState, useCallback } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import { RouteComponentProps } from "react-router";
import { differenceInMinutes, endOfDay, format, startOfDay, subMonths } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthClaims } from "../../../models/AuthClaims.model";
import { isCurrentUserSuperAdmin } from "../../../common/user.util";
import AnalyticsReport, { VehicleStat } from "../../../models/AnalyticsReport.model";
import AdminAnalyticsService from "../../../services/adminAnalytics.service";
import { ValueByVehicleType } from "../../../models/HistoryOverview.model";
import { VehicleType } from "../../../models/Vehicle.model";
import clsx from "clsx";
import DoughnutChart from "../../../common/ui/DoughnutChart/DoughnutChart.component";
import { COLORS } from "../../../styles/theme";
import { numberFormat } from "../../../common/misc.util";
import { makeStyles } from "@material-ui/core/styles";
import HorizontalBarChart from "../../../common/ui/DoughnutChart/BarChart.component";
import { useTranslation } from "react-i18next";
import { Organization } from "../../../models/Organization.model";
import AdminOrganizationService from "../../../services/adminOrganization.service";
import * as Excel from 'exceljs';
import { connect } from "react-redux";
import { vehicleDescriptor } from "../../../common/vehicle.util";
import VerticalBarChart from "../../../common/ui/DoughnutChart/VerticalBarChart.component";
import { saveAs } from 'file-saver';

interface Props extends RouteComponentProps {
    claims?: AuthClaims;
}

function pad(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

const useStyles = makeStyles(_theme => ({
    overview: {
        marginRight: 10,
        position: 'relative',
        boxSizing: 'border-box'
    },
    chartValueHolder: {
        // maxWidth: 'calc(50vw)'
    },
    overviewChartHolder: {
        width: 250,
        height: 250,
        [_theme.breakpoints.up('xl')]: {
            width: 350,
            height: 350,
        }
    },
    totalSpendingChartHolder: {
        width: 300,
        height: 300,
        [_theme.breakpoints.up('xl')]: {
            width: 400,
            height: 400,
        }
    },
    vehicleChartHolder: {
        width: 250,
        height: 250,
        [_theme.breakpoints.up('xl')]: {
            width: 350,
            height: 350,
        }
    },
    vehicleBookingDaysChartHolder: {
        width: 400,
        height: 250,
        [_theme.breakpoints.up('xl')]: {
            width: 500,
            height: 350,
        }
    },
    chartHolder: {
        width: '100%',
        position: 'relative'
    },
    valueHolder: {
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    value: {
        fontSize: 24,
        marginBottom: 0
    },
    type: {
        fontSize: 12,
        // color: $color-primary;
    },
    vehicleStatHolder: {
        width: 'calc(100% - 100px)',
        maxWidth: 900,
        paddingTop: 50,
        paddingBottom: 50,
        border: '1px solid #cecece',
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vehicleImage: {
        width: 60,
        height: 60,
        objectFit: 'cover',
        marginRight: 20,
        borderRadius: 10
    }
}))

const AdminAnalytics = (props: Props) => {

    const t = useTranslation().t
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState<Date>(subMonths(new Date(), 1))
    const [toDate, setToDate] = useState<Date>(new Date())
    const [report, setReport] = useState<AnalyticsReport>()
    const classes = useStyles()
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [selectedOrganization, setSelectedOrganization] = useState<Organization>()

    // const [vehicleFilter, setVehicleFilter] = useState<number>();
    // const [organizationFilter, setOrganizationFilter] = useState<number>();

    const getValueForType = (arr?: ValueByVehicleType[], vehicleType?: VehicleType) => {
        return arr?.find(v => v.vehicleType === vehicleType)?.total || 0
    }

    const getSummedValue = (arr?: ValueByVehicleType[]) => {
        return arr?.reduce((prev, curr) => prev + (curr.total || 0), 0) || 0
    }

    const hasValueForType = (arr?: ValueByVehicleType[], vehicleType?: VehicleType) => {
        return !!getValueForType(arr, vehicleType)
    }

    const renderDoughnutChart = (data: any[], colors: any[], title: string, value: string, extraClass?: string, cutoutPercentage?: number) => (
        <FlexView
            column
            hAlignContent='center'
            vAlignContent='center'
            className={clsx(classes.overview, extraClass)}
        >
            <Box
                display={'flex'}
                alignItems='center'
                justifyContent='center'
                width={'100%'}
                height={'100%'}
                className={classes.chartValueHolder}
            >
                <div className={classes.chartHolder}>
                    <DoughnutChart
                        data={data}
                        colors={colors}
                        cutoutPercentage={cutoutPercentage}
                    />
                </div>

                <FlexView
                    column
                    hAlignContent='center'
                    vAlignContent='center'
                    className={classes.valueHolder}
                >
                    <div className={classes.value}>{value}</div>
                    <div className={classes.type}>{title}</div>
                </FlexView>
            </Box>
        </FlexView>
    )

    const renderHorizontalBarChart = (data: any[], colors: any[], _title: string, _value: string, extraClass?: string) => (
        <FlexView
            column
            hAlignContent='center'
            vAlignContent='center'
            className={clsx(classes.overview, extraClass)}
        >
            <div className={classes.chartHolder}>
                <HorizontalBarChart
                    data={data}
                    colors={colors}
                />
            </div>
        </FlexView>
    )

    const renderVerticalChart = (data: any[], colors: any[], _title: string, _value: string, extraClass?: string) => (
        <FlexView
            column
            hAlignContent='center'
            vAlignContent='center'
            className={clsx(classes.overview, extraClass)}
        >
            <div className={classes.chartHolder}>
                <VerticalBarChart
                    data={data}
                    colors={colors}
                />
            </div>
        </FlexView>
    )


    const renderBox = (arr: ValueByVehicleType[], formatter: (value: number) => string, title: string, colorOptions: string[], extraClasses?: any) => {
        const data = []
        const colors = []

        if (hasValueForType(arr, VehicleType.CAR)) {
            data.push({
                label: `${t('Cars')}\n${formatter(getValueForType(arr, VehicleType.CAR))}`,
                value: getValueForType(arr, VehicleType.CAR)
            })
            colors.push(colorOptions[0])
        }

        if (hasValueForType(arr, VehicleType.SCOOTER)) {
            data.push({
                label: `${t('Scooters')}\n${formatter(getValueForType(arr, VehicleType.SCOOTER))}`,
                value: getValueForType(arr, VehicleType.SCOOTER)
            })
            colors.push(colorOptions[1])
        }

        if (hasValueForType(arr, VehicleType.BICYCLE)) {
            data.push({
                label: `${t('Bikes')}\n${formatter(getValueForType(arr, VehicleType.BICYCLE))}`,
                value: getValueForType(arr, VehicleType.BICYCLE)
            })
            colors.push(colorOptions[2])
        }

        if (!data.length) {
            data.push({
                label: '',
                value: 1
            })
            colors.push('#c8c8c8')
        }

        return (
            <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {renderDoughnutChart(data, colors, title, formatter(getSummedValue(arr)), clsx(classes.overviewChartHolder, extraClasses))}
            </Box>
        )
    }

    //region Time spent

    const timeFormatter = (minutesTotal: number) => {
        const hours = Math.floor(minutesTotal / 60);
        const minutes = Math.floor(minutesTotal - hours * 60);
        return `${pad(hours, 2)}:h ${pad(minutes, 2)}:m`
    };

    const renderTimeBox = () => {
        return renderBox(
            report?.totalMinutes || [],
            timeFormatter,
            t('Rent hours'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
        )
    }

    //endregion Time spent

    //region Mileage

    const mileageFormatter = (mileageTotal: number) => {
        return numberFormat(mileageTotal, 0, '.', ' ') + 'km';
    };

    const renderMileageBox = () => {
        return renderBox(
            report?.totalMileage || [],
            mileageFormatter,
            t('Driven'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
        )
    }

    //endregion Mileage

    //region CO2 reduction

    const co2Formatter = (co2Total: number) => {
        return numberFormat(co2Total / 1000, 0, '.', ' ') + 'kg';
    };

    const renderCO2Box = () => {
        return renderBox(
            report?.totalCO2 || [],
            co2Formatter,
            t('CO2 Reduction'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
        )
    }

    //endregion CO2 reduction

    //region Total spent

    const totalSpentFormatterFormatter = (totalSpent: number) => {
        return numberFormat(totalSpent, 0, '.', ',') + ' SEK';
    };

    const renderTotalSpentBox = () => {
        return renderBox(
            report?.totalSpent || [],
            totalSpentFormatterFormatter,
            t('Gross income'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
            classes.totalSpendingChartHolder
        )
    }

    //endregion Total spent

    const fetchAnalytics = useCallback(async () => {
        if (!props.claims?.user) return;

        setLoading(true);

        try {
            const response = await AdminAnalyticsService.getAnalyticsReport(
                isCurrentUserSuperAdmin() ? selectedOrganization?.id : props.claims?.organization?.id,
                fromDate && startOfDay(fromDate) || undefined,
                toDate && endOfDay(toDate) || undefined
            );
            setReport(response)
        } catch (e: any) {
            console.log(e);
            setReport(undefined);
        } finally {
            setLoading(false);
        }
    }, [fromDate, props.claims, selectedOrganization, toDate]);

    const fetchOrganizations = async () => {
        if (!isCurrentUserSuperAdmin()) return
        const organizations = (await AdminOrganizationService.getAll(0, 1000)).content || []
        setOrganizations(organizations)
    }

    const cleanupExportNumber = (value?: number) => {
        if (!value) return 0

        return Math.round(value * 100) / 100
    }

    const exportBookingsToXlsHandler = async () => {

        let workbook = new Excel.Workbook();
        const overviewWorksheet = workbook.addWorksheet('Overview');

        const vehicleStatsColumns = [
            {header: t('Booking times 00-08 (%)'), key: 'bookingTimeMorning', width: 32},
            {header: t('Booking times 08-16 (%)'), key: 'bookingTimeDay', width: 32},
            {header: t('Booking times 16-00 (%)'), key: 'bookingTimeEvening', width: 32},
            {header: t('Total bookings'), key: 'totalBookings', width: 32},
            {header: t('Total bookings - Cars'), key: 'totalBookingsCars', width: 32},
            {header: t('Total bookings - Scooters'), key: 'totalBookingsScooters', width: 32},
            {header: t('Total bookings - E-Bikes'), key: 'totalBookingsBikes', width: 32},
            {header: t('Occupancy rate (%)'), key: 'occupancy', width: 32},
            {header: t('Weekday - Monday (%)'), key: 'monday', width: 32},
            {header: t('Weekday - Tuesday (%)'), key: 'tuesday', width: 32},
            {header: t('Weekday - Wednesday (%)'), key: 'wednesday', width: 32},
            {header: t('Weekday - Thursday (%)'), key: 'thursday', width: 32},
            {header: t('Weekday - Friday (%)'), key: 'friday', width: 32},
            {header: t('Weekday - Saturday (%)'), key: 'saturday', width: 32},
            {header: t('Weekday - Sunday (%)'), key: 'sunday', width: 32},
        ]

        overviewWorksheet.columns = [
            {header: t('Gross income total (SEK)'), key: 'grossIncomeTotal', width: 32},
            {header: t('Gross income car (SEK)'), key: 'grossIncomeCar', width: 32},
            {header: t('Gross income scooter (SEK)'), key: 'grossIncomeScooter', width: 32},
            {header: t('Gross income bike (SEK)'), key: 'grossIncomeBike', width: 32},
            {header: t('Rent hours total'), key: 'rentHoursTotal', width: 32},
            {header: t('Rent hours car'), key: 'rentHoursCar', width: 32},
            {header: t('Rent hours scooter'), key: 'rentHoursScooter', width: 32},
            {header: t('Rent hours bike'), key: 'rentHoursBike', width: 32},
            {header: t('Mileage total (km)'), key: 'mileageTotal', width: 32},
            {header: t('Mileage car (km)'), key: 'mileageCar', width: 32},
            {header: t('Mileage scooter (km)'), key: 'mileageScooter', width: 32},
            {header: t('Mileage bike (km)'), key: 'mileageBike', width: 32},
            {header: t('C02 reduction total (kg)'), key: 'co2Total', width: 32},
            {header: t('C02 reduction car (kg)'), key: 'co2Car', width: 32},
            {header: t('C02 reduction scooter (kg)'), key: 'co2Scooter', width: 32},
            {header: t('C02 reduction bike (kg)'), key: 'co2Bike', width: 32},
            ...vehicleStatsColumns
        ];

        const monday = report?.aggregatedVehicleStats?.weekdays?.monday || 0
        const tuesday = report?.aggregatedVehicleStats?.weekdays?.tuesday || 0
        const wednesday = report?.aggregatedVehicleStats?.weekdays?.wednesday || 0
        const thursday = report?.aggregatedVehicleStats?.weekdays?.thursday || 0
        const friday = report?.aggregatedVehicleStats?.weekdays?.friday || 0
        const saturday = report?.aggregatedVehicleStats?.weekdays?.saturday || 0
        const sunday = report?.aggregatedVehicleStats?.weekdays?.sunday || 0
        const total = monday + tuesday + wednesday + thursday + friday + saturday + sunday

        const totalBookingsCars = getValueForType(report?.totalBookings, VehicleType.CAR) || 0
        const totalBookingsScooters = getValueForType(report?.totalBookings, VehicleType.SCOOTER) || 0
        const totalBookingsBikes = getValueForType(report?.totalBookings, VehicleType.BICYCLE) || 0
        const totalBookings = totalBookingsCars + totalBookingsScooters + totalBookingsBikes

        overviewWorksheet.addRow({
            grossIncomeTotal: cleanupExportNumber(getSummedValue(report?.totalSpent)),
            grossIncomeCar: cleanupExportNumber(getValueForType(report?.totalSpent, VehicleType.CAR)),
            grossIncomeScooter: cleanupExportNumber(getValueForType(report?.totalSpent, VehicleType.SCOOTER)),
            grossIncomeBike: cleanupExportNumber(getValueForType(report?.totalSpent, VehicleType.BICYCLE)),
            rentHoursTotal: cleanupExportNumber(getSummedValue(report?.totalMinutes)),
            rentHoursCar: cleanupExportNumber(getValueForType(report?.totalMinutes, VehicleType.CAR)),
            rentHoursScooter: cleanupExportNumber(getValueForType(report?.totalMinutes, VehicleType.SCOOTER)),
            rentHoursBike: cleanupExportNumber(getValueForType(report?.totalMinutes, VehicleType.BICYCLE)),
            mileageTotal: cleanupExportNumber(getSummedValue(report?.totalMileage)),
            mileageCar: cleanupExportNumber(getValueForType(report?.totalMileage, VehicleType.CAR)),
            mileageScooter: cleanupExportNumber(getValueForType(report?.totalMileage, VehicleType.SCOOTER)),
            mileageBike: cleanupExportNumber(getValueForType(report?.totalMileage, VehicleType.BICYCLE)),
            co2Total: cleanupExportNumber(getSummedValue(report?.totalCO2) / 1000),
            co2Car: cleanupExportNumber(getValueForType(report?.totalCO2, VehicleType.CAR) / 1000),
            co2Scooter: cleanupExportNumber(getValueForType(report?.totalCO2, VehicleType.SCOOTER) / 1000),
            co2Bike: cleanupExportNumber(getValueForType(report?.totalCO2, VehicleType.BICYCLE) / 1000),
            bookingTimeMorning: cleanupExportNumber(morningPercent(report?.aggregatedVehicleStats)),
            bookingTimeDay: cleanupExportNumber(dayPercent(report?.aggregatedVehicleStats)),
            bookingTimeEvening: cleanupExportNumber(eveningPercent(report?.aggregatedVehicleStats)),
            totalBookings: totalBookings,
            totalBookingsCars: totalBookingsCars,
            totalBookingsScooters: totalBookingsScooters,
            totalBookingsBikes: totalBookingsBikes,
            occupancy: cleanupExportNumber(occupancyRate(report?.aggregatedVehicleStats)),
            monday: cleanupExportNumber(total > 0 ? monday / total * 100 : 0),
            tuesday: cleanupExportNumber(total > 0 ? tuesday / total * 100 : 0),
            wednesday: cleanupExportNumber(total > 0 ? wednesday / total * 100 : 0),
            thursday: cleanupExportNumber(total > 0 ? thursday / total * 100 : 0),
            friday: cleanupExportNumber(total > 0 ? friday / total * 100 : 0),
            saturday: cleanupExportNumber(total > 0 ? saturday / total * 100 : 0),
            sunday: cleanupExportNumber(total > 0 ? sunday / total * 100 : 0)
        })

        const vehiclesWorksheet = workbook.addWorksheet('Vehicles');
        vehiclesWorksheet.columns = [
            {header: t('Vehicle'), key: 'vehicle', width: 50},
            ...vehicleStatsColumns
        ]

        report?.vehicleStats?.forEach(vehicleStat => {
            const monday = vehicleStat.weekdays?.monday || 0
            const tuesday = vehicleStat.weekdays?.tuesday || 0
            const wednesday = vehicleStat.weekdays?.wednesday || 0
            const thursday = vehicleStat.weekdays?.thursday || 0
            const friday = vehicleStat.weekdays?.friday || 0
            const saturday = vehicleStat.weekdays?.saturday || 0
            const sunday = vehicleStat.weekdays?.sunday || 0
            const total = monday + tuesday + wednesday + thursday + friday + saturday + sunday

            vehiclesWorksheet.addRow({
                vehicle: vehicleDescriptor(vehicleStat.vehicle),
                bookingTimeMorning: cleanupExportNumber(morningPercent(vehicleStat)),
                bookingTimeDay: cleanupExportNumber(dayPercent(vehicleStat)),
                bookingTimeEvening: cleanupExportNumber(eveningPercent(vehicleStat)),
                occupancy: cleanupExportNumber(occupancyRate(vehicleStat)),
                monday: cleanupExportNumber(total > 0 ? monday / total * 100 : 0),
                tuesday: cleanupExportNumber(total > 0 ? tuesday / total * 100 : 0),
                wednesday: cleanupExportNumber(total > 0 ? wednesday / total * 100 : 0),
                thursday: cleanupExportNumber(total > 0 ? thursday / total * 100 : 0),
                friday: cleanupExportNumber(total > 0 ? friday / total * 100 : 0),
                saturday: cleanupExportNumber(total > 0 ? saturday / total * 100 : 0),
                sunday: cleanupExportNumber(total > 0 ? sunday / total * 100 : 0)
            })
        })

        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), 'analytics.xlsx');
    };

    useEffect(() => {
        fetchOrganizations();
    }, [props.claims]);

    useEffect(() => {
        fetchAnalytics();
    }, [fetchAnalytics, props.claims]);

    const filterUI = (
        <Box display={'flex'} alignItems={'center'}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                {
                    isCurrentUserSuperAdmin() &&
                    <FormControl
                        margin="normal"
                        variant={'outlined'}
                        size={'small'}
                        style={{marginTop: 0, marginBottom: 0}}
                    >
                        <InputLabel>{t('Organization')}</InputLabel>
                        <Select
                            style={{width: 150}}
                            value={selectedOrganization?.id || 0}
                            onChange={(event) => {
                                const orgId = event.target.value
                                setSelectedOrganization(organizations?.find(o => o.id === orgId))
                            }}
                            label={t('Organization')}
                        >
                            <MenuItem
                                key={0}
                                value={0}
                            >
                                {t('All')}
                            </MenuItem>

                            {
                                organizations.map(organization => (
                                    <MenuItem
                                        key={organization.id}
                                        value={organization.id}
                                    >
                                        {organization.name}
                                    </MenuItem>
                                ))
                            }

                        </Select>
                    </FormControl>
                }


                <DatePicker
                    style={{marginTop: 0, marginBottom: 0, marginLeft: 10}}
                    label={t('From')}
                    maxDate={toDate}
                    value={fromDate || null}
                    inputVariant={'outlined'}
                    margin={'normal'}
                    format={'dd.MM.yyyy.'}
                    onChange={date => setFromDate(date || new Date())}
                    disabled={loading}
                    size={'small'}
                />

                <DatePicker
                    style={{marginTop: 0, marginBottom: 0, marginLeft: 10}}
                    label={t('To')}
                    maxDate={new Date()}
                    value={toDate || null}
                    inputVariant={'outlined'}
                    margin={'normal'}
                    format={'dd.MM.yyyy.'}
                    onChange={date => setToDate(date || new Date())}
                    disabled={loading}
                    size={'small'}
                />

            </MuiPickersUtilsProvider>

            <Box m={2}/>

            {
                loading ?
                    <FlexView vAlignContent='center'>
                        <CircularProgress/>
                    </FlexView>
                    :
                    <Button variant="contained" color="primary"
                            onClick={() => fetchAnalytics()}
                    >
                        {t('Show')}
                    </Button>
            }

            <Button
                variant="contained"
                color="primary"
                onClick={exportBookingsToXlsHandler}
                style={{marginLeft: 'auto'}}
            >
                {t('Export to XLS')}
            </Button>

        </Box>
    )

    const occupancyRate = (vehicleStat?: VehicleStat) => {
        const totalMinutesInPeriod = differenceInMinutes(toDate, fromDate)
        const totalMinutesBooked = vehicleStat?.totalMinutes || 0
        return Math.ceil(totalMinutesBooked / totalMinutesInPeriod * 100 * 100) / 100
    }

    const renderVehicleOccupancyRate = (vehicleStat?: VehicleStat) => {

        const totalMinutesInPeriod = differenceInMinutes(toDate, fromDate)
        const totalMinutesBooked = vehicleStat?.totalMinutes || 0
        const occupancyRate = Math.ceil(totalMinutesBooked / totalMinutesInPeriod * 100 * 100) / 100


        const data = [
            {value: totalMinutesBooked, label: ''},
            {value: totalMinutesInPeriod, label: ''}
        ]

        const colors = [COLORS.SECONDARY, COLORS.PRIMARY]

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                {renderDoughnutChart(data, colors, t('Occupancy'), `${occupancyRate}%`, classes.vehicleChartHolder)}
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    height={20}
                    marginBottom={5}
                >
                    <Box
                        width={10}
                        height={10}
                        style={{backgroundColor: COLORS.SECONDARY}}
                        marginRight={1}
                    />

                    <Typography variant={'subtitle2'}>
                        {t('Occupancy')} {`${occupancyRate}%`}
                    </Typography>

                </Box>
            </Box>
        )
    }

    // const renderVehicleCO2Reduction = (vehicleStat: VehicleStat) => {
    //
    //     const targetCO2 = 550
    //     const totalReduction = vehicleStat.totalCO2 || 0
    //
    //     const data = [
    //         {value: totalReduction, label: ''},
    //         {value: targetCO2, label: ''}
    //     ]
    //
    //     const colors = [COLORS.PRIMARY, COLORS.SECONDARY]
    //
    //     return (
    //         <Box
    //             display={'flex'}
    //             flexDirection={'column'}
    //             alignItems={'center'}
    //         >
    //             {renderDoughnutChart(data, colors, t('CO2 Reduction'), `${totalReduction.toFixed(2)}kg`, classes.vehicleChartHolder, 80)}
    //             <Box
    //                 display={'flex'}
    //                 flexDirection={'row'}
    //                 alignItems={'center'}
    //             >
    //                 <Box
    //                     width={15}
    //                     height={15}
    //                     style={{backgroundColor: COLORS.SECONDARY}}
    //                     marginRight={1}
    //                 />
    //
    //                 <Typography variant={'body1'}>
    //                     {t('550 kg CO2 ekvivalenter (2020)')}
    //                 </Typography>
    //
    //             </Box>
    //         </Box>
    //     )
    //
    // }

    const totalBookingTimes = (vehicleStat?: VehicleStat) => {
        const morning = vehicleStat?.bookingTimes?.morning || 0
        const day = vehicleStat?.bookingTimes?.day || 0
        const evening = vehicleStat?.bookingTimes?.evening || 0

        return morning + day + evening
    }

    const morningPercent = (vehicleStat?: VehicleStat) => {
        const morning = vehicleStat?.bookingTimes?.morning || 0
        const total = totalBookingTimes(vehicleStat)
        return total > 0 ? morning / total * 100 : 0
    }

    const dayPercent = (vehicleStat?: VehicleStat) => {
        const day = vehicleStat?.bookingTimes?.day || 0
        const total = totalBookingTimes(vehicleStat)
        return total > 0 ? day / total * 100 : 0
    }

    const eveningPercent = (vehicleStat?: VehicleStat) => {
        const evening = vehicleStat?.bookingTimes?.evening || 0
        const total = totalBookingTimes(vehicleStat)
        return total > 0 ? evening / total * 100 : 0
    }

    const renderVehicleBookingHours = (vehicleStat?: VehicleStat) => {
        let data
        let colors

        const hasData = totalBookingTimes(vehicleStat) > 0
        if (hasData) {
            data = [
                {value: morningPercent(vehicleStat), label: `${morningPercent(vehicleStat).toFixed(1)}%`},
                {value: dayPercent(vehicleStat), label: `${dayPercent(vehicleStat).toFixed(1)}%`},
                {value: eveningPercent(vehicleStat), label: `${eveningPercent(vehicleStat).toFixed(1)}%`},
            ]
            colors = [COLORS.PRIMARY, COLORS.SECONDARY, COLORS.ORANGE]
        } else {
            data = [{value: 100, label: ''}]
            colors = ['#c8c8c8']
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                {renderDoughnutChart(data, colors, t('Booking times'), hasData ? `` : t('No data'), classes.vehicleChartHolder)}
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={60}
                >
                    <Box display={'flex'} alignItems={'center'}>
                        <Box
                            width={10}
                            height={10}
                            style={{backgroundColor: COLORS.PRIMARY}}
                            marginRight={1}
                        />

                        <Typography variant={'subtitle2'}>
                            00:00 - 07:59
                        </Typography>
                    </Box>

                    <Box display={'flex'} alignItems={'center'}>
                        <Box
                            width={10}
                            height={10}
                            style={{backgroundColor: COLORS.SECONDARY}}
                            marginRight={1}
                        />

                        <Typography variant={'subtitle2'}>
                            08:00 - 15:59
                        </Typography>
                    </Box>

                    <Box display={'flex'} alignItems={'center'}>
                        <Box
                            width={10}
                            height={10}
                            style={{backgroundColor: COLORS.ORANGE}}
                            marginRight={1}
                        />

                        <Typography variant={'subtitle2'}>
                            16:00 - 23:59
                        </Typography>
                    </Box>

                </Box>
            </Box>
        )
    }

    const renderVehicleBookingDays = (vehicleStat?: VehicleStat) => {

        const monday = vehicleStat?.weekdays?.monday || 0
        const tuesday = vehicleStat?.weekdays?.tuesday || 0
        const wednesday = vehicleStat?.weekdays?.wednesday || 0
        const thursday = vehicleStat?.weekdays?.thursday || 0
        const friday = vehicleStat?.weekdays?.friday || 0
        const saturday = vehicleStat?.weekdays?.saturday || 0
        const sunday = vehicleStat?.weekdays?.sunday || 0

        const total = monday + tuesday + wednesday + thursday + friday + saturday + sunday

        const mondayPercent = total > 0 ? monday / total * 100 : 0
        const tuesdayPercent = total > 0 ? tuesday / total * 100 : 0
        const wednesdayPercent = total > 0 ? wednesday / total * 100 : 0
        const thursdayPercent = total > 0 ? thursday / total * 100 : 0
        const fridayPercent = total > 0 ? friday / total * 100 : 0
        const saturdayPercent = total > 0 ? saturday / total * 100 : 0
        const sundayPercent = total > 0 ? sunday / total * 100 : 0

        const data = [
            {value: mondayPercent, label: t(`Monday`)},
            {value: tuesdayPercent, label: t(`Tuesday`)},
            {value: wednesdayPercent, label: t(`Wednesday`)},
            {value: thursdayPercent, label: t(`Thursday`)},
            {value: fridayPercent, label: t(`Friday`)},
            {value: saturdayPercent, label: t(`Saturday`)},
            {value: sundayPercent, label: t(`Sunday`)},
        ]

        const colors = [
            COLORS.SECONDARY,
            COLORS.SECONDARY,
            COLORS.SECONDARY,
            COLORS.SECONDARY,
            COLORS.SECONDARY,
            COLORS.SECONDARY,
            COLORS.SECONDARY,
        ]

        return renderHorizontalBarChart(data, colors, t('Booking times'), ``, classes.vehicleBookingDaysChartHolder)
    }

    const renderTotalBookings = () => {

        const car = getValueForType(report?.totalBookings, VehicleType.CAR) || 0
        const scooter = getValueForType(report?.totalBookings, VehicleType.SCOOTER) || 0
        const bicycle = getValueForType(report?.totalBookings, VehicleType.BICYCLE) || 0

        const data = [
            {value: car, label: t(`Cars`)},
            {value: scooter, label: t(`Scooters`)},
            {value: bicycle, label: t(`Bikes`)},
        ]

        const colors = [
            COLORS.PRIMARY,
            COLORS.ORANGE,
            COLORS.BLUE,
        ]

        return renderVerticalChart(data, colors, t('Total bookings'), ``, classes.vehicleBookingDaysChartHolder)
    }

    // const renderVehicleStatsAll = (dates: string, vehicleName: string, vehicleStat?: VehicleStat) => (
    //     <Box
    //         display={'flex'}
    //         flexDirection={'column'}
    //         marginTop={2}
    //         width={'100%'}
    //         alignItems={'center'}
    //     >
    //
    //         <Box className={classes.vehicleStatHolder}>
    //             <Typography variant={'h4'}>{t('Booking times')}</Typography>
    //             <Typography variant={'subtitle1'}>{vehicleName}</Typography>
    //             <Typography variant={'subtitle1'}>{dates}</Typography>
    //             {renderVehicleBookingHours(vehicleStat)}
    //         </Box>
    //
    //         <Box className={classes.vehicleStatHolder}>
    //             <Typography variant={'h4'}>{t('Occupancy Rate')}</Typography>
    //             <Typography variant={'subtitle1'}>{vehicleName}</Typography>
    //             <Typography variant={'subtitle1'}>{dates}</Typography>
    //             {renderVehicleOccupancyRate(vehicleStat)}
    //         </Box>
    //
    //         {/*<Box className={classes.vehicleStatHolder}>*/}
    //         {/*    <Typography variant={'h4'}>{t('CO2 Reduction')}</Typography>*/}
    //         {/*    <Typography variant={'subtitle1'}>{vehicleName}</Typography>*/}
    //         {/*    <Typography variant={'subtitle1'}>{dates}</Typography>*/}
    //         {/*    {renderVehicleCO2Reduction(vehicleStat)}*/}
    //         {/*</Box>*/}
    //
    //         <Box className={classes.vehicleStatHolder}>
    //             <Typography variant={'h4'}>{t('Weekdays')}</Typography>
    //             <Typography variant={'subtitle1'}>{vehicleName}</Typography>
    //             <Typography variant={'subtitle1'}>{dates}</Typography>
    //             {renderVehicleBookingDays(vehicleStat)}
    //         </Box>
    //     </Box>
    // )

    const renderVehicleStatsAllSimple = (dates: string, vehicleName: string, size: 'full' | 'semi', vehicleStat?: VehicleStat) => (
        <React.Fragment>
            <Grid item xs={12} sm={6} lg={size === 'full' ? 3 : 6} xl={4}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant={'h6'}>{t('Weekdays')}</Typography>
                    {vehicleName && <Typography variant={'subtitle2'}>{vehicleName}</Typography>}
                    <Typography variant={'subtitle2'}>{dates}</Typography>
                    {renderVehicleBookingDays(vehicleStat)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={size === 'full' ? 3 : 6} xl={2}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant={'h6'}>{t('Occupancy Rate')}</Typography>
                    {vehicleName && <Typography variant={'subtitle2'}>{vehicleName}</Typography>}
                    <Typography variant={'subtitle2'}>{dates}</Typography>
                    {renderVehicleOccupancyRate(vehicleStat)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={size === 'full' ? 3 : 6} xl={2}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant={'h6'}>{t('Booking times')}</Typography>
                    {vehicleName && <Typography variant={'subtitle2'}>{vehicleName}</Typography>}
                    <Typography variant={'subtitle2'}>{dates}</Typography>
                    {renderVehicleBookingHours(vehicleStat)}
                </Box>
            </Grid>
        </React.Fragment>
    )

    const dates = `${format(fromDate, 'yyyy-MM-dd')} - ${format(toDate, 'yyyy-MM-dd')}`

    return (
        <Container>

            {filterUI}

            <Box m={3}/>

            <Typography
                variant={'h6'}
            >
                {t('Overview')}
            </Typography>

            <Grid container
                  alignItems={'center'}
                  justify={'center'}
                // display={'flex'}
                // flexDirection={'row'}
                // alignItems={'center'}
                // justifyContent={'center'}
                // flexWrap={'wrap'}
                // marginTop={2}
            >
                <Grid item xs={12} sm={6} lg={3}>{renderTotalSpentBox()}</Grid>
                <Grid item xs={12} sm={6} lg={3}>{renderTimeBox()}</Grid>
                <Grid item xs={12} sm={6} lg={3}>{renderMileageBox()}</Grid>
                <Grid item xs={12} sm={6} lg={3}>{renderCO2Box()}</Grid>
            </Grid>

            <Box height={50}/>

            <Grid
                container
                alignItems={'flex-start'}
                justify={'flex-start'}
            >

                <Grid item xs={12} sm={6} lg={6} xl={4}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Typography variant={'h6'}>{t('Total bookings')}</Typography>
                        <Typography variant={'subtitle2'}>{t('All vehicles')}</Typography>
                        <Typography variant={'subtitle2'}>{dates}</Typography>
                        {renderTotalBookings()}
                    </Box>
                </Grid>

                {renderVehicleStatsAllSimple(dates, t('All vehicles'), 'semi', report?.aggregatedVehicleStats)}
            </Grid>

            <Box height={100}/>

            {
                report?.vehicleStats?.map(vehicleStat => {
                    const vehicle = vehicleStat.vehicle
                    const vehicleName = [vehicle.make, vehicle.model, vehicle.license].filter(x => !!x).join(' ')

                    return (
                        <Box
                            marginTop={4}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                            >
                                <img className={classes.vehicleImage} src={vehicle.image}/>
                                <Typography
                                    variant={'h6'}
                                >
                                    {vehicleName}
                                </Typography>
                            </Box>

                            {/*{renderVehicleStatsAll(dates, vehicleName, vehicleStat)}*/}

                            <Grid
                                container
                                alignItems={'flex-start'}
                                justify={'flex-start'}
                                style={{marginTop: 50, marginBottom: 50}}
                            >
                                {renderVehicleStatsAllSimple(dates, '', 'full', vehicleStat)}
                            </Grid>

                        </Box>
                    )
                })
            }


        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(AdminAnalytics);
