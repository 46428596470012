import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import classes from './LandingHeader.module.scss';
import clsx from 'clsx';
import FlexView from "react-flexview";
import {connect} from "react-redux";
import HeaderBackground from '../../../assets/images/landing-header-background.jpg';
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props {
    claims?: AuthClaims
}

// const SignupButton = withStyles((theme: Theme) => ({
//     root: {
//         color: 'white',
//     },
// }))(Button);

const LandingHeader = (props: Props) => {

    const t = useTranslation().t

    return (
        (
            <React.Fragment>

                <img src={HeaderBackground as any} className={classes.background}/>

                <FlexView column className={classes.component} hAlignContent='center'>

                    <div style={{flex: 1}}/>

                    <FlexView wrap className={classes.titleWrapper} vAlignContent='center' hAlignContent='center'>
                        <div className={classes.title}>{t('Fast and easy vehicle sharing')}</div>
                    </FlexView>

                    <div
                        className={classes.subtitle}>{t('Book an environmentally friendly and affordable electric vehicle in no time')}</div>

                    <Link to="/find-car" id="signup" className={clsx('cleanLink', classes.findCar)}>
                        <Button
                            variant="outlined"
                            style={{
                                color: 'white',
                                borderColor: 'white',
                                fontSize: '24px',
                                fontFamily: 'Exo',
                                borderRadius: '13px',
                                paddingLeft: '25px',
                                paddingRight: '25px',
                                textTransform: 'initial',
                                borderWidth: '5px',
                                minWidth: '200px'
                            }}
                            color="primary">{t("Book The Vehicle")}</Button>
                    </Link>

                    {
                        !props.claims &&
                        <Link to="/auth/signup" id="signup" className={clsx('cleanLink', classes.signup)}>
                            <Button
                                variant="contained"
                                style={{
                                    fontSize: '24px',
                                    fontFamily: 'Exo',
                                    borderRadius: '13px',
                                    paddingLeft: '25px',
                                    paddingRight: '25px',
                                    textTransform: 'initial',
                                    minWidth: '200px'
                                }}
                                color="primary">{t("Sign up")}</Button>
                        </Link>
                    }

                    <div style={{flex: 1}}/>

                </FlexView>

                {/*<div className={classes.componentEmptySpace} />*/}

            </React.Fragment>
        )
    )
}

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(LandingHeader);
