import {BaseModel} from "./BaseModel.model";

export enum RepeatEvent {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY'
}

export interface CalendarEvent extends BaseModel {
    description?: string
    from?: Date
    to?: Date
    repeat?: RepeatEvent
}