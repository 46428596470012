import api from './axios';

/**
 * Handles all actions related to users.
 */
export default class PublicService {

    static sendContactMessage = async (name: string, phone: string, email: string, message: string) => {
        const response = await api.post<any>(`/public/contactMessage`, {
            name,
            email,
            phone,
            message
        });
        return response.data;
    };
}
