import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './BookingDoneDialog.module.scss';

import FlexView from "react-flexview";
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import {parsePhoneNumberFromString} from "libphonenumber-js";
import i18 from 'i18next';
import {calculateBookingPriceFormattedWeb, formatDateFull} from "../../../common/booking.util";
import {Vehicle} from "../../../models/Vehicle.model";
import {Booking} from "../../../models/Booking.model";
import {User} from "../../../models/User.model";
import {AuthClaims} from "../../../models/AuthClaims.model";

interface Props {
    open: boolean,
    onClose: (phoneNumber?: string) => void,
    booking?: Booking,
    car?: Vehicle,
    user?: User
    claims?: AuthClaims
}

interface State {
    phone: string;
    phoneError: string;
    savingPhoneInProgress: boolean;
    phoneNumberDirty: boolean;
}

class BookingDoneDialog extends Component<Props, State> {

    state = {
        phone: '',
        phoneError: '',
        savingPhoneInProgress: false,
        phoneNumberDirty: false
    };

    handlePhoneInput(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) {
        if (typeof e === 'string') {
            this.setState({
                phone: e,
                phoneNumberDirty: true,
                phoneError: ''
            });
        }
    }

    handleDone = () => {
        if (this.state.phoneNumberDirty) {
            if (parsePhoneNumberFromString(this.state.phone)?.isValid()) {
                this.props.onClose(parsePhoneNumberFromString(this.state.phone)?.format('E.164'));
            } else {
                this.setState({
                    phoneError: i18.t('Phone number is not valid')
                })
            }
        } else {
            this.props.onClose();
        }
    };

    render() {
        if (!this.props.booking || !this.props.car) {
            return null;
        }

        return (
            <Dialog aria-labelledby="customized-dialog-title" open={this.props.open}>
                <DialogTitle id="customized-dialog-title">
                    {i18.t('Your booking is done!')}
                </DialogTitle>
                <DialogContent dividers>
                    <div>{i18.t('Your booking for vehicle is now completed', {car: `${this.props.car.make} ${this.props.car.model}`})}</div>

                    <div className={classes.datesTitle}>{i18.t('Date and time for booking')}</div>
                    <div>{this.props.booking.from && formatDateFull(this.props.booking.from)}</div>
                    <div>{this.props.booking.to && formatDateFull(this.props.booking.to)}</div>

                    <div className={classes.total}>
                        {i18.t('Total amount', {
                            amount: calculateBookingPriceFormattedWeb(this.props.booking, this.props.car, this.props.user, this.props.claims?.subscription),
                            currency: 'kr'
                        })}
                    </div>

                    {/*<div className={classes.important}>You will be able to unlock the car 5 minutes before the booking time!</div>*/}

                    {
                        !this.props?.user?.phone &&
                        <FlexView column className={classes.phoneNumberSection}>
                            <div
                                className={classes.phoneNumberMessage}>{i18.t('You can also unlock the vehicle via SMS. Just add your phone number!')}</div>
                            <div className={classes.phoneNumberHolder}>
                                <MaterialUiPhoneNumber
                                    error={!!this.state.phoneError}
                                    className={classes.signupElement}
                                    margin="normal"
                                    defaultCountry={'se'}
                                    disabled={this.state.savingPhoneInProgress}
                                    value={this.state.phone}
                                    fullWidth
                                    helperText={this.state.phoneError}
                                    onChange={value => this.handlePhoneInput(value)}/>
                            </div>
                        </FlexView>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDone} color="primary">
                        {i18.t('Done')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default BookingDoneDialog;
