import React, {useState} from 'react';
import FlexView from "react-flexview";
import classes from './Timeline.module.scss';
import {
    differenceInMinutes,
    endOfDay,
    format,
    getHours,
    getMinutes,
    isAfter,
    isBefore,
    isSameDay,
    setHours,
    setMinutes,
    startOfDay
} from "date-fns";
import clsx from 'clsx';
import {isPhone} from "../../../common/ui.util";
import {Booking} from "../../../models/Booking.model";
import {Maintenance} from "../../../models/Maintenance.model";
import {periodsOverlap, dateInsideTimedEntities} from "../../../common/booking.util";
import {useTranslation} from "react-i18next";

interface Props {
    dates: Date[],
    bookings: Booking[],
    maintenance: Maintenance[],
    newBooking?: Booking,
    onPickStart?: (startDate: Date) => void
    onPickProgress?: (startDate: Date, endDate?: Date) => void
    onPickDone?: (startDate: Date, endDate: Date) => void
    onPickCancel?: () => void,
    interactive: boolean,
    time: 'sparse' | 'full',
    showMaintenanceDifferently?: boolean,
    onTimelineClick?: (date: Date) => void
}

const Timeline = (props: Props) => {

    // ***** Status ***** //
    
    const t = useTranslation().t
    const [pickStart, setPickStart] = useState<Date>();
    const [hoverItemDate, setHoverItemDate] = useState<Date>();

    // ***** Status ***** //

    /**
     * Inside one date row, display what the entity "takes up"
     * @param date
     * @param entity
     * @param className
     */
    const renderEntityInADay = (date: Date, entity?: { from?: Date, to?: Date }, className?: string) => {
        if (!entity) return null;

        // Start and end minutes inside 24h
        const fromMins = (entity.from ? getHours(entity.from) : 0) * 60 + (entity.from ? getMinutes(entity.from) : 0);
        const toMins = (entity.to ? getHours(entity.to) : 0) * 60 + (entity.to ? getMinutes(entity.to) : 0);
        const total = 24 * 60;

        let content = null;

        // Booking started before the current date and finishing after the current date
        if (entity.from && isBefore(entity.from, startOfDay(date)) && entity.to && isAfter(entity.to, endOfDay(date))) {
            const style = {
                left: `0`,
                right: `0`,
            };

            content = <div className={className} style={style}/>;
        }

        // Booking started and ended on the current date
        else if (entity.from && isSameDay(date, entity.from) && entity.to && isSameDay(date, entity.to)) {
            const style = {
                left: `${fromMins / total * 100}%`,
                right: `${(1 - (toMins / total)) * 100}%`,
            };

            content = <div className={className} style={style}/>;
        }

        // Booking started on the current date but finishing after the current date
        else if (entity.from && isSameDay(date, entity.from)) {
            const style = {
                left: `${fromMins / total * 100}%`,
                right: `0`,
            };

            content = <div className={className} style={style}/>;
        }

        // Booking started before current date but finishing on the current date
        else if (entity.to && isSameDay(date, entity.to)) {
            const style = {
                left: `0`,
                right: `${(1 - (toMins / total)) * 100}%`
            };

            content = <div className={className} style={style}/>;
        }

        if (hoverItemDate && isSameDay(hoverItemDate, date) && entity.from && isAfter(hoverItemDate, entity.from) && entity.to && isBefore(hoverItemDate, entity.to)) {
            const hoverMins = getHours(hoverItemDate) * 60 + getMinutes(hoverItemDate);

            const style = {
                left: `${hoverMins / total * 100}%`,
            };

            let topTextLine;
            let bottomTextLine;
            if (isSameDay(hoverItemDate, entity.from) && entity.to && isSameDay(hoverItemDate, entity.to)) {
                topTextLine = `${t('Date')}: ${format(entity.from, 'dd-MMM')}`;
                bottomTextLine = `${t('Time')}: ${format(entity.from, 'HH:mm')} - ${format(entity.to, 'HH:mm')}`;
            } else {
                topTextLine = `${t('Date')}: ${format(entity.from, 'dd-MMM')} - ${format(entity.to, 'dd-MMM')}`;
                bottomTextLine = `${t('Time')}: ${format(entity.from, 'HH:mm')} - ${format(entity.to, 'HH:mm')}`;
            }

            return (
                <React.Fragment>
                    <FlexView className={classes.hoverContent} column style={style}>
                        <div>{topTextLine}</div>
                        <div>{bottomTextLine}</div>
                    </FlexView>

                    {content}
                </React.Fragment>
            )
        } else {
            return content;
        }
    };

    /**
     *  Marks the beginning of selection or calculates
     *  start and end date if selection is done.
     * @param date
     * @param event
     */
    const handleClickOnBookingStrip = (date: Date, event: any) => {
        // Row pixel width
        const bounds = event.target.getBoundingClientRect();
        const width = bounds.width;
        // Click pixel location
        const x = event.clientX - bounds.left;
        // How much of the date is selected (%)
        const xPercent = x / width;

        // Get hours and minute based on selection %
        const hours = Math.floor(xPercent * 24 * 60 / 60);
        const minutes = (xPercent * 24 * 60) % 60;

        const clickedDate = setMinutes(setHours(date, hours), minutes);

        props.onTimelineClick && props.onTimelineClick(clickedDate);

        if (isPhone() && !pickStart) {
            if (hoverItemDate) {
                setHoverItemDate(undefined);
            } else {
                setHoverItemDate(clickedDate);
            }
        }

        if (!props.interactive) {
            return null;
        }

        if (isAfter(new Date(), clickedDate)) return;

        if (dateInsideTimedEntities(clickedDate, props.maintenance)) return;

        setPickStart(clickedDate);

        if (pickStart) {
            // Pick start already exists -> selection ended
            if (props.onPickDone) {
                const startDate = isAfter(clickedDate, pickStart) ? pickStart : clickedDate;
                const endDate = isAfter(clickedDate, pickStart) ? clickedDate : pickStart;
                props.onPickDone(startDate, endDate);
            }

            setPickStart(undefined);
        } else if (props.onPickStart) {
            props.onPickStart(clickedDate)
        }

    };

    const handleHoverOnBookingStrip = (date: Date, event: any) => {
        if (!props.interactive) {
            return null;
        }

        // Row pixel width
        const bounds = event.target.getBoundingClientRect();
        const width = bounds.width;
        // Click pixel location
        const x = event.clientX - bounds.left;
        // How much of the date is selected (%)
        const xPercent = x / width;

        // Get hours and minute based on selection %
        const hours = Math.floor(xPercent * 24 * 60 / 60);
        const minutes = (xPercent * 24 * 60) % 60;

        const hoverDate = setMinutes(setHours(date, hours), minutes);

        // if (dateInsideBookings(hoverDate, props.bookings)) return;

        const startDate = pickStart && isAfter(hoverDate, pickStart) ? pickStart : hoverDate;
        const endDate = pickStart && isAfter(hoverDate, pickStart) ? hoverDate : pickStart;

        if (props.onPickProgress && pickStart) {
            props.onPickProgress(startDate, endDate);
        }

        setHoverItemDate(hoverDate);
    };

    const renderTimePassed = (date: Date) => {
        if (isBefore(new Date(), startOfDay(date))) {
            return null;
        }

        if (isAfter(new Date(), endOfDay(date))) {
            const style = {
                left: 0,
                right: 0,
            };

            return <div className={classes.timePassed} style={style}/>;
        }

        const passedMinutes = differenceInMinutes(new Date(), startOfDay(date));
        const passedPercent = passedMinutes / (24 * 60);
        const style = {
            left: 0,
            right: `${(1 - passedPercent) * 100}%`,
        };
        return <div className={classes.timePassed} style={style}/>;
    };

    return (
        <FlexView column className={classes.component} onMouseOut={() => setHoverItemDate(undefined)}>

            {/* Header */}
            <FlexView className={classes.header} vAlignContent='center'>
                <div className={classes.datesCol}/>
                <FlexView column className={classes.headerContent} hAlignContent='center'>
                    <div className={classes.headerTimeLabel}>{t('Time')}</div>
                    <FlexView className={classes.timestamps} vAlignContent='center'>
                        <div>00:00</div>
                        <div>{props.time === 'full' ? '03:00' : null}</div>
                        <div>06:00</div>
                        <div>{props.time === 'full' ? '09:00' : null}</div>
                        <div>12:00</div>
                        <div>{props.time === 'full' ? '15:00' : null}</div>
                        <div>18:00</div>
                        <div>{props.time === 'full' ? '21:00' : null}</div>
                    </FlexView>
                </FlexView>
            </FlexView>

            <FlexView className={classes.dates}>

                <FlexView className={classes.datesLabelHolder} hAlignContent='center' vAlignContent='center'>
                    <div className={classes.datesLabel}>{t('Date')}</div>
                </FlexView>

                <FlexView column className={classes.dateRows}>
                    {/* Rows */}
                    {
                        props.dates.map((date, index) => (
                            <FlexView className={clsx(classes.dataRow, index % 2 === 0 && classes.dataRowGrey)}
                                      vAlignContent='center'>

                                {/* Date */}
                                <div className={classes.datesCol}>{format(date, 'dd-MMM')}</div>

                                {/* Selection */}
                                <FlexView className={classes.bookings} vAlignContent='center'>

                                    {
                                        renderTimePassed(date)
                                    }

                                    <div className={classes.eventCatcher}
                                         onMouseMove={e => handleHoverOnBookingStrip(date, e)}
                                         onClick={e => handleClickOnBookingStrip(date, e)}/>

                                    {
                                        props.bookings.map(booking =>
                                            renderEntityInADay(
                                                date,
                                                booking,
                                                periodsOverlap(booking, props.newBooking) ? classes.existingBookingOverlap : classes.existingBooking,
                                            ),
                                        )
                                    }

                                    {
                                        props.maintenance.map(maintenance =>
                                            renderEntityInADay(
                                                date,
                                                maintenance,
                                                periodsOverlap(maintenance, props.newBooking) ?
                                                    classes.existingBookingOverlap :
                                                    clsx(classes.existingBooking, props.showMaintenanceDifferently && classes.maintenanceSpecificLook)
                                            )
                                        )
                                    }

                                    {
                                        renderEntityInADay(date, props.newBooking, classes.newBooking)
                                    }
                                </FlexView>
                            </FlexView>
                        ))
                    }
                </FlexView>

            </FlexView>

        </FlexView>
    )
}

export default Timeline;
