import React, {Component} from 'react';
import FlexView from "react-flexview";
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import classes from './Contact.module.scss';
import clsx from 'clsx';
import {mapStyleGrey} from "../../listing/Listing/mapStyle";
import Phone from '../../../assets/icons/phone-green.svg';
import Email from '../../../assets/icons/email-green.svg';
import Location from '../../../assets/icons/location-green.svg';
import Clock from '../../../assets/icons/clock-green.svg';
import ContactForm from "../ContactForm/ContactForm.component";
import i18 from 'i18next';
import Footer from "../../../common/ui/Footer/Footer.component";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props {
    google: any
}

class Contact extends Component<Props> {

    componentDidMount() {
        trackCurrentPage()
    }

    render() {
        return (
            <FlexView className={clsx('component', classes.component)} hAlignContent='center' column>

                <FlexView className={classes.mapHolder}>
                    <Map
                        google={this.props.google}
                        /** @ts-ignore */
                        zoom={14}
                        /** @ts-ignore */
                        styles={mapStyleGrey}
                        /** @ts-ignore */
                        style={{width: '100%', height: '100%'}}
                        /** @ts-ignore */
                        fullscreenControl={false}
                        /** @ts-ignore */
                        mapTypeControl={false}
                        /** @ts-ignore */
                        zoomControl={false}
                        /** @ts-ignore */
                        streetViewControl={false}
                        /** @ts-ignore */
                        initialCenter={{lat: 59.847908, lng: 17.632138}}
                        /** @ts-ignore */
                        gestureHandling='none'
                    >

                        <Marker
                            /** @ts-ignore */
                            position={{lat: 59.847908, lng: 17.632138}}
                        />

                    </Map>
                </FlexView>

                <FlexView className={classes.contactInfoSection} vAlignContent='center' hAlignContent='center' wrap>

                    <FlexView className={classes.contactInfoHolder} column vAlignContent='top' hAlignContent='center'>
                        <img className={classes.contactInfoIcon} src={Phone}/>
                        {/*<div className={classes.contactInfoType}>Phone</div>*/}
                        <a href="tel:018 50 40 40" className={classes.contactInfoValue}>018 50 40 40</a>
                    </FlexView>

                    <FlexView className={classes.contactInfoHolder} column vAlignContent='top' hAlignContent='center'>
                        <img className={classes.contactInfoIcon} src={Email}/>
                        {/*<div className={classes.contactInfoType}>Phone</div>*/}
                        <a href="mailto:info@greencarpool.se"
                           className={classes.contactInfoValue}>info@greencarpool.se</a>
                    </FlexView>

                    <FlexView className={classes.contactInfoHolder} column vAlignContent='top' hAlignContent='center'>
                        <img className={classes.contactInfoIcon} src={Location}/>
                        {/*<div className={classes.contactInfoType}>Phone</div>*/}
                        <div className={classes.contactInfoValue}>Von Kreamers Allé 27, Uppsala, Sweden</div>
                    </FlexView>

                    <FlexView className={classes.contactInfoHolder} column vAlignContent='top' hAlignContent='center'>
                        <img className={classes.contactInfoIcon} src={Clock}/>
                        {/*<div className={classes.contactInfoType}>Phone</div>*/}
                        <div className={classes.contactInfoValue}>{i18.t('Mon Fri')} 9h - 17h<br/>{i18.t('Sat')} 9h -
                            14h
                        </div>
                        {/*<a href="tel:+24 242 98293893" className={classes.contactInfoValue}>Sat: 9h - 14h</a>*/}
                    </FlexView>

                </FlexView>

                <div className={classes.title}>{i18.t('Contact Us')}</div>

                <ContactForm/>

                <Footer/>

            </FlexView>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD8wOsOYDoqUqcwFG23J4ZoLwp_17A0tHI'
})(Contact);
