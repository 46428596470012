import React, {useEffect, useState} from 'react';
import {User} from "../../../models/User.model";
import AdminUserService from "../../../services/adminUser.service";
import {Page} from "../../../models/Page";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Button, Container} from "@material-ui/core";
import GcpList from "../../../common/ui/List/List";
import {GeneralSet} from "../../../common/generalSet";
import SendMessageDialog from "./SendMessageDialog/SendMessageDialog.component";
import {useTranslation} from "react-i18next";

const AdminUsersList = () => {

    //region State

    const t = useTranslation().t
    const [users, setUsers] = useState<Page<User>>();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('')
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sendOpen, setSendOpen] = useState(false)
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const [selected, setSelected] = useState<GeneralSet<User>>(new GeneralSet())
    const history = useHistory()

    //endregion

    //region Handlers

    useEffect(() => {
        fetchUsers();
        setSelected(new GeneralSet())
    }, [claims, search, page, size]);

    const fetchUsers = async () => {
        if (!claims?.user) return;

        try {
            setLoading(true)
            const users = await AdminUserService.getAll(page, size, undefined, search);
            setUsers(users);
        } catch (e: any) {
            console.log(e);
            setUsers(undefined);
        } finally {
            setLoading(false);
        }
    };

    const openUser = async (user: User) => {
        history.push(`/admin/user/${user.id}`);
    };

    //endregion Handlers

    //region UI

    return (
        <Container>

            {
                selected.size() > 0 &&
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => setSendOpen(true)}
                    style={{marginBottom: 10}}
                >
                    {t('Send message to', {size: selected.size()})}
                </Button>
            }

            <GcpList
                items={users?.content}
                columns={[
                    {id: 'name', name: t('Name'), size: 'l'},
                    {id: 'email', name: t('Email'), size: 'l'},
                    {id: 'phone', name: t('Phone')},
                    {id: 'status', name: t('Status')},
                    {id: 'verification', name: t('Verification')},
                    {id: 'organization', name: t('Organization')},
                ]}
                renderCell={((user, column) => {
                    switch (column.id) {
                        case 'name':
                            return <div>{user.name}</div>
                        case 'email':
                            return <div>{(user.email || "").substring(0, 40)}</div>
                        case 'phone':
                            return <div>{user.phone}</div>
                        case 'status':
                            return <div>{user.suspended ? t('Suspended') : t('Active')}</div>
                        case 'verification':
                            return <div>{user.verified ? t('Verified') : t('Not verified')}</div>
                        case 'organization':
                            return <div>{user.organization?.name}</div>
                        default:
                            return null
                    }
                })}
                size={size}
                onSizeChange={setSize}
                page={page + 1}
                onPageChange={page => setPage(page - 1)}
                totalPages={users?.totalPages}
                totalElements={users?.totalElement}
                onItemClick={openUser}
                loading={loading}
                placeholder={'Search by name, email, phone, organization name'}
                onSearch={setSearch}
                multiselect
                selected={selected}
                onSelect={user => {
                    if (selected.has(user)) {
                        setSelected(selected.deleteImmutable(user))
                    } else {
                        setSelected(selected.addImmutable(user))
                    }
                }}
                onSelectAll={() => {
                    if (selected.size() && selected.size() === users?.content.length) {
                        setSelected(new GeneralSet())
                    } else {
                        setSelected(new GeneralSet(users?.content))
                    }
                }}
            />

            <SendMessageDialog
                open={sendOpen}
                onClose={() => setSendOpen(false)}
                users={selected.values()}
            />

        </Container>
    )

    //endregion UI
}

export default AdminUsersList;