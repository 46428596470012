import axios from 'axios';
import AuthServiceNew from "./auth.service";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});

instance.interceptors.request.use(config => {
    const token = AuthServiceNew.token;
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
});

export default instance;