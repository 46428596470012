import React, {useState} from 'react';
import FlexView from "react-flexview";
import {Button, CircularProgress} from "@material-ui/core";
import classes from './CarStatus.module.scss';
import {Vehicle} from "../../../../models/Vehicle.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {useTranslation} from "react-i18next";

interface Props {
    vehicle?: Vehicle,
    onUpdate: (vehicle: Vehicle) => void;
}

export const CarStatus = (props: Props) => {

    const t = useTranslation().t
    const [statusChangeInProgress, setStatusChangeInProgress] = useState(false);
    const [statusChangeResult, setStatusChangeResult] = useState('');

    const updateCarStatus = async (newStatus: string) => {
        setStatusChangeInProgress(true);
        setStatusChangeResult('');

        try {
            const vehicle = await AdminVehicleService.update({
                ...props.vehicle,
                status: newStatus
            });
            props.onUpdate(vehicle);
        } catch (e: any) {
            setStatusChangeResult('Error while changing status: ' + e.message);
        }

        setStatusChangeInProgress(false);
    };

    return (
        <FlexView column>
            <FlexView className={classes.manualActions} column>

                <div className={classes.statusLabel}>{t('Current status')}: {props.vehicle?.status || 'Not active!'}</div>

                {
                    statusChangeInProgress ?
                        <CircularProgress/>
                        :
                        <Button variant="contained" color="primary" style={{width: 'fit-content'}}
                                onClick={() => updateCarStatus(props.vehicle?.status === 'active' ? 'inactive' : 'active')}>
                            {props.vehicle?.status === 'active' ? t('Deactivate Car') : t('Activate Car')}
                        </Button>
                }

            </FlexView>

            {statusChangeResult && <div className={classes.adminStatus}>{statusChangeResult}</div>}

        </FlexView>
    )
};