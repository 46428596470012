import api from './axios';
import {DamageReport} from "../models/DamageReport.model";

/**
 * Handles all actions related to damage report.
 */
export default class DamageReportService {

    // static convertToWebFormat(booking?: Booking): Booking | undefined {
    //     if (!booking) return;
    //
    //     return {
    //         ...booking,
    //         createdAt: booking.createdAt && new Date(booking.createdAt),
    //         updatedAt: booking.updatedAt && new Date(booking.updatedAt),
    //         from: booking.from && new Date(booking.from),
    //         to: booking.to && new Date(booking.to),
    //         sessionEndedAt: booking.sessionEndedAt && new Date(booking.sessionEndedAt),
    //         vehicleReturnedAt: booking.vehicleReturnedAt && new Date(booking.vehicleReturnedAt)
    //     }
    // }


    static async create(damageReport: DamageReport) {
        const response = await api.post<DamageReport>(`/damageReport`, damageReport);
        return response.data;
    }
}
