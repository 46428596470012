import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CircularProgress, Tab, Tabs} from "@material-ui/core";
import {Group, GroupVisibilityLevel} from "../../../models/Group.model";
import {useSelector} from "react-redux";
import AdminGroupService from "../../../services/adminGroup.service";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useHistory, useParams} from 'react-router-dom';
import BasicInfo from "./BasicInfo.component";
import Vehicles from "./Vehicles.component";
import GcpButton from "../../../common/ui/GcpButton";
import AlertDialog from "../../../common/ui/AlertDialog.component";
import PeriodicAvailabilityTab from "./PeriodicAvailabilityTab.component";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const AdminGroup = () => {

    //region State

    const t = useTranslation().t
    const [group, setGroup] = useState<Group>({visibilityLevel: GroupVisibilityLevel.PUBLIC});
    const [originalGroup, setOriginalGroup] = useState<Group>({visibilityLevel: GroupVisibilityLevel.PUBLIC});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const params = useParams<{ id: string }>();
    const isNew = params.id === 'new';
    const [tab, setTab] = useState(0);
    const history = useHistory();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const isDirty = !_.isEqual(group, originalGroup)

    //endregion

    //region Handlers

    const fetch = async () => {
        if (isNew) return;

        try {
            setLoading(true)
            const group = await AdminGroupService.get(+params.id);
            setGroup(group);
            setOriginalGroup(group)
        } catch (e: any) {
            console.log(e);
            setGroup({});
        } finally {
            setLoading(false);
        }
    }

    const save = async () => {
        setSaving(true);
        try {
            if (isNew) {
                const newGroup = await AdminGroupService.add(group);
                setGroup(newGroup);
                setOriginalGroup(group)
                history.replace(`/admin/group/${newGroup.id}`)
            } else {
                const updateGroup = {...group}
                if (updateGroup.visibilityLevel !== GroupVisibilityLevel.PUBLIC) {
                    updateGroup.periodicAvailability = []
                }

                const newGroup = await AdminGroupService.update(updateGroup);
                setGroup(newGroup);
                setOriginalGroup(newGroup)
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        fetch();
    }, [claims, params.id]);

    //endregion Handlers

    //region UI

    const renderActions = () => {
        if (loading || saving) return <CircularProgress/>;

        return (
            <Box>

                {
                    isDirty &&
                    <Button
                        disabled={!group.name}
                        variant={'contained'}
                        color={"primary"}
                        onClick={save}
                    >
                        {t('Save')}
                    </Button>
                }

                {
                    !isNew &&
                    <GcpButton
                        style={{marginLeft: 20}}
                        variant={'contained'}
                        color={"error"}
                        onClick={() => setDeleteDialogVisible(true)}
                    >
                        {t('Delete')}
                    </GcpButton>
                }

            </Box>
        )
    }


    const tabs = (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{marginBottom: 20}}
        >
            <Card>

                <Tabs
                    textColor={"primary"}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_, t) => setTab(t)}
                >
                    <Tab label={t('Basic info')} tabIndex={0}/>
                    {!isNew && <Tab label={t('Vehicles')} tabIndex={2}/>}
                    {!isNew && group.visibilityLevel === GroupVisibilityLevel.PUBLIC &&
                    <Tab label={t('Periodic availability')} tabIndex={3}/>}
                </Tabs>
            </Card>


            {renderActions()}

        </Box>
    )

    return (
        <Box>
            {tabs}

            {
                tab === 0 &&
                <BasicInfo
                    group={group}
                    setGroup={setGroup}
                />
            }

            {
                tab === 1 &&
                <Vehicles
                    group={group}
                    setGroup={setGroup}
                />
            }

            {
                tab === 2 &&
                <PeriodicAvailabilityTab
                    group={group}
                    setGroup={setGroup}
                />
            }

            <AlertDialog
                open={deleteDialogVisible}
                onClose={() => {
                    setDeleteDialogVisible(false);
                }}
                onConfirm={() => {
                    setDeleteDialogVisible(false);
                    // deleteOrganization();
                }}
                title={t('Delete group?')}
                message={t(`This will remove group and all of it's data`)}
            />

        </Box>
    )

    //endregion UI
}

export default AdminGroup;