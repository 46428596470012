import {BaseModel} from "./BaseModel.model"
import {Vehicle} from "./Vehicle.model"
import {User} from "./User.model"
import {Booking} from "./Booking.model"
import {Organization} from "./Organization.model"

export enum DamageReportType {
    DIRTY = 'DIRTY',
    DAMAGE = 'DAMAGE',
    ACCIDENT = 'ACCIDENT',
    OTHER = 'OTHER',
}

export interface DamageReport extends BaseModel {
    message?: string
    images?: string[]
    vehicle?: Vehicle
    booking?: Booking
    user?: User
    organization?: Organization
    damageType?: DamageReportType
}
