import React from 'react';
import {Route, Switch} from "react-router";
import AdminBookingsList from "./adminBookings/AdminBookingsList/AdminBookingsList.component";
import AdminUsersList from "./users/AdminUsersList.component";
import AdminCarsList from "./vehicles/AdminCarsList/AdminCarsList.component";
import {NavLink} from "react-router-dom";
import AdminUser from "./users/AdminUser/AdminUser.component";
import AdminBooking from "./adminBookings/AdminBooking/AdminBooking.component";
import AdminCar from "./vehicles/AdminCar/AdminCar.component";
import AdminDamageReportsList from "./vehicles/AdminDamageReportsList/AdminDamageReportsList.component";
import LoggedInAs from "../listing/LoggedInAs/LoggedInAs.component";
import {Box, Card, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AdminOrganizationsList from "./organizations/AdminOrganizationsList.component";
import AdminOrganization from "./organizations/AdminOrganization.component";
import AdminGroupsList from "./groups/AdminGroupsList.component";
import AdminGroup from "./groups/AdminGroup.component";
import {isCurrentUserSuperAdmin} from "../../common/user.util";
import AdminAnalytics from "./analytics/AdminAnalytics.component";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 20,
    },
    navigation: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        marginRight: 20,
        width: 250,
        minWidth: 250,
        height: 'fit-content'
    },
    navigationLink: {
        margin: '0px 20px',
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        cursor: 'pointer',
        marginTop: 10,
        marginBottom: 10,
        borderBottom: `2px solid transparent`
    },
    '&:hover, &:focus, &:visited, &:link, &:active': {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        borderBottom: theme.palette.primary.main
    },
    active: {
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    content: {
        width: '100%',
    }
}))

const Admin = () => {

    const t = useTranslation().t
    const classes = useStyles()

    const navUI = (
        <Card className={classes.navigation}>

            <NavLink to={'/admin/user'}
                     className={classes.navigationLink}
                     activeClassName={classes.active}
            >
                {t('Users')}
            </NavLink>

            {
                isCurrentUserSuperAdmin() &&
                <NavLink to={'/admin/organization'}
                         className={classes.navigationLink}
                         activeClassName={classes.active}
                >
                    {t('Organizations')}
                </NavLink>
            }

            {
                isCurrentUserSuperAdmin() &&
                <NavLink to={'/admin/group'}
                         className={classes.navigationLink}
                         activeClassName={classes.active}
                >
                    {t('Groups')}
                </NavLink>
            }

            <NavLink to={'/admin/vehicle'}
                     className={classes.navigationLink}
                     activeClassName={classes.active}
            >
                {t('Vehicles')}
            </NavLink>

            <NavLink
                to={'/admin/booking'}
                className={classes.navigationLink}
                activeClassName={classes.active}
            >
                {t('Bookings')}
            </NavLink>

            <NavLink to={'/admin/damage-reports'}
                     className={classes.navigationLink}
                     activeClassName={classes.active}
            >
                {t('Damage reports')}
            </NavLink>

            <NavLink to={'/admin/analytics'}
                     className={classes.navigationLink}
                     activeClassName={classes.active}
            >
                {t('Analytics')}
            </NavLink>

        </Card>
    )

    const contentUI = (
        <Box className={classes.content}>
            <Switch>

                <Route path="/admin/user/:id" component={AdminUser}/>
                <Route path="/admin/user" component={AdminUsersList}/>

                <Route path="/admin/organization/:id" component={AdminOrganization}/>
                <Route path="/admin/organization" component={AdminOrganizationsList}/>

                <Route path="/admin/group/:id" component={AdminGroup}/>
                <Route path="/admin/group" component={AdminGroupsList}/>

                <Route path="/admin/vehicle/:id" component={AdminCar}/>
                <Route path="/admin/vehicle" component={AdminCarsList}/>

                <Route path="/admin/booking/:id" component={AdminBooking}/>
                <Route path="/admin/booking" component={AdminBookingsList}/>

                <Route path="/admin/damage-reports" component={AdminDamageReportsList}/>

                <Route path="/admin/analytics" component={AdminAnalytics}/>

            </Switch>
        </Box>
    )

    return (
        <Container className={classes.container}>
            <LoggedInAs/>
            <Box display={'flex'} flexDirection={'row'}>
                {navUI}
                {contentUI}
            </Box>
        </Container>
    )
};

export default Admin;