import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Organization} from "../../../models/Organization.model";
import {Booking} from "../../../models/Booking.model";
import AdminBookingService from "../../../services/adminBooking.service";
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Page} from "../../../models/Page";
import BookingsList from "../common/BookingList.component";

const useStyle = makeStyles(_theme => ({
    content: {
        padding: 20
    },
    nameInput: {
        width: 300,
        marginTop: 20,
        marginBottom: 40
    },
    actionButton: {
        marginLeft: 20
    }
}))

interface Props {
    organization: Organization;
}

const Bookings = ({organization}: Props) => {

    // @ts-ignore
    const classes = useStyle();
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState<Page<Booking>>();
    const history = useHistory();

    //region Handlers

    const fetch = async (page: number) => {
        setLoading(true);
        try {
            const response = await AdminBookingService.getAllBookings(page, 20, organization.id);
            setBookings(response);
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (organization.id) {
            fetch(0);
        }
    }, [organization.id])

    //endregion Handlers

    //region UI

    if (loading) {
        return <CircularProgress/>
    }

    return (
        <BookingsList
            bookings={bookings}
            onBookingClick={booking => {
                history.push(`/admin/booking/${booking.id}/basic`)
            }}

            onPageChange={page => fetch(page)}
        />
    )

    //endregion UI
}

export default Bookings;