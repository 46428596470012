import {LOGOUT_DONE} from "../actions/actionTypes";

interface State {
}

const initialState: State = {};

const adminReducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case LOGOUT_DONE:
            return {
                ...initialState
            }
    }
    return state;
};

export default adminReducer;
