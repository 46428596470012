import api from './axios';
import {Vehicle} from "../models/Vehicle.model";
import {Maintenance} from "../models/Maintenance.model";
import DeviceInfo from "../../../api/src/connectors/deviceInfo";
import {Booking} from "../models/Booking.model";
import BookingService from "./booking.service";
import VehicleService from "./vehicle.service";
import {User} from "../models/User.model";
import TelemetryModel from "../models/Telemetry.model";
import {Page} from "../models/Page";
import {VehicleCalendarEvent} from "../models/VehicleCalendarEvent.model";

/**
 * Handles all actions related to vehicles.
 */
export default class AdminVehicleService {

    static getAllVehicles = async (page?: number, size?: number, organizationId?: number, groupId?: number, search?: string): Promise<Page<Vehicle>> => {
        const params = [
            page ? `page=${page}` : '',
            size ? `size=${size}` : '',
            organizationId ? `organizationId=${organizationId}` : '',
            groupId ? `groupId=${groupId}` : '',
            search ? `search=${search}` : '',
        ].filter(x => !!x).join('&');

        const response = await api.get<Page<Vehicle>>(`/admin/vehicle?${params}`);
        return response.data;
    };

    static getVehicles = async (id: number): Promise<Vehicle> => {
        const response = await api.get<Vehicle>(`/admin/vehicle/${id}`);
        return response.data;
    };

    static add = async (vehicle: Vehicle): Promise<Vehicle> => {
        const response = await api.post<Vehicle>(`/admin/vehicle`, vehicle);
        return response.data;
    };

    static update = async (vehicle: Vehicle): Promise<Vehicle> => {
        const response = await api.put<Vehicle>(`/admin/vehicle/${vehicle.id}`, vehicle);
        return response.data;
    };

    static getTelemetry = async (id?: number): Promise<TelemetryModel> => {
        const response = await api.get<TelemetryModel>(`/admin/vehicle/${id}/telemetry`);
        return response.data;
    };

    static getDeviceInfo = async (id?: number): Promise<DeviceInfo> => {
        const response = await api.get<DeviceInfo>(`/admin/vehicle/${id}/deviceInfo`);
        return response.data;
    };

    static lock = async (id?: number): Promise<any> => {
        const response = await api.put<any>(`/admin/vehicle/${id}/lock`);
        return response.data;
    };

    static unlock = async (id?: number): Promise<any> => {
        const response = await api.put<any>(`/admin/vehicle/${id}/unlock`);
        return response.data;
    };

    static setupDevice = async (id?: number, request?: any): Promise<any> => {
        const response = await api.put<any>(`/admin/vehicle/${id}/setupDevice`, request);
        return response.data;
    };

    static getAllBookingsForVehicle = async (vehicle?: Vehicle): Promise<Booking[]> => {
        if (!vehicle) return [];

        const response = await api.get<Booking[]>(`/admin/vehicle/${vehicle?.id}/booking`);
        return response.data.map(BookingService.convertToWebFormat);
    };

    static getAllBookingsForUser = async (user?: User): Promise<Booking[]> => {
        if (!user) return [];

        const response = await api.get<Booking[]>(`/admin/user/${user?.id}/booking`);
        return response.data.map(BookingService.convertToWebFormat);
    };

    //region Maintenance

    static getAllMaintenancesForVehicle = async (vehicle?: Vehicle): Promise<Maintenance[]> => {
        if (!vehicle) return [];

        const response = await api.get<Maintenance[]>(`/admin/vehicle/${vehicle?.id}/maintenance`);
        return response.data.map(VehicleService.convertMaintenanceToWebFormat);
    };

    static addMaintenance = async (vehicle?: Vehicle, maintenance?: Maintenance): Promise<Maintenance> => {
        const response = await api.post<Maintenance>(`/admin/vehicle/${vehicle?.id}/maintenance`, maintenance);
        return VehicleService.convertMaintenanceToWebFormat(response.data);
    };

    static updateMaintenance = async (vehicle?: Vehicle, maintenance?: Maintenance): Promise<Maintenance> => {
        const response = await api.put<Maintenance>(`/admin/vehicle/${vehicle?.id}/maintenance/${maintenance?.id}`, maintenance);
        return VehicleService.convertMaintenanceToWebFormat(response.data);
    };

    static removeMaintenance = async (vehicle?: Vehicle, maintenance?: Maintenance): Promise<Maintenance> => {
        const response = await api.delete<Maintenance>(`/admin/vehicle/${vehicle?.id}/maintenance/${maintenance?.id}`);
        return VehicleService.convertMaintenanceToWebFormat(response.data);
    };

    //endregion Maintenance

    //region Event

    static getAllEventsForVehicle = async (vehicle?: Vehicle): Promise<VehicleCalendarEvent[]> => {
        if (!vehicle) return [];

        const response = await api.get<VehicleCalendarEvent[]>(`/admin/vehicle/${vehicle?.id}/event`);
        return response.data.map(VehicleService.convertEventToWebFormat);
    };

    static addEvent = async (vehicle?: Vehicle, event?: VehicleCalendarEvent): Promise<VehicleCalendarEvent> => {
        const response = await api.post<VehicleCalendarEvent>(`/admin/vehicle/${vehicle?.id}/event`, event);
        return VehicleService.convertEventToWebFormat(response.data);
    };

    static updateEvent = async (vehicle?: Vehicle, event?: VehicleCalendarEvent): Promise<VehicleCalendarEvent> => {
        const response = await api.put<VehicleCalendarEvent>(`/admin/vehicle/${vehicle?.id}/event/${event?.id}`, event);
        return VehicleService.convertEventToWebFormat(response.data);
    };

    static removeEvent = async (vehicle?: Vehicle, event?: VehicleCalendarEvent): Promise<VehicleCalendarEvent> => {
        const response = await api.delete<VehicleCalendarEvent>(`/admin/vehicle/${vehicle?.id}/event/${event?.id}`);
        return VehicleService.convertEventToWebFormat(response.data);
    };

    //endregion Event

    static startClima = async (id?: number): Promise<any> => {
        const response = await api.put<any>(`/admin/vehicle/${id}/startClima`);
        return response.data;
    };

    static stopClima = async (id?: number): Promise<any> => {
        const response = await api.put<any>(`/admin/vehicle/${id}/stopClima`);
        return response.data;
    };
}
