import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './PaymentDialog.module.scss';
import FlexView from "react-flexview";
import i18 from "i18next";
import {SplitFieldsForm} from "../../../common/ui/CardInputForm.component";
import {Elements} from 'react-stripe-elements';
import {connect} from "react-redux";
import {initAddPaymentMethod} from "../../../store/actions/billing.actions";
import {calculateBookingPriceFormattedWeb} from "../../../common/booking.util";
import {Booking} from "../../../models/Booking.model";
import {AuthClaims} from "../../../models/AuthClaims.model";
import PaymentMethod from "../../../models/PaymentMethod.model";
import {isCurrentUserSuperAdmin} from "../../../common/user.util";

interface Props {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void,
    booking?: Booking;

    claims?: AuthClaims;

    paymentMethods: PaymentMethod[];
    addPaymentMethodLoading: boolean;
    initAddPaymentMethod: (cardInfo?: any) => void;
}

class PaymentDialog extends Component<Props> {

    handleAddCreditCard = (cardInfo: any) => {
        this.props.initAddPaymentMethod(cardInfo);
        // this.props.onConfirm();
    };

    componentDidUpdate(prevProps: any) {
        if (this.props !== prevProps.props && this.props.open && this.props.paymentMethods && this.props.paymentMethods.length) {
            this.props.onConfirm();
        }
    }

    renderPayments() {
        if (this.props.addPaymentMethodLoading) {
            return <CircularProgress/>;
        }

        return (
            <FlexView hAlignContent='center' vAlignContent='center' className={classes.paymentForm}>
                <Elements>
                    <SplitFieldsForm handleResult={this.handleAddCreditCard}/>
                </Elements>
            </FlexView>
        )
    }

    render() {
        if (!this.props.booking || !this.props.booking.vehicle) {
            return null;
        }

        return (
            <Dialog onClose={this.props.onClose} aria-labelledby="customized-dialog-title" open={this.props.open}>
                <DialogTitle id="customized-dialog-title">
                    {i18.t('Checkout')} ({calculateBookingPriceFormattedWeb(this.props.booking, this.props.booking.vehicle, this.props.claims?.user, this.props.claims?.subscription)} kr)
                </DialogTitle>
                <DialogContent dividers>
                    <div>{i18.t('Your booking is reserved.')}</div>
                    <div>{i18.t('Please complete payment to confirm it.')}</div>

                    {this.renderPayments()}

                    {
                        isCurrentUserSuperAdmin() &&
                        <Button
                            onClick={() => this.props.onConfirm()}
                            variant='contained' style={{width: '100%'}} color="primary">
                            {i18.t('(Admin) Continue without payment ')}
                        </Button>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        {i18.t('Cancel')}
                    </Button>
                    {/*<Button onClick={props.onConfirm} color="primary">*/}
                    {/*{i18.t('Pay')}*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        paymentMethods: state.billing.paymentMethods,
        addPaymentMethodLoading: state.billing.addPaymentMethodLoading
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        initAddPaymentMethod: (cardInfo?: any) => dispatch(initAddPaymentMethod(cardInfo))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialog);
