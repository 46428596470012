import React, { useEffect } from 'react';
import classes from './Legal.module.scss';
import { trackCurrentPage } from "../../services/googleAnalytics";

const ToS = () => {

  useEffect(() => {
    trackCurrentPage()
  }, [])

  return (
    <div className={classes.container}>
      <h1>GreenCarpools ALLMÄNNA VILLKOR</h1>
      <h5>Gäller från 18 januari 2023</h5>
      <p>Nedan följer de Allmänna villkor under vilka avtal träffats mellan GreenCarpool Norden AB,
        organisationsnummer 559169-2628 (nedan kallat <b>”GCP”</b>)och avtalsslutande medlem (nedan
        kallad <b>”Kund”</b>).</p>

      <h2>§ 1 Medlemskap och Avtalet</h2>
      <p><strong>1.1</strong> Avtalet består ett tecknat Avtal mellan GCP och Kunden samt dessa Allmänna villkor,
        tillsammans fortsättningsvis kallat för ”Avtal”.</p>
      <p><strong>1.2 </strong>Avtalet förutsätter att Kunden skapar ett konto och blir medlem hos GCP. GCP
        erbjuder två typer av medlemskap Freemium och Premium, med olika prissättning. Kunden bokar, betalar och
        administrerar sina bokningar och medlemskap via sitt registrerade konto på GCP:s hemsida
        (<a href="https://www.greencarpool.se">www.greencarpool.se</a>).</p>
      <p><strong>1.3 </strong>Kunden kan när som helst administrera sitt medlemskap via ”Mina sidor”. Där kan
        medlemskapet ändras, eller sägas upp. Medlemskapet löper och förnyas varje månad automatisk tills Kunden
        själv väljer att avsluta det. Vid ändring från Premium till Freemium behåller Kunden sina Premium
        förmåner den betalda perioden ut. Medlemsavgiften för den aktuella månaden betalas inte tillbaka.</p>
      <p><strong>1.4 </strong>Kunden har rätt att säga upp sitt medlemskap och gör det genom ”Mina sidor”.
        Medlemskapets uppsägningstid är en månad, oavsett typ av medlemskap och kundens konto hos GCP raderas
        efter uppsägningstidens slut tillsammans med personuppgifter.</p>
      <p><strong>1.5 </strong>GCP förbehåller sig rätten att pausa eller avsluta medlemskapet om Kunden inte
        följer GCP:s Allmänna Villkor.</p>

      <h2>§ 2 Grundläggande krav</h2>
      <p><strong>2.1 </strong>Kunden måste ha fyllt 18 år för att kunna bli medlem</p>
      <p><strong>2.2 </strong>Kunden ska ha giltigt körkort, vilket kontrolleras mot körkortsregistret, för att få
        tillgång till GCP:s tjänster.</p>
      <p><strong>2.3 </strong>Kunden ska alltid ha sitt körkort med sig i samband med hyra av GCP.</p>
      <p><strong>2.4 </strong>Observera att särskilda bestämmelser gäller för körkort utfärdat utanför EU/EES. Se
        Transportstyrelsen hemsida.</p>
      <p><strong>2.4</strong> Kunden ska ha betalningsförmåga vilket kontrolleras med hjälp av kreditupplysning.
      </p>
      <p><strong>2.5</strong> Kunden måste vara folkbokförd i Sverige och inneha svenskt personnummer.</p>

      <h2>§ 3 Betalningsvillkor</h2>
      <p><strong>3.1 </strong>Medlemsavgiften beror på typ av medlemskap och debiteras löpande via autogiro från
        det registrerade betalkortets konto varje månad. De rörliga avgifterna för bokningarna debiteras per
        minut och direktbetalning görs efter varje avslutad bokning på det registrerade betalkortet. Avgifter
        för sen avbokning och för sent återlämnade av fordon görs i form av direktbetalning efter avslutad
        bokning. Övriga avgifter som självrisk, böter och avgifter, ersättning för laddkabel och eventuella
        andra krav har GCP rätt att efterdebitera Kunden. Samtliga priser finns under §10 PRISER OCH AVGIFTER
        eller på hemsida (<a href="https://www.greencarpool.se">www.greencarpool.se</a>).</p>
      <p><strong>3.2 </strong>GCP har rätt att ändra priset på de rörliga avgifterna samt de löpande för
        medlemskapet. Eventuella prisändringar meddelas direkt till Kunden på den e-mailadress som uppges vid
        registrering.</p>

      <h2>§ 4 Skador</h2>
      <p><strong>4.1 </strong>Kunden ansvarar fullt ut för fordonet under hela bokningen från början till slut och
        fram till att fordonet är återlämnat på korrekt sätt. Kunden är ansvarig för alla skador som uppkommer
        under bokningen. GCP kontrollerar alla fordon regelbundet och dokumenterar eventuella skador.
        Kunden är skyldig att innan resan påbörjas undersöka fordonet för att hitta eventuella skador eller
        brister.</p>
      <p><strong>4.2 </strong>Kunden ansvarar för att inom rimlig tid rapportera skador och brister till GCP genom
        att ringa 018-50 40 40 eller maila (<a href="mailto:info@greencarpool.se">info@greencarpool.se</a>) och
        använda rapporterings verktyg som finns på
        hemsida. Skador som kunden anser ha inträffat före bokningen, men som kunden upptäckt vid en normal
        kontroll av fordonet vid upphämtning ska rapporteras på samma sätt.</p>
      <p><strong>4.3 </strong>Kunden är skyldig att omedelbart anmäla alla skador, tekniska fel eller olyckor till
        GCP genom att ringa 018-50 40 40, maila (<a href="mailto:info@greencarpool.se">info@greencarpool.se</a>)
        eller använda rapporterings verktyg som
        finns på hemsidan.</p>
      <p><strong>4.4 </strong>Kunden är skyldig att vid stöld, brand, skadegörelse eller skada på person eller
        djur även alltid anmäla till polisen. Kunden ska fylla i en fullständig skadeanmälan och inte lämna
        fordon utan rätt säkerhetsvidtagelser. Skadeanmälan ska skickas omgående till GCP. Om Kunden inte
        uppfyller sina plikter i samband med skada är Kunden ansvarig för de eventuella merkostnader som kan
        hänföras till försummelsen.</p>
      <p><strong>4.5 </strong>Kunden är skyldig att täcka kostnader för maximal självrisk vid skada eller stöld,
        mer detaljer finns under §10 PRISER OCH AVGIFTER. Om Kunden uppvisat grov oaktsamhet är Kunden ansvarig
        för hela skadan. Detta gäller även för skador som inte täcks av försäkringen.</p>
      <p><strong>4.6 </strong>Kunden är ansvarig för att betala offentliga böter, parkeringsavgifter och andra
        avgifter som sammanhänger med Kundens bokning. I de fall där kund inte betalar dessa avgifter i tid
        tillkommer en administrativ avgift från GCP, se §10 PRISER OCH AVGIFTER.</p>
      <p><strong>4.7 </strong>Kostnader för exempelvis reparationer, transport, skador eller andra avgifter som
        drabbar GCP och som kan härledas till Kundens bokning, är kunden ersättningsansvarig. Självrisken
        framgår under §10 PRISER OCH AVGIFTER. Ansvar som inte täcks av försäkring ska täckas av kunden för
        tillexempel vårdslöshet.</p>

      <h2>§ 5 Kundens skyldigheter</h2>
      <p><strong>5.1 </strong>För att få tillgång till fordonet måste Kunden ha gjort en bokning av fordonet. Bara
        kunder som blivit medlemmar och gjort den aktuella bokningen får använda fordonet under bokad tid.</p>
      <p><strong>5.2 </strong>Kunden hämtar fordonet på bokad tid vid vald hyranläggning. Kunden får tillgång till
        fordonet genom att låsa upp via webbapp. Fordonen har en elektronisk nyckel som aktiveras när bilen
        låses upp och fordonet startas och stängs av genom att trycka på tändningsknapp (Start/Stop).</p>
      <p><strong>5.3 </strong>Kunden är skyldig att besikta fordon innan varje användning och rapportera alla
        skador (invändiga och utvändiga) i webbapp.</p>
      <p><strong>5.4 </strong>Kunden är ansvarig för normalt underhåll under hela bokningen. Normalt underhåll
        innefattar bland annat att däcken har rätt lufttryck och att det finns tillräckligt med spolarvätska. Om
        kunden är tveksam kring normalt underhåll kan kund kontakta GCP. Kunden är skyldig att behandla fordonet
        väl och inte påföra skador. Fordonet ska återlämnas i samma skick som vid avhämtning. Rökning och djur
        är inte tillåtet i GCP:s fordon eller de fordon GCP administrerar.</p>
      <p><strong>5.5 </strong>Kunden ansvarar för att ta med laddkabeln under bokningen om den valda
        hyresanläggningen inte har en fast monterad laddkabel.</p>
      <p><strong>5.6 </strong>Kunden ska återlämna fordonet (efter max 48h) på samma parkeringsplats på
        hyresanläggningen som den hämtade fordonet på och ansluta laddkabel samt kontrollera att laddning
        påbörjats och rapportera eventuella skador samt låsa fordon innan bokning avslutas.
        Kunden ska dock kontrollera att fordon är låst innan den lämnar fordonet.</p>
      <p><strong>5.7 </strong>Bokning övergår till övertid om inte Kunden förlänger bokningen innan eller avslutar
        bokning i tid. Vid övertid debiteras Kunden dubbel minuttaxa till dess att Kunden återlämnat fordonet.
        Överlappar Kundens övertid med efterkommande bokning debiteras även kund en avgift, se §10 PRISER OCH
        AVGIFTER. I de fall Kunden själv glömmer bort att avsluta bokning gör system det automatiskt när
        fordonet är återlämnat efter att bokningen löpt ut, dock sker det inte momentant och den extra tid som
        bilen står parkerad debiteras Kunden för.</p>

      <h2>§ 6 Användningsområden</h2>
      <p><strong>6.1 </strong>Fordonet får inte köras eller på annat sätt transporteras ut ur Sverige utan
        skriftligt samtycke från GCP.</p>
      <p><strong>6.2 </strong>Kunden får inte använda bokat fordon till följande:
        <ul>
          <li>Uthyrning</li>
          <li>Körning av passagerare eller varor mot ersättning</li>
          <li>Körning utanför offentlig väg eller område</li>
          <li>Körning under påverkan av alkohol, narkotika eller medicin</li>
          <li>Tävling, fartprover eller annan form av testkörning</li>
          <li>Upplärning eller annan övningskörning</li>
          <li>Frakt av passagerare eller tyngre last än fordon är registrerat för</li>
          <li>Bogsering av andra fordon eller släp</li>
          <li>Använda fordon på andra sätt, än de ovan beskrivna, som strider mot lagar och trafikregler</li>
        </ul></p>

      <h2>§ 7 GCP:s skyldigheter</h2>
      <p><strong>7.1 </strong>GCP ansvarar för att bokat fordon av kund är tillgänglig och i bra tekniskt skick då
        bokningen börjar.</p>
      <p><strong>7.2 </strong>Kunden har rätt till följande:
        <ul>
          <li>24 timmars vägassistans</li>
          <li>GCP:s kundservice 09.00-18.00 (mån-fre) tel: 018-50 40 40</li>

          <li>Fri körsträcka</li>
          <li>Drivmedel som finns i tank vid start (kostnad för laddning utanför fordons hemmaparkering står
            kund för)
          </li>
          <li>Försäkring i enlighet med de allmänna försäkringsvillkoren i detta avtal</li>
        </ul>
      </p>


      <h2>§ 8 Försäkringsvillkor</h2>
      <p><strong>8.1 </strong>Försäkringen gäller för följande villkor:
        <ul>
          <li>Försäkringen gäller endast i Sverige</li>
          <li>Försäkringen gäller bara för kunder som är över 18 år och har giltigt körkort</li>
          <li>Försäkringen gäller för kunder som kör fordonet under bokningen</li>
          <li>Vid skada eller olycka se §10 för avgifter.</li>
        </ul></p>
      <p><strong>8.2 </strong>Försäkringen gäller inte för:
        <ul>
          <li>Skador som kunden framkallat genom grov oaktsamhet</li>
          <li>Skada som uppstår vid körning på avspärrat område (bana eller landsväg) eller vid deltagande i
            fortkörningstävlingar eller fartprover
          </li>
          <li>Skador som uppstått när fordonet körts medan kunden är påverkad av berusande eller bedövande
            medel
          </li>
          <li>Skada som kunden medvetet orsakat</li>
          <li>Skada som leder till arbete och där med relaterade förluster, exempelvis uteblivna intäkter.
          </li>
        </ul></p>

      <h2>§ 9 Datahantering</h2>
      <p><strong>9.1 </strong>Data samlas från samtliga fordon som ägs eller administreras av GCP för att kunna
        utveckla, analysera och förbättra tjänsten. Exempel på data är GPS-positionering, personuppgifter och
        användarstatistik som kan används för statistiska ändamål. Den insamlade data behandlas konfidentiellt.
      </p>
      <p><strong>9.2 </strong>Fordon som inte återlämnas eller som blir stulna kommer kunna sökas upp och hämtas
        hem via det GPS-lokaliseringsverktyget som GCP administrerar.</p>
      <p><strong>9.3 </strong>Vid ändring av Användarens namn, adress, telefonnummer/mobilnummer eller
        e-postadress under avtalstiden skall Kunden själv omgående uppdatera sin användarprofil på GCP:s
        bokningssida. Kunden är själv ansvarig för att denna uppdatering sker.</p>

      <h2>§ 10 Priser och avgifter</h2>
      <p><strong>10.1</strong> Priser för användande av tjänst samt medlemskap finns på hemsida
        (<a href="https://www.greencarpool.se">www.greencarpool.se</a>).</p>
      <p><strong>10.2</strong> Följande avgifter gäller vid överträdelser (inkl. moms)
        <ul>
          <li>Borttappad labbkabel upp till 5 000 SEK</li>
          <li>Rökning i fordonet 1 500 SEK</li>
          <li>Djur i fordonet 1 000 SEK</li>
          <li>Ostädad eller kraftigt nedsmutsat fordon 300 – 1 000 SEK (ytterligare kostnad kan tillkomma vid
            rekonditionering)
          </li>
          <li>Försent återlämnat fordon som överlappar nästkommande bokning 500 SEK</li>
          <li>Försenat fordon med mindre än 20% drivmedel kvar som inte överlappar nästkommande bokning men
            har mindre har mindre än 15 minuter till nästkommande bokning 250 SEK
          </li>
          <li>Försenat fordon som inte överlappar nästkommande bokning och har mer än 20% batteri dubbel
            minuttaxa under övertidsminuter. Minutpris varierar på medlemskap, se hemsida.
          </li>
          <li>Olåst fordon, påslagna lampor eller nedhissade fönster som kräver utryckning från GCP 500 SEK
          </li>

          <li>Återlämnat fordon där laddkabel inte har anslutits och kontroll gjorts att fordon börjat ladda
            250 SEK
          </li>
          <li>Offentliga böter, parkeringsavgifter eller andra avgifter som utfärdas men inte betalas av kund
            tillkommer, utöver själva avgiften utfärdat av polis, parkeringsvakt m.m., en
            administrativavgift om 250 SEK.
          </li>
          <li>Avbokning upp till fem minuter innan eller under ordinarie bokning 25 SEK (minimumavgift för
            bokning)
          </li>
          <li>Fordon som stjäls med webbapp 100 000 SEK</li>
        </ul></p>
      <p><strong>10.3</strong> Självrisk vid skada eller olycka
        <ul>
          <li>Självrisk vagnskada maxbelopp 9 500 SEK. Vid kollision med annat fordon eller egendom där även
            bilens trafikförsäkring behöver nyttjas kan ytterligare självrisk på upp till 4500 SEK
            tillkomma.
          </li>
        </ul></p>

      <h2>§ 11 Hantering av personuppgifter</h2>
      <p><strong>11.1</strong> Informationen från de registrerade betalkorten lagras tryggt hos vår partner
        (enligt GDPR) som tillhandahåller betalningslösningen.</p>
      <p><strong>11.2</strong> GCP värderar din integritet och lagrar därför dina personuppgifter i enlighet med
        GDPR. Dina personuppgifter lagras hos oss så länge det är nödvändigt för att kunna uppfylla de olika
        ändamålen, vilket varierar för olika typ av information.</p>
      <p><strong>11.3</strong> De personuppgifter GCP lagrar är:
        <ul>
          <li>Namn, adress och kontaktuppgifter</li>
          <li>Betalningsinformation</li>
          <li>Ditt körkort</li>
          <li>Din historik</li>
        </ul>
      </p>
      <p><strong>11.4</strong> Data för övergripande användande lagras och analyseras så länge du är medlem hos
        GCP och raderas i samband med att du avslutar ditt medlemskap.</p>
      <p><strong>11.5</strong> Dina personuppgifter lämnas inte ut till andra mottagare såvida inte det finns
        särskilda skäl som vid exempelvis, brott eller försäkrings­skador.</p>
      <p><strong>11.6</strong> Som kund hos GCP har:
        <ul>
          <li>du rätt att när som helst begära information om vilka personuppgifter vi har sparat om dig</li>
          <li>du rätt att när som helst få felaktiga uppgifter rättade.</li>
          <li>du rätt att när som helst få dina personuppgifter raderade från vårt system</li>
          <li>du även rätt att klaga till Datainspektionen, som är tillsynsmyndighet för behandling av
            personuppgifter.
          </li>
        </ul></p>
      <p><strong>11.7</strong> Om du skulle anse att vår behandling av dina personuppgifter inte sker enligt
        gällande lagar och regler ber vi dig att kontakta oss på <a
          href="mailto:info@greencarpool.se">info@greencarpool.se</a></p>

      <h2>§ 12 Tvister</h2>
      <p><strong>12.1</strong> Tvist med anledning av Avtalet mellan GCP och Kunden och/eller tolkning av dessa
        Allmänna Villkor ska i första hand lösas genom förhandlingar. Tvister som inte kan lösas genom
        förhandlingar ska slutligen avgöras enligt svensk lag med Uppsala Tingsrätt som första instans.</p>

    </div>
  )
};

export default ToS;
