import {BaseModel} from "./BaseModel.model"
import {Organization} from "./Organization.model"
import {PeriodicAvailability} from "./PeriodicAvailability.model"
import {Vehicle} from "../../../api/src/models/Vehicle.model"

export enum GroupVisibilityLevel {
    PUBLIC = 'public',
    ORGANIZATION = 'organization',
    HIDDEN = 'hidden'
}

export interface Group extends BaseModel {
    organizations?: Organization[]
    name?: string
    visibilityLevel?: GroupVisibilityLevel
    periodicAvailability?: PeriodicAvailability[]
    vehicles?: Vehicle[]
    groupUsersHaveFreeRides?: boolean
}