import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import {RouteComponentProps} from "react-router";
import classesCommon from '../../AdminEntityCommon.module.scss';
import {AdminCarAdditionalInfo} from "../AdminCarAdditionalInfo/AdminCarAdditionalInfo.component";
import AdminCarBasicForm from '../AdminCarBasicForm/AdminCarBasicForm.component';
import AdminCarExternalForm from "../AdminCarExternalForm/AdminCarExternalForm.component";
import {connect} from "react-redux";
import {AuthClaims} from "../../../../models/AuthClaims.model";
import AdminVehicleService from "../../../../services/adminVehicle.service";
import {Vehicle} from "../../../../models/Vehicle.model";
import {Card, Tab, Tabs} from "@material-ui/core";
import Bookings from "../Bookings.component";
import {AdminCarCalendar} from "../AdminCarCalendar/AdminCarCalendar.component";
import {useTranslation} from "react-i18next";

interface Params {
    id: string;
}

interface Props extends RouteComponentProps<Params> {
    claims?: AuthClaims;
}

const AdminCar = (props: Props) => {

    const [id, setId] = useState<string>();
    const [vehicle, setVehicle] = useState<Vehicle>();
    const [tab, setTab] = useState(0);
    const t = useTranslation().t

    const fetchData = async () => {
        if (id) {
            const vehicle = await AdminVehicleService.getVehicles(+id);
            setVehicle(vehicle);
        }
    }

    useEffect(() => {
        const id = props.match.params.id
        setId(id);
    }, [props.match.params.id, props.claims]);

    useEffect(() => {
        fetchData();
    }, [id]);

    if (!id) return null;

    const isNew = id === 'new';

    const tabs = (
        <Card style={{marginBottom: 20, width: 'fit-content'}}>
            <Tabs
                textColor={"primary"}
                indicatorColor={'primary'}
                value={tab}
                onChange={(_, t) => setTab(t)}
            >
                <Tab label={t('Basic info')} tabIndex={0}/>
                <Tab label={t('Additional info')} tabIndex={1}/>
                {!isNew && <Tab label={t('Device control')} tabIndex={2}/>}
                {!isNew && <Tab label={t('Bookings')} tabIndex={3}/>}
                {!isNew && <Tab label={t('Calendar')} tabIndex={4}/>}
            </Tabs>
        </Card>
    )

    return (
        <FlexView hAlignContent='center'>

            <FlexView column className={classesCommon.component}>

                {tabs}

                {
                    tab === 0 &&
                    <AdminCarBasicForm
                        vehicle={vehicle}
                        onUpdate={setVehicle}
                        claims={props.claims}
                    />
                }

                {
                    tab === 1 &&
                    <AdminCarAdditionalInfo
                        vehicle={vehicle}
                        onUpdate={setVehicle}
                    />
                }

                {
                    tab === 2 &&
                    <AdminCarExternalForm
                        vehicle={vehicle}
                        onUpdate={setVehicle}
                    />
                }

                {
                    tab === 3 &&
                    <Bookings vehicle={vehicle}/>
                }

                {
                    tab === 4 &&
                    <AdminCarCalendar vehicle={vehicle}/>
                }

            </FlexView>

        </FlexView>
    );
};


const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(AdminCar);
