import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography
} from "@material-ui/core";
import classes from './SessionEndConfirmationDialog.module.scss';
import {RouteComponentProps, withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import GcpButton from "../../../common/ui/GcpButton";
import CloseIcon from '@material-ui/icons/Close';

interface Props extends RouteComponentProps {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void
}

const SessionEndConfirmationDialog = (props: Props) => {

    const t = useTranslation().t
    const [engineOff, setEngineOff] = useState(false)
    const [doorsClosed, setDoorsClosed] = useState(false)
    const [personalStuffOut, setPersonalStuffOur] = useState(false)
    const [noDamage, setNoDamage] = useState(false)
    const [vehicleCharging, setVehicleCharging] = useState(false)
    const [vehicleLocked, setVehicleLocked] = useState(false)

    useEffect(() => {
        if (props.open) {
            setEngineOff(false)
            setDoorsClosed(false)
            setPersonalStuffOur(false)
            setNoDamage(false)
            setVehicleCharging(false)
            setVehicleLocked(false)
        }
    }, [props.open])

    const renderCheck = (checked: boolean, setChecked: (checked: boolean) => void, title: string) => (
        <FormControlLabel
            control={<Checkbox
                color={'primary'}
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
            />}
            label={title}
        />
    )

    return (
        <Dialog aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={'sm'}
                onBackdropClick={props.onClose}
        >

            <DialogTitle id="customized-dialog-title">
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                >

                    <Typography>
                        {t('Before ending this booking')}
                    </Typography>

                    <Box marginLeft={'30px'}>
                        <GcpButton
                            color={'error'}
                            onClick={props.onClose}
                        >
                            <CloseIcon />
                        </GcpButton>
                    </Box>

                </Box>

            </DialogTitle>

            <DialogContent dividers className={classes.container}>
                {/*<div*/}
                {/*    className={classes.welcomeMessage}>{t(`Don't forget to connect the charger to the vehicle`)}</div>*/}

                {/*<img src={Charger} className={classes.charger} />*/}

                {renderCheck(engineOff, setEngineOff, t('Engine is turned off'))}
                {renderCheck(doorsClosed, setDoorsClosed, t('Doors and windows are closed'))}
                {renderCheck(personalStuffOut, setPersonalStuffOur, t('Personal stuff is cleaned out'))}
                {renderCheck(noDamage, setNoDamage, t('No damages on vehicle'))}
                {renderCheck(vehicleCharging, setVehicleCharging, t('Vehicle is charging'))}
                {renderCheck(vehicleLocked, setVehicleLocked, t('Vehicle is locked'))}
            </DialogContent>

            <DialogActions className={classes.container}>
                <Button
                    disabled={!(engineOff && doorsClosed && personalStuffOut && noDamage && vehicleCharging && vehicleLocked)}
                    onClick={props.onConfirm} color="primary"
                    variant='contained'
                >
                    {t('Ok')}
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default withRouter(SessionEndConfirmationDialog);
