import {User} from "../models/User.model";
import {Booking} from "../models/Booking.model";
import {Subscription} from "../../../api/src/models/Subscription.model";
import {Vehicle} from "../models/Vehicle.model";
import {
    addDays,
    differenceInDays,
    differenceInMinutes,
    format,
    getDay,
    isAfter,
    isBefore,
    setHours,
    setMinutes,
    subDays
} from "date-fns";
import {DAY_OF_WEEK_DATE_FNS, PeriodicAvailability} from "../models/PeriodicAvailability.model";
import {Maintenance} from "../models/Maintenance.model";

export const getPriceForUser = (vehicle?: Vehicle, _user?: User, _subscription?: Subscription) => {
    // const regularPriceDefault = +(process.env.REACT_APP_REGULAR_PRICE || 0);
    // const premiumPriceDefault = +(process.env.REACT_APP_PREMIUM_PRICE || 0);
    //
    // const regularPrice = vehicle?.regularPriceOverride || regularPriceDefault
    // const premiumPrice = vehicle?.premiumPriceOverride || premiumPriceDefault
    //
    // const premium = user && hasPremiumSubscription(subscription) || false;
    // return premium ? premiumPrice : regularPrice;
    return vehicle?.pricePerMinuteForUser || 0
};

export const getMinCharge = (_vehicle?: Vehicle, _user?: User, _subscription?: Subscription) => {
    const minCharge = +(process.env.REACT_APP_MIN_CHARGE || '0');
    return minCharge
}

export const getDailyMax = (vehicle?: Vehicle, _user?: User, _subscription?: Subscription) => {
    // const regularDailyMaxDefault = +(process.env.REACT_APP_REGULAR_DIALY_MAX || '0')
    // const premiumDailyMaxDefault = +(process.env.REACT_APP_PREMIUM_DIALY_MAX || '0')
    //
    // const regularDailyMax = vehicle?.regularDailyMaxOverride || regularDailyMaxDefault
    // const premiumDailyMax = vehicle?.premiumDailyMaxOverride || premiumDailyMaxDefault
    //
    // const premium = user && hasPremiumSubscription(subscription) || false;
    // return premium ? premiumDailyMax : regularDailyMax;
    return vehicle?.dailyMaxForUser || 0
}

export const calculateBookingPrice = (
    booking: Booking,
    vehicle?: Vehicle,
    user?: User,
    subscription?: Subscription
): number => {
    if (!booking.to || !booking.from) return 0

    const pricePerMinute = booking.id ? booking.pricePreMinute ?? getPriceForUser(vehicle, user, subscription) : getPriceForUser(vehicle, user, subscription)
    const dailyMax = booking.id ? booking.dailyMax ?? getDailyMax(vehicle, user, subscription) : getDailyMax(vehicle, user, subscription)
    let minCharge = getMinCharge(vehicle, user, subscription)
    if (pricePerMinute === 0) {
        minCharge = 0
    }

    const totalMinutes = differenceInMinutes(booking.to, booking.from);

    // Daily, we can charge max of "dailyMax" value
    const totalDays = differenceInDays(booking.to, booking.from);

    // Remaining minutes in the last "day"
    const remainingMinutes = totalMinutes - totalDays * 24 * 60;

    // In the last day, we may as well "hit" the daily limit so check for that
    const remainingPrice = Math.min(remainingMinutes * pricePerMinute, dailyMax);

    const totalPrice = totalDays * dailyMax + remainingPrice;

    // Make sure we charge at least minCharge
    return Math.max(minCharge, totalPrice);
};

export const calculateBookingPriceFormattedWeb = (booking?: Booking, vehicle?: Vehicle, user?: User, subscription?: Subscription): string => {
    if (!booking?.from || !booking.to) return '0.00';

    const price = calculateBookingPrice(booking, vehicle, user, subscription)
    return price && price.toFixed(2) || '0.00';
};

export function formatDateFull(date?: Date): string {
    return date && format(date, 'yyyy/MM/dd HH:mm') || '';
}

export function periodsOverlap(period1?: { from?: Date, to?: Date }, period2?: { from?: Date, to?: Date }): boolean {
    if (!period1 || !period2 || !period1.from || !period1.to || !period2.from || !period2.to) return false;

    const from1 = period1.from
    const to1 = period1.to

    const from2 = period2.from
    const to2 = period2.to

    return  isAfter(to1, from2) && isBefore(from1, to2)
}

export function multiplePeriodsOverlap(periods: { from?: Date, to?: Date }[], periodToCompare?: { from?: Date, to?: Date }) {
    return periods.reduce((overlap, booking) => overlap || periodsOverlap(booking, periodToCompare), false);
}

export function dateInsideTimedEntities(date: Date, entities: { from?: Date, to?: Date }[]): boolean {
    if (!date || !entities) return false;
    return entities.reduce((isInside: boolean, booking) =>
        isInside || (booking.from && booking.to && (isAfter(date, booking.from) && isBefore(date, booking.to)) || false)
        , false);
}

export function totalMileage(booking: Booking) {
    if (!booking) return 0;
    if (!booking.initialMileage || !booking.endMileage) return 0;

    return booking.endMileage - booking.initialMileage;
}

export function convertPeriodicAvailabilityAsMaintenance(fromDate?: Date, toDate?: Date, maxRange?: number, periodicAvailability?: PeriodicAvailability[]) {
    if (!fromDate || !toDate) return []

    const results: Maintenance[] = []
    periodicAvailability?.forEach(periodicAvailability => {
        // Go 2 weeks back
        let startDate = subDays(fromDate, maxRange || 7)
        // Find the nearest week day
        const currentDayOfWeek = getDay(startDate)
        const diff = DAY_OF_WEEK_DATE_FNS[periodicAvailability.fromDay!] - currentDayOfWeek

        startDate = addDays(startDate, diff)
        startDate = setHours(startDate, periodicAvailability.fromHours!)
        startDate = setMinutes(startDate, periodicAvailability.fromMinutes!)

        let startToEndDiff = DAY_OF_WEEK_DATE_FNS[periodicAvailability.toDay!] - DAY_OF_WEEK_DATE_FNS[periodicAvailability.fromDay!]
        if (startToEndDiff < 0) {
            startToEndDiff = 7 + startToEndDiff
        }

        let endDate = addDays(startDate, startToEndDiff)
        endDate = setHours(endDate, periodicAvailability.toHours!)
        endDate = setMinutes(endDate, periodicAvailability.toMinutes!)

        while (isBefore(startDate, addDays(toDate, maxRange || 7))) {
            results.push({
                from: startDate,
                to: endDate
            })
            startDate = addDays(startDate, 7)
            endDate = addDays(endDate, 7)
        }
    })
    return results
}