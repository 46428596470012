import {
    ADD_PAYMENT_METHOD,
    REMOVE_PAYMENT_METHOD,
    SET_INVOICES,
    SET_PAYMENT_METHODS,
    SET_STRIPE_COUPON,
    SET_STRIPE_USER,
    SET_UPDATING_BILLING
} from "../actions/billing.actions";
import PaymentMethod from "../../models/PaymentMethod.model";
import {LOGOUT_DONE} from "../actions/actionTypes";
import StripeInvoice from "../../models/StripeInvoice.model";

interface State {
    paymentMethods: PaymentMethod[],
    paymentMethodsError: string,
    paymentMethodsLoading: boolean,

    addPaymentMethodError: string,
    addPaymentMethodLoading: boolean
    removePaymentMethodError: string,
    removePaymentMethodLoading: boolean,
    updatingBilling: boolean;

    stripeUser: any,
    stripeUserError: string,
    stripeUserLoading: boolean,

    stripeCoupon: any,
    stripeCouponLoading: boolean,
    stripeCouponError: string,

    invoices: StripeInvoice[],
    invoicesError: string,
    invoicesLoading: boolean,
}

const initialState: State = {
    paymentMethods: [],
    paymentMethodsError: '',
    paymentMethodsLoading: false,

    addPaymentMethodError: '',
    addPaymentMethodLoading: false,
    removePaymentMethodError: '',
    removePaymentMethodLoading: false,
    updatingBilling: false,

    stripeUser: null,
    stripeUserError: '',
    stripeUserLoading: false,

    stripeCoupon: null,
    stripeCouponLoading: false,
    stripeCouponError: '',

    invoices: [],
    invoicesError: '',
    invoicesLoading: false,
};

const billingReducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case SET_PAYMENT_METHODS:
            return {
                ...state,
                ...action.payload
            };
        case ADD_PAYMENT_METHOD:
            let newPaymentMethods = [...state.paymentMethods];
            if (action.payload.method) {
                newPaymentMethods.unshift(action.payload.method);
            }

            return {
                ...state,
                addPaymentMethodError: action.payload.addPaymentMethodError,
                addPaymentMethodLoading: action.payload.addPaymentMethodLoading,
                paymentMethods: newPaymentMethods,
                updatingBilling: false
            };
        case REMOVE_PAYMENT_METHOD:
            newPaymentMethods = [...state.paymentMethods];
            if (action.payload.methodId) {
                newPaymentMethods = newPaymentMethods.filter(method => method.id !== action.payload.methodId);
            }

            return {
                ...state,
                removePaymentMethodError: action.payload.removePaymentMethodError,
                removePaymentMethodLoading: action.payload.removePaymentMethodLoading,
                paymentMethods: newPaymentMethods
            };
        case SET_UPDATING_BILLING:
            return {
                ...state,
                updatingBilling: action.payload
            };

        case SET_INVOICES:
            return {
                ...state,
                ...action.payload
            };

        case SET_STRIPE_USER:
            return {
                ...state,
                ...action.payload
            };

        case SET_STRIPE_COUPON:
            return {
                ...state,
                ...action.payload
            };

        case LOGOUT_DONE:
            return {
                ...initialState
            }
    }
    return state;
};

export default billingReducer;
