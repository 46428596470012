import classes from "./MyBookings.module.scss";
import FlexView from "react-flexview/lib/FlexView";
import React from "react";
import clsx from "clsx";
import DoughnutChart from "../../../common/ui/DoughnutChart/DoughnutChart.component";
import {COLORS} from "../../../styles/theme";
import {fillLoaderContent} from "../../../common/ui.util";
import HistoryOverview, {ValueByVehicleType} from "../../../models/HistoryOverview.model";
import {VehicleType} from "../../../models/Vehicle.model";
import {numberFormat} from "../../../common/misc.util";
import {Box} from "@material-ui/core";
import VehicleTypeCarGreen from '../../../assets/icons/vehicle-type-car-green.svg';
import VehicleTypeScooterOrange from "../../../assets/icons/vehicle-type-scooter-orange.svg";
import VehicleTypeBikeBlue from "../../../assets/icons/vehicle-type-bicycle-blue.svg";
import {useTranslation} from "react-i18next";

interface Props {
    overview?: HistoryOverview
    signInProgress: boolean,
    overviewLoading: boolean,
    overviewLoadingError: string,
    showSpending?: boolean
}

function pad(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

const Overview = (props: Props) => {

    //region Util

    const t = useTranslation().t
    const getValueForType = (arr?: ValueByVehicleType[], vehicleType?: VehicleType) => {
        return arr?.find(v => v.vehicleType === vehicleType)?.total || 0
    }

    const getSummedValue = (arr?: ValueByVehicleType[]) => {
        return arr?.reduce((prev, curr) => prev + (curr.total || 0), 0) || 0
    }

    const hasValueForType = (arr?: ValueByVehicleType[], vehicleType?: VehicleType) => {
        return !!getValueForType(arr, vehicleType)
    }

    const renderBox = (arr: ValueByVehicleType[], formatter: (value: number) => string, title: string, colorOptions: string[], extraClass?: string) => {
        const data = []
        const colors = []

        if (hasValueForType(arr, VehicleType.CAR)) {
            data.push({
                label: `${t('Cars')}\n${formatter(getValueForType(arr, VehicleType.CAR))}`,
                value: getValueForType(arr, VehicleType.CAR)
            })
            colors.push(colorOptions[0])
        }

        if (hasValueForType(arr, VehicleType.SCOOTER)) {
            data.push({
                label: `${t('Scooters')}\n${formatter(getValueForType(arr, VehicleType.SCOOTER))}`,
                value: getValueForType(arr, VehicleType.SCOOTER)
            })
            colors.push(colorOptions[1])
        }

        if (hasValueForType(arr, VehicleType.BICYCLE)) {
            data.push({
                label: `${t('Bikes')}\n${formatter(getValueForType(arr, VehicleType.BICYCLE))}`,
                value: getValueForType(arr, VehicleType.BICYCLE)
            })
            colors.push(colorOptions[2])
        }

        return (
            <FlexView
                column
                hAlignContent='center'
                vAlignContent='center'
                className={clsx('container', classes.overview, extraClass)}
            >
                <Box
                    display={'flex'}
                    alignItems='center'
                    justifyContent='center'
                    width={'100%'}
                    height={'100%'}
                    className={classes.chartValueHolder}
                >
                    <div className={classes.chartHolder}>
                        <DoughnutChart
                            data={data}
                            colors={colors}
                        />
                    </div>

                    <FlexView
                        column
                        hAlignContent='center'
                        vAlignContent='center'
                        className={classes.valueHolder}
                    >
                        <div className={classes.value}>{formatter(getSummedValue(arr))}</div>
                        <div className={classes.type}>{title}</div>
                    </FlexView>
                </Box>

                {
                    window.innerWidth < 700 &&
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        height={40}
                        flexWrap={'wrap'}
                        padding={0.5}
                        width={'100%'}
                        boxSizing={'border-box'}
                        justifyContent={'space-between'}
                        // paddingLeft={2}
                        // paddingTop={1}
                        // p={2}
                        // className={clsx('container', classes.overview)}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginRight={1}
                            marginBottom={0.3}
                        >
                            <img src={VehicleTypeCarGreen} className={classes.vehicleOverviewLegend}/>
                            <div
                                className={classes.vehicleOverviewLegendText}>{formatter(getValueForType(arr, VehicleType.CAR))}</div>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginRight={1}
                            marginBottom={0.3}
                        >
                            <img src={VehicleTypeScooterOrange} className={classes.vehicleOverviewLegend}/>
                            <div
                                className={classes.vehicleOverviewLegendText}>{formatter(getValueForType(arr, VehicleType.SCOOTER))}</div>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                        >
                            <img src={VehicleTypeBikeBlue} className={classes.vehicleOverviewLegend}/>
                            <div
                                className={classes.vehicleOverviewLegendText}>{formatter(getValueForType(arr, VehicleType.BICYCLE))}</div>
                        </Box>
                    </Box>
                }
            </FlexView>
        )
    }


    //endregion Util

    //region Time spent

    const timeFormatter = (minutesTotal: number) => {
        const hours = Math.floor(minutesTotal / 60);
        const minutes = Math.floor(minutesTotal - hours * 60);
        return `${pad(hours, 2)}:h ${pad(minutes, 2)}:m`
    };

    const renderTimeBox = () => {
        return renderBox(
            props.overview?.totalMinutes || [],
            timeFormatter,
            t('Rent hours'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
            classes.timeHolder
        )
    }

    //endregion Time spent

    //region Mileage

    const mileageFormatter = (mileageTotal: number) => {
        return numberFormat(mileageTotal, 0, '.', ' ') + 'km';
    };

    const renderMileageBox = () => {
        return renderBox(
            props.overview?.totalMileage || [],
            mileageFormatter,
            t('Driven'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE],
            classes.kmHolder
        )
    }

    //endregion Mileage

    //region CO2 reduction

    const co2Formatter = (co2Total: number) => {
        return numberFormat(co2Total / 1000, 0, '.', ' ') + 'kg';
    };

    const renderCO2Box = () => {
        return renderBox(
            props.overview?.totalCO2 || [],
            co2Formatter,
            t('CO2 Reduction'),
            window.innerWidth < 700 ? [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE] : ['#212121', '#6b6b6b', '#8f8f8f'],
            classes.co2Holder
        )
    }

    //endregion CO2 reduction

    //region Spending

    const spendingFormatter = (spending: number) => {
        return numberFormat(spending / 1000, 2, '.', ' ') + 'kr';
    };

    const renderSpendingBox = () => {
        return renderBox(
            props.overview?.totalSpent || [],
            spendingFormatter,
            t('Total cost'),
            [COLORS.PRIMARY, COLORS.ORANGE, COLORS.BLUE]
        )
    }

    //endregion Spending

    const renderOverview = () => {
        if (props.overviewLoadingError) {
            return (
                <FlexView
                    className={classes.errorHolder}
                    hAlignContent='center'
                    vAlignContent='center'
                >
                    <div>{t("We couldn't load your overview...")}</div>
                </FlexView>
            )
        }


        return (
            <FlexView className={classes.overviewHolder} wrap>
                {renderTimeBox()}
                {renderMileageBox()}
                {renderCO2Box()}
                {props.showSpending && renderSpendingBox()}

            </FlexView>
        )
    };

    const renderOverviewLoaders = () => {
        return (
            <FlexView className={classes.overviewHolder} wrap>

                {
                    [...Array(3)].map((_, index) => (
                        <FlexView key={index} column hAlignContent='center' vAlignContent='center'
                                  className={clsx('container', classes.overview, index === 2 && classes.co2Overview)}>
                            <div className={classes.value}>{fillLoaderContent(40, 30)}</div>
                            <div className={classes.type}>{fillLoaderContent(60, 15)}</div>
                        </FlexView>
                    ))
                }

            </FlexView>
        )
    };

    return (
        <React.Fragment>
            <div className={classes.sectionTitle}>{t('Overview')}</div>
            {props.signInProgress || props.overviewLoading ? renderOverviewLoaders() : renderOverview()}
        </React.Fragment>
    )
}

export default Overview;