import React from 'react';
import {Card, CircularProgress} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../AdminListCommon.module.scss';
import clsx from 'clsx';
import {PeriodicAvailability} from "../../../models/PeriodicAvailability.model";
import {useTranslation} from "react-i18next";

interface Props {
    loading?: boolean;
    periodicAvailability?: PeriodicAvailability[];
    onPeriodicAvailabilityClick: (periodicAvailability: PeriodicAvailability) => void;
    showDelete?: boolean;
}

const PeriodicAvailabilityList = ({loading, periodicAvailability, onPeriodicAvailabilityClick}: Props) => {

    const t = useTranslation().t

    if (loading) {
        return <CircularProgress />
    }

    if (!periodicAvailability?.length) {
        return <div>{t('No periodic availability found')}</div>
    }

    const header = (
        <FlexView vAlignContent='center' className={classes.header}>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Start day')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('End day')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Start time')}</div>
            <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('End time')}</div>
        </FlexView>
    )

    const content = periodicAvailability.map(periodicAvailability => {
        return (
            <FlexView key={periodicAvailability.id} onClick={() => onPeriodicAvailabilityClick?.(periodicAvailability)}
                      vAlignContent='center'
                      className={classes.tableRow}>
                <div className={classes.cellSmall}>{periodicAvailability.fromDay}</div>
                <div className={classes.cellSmall}>{periodicAvailability.toDay}</div>
                <div
                    className={classes.cellSmall}>{periodicAvailability.fromHours}:{periodicAvailability.fromMinutes}</div>
                <div className={classes.cellSmall}>{periodicAvailability.toHours}:{periodicAvailability.toMinutes}</div>

            </FlexView>
        )
    })

    return (
        <Card>

            {header}
            {content}

        </Card>
    )
}

export default PeriodicAvailabilityList;