import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import FlexView from "react-flexview";
import classes from './Profile.module.scss';
import {Redirect, RouteComponentProps} from "react-router";
import clsx from "clsx";
import {initLogout, initUpdateInfo, setLanguage} from "../../store/actions/auth.actions";
import UK from '../../assets/icons/flag-uk.svg';
import Sweden from '../../assets/icons/flag-sweden.svg';
import DangerButton from "../../common/ui/DangerButton.component";
import Footer from "../../common/ui/Footer/Footer.component";
import Subscription from "./Subscription/Subscriptions.component";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import UserInfo from "./UserInfo/UserInfo.component";
import PaymentMethods from "./PaymentMethods/PaymentMethods.component";
import Billing from "./InvoicePreview/Billing.component";
import AuthServiceNew from "../../services/auth.service";
import {AuthClaims} from "../../models/AuthClaims.model";
import LoggedInAs from "../listing/LoggedInAs/LoggedInAs.component";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../services/googleAnalytics";

interface Props extends RouteComponentProps {
    error: boolean,
    claims?: AuthClaims,
    tokenSigninInProgress: boolean,
    tokenSigninTried: boolean,

    setLanguage: typeof setLanguage,
    initLogout: typeof initLogout,
}

const Profile = (props: Props) => {

    // ***** State ***** //

    const {t, i18n} = useTranslation()
    // const [requestDataInProgress, setRequestDataInProgress] = useState(false);
    const [requestDeleteInProgress, setRequestDeleteInProgress] = useState(false);
    const [requestSnackbarText, setRequestSnackbarText] = useState('');

    // ***** Handlers ***** //

    useEffect(() => {
        trackCurrentPage()
    }, [])

    const handleLogout = () => {
        props.initLogout()
    };

    const handleLanguageChange = async (languageCode: string) => {
        await i18n.changeLanguage(languageCode);
        props.setLanguage(languageCode);
    };

    const hideSnackbar = () => {
        setRequestSnackbarText('');
    };

    // const handleRequestData = async () => {
    //     setRequestDataInProgress(true);
    //     await AuthServiceNew.requestData('request_data');
    //     setRequestSnackbarText(t('Your request was received. We will notify you once the data processing is done.'));
    //     setRequestDataInProgress(false);
    // };

    const handleRequestDeleteProfile = async () => {
        setRequestDeleteInProgress(true);
        await AuthServiceNew.requestData('delete_account');
        setRequestSnackbarText(t('Your request was received. We will notify you once the data processing is done.'));
        setRequestDeleteInProgress(false);
    };

    // ***** Render ***** //

    const renderSnackBar = () => {
        const snackbarText = props.error || requestSnackbarText;

        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={!!snackbarText}
                autoHideDuration={4000}
                onClose={hideSnackbar}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={snackbarText}
                />
            </Snackbar>
        );
    };

    return (
        <FlexView column className='component' vAlignContent='top' hAlignContent='center'>

            <FlexView wrap className={clsx('container', classes.form)} vAlignContent='center'>

                <div>{t('Logged in as', {name: props.claims?.user?.name})}</div>

                <Button variant="contained" color="primary"
                        onClick={handleLogout}>
                    {t('Logout')}
                </Button>

            </FlexView>

            <LoggedInAs/>

            {/* Basic info */}
            <div className={classes.title}>{t('Update info')}</div>
            <UserInfo/>

            {/* Subscription */}
            {
                process.env.REACT_APP_PREMIUM_SUBSCRIPTIONS_ENABLED === 'true' &&
                <React.Fragment>
                    <div className={classes.title}>{t('Subscription')}</div>
                    <FlexView column className={clsx('container', classes.form)}>
                        <Subscription confirmationMode={'button'}/>
                    </FlexView>
                </React.Fragment>
            }

            {/* Payment method */}
            <div className={classes.title}>{t('Payment method')}</div>
            <FlexView column className={clsx('container', classes.form)}>
                <PaymentMethods/>
            </FlexView>

            {/* Invoices */}
            <Billing/>

            {/* Misc */}
            <div className={classes.title}>{t('More actions')}</div>

            <FlexView column className={clsx('container', classes.form)}>

                {/* Language */}
                <FlexView hAlignContent='left' vAlignContent='center' className={classes.moreInfoSectionRow}>
                    <div className={classes.moreInfoLabel}>{t('Language')}</div>
                    <img className={classes.languageFlag} src={Sweden}
                         onClick={() => handleLanguageChange('sv')}/>
                    <img className={classes.languageFlag} src={UK}
                         onClick={() => handleLanguageChange('en')}/>
                    {/*<img className={classes.languageFlag} src={Serbia}*/}
                    {/*onClick={() => handleLanguageChange('sr')}/>*/}
                </FlexView>

                {/* Get info */}
                {/*<FlexView className={classes.moreActionsButtonHolder} vAlignContent={'center'}*/}
                {/*          hAlignContent={'center'}>*/}
                {/*    {*/}
                {/*        requestDataInProgress ? <CircularProgress/> :*/}
                {/*            <Button variant="contained" color="primary"*/}
                {/*                    onClick={handleRequestData}*/}
                {/*                    className={classes.moreActionsButton}>{t('Request my data')}</Button>*/}
                {/*    }*/}
                {/*</FlexView>*/}

                {/* Delete profile */}
                <FlexView className={classes.moreActionsButtonHolder} vAlignContent={'center'}
                          hAlignContent={'center'}>
                    {
                        requestDeleteInProgress ? <CircularProgress/> :
                            <DangerButton variant="contained" color="primary"
                                          onClick={handleRequestDeleteProfile}
                                          className={classes.moreActionsButton}>{t('Delete my account')}</DangerButton>
                    }
                </FlexView>

            </FlexView>

            <Footer/>

            {renderSnackBar()}

            {props.tokenSigninTried && !props.tokenSigninInProgress && !props.claims?.user ?
                <Redirect to={"/"}/> : null}

        </FlexView>
    );
}

// ***** Redux ***** //

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
        error: state.auth.error,
        updateInfoInProgress: state.auth.updateInfoInProgress,
        tokenSigninInProgress: state.auth.tokenSigninInProgress,
        tokenSigninTried: state.auth.tokenSigninTried
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initUpdateInfo,
        setLanguage,
        initLogout
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
