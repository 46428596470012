import React from 'react';
import FlexView from "react-flexview";
import classes from './AboutUs.module.scss';
import GreenMobility from '../../../assets/images/green-mobility.jpg';
import {useTranslation} from "react-i18next";

const AboutUs = () => {
    const t = useTranslation().t
    
    return (
        <FlexView className={classes.component} hAlignContent='center' wrap>
            <FlexView column className={classes.text} hAlignContent='center' vAlignContent='center'>
                <div
                    className={classes.short}>{t("We believe the way we own our vehicles will fundamentally change")}</div>
                <div
                    className={classes.long}>{t("We believe the way we own our vehicles will fundamentally change details")}</div>
            </FlexView>

            <img className={classes.image} src={GreenMobility}/>
        </FlexView>
    )
}

export default AboutUs;
