import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField
} from "@material-ui/core"
import {User} from "../../../../models/User.model"
import AdminUserService from "../../../../services/adminUser.service";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    onClose: () => void
    users: User[]
}

const SendMessageDialog = (props: Props) => {

    const t = useTranslation().t
    const [sending, setSending] = useState(false)
    const [sentCount, setSentCount] = useState(0)
    const [failedCount, setFailedCount] = useState(0)
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [sendSMS, setSendSms] = useState(true)
    const [sendMail, setSendMail] = useState(true)

    useEffect(() => {
        if (props.open) {
            setSending(false)
            setSubject('')
            setMessage('')
            setSendSms(true)
            setSendMail(true)
            setSentCount(0)
            setFailedCount(0)
        }
    }, [props.open])

    const sendMessage = async () => {
        setSending(true)
        setSentCount(0)
        setFailedCount(0)
        let sent = 0;
        let failed = 0
        for (let i = 0; i < props.users.length; i++) {
            try {
                let user = props.users[i]
                const result = await AdminUserService.sendMessage(user, subject, message, sendSMS, sendMail)
                if (result.sent) {
                    sent += 1
                    setSentCount(sent)
                } else {
                    failed += 1
                    setFailedCount(failed)
                }
            } catch (e: any) {
                console.log(e)
                failed += 1
                setFailedCount(failed)
            }
        }
        setSending(false)
    }


    return (
        <Dialog aria-labelledby="customized-dialog-title" open={props.open} fullWidth>
            <DialogTitle id="customized-dialog-title">
                {t(`Send message t`, {size: props.users.length})}
            </DialogTitle>

            <DialogContent dividers>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <TextField
                        label={t('Subject (email only)')}
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        disabled={sending}
                        style={{marginBottom: 10}}
                    />

                    <TextField
                        label={t('Message')}
                        rows={8}
                        multiline
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        disabled={sending}
                        style={{marginBottom: 20}}
                    />

                    <FormControlLabel
                        control={<Switch
                            disabled={sending}
                            color={'primary'}
                            checked={sendSMS}
                            onChange={e => setSendSms(e.target.checked)}
                        />}
                        label={t('Send SMS')}
                    />
                    <FormControlLabel
                        control={<Switch
                            disabled={sending}
                            color={'primary'}
                            checked={sendMail}
                            onChange={e => setSendMail(e.target.checked)}
                        />}
                        label={t('Send Email')}
                    />

                    {sentCount > 0 && <div style={{marginTop: 15}}>{t('Sent emails', {count: sentCount})}</div>}
                    {failedCount > 0 &&
                    <div style={{marginTop: 15, color: 'red'}}>{t('Failed emails', {count: failedCount})}</div>}

                </Box>

            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t('Cancel')}
                </Button>

                {
                    sending ? <CircularProgress/> :
                        <Button
                            onClick={sendMessage} color="primary"
                            disabled={!message || (!sendMail && !sendSMS) || (sendMail && !subject)}
                        >
                            {t('Send')}
                        </Button>
                }

            </DialogActions>
        </Dialog>
    )
}

export default SendMessageDialog
