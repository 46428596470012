import React, {useEffect, useState} from 'react';
import FlexView from "react-flexview";
import {Button, CircularProgress} from "@material-ui/core";
import classes from './Billing.module.scss';
import InvoicePreview from "./InvoicePreview.component";
import {initLoadInvoices, initLoadStripeUser, initUpdateStripeCoupon} from "../../../store/actions/billing.actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import clsx from 'clsx';
import GcpInput from "../../../common/ui/GcpInput";
import DangerButton from "../../../common/ui/DangerButton.component";
import StripeInvoice, {StripeCustomer} from "../../../models/StripeInvoice.model";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useTranslation} from "react-i18next";

interface Props {
    claims?: AuthClaims,

    stripeUser: StripeCustomer,
    stripeUserError: string,
    stripeUserLoading: boolean

    invoices?: StripeInvoice[],
    invoicesError: string,
    invoicesLoading: boolean,

    stripeCoupon: any,
    stripeCouponLoading: boolean,
    stripeCouponError: string,

    initLoadInvoices: typeof initLoadInvoices,
    initLoadStripeUser: typeof initLoadStripeUser,
    initUpdateStripeCoupon: typeof initUpdateStripeCoupon
}

const Billing = (props: Props) => {

    const t = useTranslation().t
    const [showAllPayments, setShowAllPayments] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [couponCodeValidationError, setCouponCodeValidationError] = useState(false);

    useEffect(() => {
        if (props.claims?.user) {
            props.initLoadInvoices();
            props.initLoadStripeUser();
        }
    }, [false, props.claims]);

    const renderInvoices = () => {
        if (props.invoicesLoading) {
            return <CircularProgress/>;
        }

        if (props.invoices?.length) {
            let invoices = props.invoices;
            if (!showAllPayments) {
                invoices = invoices.slice(0, 3);
            }
            return invoices.map(invoice => <InvoicePreview invoice={invoice} key={invoice.id}/>)
        } else {
            return (
                <div>{t('No invoices')}</div>
            )
        }
    };

    const renderOutstandingBalance = () => {
        if (props.stripeUserLoading) {
            return <CircularProgress/>
        }

        if (props.stripeUserError) {
            return (
                <div>{t('We could not load you current balance')}</div>
            )
        }

        if (!props.stripeUser) return null;

        const stripeBalance = props.stripeUser.balance || 0;
        const stripeCurrency = props.stripeUser.currency || 'sek';
        let balanceText = ''
        let balanceValue = '0';
        if (stripeBalance < 0) {
            balanceText = t('Total credit');
            balanceValue = Math.abs(stripeBalance / 100).toFixed(2);
        } else if (stripeBalance > 0) {
            balanceText = t('Total outstanding balance');
            balanceValue = `-${(stripeBalance / 100).toFixed(2)}`;
        } else {
            balanceText = t('Total outstanding balance');
            balanceValue = '0';
        }

        return (
            <FlexView column className={classes.totalOutstandingContainer} hAlignContent='center'>

                <FlexView className={classes.totalOutstandingText} vAlignContent='center'>
                    <div>{balanceText}</div>
                    <div
                        className={clsx(classes.totalOutstandingAmount, stripeBalance > 0 && classes.totalOutstandingAmountRed)}
                    >
                        {balanceValue} {stripeCurrency}
                    </div>
                </FlexView>

            </FlexView>
        )
    };

    const renderCoupon = () => {
        if (props.stripeCouponLoading) {
            return <CircularProgress/>
        }

        if (!props.stripeUser) return null;

        if (props.stripeCoupon) {
            return (
                <FlexView className={classes.totalOutstandingText} column vAlignContent='center' hAlignContent='center'>

                    {props.stripeCouponError &&
                    <div className={classes.errorUpdateCoupon}>{t('We could not remove coupon')}</div>}

                    <FlexView className={classes.couponWrapper}>
                        <div>{props.stripeCoupon.name}</div>

                        {
                            props.stripeCoupon.percent_off &&
                            <div className={classes.couponValue}>
                                {props.stripeCoupon.percent_off}%
                            </div>
                        }

                        {
                            props.stripeCoupon.amount_off &&
                            <div className={classes.couponValue}>
                                {props.stripeCoupon.amount_off / 100} {props.stripeCoupon.currency}
                            </div>
                        }

                    </FlexView>
                    <DangerButton
                        variant="contained" color="primary"
                        className={classes.ctaButton}
                        onClick={() => props.initUpdateStripeCoupon(props.claims?.user, '')}
                    >
                        {t('Remove coupon')}
                    </DangerButton>
                </FlexView>
            )
        } else {
            return (
                <FlexView className={classes.totalOutstandingText} vAlignContent='center' column>

                    {props.stripeCouponError &&
                    <div className={classes.errorUpdateCoupon}>{t('We could not add coupon')}</div>}

                    <GcpInput
                        className={classes.couponInput}
                        value={couponCode}
                        error={couponCodeValidationError}
                        onChange={(event) => {
                            setCouponCode(event.target.value);
                            setCouponCodeValidationError(false);
                        }}
                        label={t('Coupon code')}
                    />
                    <Button
                        variant="contained" color="primary"
                        className={classes.ctaButton}
                        onClick={async () => {
                            if (!couponCode) {
                                setCouponCodeValidationError(true);
                                return;
                            }
                            await props.initUpdateStripeCoupon(props.stripeCoupon, couponCode);
                            setCouponCode('');
                        }}
                    >{t('Add coupon')}
                    </Button>
                </FlexView>
            )
        }

    };

    return (
        <React.Fragment>
            <a id='billing'/>

            <div className={classes.title}>{t('Coupon')}</div>

            <FlexView column className={clsx('container', classes.form)}>
                {renderCoupon()}
            </FlexView>

            <div className={classes.title}>{t('Invoices')}</div>

            <FlexView column className={clsx('container', classes.form)}>

                {renderOutstandingBalance()}

                {renderInvoices()}


                {
                    (props.invoices?.length || 0) > 3 && !showAllPayments &&
                    <Button variant="contained" color="primary" className={classes.ctaButton}
                            onClick={() => setShowAllPayments(true)}>
                        {t('Show all invoices')}
                    </Button>
                }
            </FlexView>

        </React.Fragment>
    )
};

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,

        invoices: state.billing.invoices,
        unpaidInvoices: state.billing.unpaidInvoices,
        invoicesError: state.billing.invoicesError,
        invoicesLoading: state.billing.invoicesLoading,

        stripeUser: state.billing.stripeUser,
        stripeUserError: state.billing.stripeUserError,
        stripeUserLoading: state.billing.stripeUserLoading,

        stripeCoupon: state.billing.stripeCoupon,
        stripeCouponLoading: state.billing.stripeCouponLoading,
        stripeCouponError: state.billing.stripeCouponError,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        initLoadInvoices,
        initLoadStripeUser,
        initUpdateStripeCoupon
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
