import Smartcar from '@smartcar/auth';
import api from './axios';

export default class SmartcarService {

    static generateToken = async (vin: string): Promise<any> => {
        console.log("Generate token for VIN", vin)
        return new Promise((resolve, reject) => {
            const smartcar = new Smartcar({
                clientId: process.env.REACT_APP_CLIENT_ID,
                redirectUri: process.env.REACT_APP_REDIRECT_URI,
                scope: [
                    'read_battery',
                    'read_charge',
                    'read_fuel',
                    'read_location',
                    'control_security',
                    'read_odometer',
                    // 'required:read_engine_oil',
                    // 'required:control_charge',
                    // 'required:read_tires',
                    // 'required:read_vehicle_info',
                    // 'required:read_vin',
                ],
                singleSelect: {vin},
                testMode: process.env.REACT_APP_TEST_MODE === 'true',
                flags: ['country:SE'],
                onComplete: (async (err: any, code: any, status: any) => {
                    console.log(err, code, status)
                    if (err) {
                        reject(err)
                    } else {
                        const accessToken = await api.get(`/smartcar/exchange?authCode=${code}`)
                        resolve(accessToken.data)
                    }
                })
            });

            smartcar.openDialog({forcePrompt: true})
        })
    }

}