import React, {useEffect, useState} from "react";
import FlexView from "react-flexview";
import classes from './SelectPackage.module.scss';
import Footer from "../../../common/ui/Footer/Footer.component";
import {RouteComponentProps} from "react-router";
import {CircularProgress} from "@material-ui/core";
import querystring from "query-string";
import Confirm from '../../../assets/icons/confirm-circle-green.svg';
import {setAuthorizationDone} from "../../../store/actions/auth.actions";
import GcpError from "../../../models/error.enum";
import AuthServiceNew from "../../../services/auth.service";
import {useTranslation} from "react-i18next";
import {trackCurrentPage} from "../../../services/googleAnalytics";

interface Props extends RouteComponentProps {
}

const VerifyEmail = (props: Props) => {

    // ***** State ***** //

    const t = useTranslation().t
    const [verifying, setVerifying] = useState(true);
    const code = querystring.parse(props.location.search)?.code as string;
    const redirectTo = querystring.parse(props.location.search)?.redirectTo;

    // ***** Handlers ***** //

    useEffect(() => {
        trackCurrentPage()
    }, [])

    const verifyEmail = async () => {
        try {
            const claims = await AuthServiceNew.verifyEmail(code!);
            if (claims) {
                setAuthorizationDone(claims, GcpError.NONE);
                setVerifying(false);
                setTimeout(() => {
                    props.history.push((redirectTo as string) || '/');
                }, 2000);
            } else {
                props.history.push(process.env.REACT_APP_WEBSITE_URL || '');
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (!code) {
            props.history.push(process.env.REACT_APP_WEBSITE_URL || '');
        }

        setVerifying(true);
        verifyEmail();
    }, []);

    // ***** Render ***** //

    return (

        <FlexView className={classes.component} column hAlignContent='center'>

            {
                verifying ?
                    <FlexView className={classes.content} column hAlignContent='center'>
                        <CircularProgress/>
                        <div className={classes.title}>{t('Verifying in progress')}</div>
                        <div>{code}</div>
                        <div>{redirectTo}</div>
                    </FlexView>
                    :
                    <FlexView className={classes.content} column hAlignContent='center'>
                        <img className={classes.verifiedIcon} src={Confirm}/>
                        <div className={classes.title}>{t('Verified successfully')}</div>
                        <div
                            className={classes.subtitle}>{t('You will be redirected in few seconds')}</div>
                    </FlexView>
            }

            <div style={{flex: 1}}/>
            <Footer/>

        </FlexView>
    )
}

// ***** Redux ***** //

// const mapStateToProps = (state: any) => {
//     return {}
// };
//
// export default connect(mapStateToProps)(VerifyEmail);

export default VerifyEmail;