import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classes from './HeaderMobileActionsDialog.module.scss';
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    onClose: () => void,
    onConfirm: (action: string) => void,
    canAddMoreTime: boolean,
}

const HeaderMobileActionsDialog = (props: Props) => {

    const t = useTranslation().t
    
    return (
        <Dialog aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={'sm'}
                onBackdropClick={props.onClose}
        >

            <DialogTitle id="customized-dialog-title">
                {t('More')}
            </DialogTitle>

            <DialogContent dividers className={classes.container}>

                {
                    props.canAddMoreTime &&
                    <Button onClick={() => props.onConfirm('add_time')} color="primary" variant='contained'
                            className={classes.action}>
                        {t('Add time', {minutes: 30})}
                    </Button>
                }

                <Button onClick={() => props.onConfirm('report_damage')} color="primary" variant='contained' className={classes.action}>
                    {t('Report damage', {minutes: 60})}
                </Button>

            </DialogContent>

            <DialogActions className={classes.container}>
                <Button onClick={props.onClose} color="primary">
                    {t('close')}
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default HeaderMobileActionsDialog;
