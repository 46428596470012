import React, {useEffect, useState} from 'react';
import {Container} from "@material-ui/core";
import {Page} from "../../../models/Page";
import {Organization} from "../../../models/Organization.model";
import {useSelector} from "react-redux";
import AdminOrganizationService from "../../../services/adminOrganization.service";
import {AuthClaims} from "../../../models/AuthClaims.model";
import {useHistory} from 'react-router-dom';
import GcpList from "../../../common/ui/List/List";
import {useTranslation} from "react-i18next";


const AdminOrganizationsList = () => {

    //region State

    const [organizations, setOrganizations] = useState<Page<Organization>>();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('')
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const claims = useSelector<any, AuthClaims>(state => state.auth.claims);
    const history = useHistory();
    const t = useTranslation().t

    //endregion

    //region Handlers

    const fetch = async () => {
        try {
            setLoading(true)
            const organizations = await AdminOrganizationService.getAll(page, size, search);
            setOrganizations(organizations);
        } catch (e: any) {
            console.log(e);
            setOrganizations(undefined);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetch();
    }, [claims, search, page, size]);

    const open = async (organization: Organization) => {
        history.push(`/admin/organization/${organization.id}`);
    };

    //endregion Handlers

    //region UI

    return (
        <Container>

            <GcpList
                items={organizations?.content}
                columns={[
                    {id: 'name', name: t('Name')},
                    {id: 'Type', name: t('Type')},
                ]}
                renderCell={((organization, column) => {
                    switch (column.id) {
                        case 'name':
                            return <div>{organization.name}</div>
                        case 'type':
                            return <div>Company</div>
                        default:
                            return null
                    }
                })}
                size={size}
                onSizeChange={setSize}
                page={page + 1}
                onPageChange={page => setPage(page - 1)}
                totalPages={organizations?.totalPages}
                totalElements={organizations?.totalElement}
                onItemClick={open}
                loading={loading}
                onAdd={() => history.push("/admin/organization/new")}
                placeholder={t('Search by organization name')}
                onSearch={setSearch}
            />

        </Container>
    )

    //endregion UI
}

export default AdminOrganizationsList;