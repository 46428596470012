import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Container} from "@material-ui/core";
import FlexView from "react-flexview/lib/FlexView";
import classes from '../../AdminListCommon.module.scss';
import clsx from 'clsx';
import {RouteComponentProps, useLocation} from "react-router";
import {format} from "date-fns";
import DamageReportDialog from "../../../booking/DamageReportDialog/DamageReportDialog.component";
import {connect} from "react-redux";
import * as queryString from 'query-string';
import {DamageReport, DamageReportType} from '../../../../models/DamageReport.model';
import {AuthClaims} from "../../../../models/AuthClaims.model";
import AdminDamageReportService from "../../../../services/adminDamageReport.service";
import {vehicleDescriptor} from "../../../../common/vehicle.util";
import {Page} from "../../../../models/Page";
import Pagination from "../../../../common/ui/Pagination/Pagination.component";
import Search from "../../../../common/ui/Search/Search";
import DamageReportTypePicker from "../../../booking/DamageReportDialog/DamageReportTypePicker.component";
import GcpButton from "../../../../common/ui/GcpButton";
import {useTranslation} from "react-i18next";

interface Props extends RouteComponentProps {
    claims?: AuthClaims,
}

const AdminDamageReportsList = (props: Props) => {

    const t = useTranslation().t
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState<Page<DamageReport>>();
    const [currentReport, setCurrentReport] = useState<DamageReport>();
    const [damageReportOpen, setDamageReportOpen] = useState(false);
    const [damageReportNotifyProcessed, setDamageReportNotifyProcessed] = useState(false);
    const [searchQuery, setSearchQuery] = useState('')
    const [damageReportType, setDamageReportType] = useState<DamageReportType | undefined>();

    const {search} = useLocation();
    const queryParams = queryString.parse(search);

    // const [vehicleFilter, setVehicleFilter] = useState<number>();
    // const [organizationFilter, setOrganizationFilter] = useState<number>();

    useEffect(() => {
        fetchReports(0);
    }, [props.claims, searchQuery, damageReportType]);

    useEffect(() => {
        if (reports?.content?.length) {
            const id = queryParams['id'];
            if (id && !damageReportNotifyProcessed) {
                const report = reports.content.find(r => r.id === +id);
                setCurrentReport(report);
                setDamageReportOpen(!!report);
                setDamageReportNotifyProcessed(true);
            }
        }
    }, [reports]);

    const fetchReports = async (page: number) => {
        if (!props.claims?.user) return;

        setLoading(true);

        const reports = await AdminDamageReportService.getAll(
            page,
            10,
            undefined,
            undefined,
            searchQuery,
            damageReportType
        );

        setReports(reports);
        setLoading(false);
    };

    const openReport = (report: DamageReport) => {
        setCurrentReport(report);
        setDamageReportOpen(true);
    };

    const renderList = () => {
        if (loading) {
            return <CircularProgress/>;
        }

        if (reports?.content?.length) {
            return (
                <FlexView column className={clsx('container', classes.list)}>

                    <FlexView vAlignContent='center' className={classes.header}>
                        <div className={clsx(classes.cellLarge, classes.headerCell)}>{t('Vehicle')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('User')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Booking')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Date')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Organization')}</div>
                        <div className={clsx(classes.cellSmall, classes.headerCell)}>{t('Category')}</div>
                        <div className={clsx(classes.cellGrow, classes.headerCell)}>{t('Message')}</div>
                    </FlexView>

                    {
                        reports.content.map(report => {
                            return (
                                <FlexView onClick={() => openReport(report)} key={report.id} vAlignContent='center'
                                          className={classes.tableRow}>
                                    <div className={classes.cellLarge}>{vehicleDescriptor(report.vehicle)}</div>
                                    <div className={classes.cellSmall}>{report.user?.name}</div>
                                    <div className={classes.cellSmall}>{report.booking?.id}</div>
                                    <div className={classes.cellSmall}>
                                        {report.createdAt && format(report.createdAt, 'dd/MM/yyyy HH:mm')}
                                    </div>
                                    <div className={classes.cellSmall}>{report?.organization?.name || '-'}</div>
                                    <div className={classes.cellSmall}>{report?.damageType || '-'}</div>
                                    <div className={classes.cellSmall}>{report.message}</div>
                                </FlexView>
                            )
                        })
                    }
                </FlexView>
            );
        } else {
            return <div>No damage reports in the system</div>
        }
    };

    return (
        <Container>

            <Box display={'flex'}>

                {/*<FlexView>*/}
                {/*    <VehicleFilter*/}
                {/*        id={vehicleFilter}*/}
                {/*        setId={setVehicleFilter}*/}
                {/*    />*/}

                {/*    {*/}
                {/*        props.claims?.isSuperAdmin &&*/}
                {/*        <OrganizationFilter*/}
                {/*            id={organizationFilter}*/}
                {/*            setId={setOrganizationFilter}*/}
                {/*        />*/}
                {/*    }*/}

                {/*</FlexView>*/}

                <Search
                    placeholder={t('Search damage report')}
                    onSearch={setSearchQuery}
                />

            </Box>

            <Box m={1}/>

            <Box display={'flex'} alignItems={'center'}>
                <DamageReportTypePicker damageReportType={damageReportType} setDamageReportType={setDamageReportType}/>

                <GcpButton
                    variant={'contained'}
                    color={"error"}
                    style={{
                        marginLeft: 100,
                        height: 'fit-content'
                    }}
                    onClick={() => setDamageReportType(undefined)}
                >
                    {t('Clear filter')}
                </GcpButton>

            </Box>

            <Box m={1}/>

            {renderList()}

            <Pagination
                pages={reports?.totalPages || 0}
                currentPage={reports?.page || 0}
                onPageChange={fetchReports}
            />

            <DamageReportDialog
                open={damageReportOpen}
                onClose={() => setDamageReportOpen(false)}
                previewMode
                report={currentReport}
            />


        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        claims: state.auth.claims,
    }
};

export default connect(mapStateToProps)(AdminDamageReportsList);
